import { Modal } from 'antd';
import React, { useState } from 'react';
import CalendarTab from '../../atoms/upper-navbar/CalendarTab';
import ProfileTab from '../../atoms/upper-navbar/ProfileTab';
import GeneralTab from '../../atoms/upper-navbar/GeneralTab';

const UserPreferences = (props) => {
  const [activeTab, setActiveTab] = useState('profile');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Modal
      title={props.title}
      bodyStyle={{
        backgroundColor: '#FFFFFF',
        display: 'flex',
      }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      className="new-project-Modal-div"
      centered
      closable={props.closable}
      open={props.open}
      onCancel={props.onCancel}
      width={props.width}
      height={props.height}
      footer={props.footer}
    >
      <div className="modal">
        <div className="sidebar">
          <button
            className={activeTab === 'profile' ? 'tab-button active' : 'tab-button'}
            onClick={() => handleTabClick('profile')}
          >
            Profile
          </button>
          <button
            className={activeTab === 'calendar' ? 'tab-button active' : 'tab-button'}
            onClick={() => handleTabClick('calendar')}
          >
            Calendar
          </button>
          <button
            className={activeTab === 'general' ? 'tab-button active' : 'tab-button'}
            onClick={() => handleTabClick('general')}
          >
            General
          </button>
        </div>
        <div className="main-view">
          {activeTab === 'profile' && <ProfileTab user={props.user} timezone={props.timezone} />}
          {activeTab === 'calendar' && <CalendarTab user={props.user} />}
          {activeTab === 'general' && <GeneralTab user={props.user} />}
        </div>
      </div>
    </Modal>
  );
};

export default UserPreferences;
