import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ResetPasswordSuccess = (props) => {
  const navigate = useNavigate();
  const [count, setCount] = useState(8);

  if (count > 0) {
    setInterval(() => {
      setCount(count - 1);
    }, 1000);
  } else {
    navigate('/signin', { replace: true })
  }

  return (
    <div className="form-div">
      <h1 className="text-darkGray font-40 text-center mb-2"> Your password has been successfully reset</h1>
      <p className="py-2 text-sm">
        Redirecting in {count}
      </p>
      <button
        onClick={() => navigate("/signin", { replace: true })}
        type="submit"
        className={"button-tag font-20 font-weight-500 text-white"}
      >
        Continue
      </button>
    </div>
  );
};

export default ResetPasswordSuccess;
