import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Collapse } from 'antd';
import Icon from '../UI/atoms/global/Icon';
import Pricing from './Pricing';

import ProductNavBar from '../UI/molecules/upper-navbar/ProductNavBar'
import { APPLICATION_NAME } from '../../js/constants';

const Product = () => {

  const navigate = useNavigate();
  const { Panel } = Collapse;
  const [selectedKey, setSelectedKey] = useState('1');

  const getSrc = () => {
    if (selectedKey === '1') {
      return '/images/icons/collaboration.png';
    } else if (selectedKey === '2') {
      return '/images/icons/custom-fields.png';
    } else if (selectedKey === '3') {
      return '/images/icons/features.png';
    }
    return ''
  }

  document.title = `Product Overview - ${APPLICATION_NAME}`;

  return (
    <div className='product-container'>
      <ProductNavBar showPricing />
      <div className='product-sections'>

        <div className='product-main-section' style={{ textAlign: 'left' }}>

          <div className='mr-20'>
            <h1 className='font-40'>Scale the way <span className='font-36-500'>YOU</span> work</h1>
            <h3 className='font-20' style={{ width: '30vw' }}> . . . by having a single platform to manage tasks, chats, teams and projects with Workflo</h3>
            <div className='d-flex align-center mt-5'>
              <div className='mr-20 product-button-primary' onClick={() => navigate('/signup')}>Try now</div>
              <div className='product-button-secondary'>Request Demo</div>
            </div>
          </div>

          <div>
            <img src='https://workflo.com/wp-content/uploads/2023/07/Full-Task-min.svg' style={{ objectFit: 'contain', width: 760 }} />
          </div>
        </div>

        <div className='product-blue-section-top'>
          <div className='font-40'>Architected by professionals for professionals</div>
        </div>

        <div className='product-blue-section'>
          <div className='font-20 mb-30'>Optimize how you work, by having a single platform to manage tasks and communicate across projects with Workflo</div>
          <div className='d-flex align-center' style={{ justifyContent: 'space-around', margin: '100px 0' }}>
            <div>
              <div className='font-36-600'>Collaboration</div>
            </div>
            <div>
              <img src=' /images/icons/collaboration.png' style={{ objectFit: 'contain', width: 760 }} />
            </div>
          </div>
          <div className='d-flex align-center mb-30' style={{ justifyContent: 'space-around' }}>
            <div>
              <img src='https://workflo.com/wp-content/uploads/2023/07/Full-Task-min.svg' style={{ objectFit: 'contain', width: 760 }} />
            </div>
            <div>
              <div className='font-36-600'>Task Management</div>
            </div>
          </div>
          <div className='font-20'>Don't lose productivity switching tabs!</div>
        </div>

        <div className='product-light-blue-section'>
          <div className='font-40'>Save per user, per month!</div>
          <div className='font-24-500 mt-5'>Many companies use:</div>
          <div className='d-flex align-center' style={{ justifyContent: 'center' }}>
            <img src="/images/icons/teams.png" style={{ objectFit: 'contain', width: 140 }} />
            <img className='mr-2' src="/images/icons/add.svg" style={{ objectFit: 'contain', width: 30 }} />
            <img className='mr-2' src="/images/icons/slack.png" style={{ objectFit: 'contain', width: 60 }} />
            <img className='mr-2' src="/images/icons/add.svg" style={{ objectFit: 'contain', width: 30 }} />
            <img className='ml-5' src="/images/icons/jira.png" style={{ objectFit: 'contain', width: 70 }} />
            <div className='font-40 ml-18'> = </div>
            <div className='font-40 ml-18'>~$40 Per Month </div>
          </div>
          <div>
            <div className='font-36-600' style={{ margin: 10 }}>vs</div>
            <Icon
              name='logo.svg'
              height='26px' />
          </div>
          <Pricing hideHeader />
        </div>

        <div className='product-blue-section'>
          <div className='font-40'>Wow sooo cool!</div>
        </div>

        <div className='product-light-blue-section'>
          <div className='font-40'>Customized for you!</div>
          <div className='d-flex align-center mb-30' style={{ justifyContent: 'space-around', marginTop: 50 }}>
            <div>
              <img src='/images/icons/custom-fields.png' style={{ objectFit: 'contain', width: 330 }} />
              <div className='font-20'>Custom Fields</div>
            </div>
            <div>
              <img src='/images/icons/custom-fields.png' style={{ objectFit: 'contain', width: 330 }} />
              <div className='font-20'>Custom Automations</div>
            </div>
            <div>
              <img src='/images/icons/custom-fields.png' style={{ objectFit: 'contain', width: 330 }} />
              <div className='font-20'>Custom Templates</div>
            </div>
          </div>

        </div>

        <div className='product-light-blue-section'>
          <div className='font-20'>Want more? Contact us</div>
        </div>

        <div className='product-blue-section'>
        <div className='font-40'>Frequently asked questions:</div>
          <Collapse accordion defaultActiveKey={['1']} style={{ marginTop: 50, fontSize: 30 }}>
            <Panel header="Why Workflo?" key="4">
              <p className='font-20'>An application designed to help teams organize and manage their work efficiently. It serves as a collaborative platform for task management and in house communication, enabling users to create, assign, and track tasks, set deadlines, and communicate within a shared workspace. </p>
            </Panel>
            <Panel header="What makes Workflo stand out from the competition?" key="1">
              <p className='font-20'>Our software is purposefully designed with task management and in-app messaging as foundational elements. In contrast, our competitors may not have incorporated these key features from the outset, giving us a distinct advantage in delivering a comprehensive and seamlessly integrated solution</p>
            </Panel>
            <Panel header="Is there a mobile app?" key="2">
              <p className='font-20'>
                Developing a mobile application is part of our strategic roadmap; however, in the interim, our website has been meticulously optimized to ensure full mobile compatibility.</p>
            </Panel>
            <Panel header="Can we request new features?" key="3">
              <p className='font-20'>Yes! We are always egar to hear from you. Please register all feedbacks here: https://workflo.canny.io/bugs-and-feature-requests</p>
            </Panel>
          </Collapse>

        </div>

        <div className='product-blue-section'>
          <div className='font-20'></div>
        </div>
      </div>
    </div>
  );
}

export default Product;
