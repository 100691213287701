import React, { useState } from 'react';
import { getDateText } from '../../../../js/functions';

const ActivityViewer = ({ chat, time }) => {
  const truncatedContent = chat?.newValue ? (
    chat?.oldValue ? (
      `from ${chat.field === 'dueDate' ? getDateText(chat?.oldValue) : chat?.oldValue} to ${chat.field === 'dueDate' ? getDateText(chat?.newValue) : chat?.newValue}`
    ) : (
      chat?.field === 'isCompleted' || chat?.field === 'files' ?
        chat.newValue :
        `to ${chat.field === 'assignee' ? 'Assignee' : chat.field === 'dueDate' ? getDateText(chat?.newValue) : chat?.newValue}`
    )
  ) : '';

  return (
    // <div className='w-full'>
    //   <div
    //     className='d-flex align-center'
    //     style={{ justifyContent: 'space-between' }}
    //   >
    //     <div className={'font-12'} style={{ color: '#6d6e6f', display: 'flex', alignItems: 'center' }}>
    //       <div style={{ flex: 1 }}>
    //         <span style={{ fontFamily: 'Lato-Bold' }}>{chat?.actor?.fullName}</span>
    //         <span>{chat?.action}</span>
    //         <span>{contentToDisplay}</span>
    //         {truncatedContent.length > characterLimit && (
    //           <button
    //             onClick={() => setShowFullContent(!showFullContent)}
    //             style={{ border: 'none', background: 'none', color: 'blue', cursor: 'pointer' }}
    //           >
    //             {showFullContent ? 'Show less' : 'Show more'}
    //           </button>
    //         )}
    //       </div>
    //       <span style={{ color: '#ADADAD' }}>{"  ·  " + time}</span>
    //     </div>
    //   </div>
    // </div>
    <li className="relative pb-4 last-of-type:pb-0 flex align-center justify-between w-full">
      <div className="pl-6 w-80">
        <div className="text-sm font-medium uppercase text-indigo-600 mb-0.5">
          <span style={{ fontFamily: 'Lato-Bold' }}>{chat?.actor?.fullName}</span>{chat?.action}
        </div>
        <div className="text-sm mb-2 font-medium text-slate-800 hover:text-slate-900">
          {truncatedContent}
        </div>
      </div>
      <div>
        <span style={{ color: '#ADADAD' }}>{time}</span>
      </div>
      {/* Timeline element */}
      <div className="absolute top-0.5 -bottom-1 left-0.5 ml-px w-0.5 bg-slate-200" />
      <div className="absolute top-0.5 left-0 -ml-0.5 w-3 h-3 rounded-full bg-slate-400 border-2 border-slate-50" />
    </li>
  );
};

export default ActivityViewer;
