const data = {
  getWorkspaces: [],
  pendingWorkspaceInvites: [],
  currentWorkspace: {},
  workspaceProjects: [],
  search: {},
  workspaceFilter: null,
  workspaceCustomFields: [],
  workspaceDashboard: {}
};

const workspaceReducer = (state = data, action) => {
  switch (action.type) {
    case "workspace-success":
      sessionStorage.setItem("workspaceId", action.payload?._id);
      localStorage.setItem("workspaceId", action.payload?._id);
      sessionStorage.setItem("organization", action.payload?.organization);
      localStorage.setItem("organization", action.payload?.organization);

      return {
        ...state,
        success: action.payload,
        // currentWorkspace: action.payload
      };

    case "workspace-error":
      return {
        ...state,
        error: action.payload,
      };

    case "add-project":
      return {
        ...state,
        workspaceProjects: [...state.workspaceProjects, action.payload],
      };

    case "get-workspaces":
      const workspaces = action.payload || [];
      if (!sessionStorage.getItem("workspaceId")) {
        sessionStorage.setItem("workspaceId", localStorage.getItem("workspaceId"));
      }
      if (!sessionStorage.getItem("organization")) {
        sessionStorage.setItem("organization", localStorage.getItem("organization"));
      }
      const id = sessionStorage.getItem("workspaceId") || localStorage.getItem("workspaceId");
      const workspace = workspaces.find(workspace => workspace._id === id);
      return {
        ...state,
        getWorkspaces: workspaces,
        // currentWorkspace: workspace || workspaces?.[0] || {}
      };
    case "get-workspace-invites":
      return {
        ...state,
        pendingWorkspaceInvites: action.payload
      };

    case "set-workspace-fields":
      return {
        ...state,
        workspaceCustomFields: action.payload,
      }
    case "add-workspace-field":
      return {
        ...state,
        workspaceCustomFields: [...state.workspaceCustomFields, action.payload],
      }
    case "delete-workspace-field":
      const x = state?.workspaceCustomFields?.filter(field => field?._id !== action.payload);

      return {
        ...state,
        workspaceCustomFields: x,
      }

    case "set-current-workspace":
      return {
        ...state,
        currentWorkspace: action.payload,
      };

    case "workspace-delete":
      const newState = state.getWorkspaces.filter(workspace => workspace._id !== action.payload)
      return {
        ...state,
        getWorkspaces: newState,
        currentWorkspace: newState[0] || {}
      };
    case "workspace-remove-invite":
      const newInvites = state.pendingWorkspaceInvites.filter(invite => invite._id !== action.payload)
      return {
        ...state,
        pendingWorkspaceInvites: newInvites,
      };

    case "workspace-projects":
      return {
        ...state,
        workspaceProjects: action.payload,
      };

    case "search-workspace":
      return {
        ...state,
        search: action.payload,
      };

    case "workspaceFilter":
      return {
        ...state,
        workspaceFilter: action.payload
      }

    case "remove-workspace-user":
      const updatedMembers = state.currentWorkspace?.members?.filter(member => member._id !== action.payload);
      return {
        ...state,
        currentWorkspace: { ...state.currentWorkspace, members: updatedMembers }
      }

    case "workspaceDashboard": 
      return {
        ...state,
        workspaceDashboard: action.payload
      }

    default:
      return state;
  }
};
export default workspaceReducer;
