const showToggle = localStorage.getItem('toggle') !== undefined && localStorage.getItem('toggle') === 'true';

const data = {
  toggle: showToggle ? false : true,
};

const navReducer = (state = data, action) => {
  
  switch (action.type) {

    case "collapse":
      localStorage.setItem('toggle', !action.payload)
      return {
        ...state,
        toggle: action.payload,
      };

    default:
      return state;
  }
};
export default navReducer;
