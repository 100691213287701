import React, { useState, useRef } from 'react';
import Icon from '../../atoms/global/Icon';
import { Modal } from 'antd';
import EmojiPicker from 'emoji-picker-react';
import socket from '../../../../js/socket';
import { Image } from 'antd';
import { useDispatch } from 'react-redux';
import { editTask, uploadTaskUrl, signedUploadUrl } from '../../../../actions';
import TextAreaFileHolder from '../../organisms/TextAreaFileHolder';
import { useSelector } from 'react-redux';

import { FileDrop } from 'react-file-drop';
import TextEditor from '../editor/TextEditor';
const TaskChatArea = (props) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [selectedEmoji, setSelectedEmoji] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasting, setIsPasting] = useState(false);

  const [isDragNdropVisible, setIsDragNdropVisible] = useState(false);
  const { user, users } = useSelector((state) => state.userReducer);

  const mentionOptions = users.map(user => ({
    id: user._id,
    value: user.fullName,
    icon: user.avi
  }));

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleEmoji = (emojiData) => {
    let sym = emojiData.unified.split('-');
    let codesArray = [];
    sym.forEach((el) => codesArray.push('0x' + el));
    let emoji = String.fromCodePoint(...codesArray);
    setSelectedEmoji(emojiData.unified);
    setComment(comment + emoji);
  };

  const handleSubmit = () => {
    const fileIds = file.map(fileObj => ({ _id: fileObj.fileId }));

    let messageDescription = comment?.trim();
    const extraP = messageDescription.slice(-11);
    const extraBlock = messageDescription.slice(-29);
    if (extraP == '<p><br></p>') {
      messageDescription = messageDescription.substring(0, messageDescription.length - 11);
    } 
    if (extraBlock == '<blockquote><br></blockquote>') {
      messageDescription = messageDescription.substring(0, messageDescription.length - 29);
    }

    const msg = {
      sender: { _id: localStorage.getItem('Id') },
      messageDescription: messageDescription,
      files: fileIds,
      roomId: props.roomId?._id,
    };
    socket.emit('message', msg);
    setFile([]);
    setComment('');
  };

  const handlePaste = async (e) => {
    setIsPasting(true);

    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    if (pastedText) {
      setComment(comment + pastedText);
    }
    const pastedItems = e?.clipboardData?.items || [];
    const imageOrFiles = [];

    for (let item of pastedItems) {
      const isImageOrFile = item?.type?.indexOf("image") || item?.kind === "file";
      if (isImageOrFile && item.getAsFile()) {
        imageOrFiles.push(item.getAsFile())
      }
    }
    imageOrFiles?.length > 0 && changeHandler({
      target: {
        files: imageOrFiles
      }
    })
  };

  const handleKeyUp = (e) => {

    if (e?.target?.value?.trim() === '') {
      return;
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      setComment('');
      handleSubmit();
      return;
    }
  };

  const changeHandler = async (e, isDropped = false) => {
    try {
      let fileList = [];
      let getFile = isDropped ? e : e.target.files;

      for (const file of getFile) {
        setIsLoading(true);
        const { signedUrl, fileId } = await dispatch(
          uploadTaskUrl({
            fileName: file.name,
            taskId: props.taskId,
            type: file?.type,
            fileSize: file?.size
          })
        );
        setIsLoading(false);

        if (signedUrl) {
          await dispatch(signedUploadUrl(signedUrl, file));
        }

        fileList.push({
          fileId,
          file
        });
      }

      setFile(fileList);
    } catch (error) {
      console.error(error);
    }
    document?.getElementById('file')?.remove();
  };

  const onFileDrop = async (files, e) => {
    setIsDragNdropVisible(false);
    document
      .querySelector('.onResize-ticket-drawer')
      ?.classList.remove('disable-pointer-events-div');
    try {
      changeHandler(files, true);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteFile = (fileId) => {
    props.delAttachment(fileId);
    const updatedFiles = file.filter((f) => f?.fileId !== fileId);
    setFile(updatedFiles);
  }

  return (
    <div
      className='chat-area m-0'
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          color: 'black',
          borderRadius: '5px',
          position: 'absolute',
          left: 0,
          top: 0
        }}
        className={`${isDragNdropVisible ? '' : 'd-none'}`}
      >
        <FileDrop
          onDragLeave={() => {
            setIsDragNdropVisible(false);
          }}
          frame={document}
          onDrop={onFileDrop}
          onFrameDragEnter={() => {
            setIsDragNdropVisible(true);
            document
              .querySelector('.onResize-ticket-drawer')
              ?.classList.add('disable-pointer-events-div');
          }}
          onFrameDragLeave={() => {
            setIsDragNdropVisible(false);
            document
              .querySelector('.onResize-ticket-drawer')
              ?.classList.remove('disable-pointer-events-div');
          }}
          onFrameDrop={() => {
            setIsDragNdropVisible(false);
            document
              .querySelector('.onResize-ticket-drawer')
              ?.classList.remove('disable-pointer-events-div');
          }}
        >
          Add to comments
        </FileDrop>
      </div>
      <TextEditor
        placeholder="Ask a question or post an update..."
        onChange={(content) => {
          setComment(content)
        }}
        onKeyUp={(e) => handleKeyUp(e)}
        comment={comment}
        mentionOptions={mentionOptions}
      />
      <div className='d-flex mb-2'>
        <TextAreaFileHolder fileImages={file} delAttachment={deleteFile} isLoading={isLoading} />
      </div>
      <div className='d-flex justify-content-between align-center'>
        <div className='d-flex align-center'>
          <Icon
            name='blueAdd.svg'
            height='19px'
            pr='13px'
            onClick={() => {
              let input = document.createElement('INPUT');
              input.style.display = 'none';
              input.setAttribute('type', 'file');
              input.setAttribute('id', 'file');
              document.body.appendChild(input);
              input.click();
              input.addEventListener('change', changeHandler);
            }}
          />
          <input
            style={{ display: 'none' }}
            id='file-input1'
            type='file'
            multiple
            onChange={changeHandler}
          />
          <div className='small-vertical-line' />
          {/* <Icon name='video.svg' height='13px' pr='13px' />
          <Icon name='mice.svg' height='12px' pr='13px' />
          <div className='small-vertical-line' /> */}
          <Icon name='smiley.svg' onClick={showModal} height='13px' pr='13px' />
          <Modal
            className={`${props.fullScreen
              ? 'fullScreenTaskChatAreaEmojiModal'
              : 'taskChatAreaEmojiModal'
              }`}
            style={{ marginRight: `${props.emojiPosition + -500}px` }}
            title='Basic Modal'
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <EmojiPicker onEmojiClick={handleEmoji} autoFocusSearch={false} />
          </Modal>
          <Icon name='at-the-rate.svg' height='13px' pr='13px' />
          <Icon name='Aa.svg' height='12px' />
        </div>
        <div onClick={handleSubmit}>
          <Icon name='send.svg' height='13px' />
        </div>
      </div>
    </div>
  );
};

export default TaskChatArea;
