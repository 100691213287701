const initialState = {
  messages: {},
  totalMessages: 0,
  scrollPosition: 0,
  error: null,
  singleMessage: {},
  activities: [],
  query: { page: 1 },
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'getChatMessages': {
      const { roomId, messages, currentPage } = action.payload;
      if(currentPage === 1) {
        return {
          ...state,
          messages: {
            ...state.messages,
            [roomId]: [...messages],
          },
          totalMessages: action?.payload?.totalMessages,
          activities: action?.payload?.activities,
          error: null,
        };  
      }
      return {
        ...state,
        messages: {
          ...state.messages,
          [roomId]: state?.messages?.[roomId]
            ? [...messages, ...state?.messages?.[roomId]]
            : [...messages],
        },
        totalMessages: action?.payload?.totalMessages,
        error: null,
      };
    }
    case 'getChatMessagesFailure':
      return {
        ...state,
        messages: [],
        error: action.payload,
      };

    case 'getSingleMessagesFailure':
      return {
        ...state,
        singleMessage: {}
      }

    case 'getSingleMessage': {
      return {
        ...state,
        singleMessage: action.payload
      }
    }

    case 'putMessage': {
      const { roomId, msgId } = action.payload;
      return {
        ...state,
        singleMessage:
          state.messages[roomId] &&
          state.messages[roomId].find((msg) => msg && msg._id === msgId),
      };
    }

    case 'editMessage': {
      const { roomId, msgId, messageDescription } = action.payload;

      const messageIndex = state?.messages[roomId]?.findIndex(
        (message) => message?._id === msgId
      );

      const updatedSM = {...state?.singleMessage};
      if(state?.singleMessage?._id?.toString() === msgId?.toString()) {
        updatedSM.messageDescription = messageDescription;
      }
     return {
       ...state,
       messages: {
         ...state?.messages,
         [roomId]: [
           ...state?.messages[roomId]?.slice(0, messageIndex),
           {
             ...state?.messages[roomId][messageIndex],
             messageDescription
           },
           ...state?.messages[roomId]?.slice(messageIndex + 1),
         ],
       },
       singleMessage: updatedSM
     };
    }

    case 'UPDATE_SINGLE_MESSAGE':
      return {
        ...state,
        singleMessage: action.payload,
      };

    case 'addChatMessage': {
      const { chatRoomId, message } = action.payload;
    
      let messagesCopy = [...state.messages[chatRoomId]];
      const draft = messagesCopy?.find(m => m?.isDraft);
      if(draft?._id?.toString() === message?._id?.toString()) {
        messagesCopy = messagesCopy.filter(m => !m?.isDraft);
      }
      // Check if the message with the same _id is already in the list
      const isMessageAlreadyAdded = messagesCopy?.some((existingMessage) => existingMessage?._id === message?._id);
      if (!isMessageAlreadyAdded) {
        return {
          ...state,
          totalMessages: state.totalMessages + 1,
          messages: {
            ...state.messages,
            [chatRoomId]: [...messagesCopy, message],
          },
          error: null,
        };
      }
    
      return state; // Message with the same _id already exists, return the current state unchanged
    }

    case 'setScrollPosition':
      return {
        ...state,
        scrollPosition: state.scrollPosition + action.payload,
        error: null,
      };

    case 'updateQueryState':
      return {
        ...state,
        query: { page: action.payload },
      };

    case 'clearMessagesState':
      return {
        ...state,
        messages: {},
      };

    case 'ADD_CHAT_REPLY': {
      // const { roomId, messageId, messageReply } = action.payload;
      // const messageIndex = state?.messages[roomId]?.findIndex(
      //   (message) => message?._id === messageId
      // );

      // if(messageIndex === -1 || !messageIndex) {
      //   return state;
      // }

      // return {
      //   ...state,
      //   messages: {
      //     ...state?.messages,
      //     [roomId]: [
      //       ...state?.messages[roomId]?.slice(0, messageIndex),
      //       messageReply,
      //       ...state?.messages[roomId]?.slice(messageIndex + 1),
      //     ],
      //   },
      // };

      const messageReply = action.payload;
      const { roomId, messageId } = messageReply;

      // Find the index of the message within the room
      const messageIndex = state?.messages[roomId]?.findIndex(
        (message) => message?._id === messageId
      );

      // If the message isn't found, return the current state
      if (messageIndex === -1 || messageIndex === undefined) {
        return state;
      }

      // Extract the specific message from the array
      const message = state?.messages[roomId][messageIndex];

      // Add the new reply to the replies array and sort by createdAt
      const updatedReplies = [...(message?.replies || []), messageReply].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );

      // Create the updated message object with the new replies array
      const updatedMessage = {
        ...message,
        replies: updatedReplies,
      };

      // Replace the old message with the updated message in the messages array
      const updatedMessages = [
        ...state?.messages[roomId]?.slice(0, messageIndex),
        updatedMessage,
        ...state?.messages[roomId]?.slice(messageIndex + 1),
      ];


      let updatedSingleMessage = {... state.singleMessage};

      if(state?.singleMessage?._id?.toString() === messageId?.toString()) {
        // Extract the current replies array
        const currentReplies = state.singleMessage.replies || [];

        // Add the new reply to the replies array
        const updatedReplies = [...currentReplies, messageReply].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );

        // Create the updated singleMessage object with the new replies array
        updatedSingleMessage = {
          ...state.singleMessage,
          replies: updatedReplies,
        };
      }

      // Return the updated state with the new messages array
      return {
        ...state,
        messages: {
          ...state?.messages,
          [roomId]: updatedMessages
        },
        singleMessage: updatedSingleMessage
      };
    }

    case 'UPDATE_CHAT_REPLY': {
      const { roomId, messageId, messageDescription, parent } = action.payload;
    
      if (!state?.messages[roomId]) {
        // If the room doesn't exist, return the state unchanged
        return state;
      }
    
      const updatedMessages = state.messages[roomId].map((message) => {
        if (message._id === parent) {
          // If the message ID matches, update the message
          const replies = message?.replies || [];

          const updatedReplies = replies.map(r => {
            if(r?._id?.toString() === messageId?.toString()) {
              r.repliedMsg = messageDescription;
            }
            return r;
          })

          message.replies = updatedReplies;
        }
        return message;
      });
    
      return {
        ...state,
        messages: {
          ...state.messages,
          [roomId]: updatedMessages,
        },
      };
    }

    case 'DELETE_CHAT_REPLY': {
      const { roomId, messageId, replyId } = action.payload;
      if (!state?.messages[roomId]) {
        // If the room doesn't exist, return the state unchanged
        return state;
      }

      const messageAfterReplyRemoval = state.messages[roomId]?.map((message) => {
        if (message._id?.toString() === messageId?.toString()) {
          message.replies = message.replies?.filter(r => r?._id?.toString() !== replyId?.toString());
          return message;
        } else {
          return message;
        }
      });

      return {
        ...state,
        messages: {
          ...state.messages,
          [roomId]: messageAfterReplyRemoval
        }
      }
    }

    case 'DELETE_CHAT_MESSAGE': {
      const {roomId, messageId} = action.payload;
      if (!state?.messages[roomId]) {
        // If the room doesn't exist, return the state unchanged
        return state;
      }

      const messagesAfterRemoval = state.messages[roomId].filter((message) => message?._id?.toString() !== messageId?.toString());
    
      return {
        ...state,
        messages: {
          ...state.messages,
          [roomId]: messagesAfterRemoval,
        },
      };
    }

    default:
      return state;
  }
};

export default messageReducer;
