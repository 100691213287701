import React, { useRef, useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { useParams } from 'react-router';
import { Badge, notification, Skeleton, Dropdown } from 'antd';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import MenuContext from '../right-click-menu/MenuContext';

import {
  getWorkspaces,
  unsetActiveChat,
  updateProjectPreferences,
  toggleDuplicateProjectModal,
  toggleNewProjectModal,
  toggleNewTeamChatsModal,
  setProjectLoading,
  isTicketOpen
} from '../../../../actions';

import { isChatOpen } from '../../../../actions/toggleAction';
import { setRoomId } from '../../../../actions/roomAction';

import HtmlRenderer from '../../organisms/HtmlRenderer';

import {
  RESET_COUNTER,
  UPDATE_CURRENT_RECEIVER,
} from '../../../../actions/types/chat.types';

import socket from '../../../../js/socket';
import '../../../../assets/style/chatTextArea.css';

import {
  updateChatRoomMessagesReadBy,
} from '../../../../actions/chatRoomAction';

import {
  clearMessagesState,
  updateQueryState,
} from '../../../../actions/messageAction';

import { getRandomColor } from '../../../../js/helper';
import DropdownContent from '../onboarding/DropdownContent';
import EmptyState from '../../atoms/empty-state/EmptyState';

const NavBarContainer = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const {
    currentWorkspace,
    workspaces,
    toggle,
    activeChat,
    selectedLeftNav,
    project,
    notifications,
    myProjects,
    showOnboarding,
    onBoardingStep,
    projectLoading
  } = props;

  const isListView = useSelector((state) => state.toggleReducer.isListView);

  const [chatCollaps, setChatCollaps] = useState(true);
  const [workSpaceCollaps, setWorkSpaceCollaps] = useState({
    isWorkSpace: true,
  });

  const [workspaceId, setWorkspaceId] = useState(sessionStorage.getItem("workspaceId") || currentWorkspace?._id);
  const [isChatHover, setIsChatHover] = useState(false);
  const [isProjectHover, setIsProjectHover] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentWorkspace && currentWorkspace._id) {
      setWorkspaceId(currentWorkspace._id);
      setIsLoading(false);
    }
  }, [props.teams, currentWorkspace, workspaces]);

  useEffect(() => {
    socket.on('collaborators-added', ({ roomTitle, membersArray: members }) => {
      const isMember = members.includes(localStorage.getItem('Id'));
      if (isMember)
        notification.success({
          message: `You were added as a participant in ${roomTitle} workspace`,
          placement: 'bottomRight',
        });
      dispatch(getWorkspaces('lean'));
    });
  }, []);


  const [size, setSize] = useState({
    height: Number(localStorage.getItem('height')) || 230,
  });

  const minimizeTeamsPanel = () => {
    workSpaceCollaps.isWorkSpace
      ? setSize({ height: 75 })
      : setSize({
        height: Number(localStorage.getItem('height'))
          ? Number(localStorage.getItem('height'))
          : 230,
      });
    setWorkSpaceCollaps({
      ...workSpaceCollaps,
      isWorkSpace: !workSpaceCollaps.isWorkSpace,
    });
  };

  useEffect(() => {
    if (activeChat) {
      navigate(`/chat/${activeChat}`);
      dispatch(unsetActiveChat());
    }
  }, [activeChat, navigate]);

  useEffect(() => {
    if (project) {
      navigate(`/${project._id}/${isListView ? 'list' : 'board'}`);
    }
  }, [project, navigate]);

  const setShowProjectModal = (value) => {
    dispatch(toggleNewProjectModal({
      value: value
    }));
  };

  const setIsTeamsChatModalOpen = (value, isTeams) => {
    dispatch(toggleNewTeamChatsModal({
      value: value,
      isTeams: isTeams
    }));
  }

  const isHome = selectedLeftNav === "Home";
  const isTeams = selectedLeftNav === 'Projects';
  const isChats = selectedLeftNav === 'Chats';
  const isActivity = selectedLeftNav === 'Activity';

  const [isHover, setIsHover] = useState(false);

  return (
    <div style={{ height: !isHome ? '100%' : 'auto' }}>
      {(isTeams || isHome) &&
        <div
          style={{
            paddingLeft: toggle ? '' : '15px',
            paddingRight: toggle ? '' : '15px',
          }}
          className={
            `NavBarContainer 
          ${workSpaceCollaps.isWorkSpace ? 'dropDownShow' : 'dropDownCollaps'} 
          ${!isHome && 'hide-border'}}`
          }>
          <div>
            <div
              onMouseOver={() => setIsProjectHover(true)}
              onMouseLeave={() => setIsProjectHover(false)}
              className='d-flex align-center justify-content-between disable-select h-[38px] ml-2'>
              <Dropdown
                trigger={['click']}
                dropdownRender={() => (
                  <DropdownContent
                    title={'Connect with the team!'}
                    descp={'Familiarize yourself with the projects goals, scope, and team members.'}
                  />
                )}
                arrow
                placement='bottomLeft'
                open={onBoardingStep === 1 && showOnboarding}
              >
                <div
                  className='d-flex align-center cursor-pointer'
                  onClick={() => minimizeTeamsPanel()}
                >
                  <img
                    src='/images/icons/projects.svg'
                    style={{ height: '20px', width: '20px', margin: '0 10px 0 5px' }}
                    alt='icon'
                  />
                  <div className='heading-nav font-14 text-white'>Projects</div>
                </div>
              </Dropdown>
              <div className='d-flex align-center cursor-pointer'>
                {isProjectHover && <img
                  src='/images/icons/search.svg'
                  height={12}
                  width={12}
                  style={{ marginRight: '10px' }}
                  alt='icon'
                />}
                <img
                  src='/images/icons/add.svg'
                  style={{ marginRight: '10px' }}
                  height={12}
                  width={12}
                  alt='icon'
                  onClick={() => setShowProjectModal(true)}
                />
              </div>
            </div>
            <div
              className={`scroll-y disable-select ml-1 mb-2`}
              style={{
                display: workSpaceCollaps.isWorkSpace ? 'block' : 'none',
              }}
            >
              {myProjects?.length > 0 ? myProjects?.map((project, index) => {

                const me = project?.participants?.find(mem => mem?._id?.toString() == props.user?._id?.toString());

                const updateProjPreferences = async () => {
                  await dispatch(updateProjectPreferences({ ...me, notifications: !me?.notifications, projectId: project?._id }));
                }

                return (
                  <div key={project?._id + index}>
                    <MenuContext
                      key={project?._id + 'Project Side bar'}
                      items={[
                        {
                          name: <div className='text-slate-600 font-14'>Copy Project Link</div>,
                          onClick: () => {
                            navigator.clipboard.writeText(`${window.location.origin}/${project._id}/board`);
                            notification.success({
                              message: 'Project link copied to clipboard',
                              placement: 'bottomRight',
                            });
                          }
                        },
                        {
                          name: <div className='text-slate-600 font-14'>{!me?.notifications ? 'Unmute' : 'Mute'} Project</div>,
                          onClick: async () => {
                            updateProjPreferences();
                          }
                        },
                        {
                          name: <div className='text-slate-600 font-14'>Duplicate Project</div>,
                          onClick: () => {
                            dispatch(toggleDuplicateProjectModal(true, project))
                          }
                        },
                        {
                          name: <div className='text-indigo-600 font-14'>Project Settings</div>,
                          onClick: () => {
                            navigate(`/${project?._id}/settings`)
                          }
                        }
                      ]}>
                      <div
                        onClick={() => navigate(`/${project?._id}/board`)}
                        className='d-flex align-center justify-content-between cursor-pointer'>
                        <div
                          className={`d-flex align-center hover:bg-[#394867] w-full p-2 rounded my-0.5 ${params?.projectid == project?._id && 'nav-item-active'}`}
                        >
                          <img src={`/images/icons/${project?.access !== 'public' ? 'white-lock' : 'box'}.svg`} className="h-4 w-4 mr-2 ml-1" alt="error" />
                          <span className="ml-1 truncate font-14">{project?.title}</span>
                        </div>
                        {/* </Dropdown> */}
                      </div>
                    </MenuContext>
                  </div>
                )
              }) :
                (!isLoading && !projectLoading) &&
                (<div className='px-3 m-2'>
                  <EmptyState
                    header={"No Projects!"}
                    description={"Let's create a new project to get started"}
                    button={{
                      text: 'Create Project',
                      color: 'bg-orange-300'
                    }}
                    onPress={() => {
                      setShowProjectModal(true)
                    }}
                    iconSize='h-8 w-8'
                  />
                </div>)}
            </div>
          </div>
        </div>}
      {(isLoading || projectLoading) &&
        <div className='ml-2 mb-2'>
          <Skeleton.Button
            style={{
              width: 230,
              height: 22,
              borderRadius: 6,
            }}
            active={true} />
          <Skeleton.Button
            style={{
              width: 230,
              height: 22,
              borderRadius: 6,
            }}
            active={true} />
          <Skeleton.Button
            style={{
              width: 230,
              height: 22,
              borderRadius: 6,
            }}
            active={true} />
          <Skeleton.Button
            style={{
              width: 230,
              height: 22,
              borderRadius: 6,
            }}
            active={true} />
        </div>}
      {/* chat collaps .................................................................*/}
      <div>
        {(isChats || isHome) && <div
          className={`NavBarContainer ${chatCollaps ? 'dropDownShow' : 'dropDownCollaps'
            }`}
          style={{
            paddingLeft: toggle ? '10px' : '15px',
            paddingRight: toggle ? '10px' : '15px',
            paddingBottom: 10
          }}
        >
          <div
            onMouseOver={() => setIsChatHover(true)}
            onMouseLeave={() => setIsChatHover(false)}
            className='d-flex align-center justify-content-between h-[38px] ml-2 mt-3' style={{ paddingBottom: 10 }}>
            <Dropdown
              trigger={['click']}
              dropdownRender={() => (
                <DropdownContent
                  title={'Communication Channels!'}
                  descp={'Connect with the team through our communication chats for seamless interaction'}
                  nextButtonText='Get Started'
                />
              )}
              arrow
              placement='bottomLeft'
              open={onBoardingStep === 2 && showOnboarding}
            >
              <div
                className='d-flex align-center cursor-pointer'
                onClick={() => setChatCollaps(!chatCollaps)}
              >
                <img
                  src='/images/icons/chat-tooltip.svg'
                  style={{ height: 20, width: 20, margin: '0 10px 0 5px' }}
                  alt='icon'
                />
                <div className='heading-nav font-14 text-white'>Chats</div>
              </div>
            </Dropdown>
            <div className='d-flex align-center cursor-pointer'>
              {isChatHover && <img
                src='/images/icons/search.svg'
                height={12}
                width={12}
                style={{ marginRight: '10px' }}
                alt='icon'
              />}
              <img
                src='/images/icons/add.svg'
                style={{ marginRight: '10px' }}
                height={12}
                width={12}
                alt='icon'
                onClick={() => setIsTeamsChatModalOpen(true, false)}
              />
            </div>
          </div>
          {/* .........................Chat area.......................... */}
          {chatCollaps && <ul className='overflow-x-hidden'>
            {props?.chats &&
              props?.chats.map((chat, i) => {
                const isDirectChat = chat.roomType === 'direct'
                const isProjectChat = chat.roomType === 'project'

                const dmUser = chat.members?.filter(memeber => memeber._id !== localStorage.getItem('Id'));
                const name = isDirectChat && !isProjectChat ? dmUser[0]?.fullName || 'User' : chat.roomName;
                const avi = isDirectChat ? dmUser[0]?.avi : chat.roomAvi;
                const mostRecentMessage = chat.mostRecentMessage || '';

                const currentMessageDate = new Date(chat?.lastUpdate).toLocaleDateString('en-us',
                  {
                    weekday: "short",
                    month: "short",
                    day: "numeric"
                  });

                const iconLetters = name?.slice(0, 2).toUpperCase() || '';

                let span = document.createElement('span');
                span.innerHTML = mostRecentMessage?.messageDescription;
                const htmlContent = span.textContent || span.innerText;

                const hasNotification = chat.count > 0;
                const substringLength = hasNotification ? 25 : 35;

                if (isProjectChat) {
                  return;
                }

                return (
                  <li
                    key={chat?._id}
                    className={`flex px-2 py-0.5 mb-1 cursor-pointer align-center nav-bar-chat ${params?.chatId?.toString() === chat?._id?.toString() && 'nav-item-active'} ${hasNotification && 'font-bold'}`}
                    onClick={() => {
                      if (params?.chatId?.toString() != chat?._id?.toString()) {
                        dispatch(updateQueryState(1));
                        dispatch(clearMessagesState());
                        dispatch(setRoomId(chat._id));
                        dispatch({ type: RESET_COUNTER, payload: chat._id });
                        navigate(`/chat/${chat._id}`);
                        dispatch({
                          type: UPDATE_CURRENT_RECEIVER,
                          payload: chat.roomName,
                        });
                      }
                      dispatch(updateChatRoomMessagesReadBy(chat._id, 'ChatsNavBar1'));
                    }}
                  >

                    {avi ?
                      <img
                        src={avi}
                        className='my-1 mr-3 w-7 h-7 ml-1'
                        style={{ objectFit: 'cover', borderRadius: 60, background: getRandomColor(i + 1), aspectRatio: 1 }}
                        alt='icon'
                      /> :
                      <div
                        className={'square-letter-large my-2 mr-3 ml-1 w-6 h-6 font-12'}
                        style={{ background: getRandomColor(i + 1) }}>
                        {iconLetters}
                      </div>
                    }
                    <div>
                      <div className={`font-14 leading-5 self-center truncate ${hasNotification && 'font-bold'}`}>{name?.substring(0, hasNotification ? 20 : 25)}</div>
                      {htmlContent !== 'undefined' && <div className={`font-12 leading-2 truncate max-w-[80%] ${hasNotification && 'font-bold'}`} style={{ width: hasNotification ? 150 : 200 }}>
                        {htmlContent}
                      </div>}
                    </div>
                    {hasNotification && <div>
                      <Badge
                        count={chat.count || 0}
                        // count={0}
                        style={{
                          backgroundColor: '#FE3C32',
                          boxShadow: 'none',
                        }}
                      />
                    </div>}
                  </li>)
              })}
          </ul>}
          {/* {(!props?.chats || props?.chats.length === 0) &&
            <div>
              <Skeleton.Button
                style={{
                  width: 230,
                  height: 22,
                  borderRadius: 6,
                  marginTop: 2
                }}
                active={true} />
              <Skeleton.Button
                style={{
                  width: 230,
                  height: 22,
                  borderRadius: 6,
                  marginTop: 2
                }}
                active={true} />
              <Skeleton.Button
                style={{
                  width: 230,
                  height: 22,
                  borderRadius: 6,
                  marginTop: 2
                }}
                active={true} />
              <Skeleton.Button
                style={{
                  width: 230,
                  height: 22,
                  borderRadius: 6,
                  marginTop: 2
                }}
                active={true} />
            </div>} */}
        </div>}
      </div>
      {isActivity &&
        <div>
          <div
            className='d-flex align-center cursor-pointer p-2.5'
            onClick={() => minimizeTeamsPanel()}
          >
            <img
              src='/images/icons/mail.svg'
              style={{ height: '18px', width: '18px', margin: '0 10px 0 5px' }}
              alt='icon'
            />
            <div className='heading-nav'>Activity</div>
          </div>
          <ul>
            {notifications && notifications?.map(notif => {

              const icon = notif?.assignor?.avi || notif?.icon;

              const date = new Date(notif?.createdAt);
              TimeAgo.addLocale(en);
              const timeAgo = new TimeAgo('en-US');
              const time = timeAgo.format(date);

              const notificationType = notif?.notificationType;
              let notifActionLabel = '';
              let notifAction = () => { };

              // if (notificationType === 'CommentNotification') {
              //   notifActionLabel = 'Go to Comment';
              // } else
              if (notificationType === 'TaskNotification') {
                notifActionLabel = 'Open task';
                notifAction = () => {
                  dispatch(isTicketOpen(true));
                  navigate(`${window.location?.pathname}?selectedTask=${notif?.task?.titleIdentifier}`)
                }
              } else if (notificationType === 'ChatReplyNotification') {
                notifActionLabel = 'Open reply';
                notifAction = () => dispatch(isChatOpen(true, notif?.repliedMessage?._id));
              }

              const isViewed = notif?.assignee?.isViewed;
              const description = notif?.description;

              return (
                <li
                  onMouseOver={() => setIsHover(notif?._id)}
                  onMouseLeave={() => setIsHover()}
                  onClick={() => notifAction()}
                  className="cursor-pointer border-b border-gray-600 last:border-0 relative hover:bg-[#394867]" >
                  <div
                    className="block py-2 px-4"
                    to="#0"
                  >
                    <div className='flex align-center mb-2'>
                      <img className='h-6 w-6 mr-3 bg-slate-500' src={icon} style={{ borderRadius: 60 }} />
                      <div className="block text-sm">{notif?.title}</div>
                    </div>
                    {description && notificationType !== 'TaskNotification' && <div className='text-white additional-info mt-2'>
                      <HtmlRenderer html={description} />
                    </div>}
                    <span className="block text-xs font-medium text-slate-400">{time}</span>
                  </div>
                  {/* <div
                    className='abosulte h-full bg-blue-200 flex align-center justify-center'
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      width: isHover == notif?._id ? 100 : 0,
                      transition: 'all 0.4s'
                    }}>
                    {isHover == notif?._id && <div style={{ transition: 'all 5s', width: isHover == notif?._id ? 30 : 0 }} className='font-14 font-bold text-slate-700'>
                      {notifActionLabel}
                    </div>}
                  </div> */}
                </li>
              );
            })}
          </ul>
        </div>}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    toggle: state.navReducer.toggle,
    activeChat: state.chatReducer.activeChat,
    project: state.projectReducer.success,
    projectData: state.projectReducer.getProjects,
    showOnboarding: state.onboardingReducer.showOnboarding,
    onBoardingStep: state.onboardingReducer.onBoardingStep,
    projectLoading: state.projectReducer.loading,
  };
}

export default connect(mapStateToProps)(NavBarContainer);
