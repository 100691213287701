import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";

import { getRandomColor } from '../../../js/helper';
import { taskPriority } from '../../../js/constants'

import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { setTeamsState } from '../../../actions/toggleAction';
import { isTicketOpen, isChatOpen, getProjectById, getTasks } from '../../../actions';

function CalendarComponent(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const today = new Date();
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const [month, setMonth] = useState(today.getMonth());
  // eslint-disable-next-line no-unused-vars
  const [year, setYear] = useState(today.getFullYear());
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [startingBlankDays, setStartingBlankDays] = useState([]);
  const [endingBlankDays, setEndingBlankDays] = useState([]);

  const [events, setEvents] = useState([]);
  const [tasks, setTasks] = useState(props?.tasks);

  const isToday = (date) => {
    const day = new Date(year, month, date);
    return today.toDateString() === day.toDateString() ? true : false;
  }

  const getEvents = (date) => {
    return events.filter(e => new Date(e.eventStart).toDateString() === new Date(year, month, date).toDateString());
  }

  const handleTaskClick = async (task) => {
    console.log("----- openTicket", task)

    const params = new URLSearchParams(location.search);
    params.delete('msgId'); // Remove the specified parameter
    params.set('selectedTask', task.titleIdentifier)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    await dispatch(isChatOpen(false));
    await dispatch(setTeamsState(false));

    // Use navigate to replace the current URL
    navigate(newUrl);

    await dispatch(isTicketOpen(true));
  };

  const getDays = () => {
    const days = new Date(year, month + 1, 0).getDate();

    // starting empty cells (previous month)
    const startingDayOfWeek = new Date(year, month).getDay();
    let startingBlankDaysArray = [];
    for (let i = 1; i <= startingDayOfWeek; i++) {
      startingBlankDaysArray.push(i);
    }

    // ending empty cells (next month)
    const endingDayOfWeek = new Date(year, month + 1, 0).getDay();
    let endingBlankDaysArray = [];
    for (let i = 1; i < 7 - endingDayOfWeek; i++) {
      endingBlankDaysArray.push(i);
    }

    // current month cells
    let daysArray = [];
    for (let i = 1; i <= days; i++) {
      daysArray.push(i);
    }

    setStartingBlankDays(startingBlankDaysArray);
    setEndingBlankDays(endingBlankDaysArray);
    setDaysInMonth(daysArray);
  }

  useEffect(() => {
    getDays();
    let x = [];
    tasks?.map((task, i) => {

      Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      }

      var date = new Date(task?.dueDate);
      date = date.addDays(1)

      const colr = taskPriority?.find(priority => priority?.rank == task?.priority)?.color;

      x.push({
        task: task,
        eventStart: new Date(date),
        eventEnd: new Date(date),
        eventName: task?.taskTitle || 'Task',
        eventColor: colr || getRandomColor(i)
      })
    })
    setEvents(x);

    document.querySelectorAll('.topNavbarLink').forEach((item) => {
      item.classList.remove('topNavBar-activeLink');
    });
    document
      .querySelector(`.calendarTopNavbarLink`)
      ?.classList?.add('topNavBar-activeLink');

  }, [tasks]);

  useEffect(() => {
    dispatch(getProjectById(params.projectid)).then(res => {
      dispatch(getTasks(params.projectid));
    });
  }, [params.projectid]);

  useEffect(() => {
    setTasks(props?.tasks);
  }, [props?.tasks]);

  return (
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

      {/*  Site header */}


      <main className="grow">
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

          {/* Page header */}
          <div className="flex align-center">

            {/* Left: Title */}
            <div className="mb-4 sm:mb-0 mr-5">
              <h1 className="text-2xl md:text-3xl text-slate-800  font-bold"><span>{`${monthNames[month]} ${year}`}</span></h1>
            </div>

            {/* Right: Actions */}
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

              {/* Previous month button */}
              <button
                className="btn-2 px-2.5 bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                disabled={month === 0 ? true : false}
                onClick={() => { setMonth(month - 1); getDays(); }}
              >
                <span className="sr-only">Previous month</span><wbr />
                <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
                  <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
                </svg>
              </button>

              {/* Next month button */}
              <button
                className="btn-2 px-2.5 bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                disabled={month === 11 ? true : false}
                onClick={() => { setMonth(month + 1); getDays(); }}
              >
                <span className="sr-only">Next month</span><wbr />
                <svg className="h-4 w-4 fill-current" viewBox="0 0 16 16">
                  <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                </svg>
              </button>
            </div>

          </div>

          {/* Filters and view buttons */}
          {/* <div className="sm:flex sm:justify-between sm:items-center mb-4">

          
            <div className="flex flex-nowrap -space-x-px">
              <button className="btn-2 bg-slate-50 border-slate-200 hover:bg-slate-50 text-indigo-500 rounded-none first:rounded-l last:rounded-r">Month</button>
              <button className="btn-2 bg-white border-slate-200 hover:bg-slate-50 text-slate-600 rounded-none first:rounded-l last:rounded-r">Week</button>
              <button className="btn-2 bg-white border-slate-200 hover:bg-slate-50 text-slate-600 rounded-none first:rounded-l last:rounded-r">Day</button>
            </div>
          </div> */}

          {/* CalendarComponent table */}
          <div className="bg-white rounded-sm shadow overflow-hidden">

            {/* Days of the week */}
            <div className="grid grid-cols-7 gap-px border-b border-slate-200">
              {
                dayNames.map(day => {
                  return (
                    <div className="px-1 py-3" key={day}>
                      <div className="text-slate-500 text-sm font-medium text-center lg:hidden">{day.substring(0, 3)}</div>
                      <div className="text-slate-500 text-sm font-medium text-center hidden lg:block">{day}</div>
                    </div>
                  )
                })
              }
            </div>

            {/* Day cells */}
            <div className="grid grid-cols-7 gap-px bg-slate-200">
              {/* Diagonal stripes pattern */}
              <svg className="sr-only">
                <defs>
                  <pattern id="stripes" patternUnits="userSpaceOnUse" width="5" height="5" patternTransform="rotate(135)">
                    <line className="stroke-current text-slate-200 opacity-50" x1="0" y="0" x2="0" y2="5" strokeWidth="2" />
                  </pattern>
                </defs>
              </svg>
              {/* Empty cells (previous month) */}
              {
                startingBlankDays.map(blankday => {
                  return (
                    <div className="bg-slate-50 h-20 sm:h-28 lg:h-36" key={blankday}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                        <rect width="100%" height="100%" fill="url(#stripes)" />
                      </svg>
                    </div>
                  )
                })
              }
              {/* Days of the current month */}
              {
                daysInMonth.map(day => {
                  return (
                    <div className="relative bg-white h-20 sm:h-28 lg:h-36 overflow-hidden" key={day}>
                      <div className="h-full flex flex-col justify-between">
                        {/* Events */}
                        <div className="grow flex flex-col relative p-0.5 sm:p-1.5 overflow-hidden">
                          {
                            getEvents(day).map(event => {
                              return (
                                <button className="relative w-full text-left mb-1" key={event.eventName} onClick={() => handleTaskClick(event.task)}>
                                  <div className={`px-2 py-0.5 rounded overflow-hidden text-white`} style={{ background: event.eventColor }}>
                                    {/* Event name */}
                                    <div className="text-xs font-semibold truncate">{event.eventName}</div>
                                  </div>
                                </button>
                              )
                            })
                          }
                          <div className="absolute bottom-0 left-0 right-0 h-4 bg-gradient-to-t from-white to-transparent pointer-events-none" aria-hidden="true"></div>
                        </div>
                        {/* Cell footer */}
                        <div className="flex justify-between items-center p-0.5 sm:p-1.5">
                          {/* More button (if more than 2 events) */}
                          {getEvents(day).length > 2 &&
                            <button className="text-xs text-slate-500 font-medium whitespace-nowrap text-center sm:py-0.5 px-0.5 sm:px-2 border border-slate-200 rounded">
                              <span className="md:hidden">+</span><span>{getEvents(day).length - 2}</span> <span className="hidden md:inline">more</span>
                            </button>
                          }
                          {/* Day number */}
                          <button className={`inline-flex ml-auto w-6 h-6 items-center justify-center text-xs sm:text-sm font-medium text-center rounded-full hover:bg-indigo-100 ${isToday(day) && 'text-indigo-500'}`}>{day}</button>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              {/* Empty cells (next month) */}
              {
                endingBlankDays.map(blankday => {
                  return (
                    <div className="bg-slate-50 h-20 sm:h-28 lg:h-36" key={blankday}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                        <rect width="100%" height="100%" fill="url(#stripes)" />
                      </svg>
                    </div>
                  )
                })
              }
            </div>
          </div>

        </div>
      </main>

    </div>
  );
}

function mapStateToProps(state) {
  return {
    tasks: state.taskReducer.tasks,
    user: state.userReducer.user,
    lruProjectTasks: state.taskReducer.lruProjectTasks
  };
}

export default connect(mapStateToProps)(CalendarComponent);
