import io from 'socket.io-client';

const sockConfig = {
  transports: ['websocket'],
  maxHttpBufferSize: 1e8,
  query: {
    userId: localStorage.getItem('Id'),  // Pass the user's ID here
    userAgent: navigator.userAgent,  // Pass the user agent here
    token: `Bearer ${localStorage.getItem("token")}`
  },
};
const socket = io(`${process.env.REACT_APP_SOCKET_HOST}`, sockConfig);

// if socket give any error run it ........................

// socket.on("connect_error", (err) => {
//   console.log(`connect_error due to ${err.message}`);
// });

export default socket;
