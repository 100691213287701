import React, { useEffect } from 'react';
import { connect, useDispatch } from "react-redux";

import DashboardMembers from '../molecules/dashboard/DashboardMembers';
import { getProjectById,  openInvitePanel } from '../../../actions';

const ProjectMembers = (props) => {
  const dispatch = useDispatch();
  const { project } = props;

  useEffect(() => {
    document.querySelectorAll('.topNavbarLink').forEach((item) => {
      item.classList.remove('topNavBar-activeLink');
    });
    document
      .querySelector(`.membersTopNavbarLink`)
      ?.classList?.add('topNavBar-activeLink');
  }, []);

  useEffect(() => {
    if (!project?.participants) {
      const { 1: projectId, 2: path } = window.location.pathname.split("/");
      dispatch(getProjectById(projectId));
    }
    
  }, []);

  return (
    <div style={{ margin: 20 }}>
        <DashboardMembers
          invites={[]}
          data={{
            members: project.participants,
            _id: project?._id
          }}
          phase='project'
          requests={project?.accessRequests || []}
          onAddMembers={() => {
            dispatch(openInvitePanel(true, {
              pastInvites: project?.pendingInvites || [],
              existingMembers: project?.participants || [],
              selectWorkspaceMembers: true,
              type: 'Project'
            }));
          }}
        />
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.projectReducer.getProjects,
  user: state.userReducer.user,
  fields: state.aiReducer.customFieldSuggestions,
  workspace: state.workspaceReducer.currentWorkspace
});

export default connect(mapStateToProps)(ProjectMembers);
