/**
 * Returns the display value for a custom field based on its type and value.
 * If the custom field is falsy or has no value, returns null.
 * If the type is 'dropdown', looks up the label in the possibleValues array based on the value.
 * @param {object} customField - The custom field object containing possibleValues, type, and value.
 * @param {array} customField.possibleValues - An array of possible values for the dropdown.
 * @param {string} customField.type - The type of the custom field.
 * @param {string} customField.value - The value of the custom field.
 * @returns {string|null} The display value of the custom field, or null if customField is falsy or has no value.
 */
const getCustomFieldDisplayValue = (customField) => {
  // Check if customField is falsy
  if (!customField) {
    return null;
  }

  const { possibleValues, type, value } = customField;

  let displayValue = value;
  // We are only checking for dropdown for now.
  // As more examples show up, we will add more conditions to handle cases correctly.
  if (type === 'dropdown' || type === 'status') {
    // Find the corresponding label in possibleValues
    const foundValueObj = possibleValues.find((pv) => pv._id === value);

    if (foundValueObj) {
      displayValue = foundValueObj.label;
    }
  }

  if (type == 'cost' && displayValue) {
    return '$' + displayValue;
  }

  return displayValue;
};

export default getCustomFieldDisplayValue;
