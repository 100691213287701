import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { editUser, getUser } from '../../../../actions';
import socket from '../../../../js/socket';


const GeneralTab = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState(user?.preferences?.notifications);
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on('user-updated', (id) => {
      dispatch(getUser(id));
    });
  }, [dispatch]);

  const handlePreferencesUpdate = (e) => {
    setNotifications(!notifications);
    dispatch(
      editUser(
        {
          _id: user._id,
          preferences: {
            notifications: !notifications
          }
        },
        async () => {
          await dispatch(getUser(user._id));
        }
      )
    );
  }

  return (
    <div>
      <h2>General Settings</h2>
      <div className="toggle-switch">
        <label>
          In-App Notifications
          <input
            type="checkbox"
            checked={notifications}
            onChange={handlePreferencesUpdate}
            disabled={isLoading}
          />
          <span className="slider"></span>
        </label>
      </div>
    </div>
  );
    
}

export default GeneralTab;