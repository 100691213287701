import React from 'react';

function StepTwo({ onChange, handleClick, onBack, workspaceDesc }) {
  return (
    <div className="px-4 py-8">
      <div className="max-w-md mx-auto">
        <h1 className="text-3xl text-slate-800 font-bold mb-6">Tell us about your workspace</h1>
        <div className="space-y-3 mb-8">
          <input
            type='text'
            placeholder='This workspace is for the film industry...'
            onChange={onChange}
            className="w-full flex items-center bg-white text-sm font-medium text-slate-800 p-4 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out" />
        </div>
        <div className='mb-5'>
          <div className="font-medium text-slate-800 text-sm mb-1">Why is this needed?</div>
          <div className="text-xs">
            We'd like to learn more about how you use your workspace so we can make it better suited to your needs and preferences!</div>
        </div>
        <div className="flex items-center justify-between">
          <div
            onClick={onBack}
            className="text-16 text-blue-500 underline hover:no-underline px-2 border-radius10 cursor-pointer">Go Back</div>
          <div
            onClick={handleClick}
            className="btn bg-blue-500 hover:bg-blue-400 text-white ml-auto p-2 px-5 rounded-md cursor-pointer">{workspaceDesc ? 'Next' : 'Skip'}</div>
        </div>
      </div>
    </div>
  );
}

export default StepTwo;