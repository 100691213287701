import React, { useState } from "react";
import NewPasswordForm from "../molecules/NewPasswordForm";
import ResetPasswordSuccess from "./ResetPasswordSuccess";

import { useNavigate } from "react-router-dom";

import Icon from '../atoms/global/Icon';


const NewPassword = (props) => {
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const navigate = useNavigate();

  return (
    <div
      className='sign-in-page'>
      <div style={{ padding: 15, float: 'left', cursor: 'pointer' }} onClick={() => navigate('/signup', { replace: true })}>
        <Icon
          name='logo.svg'
          height='16px'
          pr='20px' />
      </div>
      <div className="login-page d-flex align-center" style={{ top: 0 }}>
        <img src='images/icons/loginIcon.png' width={200} />
        {/* {!signup ? */}
        <div className="login-container">
          {isPasswordReset ? (
            <ResetPasswordSuccess />
          ) : (
            <NewPasswordForm setIsPasswordReset={setIsPasswordReset} />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
