import React from 'react';
import { Modal } from "antd";

import '../../../../assets/style/modal.css';

const ConfirmationModal = (props) => {
  const {
    isModalOpen,
    onYesClick,
    onCancel,
    title,
    description
  } = props;

  const footer = () => {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <div
          onClick={onCancel}
          className='nav-button new-task-nav-button font-14' style={{ width: '8rem' }}>
          No
        </div>
        <div
          onClick={onYesClick}
          className='nav-button modal-danger-button font-14' style={{ width: '8rem' }}>
          Yes
        </div>
      </div>
    );
  };

  return (
    <Modal title={title}
      open={isModalOpen}
      onCancel={onCancel}
      bodyStyle={{
        backgroundColor: '#FFFFFF',
        padding: '1rem'
      }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={footer()}>
      <div className='new-task-modal'>
        {description}
      </div>
    </Modal>
  );
};


export default ConfirmationModal;
