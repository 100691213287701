import React from "react";
import { useNavigate } from "react-router-dom";

import SigninForm from "../molecules/SigninForm";
import Icon from '../atoms/global/Icon';

const SignIn = () => {
  const navigate = useNavigate();

  if (localStorage.getItem('token') != undefined) {
    navigate('/', { replace: true });
  }

  return (
    <div className='sign-in-page'>
      <div
        onClick={() => navigate('/')}
        style={{ padding: 15, float: 'left', cursor: 'pointer' }}>
        <Icon
          name='logo.svg'
          height='16px'
          pr='20px' />
      </div>
      <div className="login-page d-flex align-center">
        <img src='images/icons/loginIcon.png' width={200} />
        <div className="login-container">
          <h1 className="text-darkGray font-40" style={{ textAlign: 'center', marginBottom: 30 }}>Welcome back!</h1>
          <SigninForm />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
