import { Avatar, Tooltip } from "antd";
import React from "react";
const ReplyAvatar = ({ avis }) => {
  const aviEls = [];
  return (
    <>
      <Avatar.Group
        maxCount={2}
        maxPopoverTrigger="click"
        size="small"

        maxStyle={{
          color: "white",
          backgroundColor: "#4077EE",
          cursor: "pointer",
          fontSize: "12px",
        }}
      >

        {avis && avis.forEach(avi => {
          aviEls.push(
            <Tooltip placement="top">
              <Avatar
                style={{
                  backgroundColor: "#87d068",
                }}
                icon={<img src={avi} alt="icon" />}
              />
            </Tooltip>
          );
        })}
        {aviEls}


      </Avatar.Group>
    </>
  )
};
export default ReplyAvatar;
