const initialState = {
  summary: {
    summary: "",
    actionItems: [],
    talkingPoints: []
  },
  customFieldSuggestions: []
};

const aiReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHAT_SUMMARY': 
    return {
      ...state,
      summary: action.payload
    }

    case 'CUSTOM_FIELD_SUGGESTIONS':
      return {
        ...state,
        customFieldSuggestions: action.payload
      }

    default:
      return state;
  }
};

export default aiReducer;
