import { setForbidden, setUnauthorized } from "../../actions/userAction";

export function UnauthorizedHandler(dispatch, error, message) {
  if(error?.response?.status === 401) {
    dispatch(setUnauthorized(message))
  } else if(error?.response?.status === 409) {
    dispatch(setUnauthorized('User already exists. Please sign in!'))
  } else if(error?.response?.status === 403) {
    dispatch(setForbidden('User already exists. Please sign in!'))
  }
  
  // else if(error?.response?.status === 403) {
  //   dispatch(setForbidden())
  // }
} 