const data = {
  inviteDetails: {
    pastInvites: [],
    existingMembers: [],
    selectWorkspaceMembers: true,
    type: 'Project',
    teamId: ''
  }
};

const inviteReducer = (state = data, action) => {
  switch (action.type) {
    case 'setInviteData':
      return {
        ...state,
        inviteDetails: action.payload,
      };

    default:
      return state;
  }
};
export default inviteReducer;
