import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Calendar,
  Tooltip,
  Checkbox,
  Radio,
  Dropdown,
  notification,
  DatePicker
} from 'antd';
import moment from 'moment';

import { editTask } from '../../../../actions';
import Icon from '../../atoms/global/Icon';

import { getDateForCard, getDateColor } from '../../../../js/functions';

const CalendarDropdown = (props) => {
  const { task, setIsHover, x, left, y, isLarge } = props;

  const [isCalender, setIsCalender] = useState(false);
  const [calendarDate, setCalendarDate] = useState(moment.utc(new Date()));
  const [calendarPosition, setCalendarPosition] = useState({ x: 0, y: 0 });

  const options = ['day', 'week', 'month', 'year'];
  const isDateSelected = calendarDate !== null && calendarDate;
  const isRecurring = task?.recurringConfig?.recurring;
  const recurringDuration = task?.recurringConfig?.recurringDuration;

  const [showRecurringDate, setShowRecurringDate] = useState(isRecurring || false);
  const [frequency, setFrequency] = useState(recurringDuration?.unit || 'day');
  const [duration, setDuration] = useState(recurringDuration?.duration || 1);
  const [iteration, setIteration] = useState(0);
  const [isTriggerComplete, setTriggerComplete] = useState(true);

  const dispatch = useDispatch();
  const refr = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isCalender &&
        refr.current &&
        !refr.current.contains(e.target) &&
        e.target.className !== 'ant-dropdown-menu-title-content'
      ) {
        setIsCalender(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isCalender]);

  useEffect(() => {
    if (task.dueDate) {
      setCalendarDate(moment.utc(new Date(task.dueDate)));
    } else {
      setCalendarDate(null);
    }
  }, [task.dueDate]);

  const newDate = new Date();
  newDate.setHours(0, 0, 0, 0);

  const getDurationItems = () => {
    let items = [];
    for (let i = 1; i <= 7; i++) {
      items.push({
        key: i,
        label: i,
        onClick: () => setDuration(i)
      })
    }
    return items;
  }

  const handleRecurringTask = async () => {
    const sections = task?.projects.map(prj => ({
      project: prj?.project?._id || '',
      section: prj?.section?._id || '',
    }));

    isDateSelected && await dispatch(
      editTask({
        _id: task._id,
        dueDate: calendarDate,
        recurringConfig: {
          recurring: true,
          recurringDuration: {
            duration: duration, // any numeric value
            unit: frequency, //allowed values are day, week, year
            iteration: iteration
          },
          trigger: isTriggerComplete ? 'done' : 'auto',
          projectSections: sections, // This array stores the destination section for recurring tasks. It is an array because the task may be in multiple projects.
        }
      }, null, () => {
        notification.success({
          message: 'Task set to recurring!',
          placement: 'bottomRight',
        });
      })
    );

    setIsCalender(false);
  };

  return (
    <div
      onMouseEnter={() => !!setIsHover && setIsHover(true)}
      onMouseLeave={() => !!setIsHover && setIsHover(false)}
      onBlur={() => !showRecurringDate && setIsCalender(false)}
      className="flex items-center">
      {isDateSelected && (
        // visible date - unopened
        <div
          onClick={e => {
            setCalendarPosition({
              x: e.clientX,
              y: e.clientY
            })
            setIsCalender(true)
          }}
          className="cursor-pointer flex items-center text-indigo-400"
          style={{ color: getDateColor(calendarDate) }}>
          {isRecurring ?
            <img
              src='/images/icons/Refresh.svg'
              alt=''
              style={{
                height: '20px',
                width: '20px',
                marginRight: 10
              }}
            />
            : <svg className="w-4 h-4 shrink-0 fill-current mr-1.5" viewBox="0 0 16 16">
              <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
            </svg>}
          <div className="font-14 text-indigo-400" style={{ color: getDateColor(calendarDate) }}> {getDateForCard(calendarDate)}</div>
        </div>
      )}
      {isCalender && (
        <div
          className='task-card open-calendar'
          style={{
            position: 'absolute',
            width: showRecurringDate ? '550px' : '300px',
            top: calendarPosition?.y < 550 ? calendarPosition?.y + (y || 0) : calendarPosition?.y - 300,
            left: left && calendarPosition?.x + (showRecurringDate ? x - 300 : x),
            right: (!left || showRecurringDate) && + (x || 50),
            zIndex: '9999',
            background: 'white'
          }}
          ref={refr}
        >
          <div className='flex h-full justify-between'>
            {showRecurringDate &&
              <div className='w-full mr-8 flex flex-col justify-between'>
                <div className='flex align-center justify-between'>
                  <div className='flex align-center'>
                    <Icon
                      name='close.svg'
                      height='14px'
                      onClick={() => {
                        setIsCalender(false);
                      }}
                    />
                    <div className='font-14 text-slate-400 ml-4'>{isRecurring ? 'EDIT' : ''} RECURRING TASK</div>
                  </div>
                  {/* <img
                      src={'/images/icons/threeDots.svg'}
                      style={{
                        cursor: 'pointer',
                        height: '16px',
                        width: '16px',
                      }}

                      alt='icon'
                    /> */}
                </div>
                <div className='mt-4'>
                  <div className='font-14 mb-2'>Repeat every</div>
                  <div className='flex align-center'>
                    {frequency !== 'year' && <Dropdown
                      className='mr-2'
                      overlayStyle={{ zIndex: 10000 }}
                      trigger={['click']}
                      menu={{
                        items: getDurationItems()
                      }}
                      placement='bottom'
                      arrow
                    >
                      <div className='flex align-center justify-center border border-slate-200 p-1 px-4'>
                        <div className='font-14'>{duration}</div>
                        <img
                          src={'/images/icons/show-more.svg'}
                          style={{ margin: "10px" }}
                          alt="icon"
                        />
                      </div>
                    </Dropdown>}
                    <Dropdown
                      overlayStyle={{ zIndex: 10000 }}
                      trigger={['click']}
                      menu={{
                        items: options?.map(option => ({
                          key: option,
                          label: option,
                          onClick: () => setFrequency(option)
                        })),
                      }}
                      placement='bottom'
                      arrow
                    >
                      <div className='flex align-center justify-center border border-slate-200 p-1 px-5'>
                        <div className='font-14'>{frequency}{duration > 1 && 's'}</div>
                        <img
                          src={'/images/icons/show-more.svg'}
                          style={{ margin: "10px" }}
                          alt="icon"
                        />
                      </div>
                    </Dropdown>
                  </div>
                </div>
                <div className='flex flex-col mt-4 font-16'>
                  <div className='font-14 mb-2'>Create new tasks</div>
                  <Radio className='mb-2' checked={isTriggerComplete} onChange={() => setTriggerComplete(true)}>Upon Completion</Radio>
                  <Radio checked={!isTriggerComplete} onChange={() => setTriggerComplete(false)}>Now</Radio>
                </div>

                <div className='my-5 flex flex-col justify-center'>
                  <div className='flex align-center mb-2'>
                    <div className='font-14 mr-5'>Ends</div>
                  </div>
                  <Radio checked={isTriggerComplete} onChange={() => setTriggerComplete(true)}>Never</Radio>
                  {/* <div className='flex align-center justify-between'>
                    <Radio checked={!isTriggerComplete} onChange={() => setTriggerComplete(false)}>On</Radio>
                    <DatePicker />
                  </div> */}
                  <div className='flex align-center justify-between'>
                    <Radio checked={!isTriggerComplete} onChange={() => setTriggerComplete(false)}>After</Radio>
                    <div className='ml-2 flex align-center'>
                      <input
                        max={99}
                        className='h-8 w-12 px-1 border-slate-400 border'
                        type='number'
                        onChange={(e) => setIteration(e?.target?.value)} />
                      <div className='font-14 ml-5'>Occurances</div>
                    </div>
                  </div>
                </div>
                <div className='flex align-center justify-around'>
                  {!isRecurring && <div
                    onClick={() => setShowRecurringDate(false)}
                    className='text-red-500 border-red-400 hover:bg-slate-50 font-14 border-slate-300 border w-full text-center cursor-pointer p-1 mr-2'>
                    Cancel
                  </div>}
                  <div
                    onClick={() => {
                      setIsCalender(false);
                      handleRecurringTask();
                    }}
                    className='text-white bg-indigo-500 hover:bg-indigo-400 font-14 border-slate-300 border w-full text-center cursor-pointer p-1'>
                    {isRecurring ? 'Update' : 'Save'}
                  </div>
                </div>
              </div>}
            <div
              className={`site-calendar-demo-card relative ${showRecurringDate && 'w-full'}`}
              style={{ color: 'black' }}
            >
              {!showRecurringDate && <Icon
                name='close.svg'
                height='14px'
                onClick={() => {
                  setIsCalender(false);
                }}
              />}
              <Calendar
                style={{ background: 'white' }}
                fullscreen={false}
                value={calendarDate ? calendarDate : moment()}
                onSelect={(e) => {

                  let dueDate = null;
                  if (e === null) {
                    dueDate = null;
                    //setCalendarDate(null);
                  } else {
                    dueDate = e?.format('YYYY-MM-DD');
                    //setCalendarDate(moment.utc(new Date(dueDate)));
                  }
                  setCalendarDate(moment.utc(new Date(e.format('YYYY-MM-DD'))));
                  dispatch(
                    editTask({
                      _id: task._id,
                      dueDate: dueDate,
                      recurringConfig: {
                        recurring: showRecurringDate || false
                      }
                    }, null, () => {
                      !!dueDate && setCalendarDate(task.dueDate && moment.utc(new Date(task.dueDate)))
                    })
                  );
                }}
              />
              <div className={`flex align-center ${!isDateSelected && 'disabled'}`} onClick={() => { isDateSelected && setShowRecurringDate(!showRecurringDate) }} >
                <Checkbox checked={showRecurringDate} />
                <div className='ml-2 underline text-indigo-500 cursor-pointer font-16'>{isRecurring ? '' : 'Make'} Recurring</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className='font-12'
        style={{ color: '#ADADAD' }}
      >
        <>
          {calendarDate === '' || !calendarDate && (
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={e => {
                setCalendarPosition({
                  x: e.pageX,
                  y: e.pageY
                })
                setIsCalender(!isCalender)
                setShowRecurringDate(false)
              }}
            >
              <div className='d-flex align-center'>
                <Tooltip title='Add due date'>
                  <div className="flex items-center text-indigo-400">
                    <svg className="w-4 h-4 shrink-0 fill-current mr-1.5" viewBox="0 0 16 16">
                      <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
                    </svg>
                    <div className={`${isLarge ? 'font-14' : 'font-12'} ml-1`}>Add Date</div>
                  </div>
                </Tooltip>
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default CalendarDropdown;
