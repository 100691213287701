import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import ProductNavBar from '../molecules/upper-navbar/ProductNavBar'
import SelectWorkspaceCard from '../molecules/select-workspace/SelectWorkspaceCard';

import { logout } from '../../../actions/logoutAction';
import { acceptWorkspaceInvite, declineWorkspaceInvite, switchWorkspace, getWorkspaces, getWorkspaceInvites, clearUnauthorized } from '../../../actions';
import '../../../assets/style/global.css';
import '../../../assets/style/workspace.css';

const SelectWorkspace = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = props;

  const [selectedWorkspace, setSelectedWorkspace] = useState('');
  const [workspaces, setWorkspaces] = useState(props.workspaces);
  const [pendingInvites, setPendingInvites] = useState(props.pendingInvites);

  useEffect(() => {
    dispatch(getWorkspaces('lean'));
    dispatch(getWorkspaceInvites());
    dispatch(clearUnauthorized());
  }, [])

  useEffect(() => {
    setPendingInvites(props.pendingInvites);
    setWorkspaces(props.workspaces);
  }, [props.pendingInvites, props.workspaces]);

  const handleSelectedWorkspace = async (workspace) => {
    setSelectedWorkspace(workspace);
  };

  const setWorkspace = async () => {
    if (selectedWorkspace) {
      console.log("------- selectedWorkspace", selectedWorkspace)
      sessionStorage.setItem("workspaceId", selectedWorkspace.targetId || selectedWorkspace._id);
      localStorage.setItem("workspaceId", selectedWorkspace.targetId || selectedWorkspace._id);
      sessionStorage.setItem("organization", selectedWorkspace?.organization?._id || selectedWorkspace?.organization);
      localStorage.setItem("organization", selectedWorkspace?.organization?._id || selectedWorkspace?.organization);

      await dispatch(switchWorkspace(selectedWorkspace, navigate('/', { replace: true })));
    }
  }

  const handleAcceptWorkspace = async (workspace) => {
    await dispatch(acceptWorkspaceInvite(workspace._id, () => {
      setWorkspaces([...workspaces, workspace]);
      dispatch(switchWorkspace(workspace, navigate('/', { replace: true })));
    }));
  }

  const handleDeclineWorkspace = async (workspace) => {
    await dispatch(declineWorkspaceInvite(workspace._id, () => {
      setWorkspaces([...workspaces, workspace]);
    }));
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('workspaceId');
    localStorage.removeItem('organization');
    localStorage.removeItem('toggle');
    localStorage.removeItem('inviteUrl');
    localStorage.removeItem('Id');


    sessionStorage.removeItem('workspaceId');
    sessionStorage.removeItem('organization');

    dispatch(logout());
    window.location.href = '/signin';
  };

  const noData = pendingInvites?.length === 0 && workspaces?.length === 0;

  return (
    <div className="select-workspace-page">
      <ProductNavBar button='Back to Workflo' />
      <div className="select-workspace-container pt-20">
        <div className="select-workspace-title">
          👋 Welcome {user?.fullName || 'back'}! Wishing you a productive day ahead!
        </div>
        <div className="select-workspace">
          <div className="select-workspace-p">Choose the workspaces you'd like to open</div>
          <SelectWorkspaceCard email={user.email || ''} workspaces={workspaces} handleSelectedWorkspace={handleSelectedWorkspace} />
          {noData &&
            <div className="font-16 text-indigo-600 text-center mb-4">
              You have no workspaces, but you can create one!
            </div>}
          <button
            onClick={() => {
              if (noData) {
                navigate('/create-workspace');
              } else {
                setWorkspace();
              }
            }}
            className={`butn-primary font-14 ${!selectedWorkspace && !noData && 'disabled'} workspace-open-button`}>
            {noData ? 'Create Workspace' : 'Open Workspace'}
          </button>
          {pendingInvites && <div style={{ marginTop: 30, width: '100%' }}>
            <SelectWorkspaceCard
              email={user.email || ''}
              workspaces={pendingInvites}
              handleSelectedWorkspace={handleSelectedWorkspace}
              handleAcceptWorkspace={handleAcceptWorkspace}
              handleDeclineWorkspace={handleDeclineWorkspace}
              isInvites />
          </div>}
          <div className='single-workspace-card' onClick={() => handleLogout()}>
            <img src='images/icons/noWorkspace.svg' width={100} height={68} />
            <div className="d-flex align-center" style={{ justifyContent: 'space-between', width: '100%' }}>
              <div style={{ paddingLeft: 20 }}>Not seeing your workspace?</div>
              <span
                className='text-blue-primary'
                style={{ cursor: 'pointer', paddingRight: 20 }} >Try a different email</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    workspaces: state.workspaceReducer.getWorkspaces,
    pendingInvites: state.workspaceReducer.pendingWorkspaceInvites
  };
}

export default connect(mapStateToProps)(SelectWorkspace);

