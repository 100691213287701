import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import socket from '../../../../js/socket';
import Icon from '../../atoms/global/Icon';
import FileHolder from '../../organisms/FileHolder';

import ConfirmationModal from '../../molecules/modals/ConfirmationModal';
import HtmlRenderer from '../../organisms/HtmlRenderer';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  isChatOpen,
  isTicketOpen,
  isChatSettingsOpen,
} from '../../../../actions';
import TextEditor from '../editor/TextEditor';

const DrawerCommentSection = (props) => {
  const [showTime, setShowTime] = useState(false);
  const [editMessage, setEditMessage] = useState(false);
  const [editedValue, setEditedValue] = useState(props.comment);
  const [open, setOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const msgId = props?.msgId || new URLSearchParams(location.search).get('msgId');
  const msgParam = new URLSearchParams(location.search).get('msgId');
  const loggedUser = useSelector((state) => state.userReducer.user);
  const { chats } = useSelector((state) => state.chatReducer);

  const { users } = useSelector((state) => state.userReducer);
  const mentionOptions = users.map(user => ({
    id: user._id,
    value: user.fullName,
    icon: user.avi
  }));

  // Delete handler
  const deleteHandler = (messageId) => {
    if (props?.context == 'task') {
      socket.emit('delete-message', { messageId, userId: loggedUser?._id, context: props?.context, roomId: props.roomId });
      setOpen(false);
    } else {
      socket.emit('delete-reply', { replyId: messageId, messageId: msgId, userId: loggedUser?._id, context: props?.context, roomId: props.roomId });
    }

    setOpen(false);
  };

  // Message Edit handler

  const messageEditHandler = (event, messageId) => {
    event.preventDefault();
    if (props?.context == 'task') {
      const messageDetails = { messageId, editedValue, roomId: props.roomId, userId: loggedUser?._id, context: props?.context, taskId: props.taskId };
      socket.emit('edit-message', messageDetails);
    } else if (messageId?.toString() === msgId?.toString()) {
      const messageDetails = { messageId, editedValue, roomId: props.roomId, userId: loggedUser?._id, parent: msgParam, context: props?.context };
      socket.emit('edit-message', messageDetails);
    } else {
      const messageDetails = { messageId, editedValue, roomId: props.roomId, userId: loggedUser?._id, parent: msgParam, context: props?.context };
      socket.emit('edit-reply', messageDetails);
    }
    setEditMessage(false);
  };

  return (
    <>
      <ConfirmationModal
        title='Delete Message?'
        description='Are you sure you want to delete this Message?'
        isModalOpen={open}
        onCancel={() => setOpen(false)}
        onYesClick={() => deleteHandler(props.msgId)}
      />
      <div
        className={`d-flex reply-comment-drawer  ${props.parentClass || ''}`}
        onMouseOver={() => {
          setIsHovering(true);
          setShowTime(true);
        }}
        onMouseOut={() => {
          setIsHovering(false);
          setShowTime(false);
        }}
      >
        {isHovering && props?.senderId === localStorage.getItem('Id') && (
          <div
            className='commentBox'
            style={{ cursor: 'pointer', top: '0px' }}
          >
            <Icon
              name='Edit.svg'
              height='20px'
              className='hover:opacity-60'
              pl='0px'
              onClick={() => {
                setEditMessage(!editMessage);
              }}
            />
            <Icon
              name='delete-red.svg'
              height='20px'
              className='hover:opacity-60'
              pl='0px'
              onClick={() => {
                setOpen(true);
              }}
            />
          </div>
        )}
        <div className='h-10 w-10 pt-2 rounded'>
          <img
            src={props?.src}
            style={{ height: props.height, width: props.width, objectFit: 'cover', borderRadius: '9999px' }}
            alt='icon'
          />
        </div>
        <div style={{ width: '90%', position: 'relative' }}>
          <div
            className='d-flex align-center'
          >
            <div
              className={props.nameClass}
              onClick={() => {
                dispatch(isChatSettingsOpen(true, props?.senderId));
              }}>
              {props.name}
            </div>
            <div className='ml-2 pt-1' style={{ fontSize: '14px', color: '#ADADAD' }}>{"  ·  " + props?.hours}</div>
          </div>
          {editMessage ? (
            <form
              onSubmit={(event) => messageEditHandler(event, props.msgId)}
              onKeyDown={(e) => {
                if (e?.code === 'Escape') {
                  messageEditHandler(e, props.msgId)
                }
              }}
            >
              <div className='chat-area'>
                <TextEditor
                  placeholder="Reply..."
                  onChange={(content) => {
                    setEditedValue(content)
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault(); // Prevent the default Enter behavior (submit)
                      messageEditHandler(e, msgId); // Call your save function here
                    }
                  }}
                  comment={editedValue}
                  mentionOptions={mentionOptions}
                />
              </div>
            </form>
          ) : (
            <>
              <div
                style={{
                  marginTop: props.commentPadding,
                  width: props.containerWidth,
                  textAlign: 'inherit',
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-line',
                }}
                onClick={async (e) => {

                  const url = e?.target?.href;

                  if (url) {
                    e.preventDefault();

                    let taskIdentifer = url.split('selectedTask=');

                    if (taskIdentifer.length > 1) {
                      const selectedTask = taskIdentifer[1];

                      const params = new URLSearchParams(location.search);
                      params.delete('msgId'); // Remove the specified parameter
                      params.set('selectedTask', selectedTask)
                      // Construct the new URL with updated parameters
                      const newUrl = `${location.pathname}?${params.toString()}`;

                      await dispatch(isChatOpen(false));

                      // Use navigate to replace the current URL
                      navigate(newUrl);

                      await dispatch(isTicketOpen(true));

                    } else {
                      window.location = url;
                    }
                  }
                }}
                className={props.commentClass}
              >
                <HtmlRenderer html={props.comment} />
              </div>
              <div className='d-flex' style={{ objectFit: 'cover' }}>
                {props.files &&
                  props.files.map((file) => {
                    return <FileHolder file={{ name: file?.name, url: file?.signedUrl }} />
                  })}
              </div>

            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DrawerCommentSection;
