import React from "react";
const AssigneeOptionLabel = ({ value, label, email, avi }) => {
  return (
    <div className='flex align-center'>
      <img
        src={avi}
        alt=''
        style={{ height: '24px', width: '24px', borderRadius: 20, objectFit: 'cover' }}
      />
      <div className='font-bold font-16 py-1 px-2'>
        {label}
      </div>
    </div>
  )
};
export default AssigneeOptionLabel;
