import React, { useState, useRef, useEffect } from 'react';

import AssigneeIcon from './Icons/Assignee.jsx';
import LabelsIcon from './Icons/Label.jsx';
import PriorityIcon from './Icons/Priority.jsx';
import ProjectIcon from './Icons/Project.jsx';
import StatusIcon from './Icons/Status.jsx';
import HighPriorityIcon from './Icons/HighPriority.jsx';
import MediumPriorityIcon from './Icons/MediumPriority.jsx';
import LowPriorityIcon from './Icons/LowPriority.jsx';
import '../../../../../assets/style/taskFilter.css';
import UserIcon from './Icons/UserIcon.jsx';
import LowestPriorityIcon from './Icons/LowestPriority.jsx';
import CustomFieldIcon from './Icons/CustomFieldIcon.jsx';
import CraetorIcon from './Icons/Creator.jsx';
import ResolutionIcon from './Icons/Resolution.jsx';

const TaskFilter = ({ selectedLabels, onLabelSelection, onPopupSelection, users, teams, handleSaveUserFilter, handleResetFilter, projects }) => {

  const handleClickOutside = (event) => {
    if (filterContainerRef.current && !filterContainerRef.current.contains(event.target)) {
      setMenuOpen(false);
      setLabelMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filterContainerRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [labelMenuOpen, setLabelMenuOpen] = useState(false);
  const [labelData, setLabelData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchInputMenu, setSearchInputMenu] = useState('');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setLabelMenuOpen(false);
    setSearchInputMenu('');
  };

  const saveFilter = (defaultFilter) => {
    handleSaveUserFilter(defaultFilter);
  }

  const resetFilter = () => {
    handleResetFilter();
  }

  const openLabelMenu = (dropdownData = []) => {
    setLabelData(dropdownData);
    setLabelMenuOpen(true);
    setMenuOpen(false);
    setSearchInputMenu('');
  };

  const toggleLabelCheck = (label) => {
    if (selectedLabels.some(item => item.id === label.id && item.type === label.type)) {
      const indexToRemove = selectedLabels.findIndex(
        (it) => it.id === label.id && it.type === label.type
      );

      if (indexToRemove !== -1) {
        selectedLabels.splice(indexToRemove, 1);
      }
      onLabelSelection(selectedLabels);

    } else {
      onLabelSelection([...selectedLabels, label]);
    }
  };

  let dropdownData = [
    // { text: 'Status', icon: <StatusIcon /> },
    {
      text: 'Assignee',
      icon: <AssigneeIcon />,
      dropdown: users?.map(u => {
        return {
          text: u?.fullName,
          id: u?._id,
          icon: <UserIcon avi={u?.avi} />,
          type: 'Assignee',
          parentIcon: <AssigneeIcon />
        }
      }) || []
    },
    {
      text: 'Priority',
      icon: <PriorityIcon />,
      dropdown: [
        { text: 'P1', id: 'p1', icon: <HighPriorityIcon />, type: 'Priority', parentIcon: <PriorityIcon /> },
        { text: 'P2', id: 'p2', icon: <MediumPriorityIcon />, type: 'Priority', parentIcon: <PriorityIcon /> },
        { text: 'P3', id: 'p3', icon: <LowPriorityIcon />, type: 'Priority', parentIcon: <PriorityIcon /> },
        { text: 'P4', id: 'p4', icon: <LowestPriorityIcon />, type: 'Priority', parentIcon: <PriorityIcon /> },
      ],
    },
    // { text: 'Labels', icon: <LabelsIcon /> },
    {
      text: 'Project',
      icon: <ProjectIcon />,
      dropdown: teams?.reduce((acc, team) => {
        const projectsArray = projects?.map(project => ({
          id: project._id,
          text: project.title,
          type: 'Project',
          parentIcon: <ProjectIcon />
        })) || [];

        return [...acc, ...projectsArray];
      }, [])
    },
    {
      text: 'Creator',
      icon: <CraetorIcon />,
      dropdown: users?.map(u => {
        return {
          text: u?.fullName,
          id: u?._id,
          icon: <UserIcon avi={u?.avi} />,
          type: 'Creator',
          parentIcon: <CraetorIcon />
        }
      }) || []
    },
    {
      text: 'Resolution',
      icon: <ResolutionIcon />,
      dropdown: [
        {
          text: 'Resolved',
          id: true,
          type: 'Resolution',
          parentIcon: <ResolutionIcon />
        }, {
          text: 'Unresolved',
          id: false,
          type: 'Resolution',
          parentIcon: <ResolutionIcon />
        }
      ]
    }
    // { text: 'Status', icon: <StatusIcon /> },
    // { text: 'Assignee', icon: <AssigneeIcon /> },
    // {
    //   text: 'Priority',
    //   icon: <PriorityIcon />,
    //   dropdown: [
    //     { text: 'High', icon: <HighPriorityIcon /> },
    //     { text: 'Medium', icon: <MediumPriorityIcon /> },
    //     { text: 'Low', icon: <LowPriorityIcon /> },
    //   ],
    // },
    // { text: 'Labels', icon: <LabelsIcon /> },
    // { text: 'Project', icon: <ProjectIcon /> },
    // { text: 'Status', icon: <StatusIcon /> },
    // { text: 'Assignee', icon: <AssigneeIcon /> },
    // {
    //   text: 'Priority',
    //   icon: <PriorityIcon />,
    //   dropdown: [
    //     { text: 'High', icon: <HighPriorityIcon /> },
    //     { text: 'Medium', icon: <MediumPriorityIcon /> },
    //     { text: 'Low', icon: <LowPriorityIcon /> },
    //   ],
    // },
    // { text: 'Labels', icon: <LabelsIcon /> },
    // { text: 'Project', icon: <ProjectIcon /> },
    // { text: 'Status', icon: <StatusIcon /> },
    // { text: 'Assignee', icon: <AssigneeIcon /> },
    // {
    //   text: 'Priority',
    //   icon: <PriorityIcon />,
    //   dropdown: [
    //     { text: 'High', icon: <HighPriorityIcon /> },
    //     { text: 'Medium', icon: <MediumPriorityIcon /> },
    //     { text: 'Low', icon: <LowPriorityIcon /> },
    //   ],
    // },
    // { text: 'Labels', icon: <LabelsIcon /> },
    // { text: 'Project', icon: <ProjectIcon /> },

  ];
  // console.log("----- teams", teams)
  // const customFieldData = teams.reduce((accumulator, team) => {
  //   const customFields = projects.reduce((projectAccumulator, project) => {
  //     const cfArray = project?.customFields || [];
  //     const dropdownFields = cfArray
  //       .filter((cf) => cf?.type === 'dropdown')
  //       .map((cf) => ({
  //         text: cf?.name,
  //         icon: <LabelsIcon />,
  //         dropdown: cf?.possibleValues?.map((pv) => ({
  //           text: pv?.label,
  //           id: pv?._id,
  //           type: 'customField',
  //           customFieldId: cf?._id,
  //           name: cf?.name
  //         })),
  //       }));
  //     return projectAccumulator.concat(dropdownFields);
  //   }, []);

  //   return accumulator.concat(customFields);
  // }, []);

  console.log("----- projects", projects)
  const customFieldData = projects?.reduce((accumulator, project) => {
    const cfArray = project?.customFields || [];
    const dropdownFields = cfArray
      .filter((cf) => cf?.type === 'dropdown')
      .map((cf) => ({
        text: cf?.name,
        icon: <LabelsIcon />,
        dropdown: cf?.possibleValues?.map((pv) => ({
          text: pv?.label,
          id: pv?._id,
          type: 'customField',
          customFieldId: cf?._id,
          name: cf?.name
        })),
      }));
    return accumulator.concat(dropdownFields);
  }, []);

  dropdownData = dropdownData.concat(customFieldData);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };
  const handleSearchInputMenuChange = (e) => {
    setSearchInputMenu(e.target.value);
  };
  const filteredLabelData = labelData?.filter((item) =>
    item?.text.toLowerCase().includes(searchInput.toLowerCase())
  );
  const filteredDropdownData = dropdownData?.filter((item) =>
    item?.text.toLowerCase().includes(searchInputMenu.toLowerCase())
  );

  return (
    <div ref={filterContainerRef} className="task-filter" style={{ zIndex: (menuOpen || labelMenuOpen) && 999999999 }}>
      <ul className="flex flex-wrap -m-1">
        <li className="m-1">
          <button onClick={toggleMenu} className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out">
            Filters
          </button>
        </li>
        <li className="m-1">
          <button onClick={() => saveFilter(true)} className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
            Save
          </button>
        </li>
        <li className="m-1">
          <button onClick={() => resetFilter()} className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
            Reset
          </button>
        </li>
      </ul>
      {labelMenuOpen && (
        <div className="filter-menu">
          <input
            type="text"
            value={searchInput}
            onChange={handleSearchInputChange}
            placeholder="Search..."
            className="filter-search-input"
          />
          <div className="divider" />
          <div className="filter-list">
            {filteredLabelData.map((item, index) => (
              <label
                key={index}
                className="filter-item" d>
                <input
                  className="filter-checkbox"
                  type="checkbox"
                  onChange={() => toggleLabelCheck(item)}
                  checked={selectedLabels.some(it => it.id === item.id && it.type === item.type)}
                />
                {item.icon}
                {item.text}
              </label>
            ))}
          </div>
        </div>
      )}
      {menuOpen && (
        <div className="filter-menu">
          <input
            type="text"
            value={searchInputMenu}
            onChange={handleSearchInputMenuChange}
            placeholder="Filter..."
            className="filter-search-input"
          />
          <div className="divider" />
          <div className="filter-dropdown">
            <div className="filter-list">
              {filteredDropdownData.map((item, index) => (
                <button
                  key={index}
                  onClick={() => openLabelMenu(item.dropdown)}
                  className="filter-item"
                  type="button"
                >
                  {item.icon}
                  {item.text}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskFilter;
