import axios from "axios";
import { notification } from "antd";
const baseUrl = process.env.REACT_APP_API_URL;
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
const config = {
  headers: { Authorization: ""}
};
export const getSections = () => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
      const res = await axios.get(`${baseUrl}/sections`, config);
      dispatch({
        type: "getSections",
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const addSection = (data,callBack) => {
  let backgroundColorArray = [
    "#24CF73",
    "#F4BF00",
    "#0077EE",
    "#FE3C32",
    "#936BF3",
  ];
  function getRandomNum(min = 0, max = 5) {
    return Math.floor(Math.random() * (max - min) + min);
  }
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
      const res = await axios.post(`${baseUrl}/sections/add`, {...data,color:backgroundColorArray[getRandomNum()]} ,config);
      await dispatch({
        type: "success",
        payload: res,
      });
      callBack && callBack();

      notification.success({
        message: "You just added a new section",
        placement: "bottomRight",
      });
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
      notification.error({
        message: "Section's Title already used",
        placement: "bottomRight",
      });
    }
  };
};

export const deleteSection = (id, callBack) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
      const res = await axios.post(`${baseUrl}/sections/remove`, {
        sectionId: id
      }, config);
      dispatch({
        type: "success",
        payload: res,
      });
      notification.success({
        message: "You just deleted a section",
        placement: "bottomRight",
      });
      callBack();
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
      notification.error({
        message: "Oops! something went wrong",
        placement: "bottomRight",
      });
    }
  };
};

export const editSection = (data, callBack) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
      const res = await axios.put(`${baseUrl}/sections/edit`, data, config);
      await dispatch({
        type: "success",
        payload: res,
      });
      callBack();
      notification.success({
        message: "You just update a section",
        placement: "bottomRight",
      });
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
      notification.error({
        message: "Section's Title already used",
        placement: "bottomRight",
      });
    }
  };
};

export const isDuplicateField = (val) => {
  return {
    type: "isDuplicateField",
    payload: val,
  };
};
