import React, { useState } from 'react';
import { Avatar, Badge } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import Icon from '../atoms/global/Icon';

const AvatarWithName = (props) => {
  const [select, setSelect] = useState(false);
  let tickBadge = (
    <Avatar
      style={{
        backgroundColor: '#007FFF',
      }}
      size={15}
      icon={<CheckOutlined />}
    />
  );

  const handleParticipants = () => {
    setSelect(!select);
    if (!select) {
      props.setParticipants([
        ...props.participants,
        { _id: props.user._id, role: 'member' },
      ]);
    } else {
      props.participants.forEach((participant, index) => {
        if (participant._id === props.user._id) {
          props.participants.splice(index, 1);
          props.setParticipants([...props.participants]);
        }
      });
    }
  };

  return (
    <div
      className="flex align-center mb-2 cursor-pointer"
      onClick={handleParticipants}
    >
      {select ? (
        <Badge count={tickBadge} offset={[-5, 30]} size="small" color="blue">
          <Avatar
            src={props?.user?.avi}
            style={{
              marginLeft: '5px',
              marginTop: '1px',
            }}
          >
          </Avatar>
        </Badge>
      ) : (
        <Avatar
          src={props?.user?.avi}
          style={{
            marginLeft: '5px',
            marginTop: '1px',
          }}
        >
        </Avatar>
      )}
      <div className='ml-4 flex-col align-center'>
          <div className='font-16 font-bold'>{props.user.fullName}</div>
          <div className='font-14'>{props.user.email}</div>
      </div>
    </div>
  );
};

export default AvatarWithName;
