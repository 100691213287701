import React, { useState } from "react";
import ResetForm from "../molecules/ResetForm";
import MessageConfirmation from "../molecules/MessageConfirmation";

import Icon from '../atoms/global/Icon';

const ResetPassword = () => {
  const [check, setCheck] = useState(false);
  const [mail, setMail] = useState("");
  return (

    <div
      className='sign-in-page'>
      <div style={{ padding: 15, float: 'left', cursor: 'pointer' }}>
        <Icon
          name='logo.svg'
          height='16px'
          pr='20px' />
      </div>
      <div className="login-page d-flex align-center" style={{ top: 0 }}>
        <img src='images/icons/loginIcon.png' width={200} />
        {/* {!signup ? */}
        <div className="login-container">
          {!check && <ResetForm setCheck={setCheck} setMail={setMail} />}
          {check && <MessageConfirmation mail={mail} />}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
