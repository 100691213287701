import { combineReducers } from 'redux';
import navReducer from './navReducer';
import toggleReducer from './toggleReducer';
import sectionReducer from './sectionReducer';
import taskReducer from './taskReducer';
import paymentReducer from './paymentReducer';
import signupReducer from './signupReducer';
import userReducer from './userReducer';
import commentReducer from './commentReducer';
import roomReducer from './roomReducer';
import messageIdReducer from './messageIdReducer';
import workspaceReducer from './workspaceReducer';
import projectReducer from './projectReducer';
import chatReducer from './chatRoomReducer';
import searchReducer from './searchReducer';
import notificationReducer from './notificationReducer';
import calendarReducer from './calendarReducer';
import messageReducer from './messageReducer';
import teamReducer from './teamReducer';
import modalReducer from './modalReducer';
import aiReducer from './aiReducer';
import inviteReducer from './inviteReducer';
import onboardingReducer from './onboardingReducer';
import orgReducer from './orgReducer';

const rootReducer = combineReducers({
  projectReducer: projectReducer,
  workspaceReducer: workspaceReducer,
  messageIdReducer: messageIdReducer,
  roomReducer: roomReducer,
  inviteReducer: inviteReducer,
  commentReducer: commentReducer,
  userReducer: userReducer,
  signupReducer: signupReducer,
  navReducer: navReducer,
  toggleReducer: toggleReducer,
  sectionReducer: sectionReducer,
  taskReducer: taskReducer,
  paymentReducer,
  chatReducer,
  searchReducer,
  notificationReducer,
  calendarReducer,
  messageReducer,
  teamReducer,
  modalReducer,
  aiReducer,
  onboardingReducer,
  orgReducer
});

export default rootReducer;
