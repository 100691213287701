import React, { useState, useEffect, useRef } from 'react';
import { Modal, Input, Dropdown, Checkbox } from "antd";

import { useDispatch } from 'react-redux';
import {
  getProjectById,
  uploadTaskUrl,
  addTask,
  editTask,
  signedUploadUrl,
  isTicketOpen,
  isChatOpen,
  clearSingleTask
} from '../../../../actions';

import TextAreaFileHolder from '../../organisms/TextAreaFileHolder';
import AssigneeSelect from '../../molecules/assignee/AssigneeSelect';
import '../../../../assets/style/modal.css';
import CalendarDropdown from '../task-components/CalendarDropdown';
import TextEditor from '../editor/TextEditor';
import { useLocation, useNavigate } from 'react-router-dom';

const NewTaskModal = (props) => {
  const {
    isModalOpen,
    onClose,
    projects,
    defaultTitle
  } = props;

  const dispatch = useDispatch();

  const [selectedProject, setSelectedProject] = useState();
  const [selectedSection, setSelectedSection] = useState();

  const [sectionsMenu, setSectionsMenu] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);

  const [taskName, setTaskName] = useState(defaultTitle || '');
  const [taskNameDescription, setTaskDescription] = useState('');

  const [keepModalOpen, setKeepModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fileInputRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();


  const closeModal = () => {
    setTaskName('');
    setTaskDescription('');
    setSelectedSection();
    setSelectedProject();
    setSelectedAssignees([]);
    setUploadedFiles([]);
    if (!keepModalOpen) {
      onClose();
    }
  }

  const handleCreate = () => {
    onCreate(taskName, taskNameDescription, selectedProject._id, selectedSection._id, selectedAssignees);
    closeModal();
  }

  useEffect(() => {
    setTaskName(defaultTitle);
  }, [defaultTitle])

  const isButtonDisabled = !taskName || !selectedProject || !selectedSection;

  const openNewTicket = (newTask) => {
    const params = new URLSearchParams(location.search);
    if(!newTask) {
      dispatch(isTicketOpen(false));
      params.delete('selectedTask');
      const newUrl = `${location.pathname}?${params.toString()}`;
      navigate(newUrl);
      return;
    }
    dispatch(clearSingleTask())
    params.delete('msgId'); // Remove the specified parameter

    params.set('selectedTask', newTask?.titleIdentifier)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    // Use navigate to replace the current URL
    navigate(newUrl);

    dispatch(isTicketOpen(true));
    dispatch(isChatOpen(false));
  };

  const onCreate = async (taskName, taskNameDescription, Project, Section, Assignee) => {
    const data = {
      taskTitle: taskName,
      taskDescription: taskNameDescription,
      // section: Section,
      isCompleted: false,
      // projectId: Project,
      projects: [
        {
          projectId: Project,
          section: Section,
          position: 'top'
        }
      ],
      dueDate: '',
      addedTo: 'top'
    };
    //TODO: Osama handle multiple assignees
    data.assignee = [];
    for (let a of Assignee) {
      data.assignee.push(
        {
          assigneeId: a
        }
      );
    }
    const files = fileInputRef.current.files;
    const uploadedFiles = await uploadFiles(files);
    data.files = uploadedFiles;
    const newTask = await dispatch(addTask(data));
    openNewTicket(newTask);
  }

  // Handle attachments
  const uploadFiles = async (files, task) => {
    const fileList = [];
    if(files?.length == 0 || !files) {
      return;
    }
    for (const file of files) {
      setIsLoading(true);
      const uploadTaskResponse = await dispatch(uploadTaskUrl({
        fileName: file.name,
        type: file?.type,
        fileSize: file?.size
        // taskId: task?._id,
      }));
      const fileId = uploadTaskResponse?.fileId;
      const signedUrl = uploadTaskResponse?.signedUrl;
      if (signedUrl) {
        fileList.push(fileId);
        await dispatch(signedUploadUrl(signedUrl, file));
      }
    }

    let allFiles = fileList;

    // if (task?.files) {
    //   allFiles = fileList.concat(task?.files || []);
    // }

    return allFiles;
    // dispatch(
    //   editTask(
    //     {
    //       _id: task._id,
    //       taskTitle: task.taskTitle,
    //       files: allFiles,
    //       attachments: []
    //     }, () => {
    //       setIsLoading(false);
    //     }
    //   ));
  }

  const footer = () => {
    return (
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
        onClick={() => handleCreate()}>
        <div className='flex align-center cursor-pointer' onClick={() => setKeepModalOpen(!keepModalOpen)}>
        {/**
         * @Osama, please uncomment the line below (onClick) once you fix VEBL-1229
         */}
          {/* <Checkbox checked={keepModalOpen} />
          <div className='font-16 ml-3'>Create another task</div> */}
        </div>
        <div className={`${isButtonDisabled && 'disabled'} nav-button new-task-nav-button font-16 py-4`} style={{ width: '8rem' }}>
          Create task
        </div>
      </div>
    );
  };

  return (
    <Modal
      className='new-task-modal-container'
      title={`Create New task ${taskName && '> ' + taskName}`}
      open={isModalOpen}
      onCancel={closeModal}
      bodyStyle={{
        backgroundColor: '#FFFFFF',
        padding: '1rem'
      }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={footer()}>
      <div className='new-task-modal'>
        <div className='font-14 font-bold mb-2'>Project Details</div>
        <div className='button-row d-flex'>
          <Dropdown
            trigger={['click']}
            menu={{
              items: projects?.map((project, i) => ({
                label:
                  <div onClick={() => {
                    setSelectedProject(project);
                    dispatch(getProjectById(project?._id, (res) => {
                      const sections = [];
                      res?.sections.map((section, i) => {
                        sections.push({
                          label:
                            <div onClick={() => setSelectedSection(section)} className='py-2 font-14'>
                              {section.title}
                            </div>,
                          key: i,
                        })
                      });
                      setSectionsMenu(sections);
                      res?.sections && setSelectedSection(res?.sections[0]);
                    }));
                  }} className='py-2 font-14'>
                    {project?.title}
                  </div>,
                key: i,
              }))
            }}>
            <div className={`cursor-pointer mr-3 w-full flex items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out`}>
              <div>{selectedProject ? selectedProject?.title : 'Select Project'}</div>
            </div>
          </Dropdown>
          <Dropdown
            disabled={!selectedProject}
            menu={{
              items: sectionsMenu
            }}>
            <div className={`${!selectedProject && 'opacity-60'} cursor-pointer w-full flex items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out`}>
              <div>{selectedSection ? selectedSection.title : 'Select Section'}</div>
            </div>
          </Dropdown>
        </div>
        <div className='font-14 font-bold mt-4 mb-2'>Task Details</div>
        <Input
          className='font-16 bg-gray-100 py-2 mb-3'
          placeholder='Task name'
          onChange={(e) => setTaskName(e.target.value)}
          maxLength={300}
          value={taskName}
        />
        {/* <Input
          className='font-16 bg-gray-100 py-2 pb-10'
          onChange={(e) => setTaskDescription(e.target.value)}
          placeholder='Task description'
          value={taskNameDescription}
        /> */}
        <TextEditor
          large
          isTask
          placeholder='Add a description...'
          onChange={(e) => setTaskDescription(e)}
          onKeyUp={(e) => { }}
          comment={taskNameDescription}
          mentionOptions={[]}
        />

        <div className='font-14 font-bold mt-4 mb-2'>Additional Details</div>
        <div className='flex'>
          {/* <div className='w-[45%] flex align-center justify-center'>
            <AssigneeSelect
              projectUsers={selectedProject?.participants || []}
              task={{
                assignee: []
              }}
              iconSize={30}
              showNames
              editHandler={() => {

              }} />
            <div className='ml-8 flex flex-col justify-center align-center'>
              <CalendarDropdown task={{}} y={-90} />
            </div>
          </div> 
            <div className='w-[1px] h-20 bg-slate-300' /> */}
          {uploadedFiles && uploadedFiles?.map(f => (
            <div>
              <img
                alt="not found"
                className='h-24 pr-2 object-cover'
                width={"250px"}
                src={URL?.createObjectURL(f)}
              />
              <br />
              <button onClick={() => setUploadedFiles([])}>Remove</button>
            </div>
          ))}
          {uploadedFiles?.length < 4 && <div
            onClick={() => {
              fileInputRef.current.click();
            }}
            className='w-full flex align-center justify-center bg-gray-100 h-24 cursor-pointer hover:opacity-80 rounded'>
            <img src='/images/icons/ticket-pin.svg' className='mr-2' />
            Upload Image
            <input
              style={{ display: 'none' }}
              id="file-input"
              ref={fileInputRef}
              type='file'
              multiple
              onChange={(event) => {
                if (uploadedFiles?.length < 4) {
                  setUploadedFiles([...uploadedFiles, ...event?.target?.files]);
                }
              }}
            />
          </div>}
        </div>
      </div>
    </Modal>
  );
};


export default NewTaskModal;
