import * as commentTypes from '../actions/types/comment.types.js';
const data = {
  comments: [{ _id: '1', messageDescription: '' }],
};

const commentReducer = (state = data, action) => {
  switch (action.type) {
    case commentTypes.COMMENT_SUCCESS:
      return {
        ...state,
        comment: action.payload,
      };

    case commentTypes.COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };

    case 'user-error':
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
export default commentReducer;
