import React, { useState, useEffect, useRef } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { Tooltip as Toolp } from 'antd';

import { Pie } from 'react-chartjs-2';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import HtmlRenderer from '../../../UI/organisms/HtmlRenderer';

import {
  getOrganizationById,
  isChatOpen,
  toggleNewTaskModal
} from '../../../../actions';

import EmptyState from '../../atoms/empty-state/EmptyState';
import { isTicketOpen, setTeamsState } from '../../../../actions/toggleAction';
import { getWorkspaceNotifications, updateQueryState, updateViewNotification } from '../../../../actions/notificationAction';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

function DashboardCardTasks(props) {

  const { taskCount, dashboardTasks, isTaskOpen, notifications, workspace, user } = props;
  ChartJS.register(ArcElement, Tooltip, Legend);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [allNotifications, setNotifications] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [orgDetails, setOrgDetails] = useState({});
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const notRef = useRef(null);


  const completedTasks = dashboardTasks?.completedTasks || 0;
  const unassignedTasks = dashboardTasks?.unassignedTasks || 0;

  const { query } = useSelector((state) => state.notificationReducer);


  const data = {
    labels: ['Incomplete', 'Completed', 'Unassigned'],
    datasets: [
      {
        label: '',
        data: [Math.max(taskCount - completedTasks, 0), completedTasks, unassignedTasks],
        backgroundColor: [
          'rgba(54, 162, 235, 0.5)',
          'rgba(75, 192, 192, 0.5)',
          'rgba(255, 159, 64, 0.5)'
        ],
      },
    ],
  };

  useEffect(() => {
    console.log("----- notifications", notifications, totalNotifications);
    if (totalNotifications < notifications?.totalNotifications) {
      // Calculate the difference between the total notifications
      const difference = notifications.totalNotifications - totalNotifications;
    
      // Get the elements from the start of notifications.notifications array
      const newNotifications = notifications.notifications.slice(0, difference);
    
      // Append the new notifications to the start of allNotifications array
      // allNotifications = [...newNotifications, ...allNotifications];
    
      // Update totalNotifications to reflect the new total
      setTotalNotifications(notifications.totalNotifications);
      setNotifications([...newNotifications, ...allNotifications])
    } else if (notifications?.notifications?.length > 0 && allNotifications?.length > 0 && notifications?.notifications[0]?._id?.toString() !== allNotifications[0]?._id?.toString()) {
      setNotifications([...notifications?.notifications]);
    }
    // setNotifications(notifications?.notifications)
  }, [notifications]);

  useEffect(() => {
    // Load initial notifications
    loadNotifications(true);

    return () => {
      // Cleanup when component unmounts
      dispatch(updateQueryState(1));
    };
  }, []);

  const loadNotifications = async (reset) => {
    // Prevent loading if already loading or all notifications are loaded
    if (isLoading || !hasMoreData) {
      return;
    }

    if (reset) {
      dispatch(updateQueryState(1));
    }

    setIsLoading(true);

    // Make your API call to fetch more notifications
    const notData = await dispatch(getWorkspaceNotifications(reset ? { page: 1 } : query));
    setTotalNotifications(notData?.totalNotifications);
    if (notData?.notifications?.length === 0) {
      // If the API returns an empty array, there is no more data
      setHasMoreData(false);
      if (reset) {
        setNotifications([]);
      }
    } else {
      // Append new notifications to the existing list
      setNotifications([...allNotifications, ...notData?.notifications]);
      dispatch(updateQueryState(query.page + 1));
    }

    setIsLoading(false);
  };

  const handleScroll = () => {
    const scrollableElement = notRef.current;

    // Check if the scrollbar is at the bottom
    if (
      scrollableElement?.scrollTop + scrollableElement?.clientHeight >=
      scrollableElement?.scrollHeight
    ) {
      loadNotifications();
    }
  };

  useEffect(() => {
    const workspaceMembers = workspace?.members || [];
    const workspaceUser = workspaceMembers?.find(m => m?._id?.toString() === user?._id?.toString());
    let isAdmin = workspaceUser?.role === 'owner' || workspaceUser?.role === 'admin';
    dispatch(getOrganizationById(workspace?.organization?._id)).then((orgById) => {
      setOrgDetails(orgById);
      const activeOrg = user?.organizations?.find(o => o?._id?.toString() === workspace?.organization?._id?.toString());
      isAdmin = activeOrg?.role === 'superAdmin' || activeOrg?.role === 'admin' || workspaceUser?.role === 'owner' || workspaceUser?.role === 'admin';
      setIsUserAdmin(isAdmin);
    });

  }, [workspace]);

  let percent = orgDetails?.size?.split('MB')?.[0] || 0;
  let divider = orgDetails?.license?.name === 'free' ? 100 : 25000;
  percent = (percent / divider) * 100;

  const openTask = async(task) => {
    console.log("----- openTicket")
    const params = new URLSearchParams(location.search);
    params.delete('msgId'); // Remove the specified parameter
    params.set('selectedTask', task.titleIdentifier)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    await dispatch(isChatOpen(false));
    await dispatch(setTeamsState(false));

    // Use navigate to replace the current URL
    navigate(newUrl);

    await dispatch(isTicketOpen(true));

  }

  const handleNotificationClick = (notification) => {
    const notificationType = notification.notificationType;
    const projectId = notification.projects && notification.projects?.[0] || '';
    const taskIdentifier = notification.task?.titleIdentifier;

    dispatch(updateViewNotification({ id: notification._id }));
    dispatch(getWorkspaceNotifications());
    dispatch(isTicketOpen(true, taskIdentifier));


    try {
      const searchParams = new URLSearchParams(window.location.search);
       if (searchParams.has('selectedTask')) {
        searchParams.delete('selectedTask');
      }

      // Remove the 'msgId' parameter if it exists
      if (searchParams.has('msgId')) {
        searchParams.delete('msgId');
      }
      if (
        notificationType === 'TaskNotification' ||
        notification.notificationType == 'ChatNotification'
      ) {
        // const url = notification?.notificationType == 'TaskNotification' ? `/${projectId}/${isListView ? 'list' : 'board'}?selectedTask=${taskIdentifier}` : `/chat/${notification?.comment?.roomId}`
        if(notification?.notificationType == 'TaskNotification') {
          searchParams.set('selectedTask', taskIdentifier);
          const newUrl = `${location.pathname}?${searchParams.toString()}`;
          navigate(newUrl, { replace: true });
        } else {
          navigate(`/chat/${notification?.comment?.roomId}`);
        }
        dispatch(setTeamsState(false))
      } else if (notification.notificationType == 'ChatReplyNotification') {
        dispatch(isChatOpen(true, notification?.repliedMessage?._id));
        searchParams.set('msgId', notification?.comment?._id);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl, { replace: true });
        // const url = `/chat/${notification?.comment?.roomId}?msgId=${notification?.comment?._id}`
        // navigate(url);
        dispatch(setTeamsState(false))
      } else if (notification.notificationType == 'InviteNotification') {
        const url = `/${notification?.contextId}/board`;
        navigate(url);
      } else {
        searchParams.set('selectedTask', taskIdentifier);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl, { replace: true });
        // navigate(`/${projectId}/${isListView ? 'list' : 'board'}?selectedTask=${taskIdentifier}`);
      }
    } catch (error) {
      navigate(`/`);
    }
  }


  return (
    <div className="grid sm:grid-rows-3 grid-rows-13 grid-flow-col grid-cols-4 gap-4 mt-10 ml-2 sm:h-[72%] h-[80vh]">
      {!isTaskOpen && <div className="row-span-1 lg:col-span-1 sm:col-span-full col-span-full flex flex-col">
        <div className='h-full flex w-full mb-4'>
          <div className='bg-[#FECDA1] h-full w-full flex flex-col align-center justify-center rounded-lg mr-4'>
            <div className='text-3xl font-light'>{unassignedTasks}</div>
            <div className='font-18'>Unassigned</div>
          </div>
          <div className='bg-[#9ECEF4] h-full w-full flex flex-col align-center justify-center rounded-lg'>
            <div className='text-3xl font-light'>{Math.max(taskCount - completedTasks, 0)}</div>
            <div className='font-18'>Incomplete</div>
          </div>
        </div>
        <div
          onClick={() => navigate('/all-tasks')}
          className='bg-[#0091CC] h-full w-full flex flex-col align-center justify-center rounded-lg shadow-lg cursor-pointer hover:opacity-90'>
          <div className='font-20 text-white'>See all tasks</div>
        </div>
      </div>}
      {!isTaskOpen && <div className="lg:col-span-1 sm:col-span-full col-span-full row-span-2 pt-10 border-2 rounded-lg border-slate-400 h-full sm:hidden hidden lg:block">
        <Pie data={data} />
      </div>}
      <div className={`sm:row-span-3 row-span-5 ${!isTaskOpen && 'lg:col-span-2'} sm:col-span-3 col-span-full overflow-hidden h-full border-2 rounded-lg border-slate-400 px-3 py-2`}>
        <div className='text-2xl font-light p-1'>Latest Tasks</div>
        <div className='overflow-y-scroll h-full sm:h-[94%] py-2 overflow-x-hidden'>
          {dashboardTasks?.tasks?.length > 0 ? dashboardTasks?.tasks?.map((task, i) => (
            <div className='p-3 px-2 flex hover:bg-indigo-100 hover:rounded-lg cursor-pointer' key={task?._id + i} onClick={() => openTask(task)}>
              <div className='flex align-center max-w-[99%]'>
                <div className='font-12 min-w-12 truncate font-light text-slate-900'>{task?.titleIdentifier}</div>
                <Toolp title={task?.taskTitle}>
                  <div className='font-16 ml-2 truncate'>{task?.taskTitle}</div>
                </Toolp>
              </div>
            </div>
          )) :
            <div className='h-full'>
              <EmptyState
                header={"No Tasks To Do!"}
                description={"Let's create your first task on your new workspace."}
                iconSize='h-24 w-24'
                onPress={() => {
                  dispatch(toggleNewTaskModal({
                    value: true
                  }));
                }}
                button={{
                  text: 'Create Task',
                  color: 'bg-orange-200',
                }}
                src='/images/icons/task.svg' />
            </div>}
        </div>
      </div>
      <div className={`sm:row-span-3 row-span-5 sm:h-full ${!isTaskOpen && 'lg:col-span-2'} sm:col-span-2 col-span-full overflow-hidden flex flex-col`}>
        {/* {isUserAdmin && <div className={`sm:h-[20%] mb-5 overflow-hidden bg-[#348A95] hidden sm:flex flex-col justify-center rounded-lg px-5`}>
          <div className="flex align-center justify-between text-white mb-6">
            <div className='font-20 text-white'>Storage Used</div>
            <div className="italic font-16">
              {orgDetails?.size || 0} <span className="text-slate-400">/</span> {`${orgDetails?.license?.name === 'free' ? divider + ' MB': divider/1000 + ' GB'}`}
            </div>
          </div>
          <div className="relative w-full h-2 bg-slate-300">
            <div className="absolute inset-0 bg-green-500" aria-hidden="true" style={{ width: `${Math.floor(percent)}%` }} />
          </div>
        </div>} */}
        <div className={`border-2 p-2 rounded-lg border-slate-400 sm:h-['100%] h-[100%]`}>
          <div className='flex align-center justify-between'>
            <div className='text-2xl font-light py-1'>Recent Activity</div>
          </div>
          <div className='overflow-y-scroll h-[95%] sm:h-[92%] py-2 overflow-x-hidden'  onScroll={handleScroll} ref={notRef}>
            {allNotifications?.length > 0 ? allNotifications?.map((notif, i) => {

              if (!notif?.description || notif?.description === 'undefined') {
                return
              }

              TimeAgo.addLocale(en);
              const timeAgo = new TimeAgo('en-US');
              const time = timeAgo.format(new Date(notif?.createdAt));

              return (
                <div className='p-3 hover:bg-indigo-100 hover:rounded-lg cursor-pointer' key={notif?._id + i} onClick={() => handleNotificationClick(notif)}>
                  <div className='font-12' style={{ width: '100%', display:'flex', justifyContent: 'space-between' }}>
                    <span className='title'>{notif?.title}</span>
                    <span className='date'>
                      {time}
                    </span>
                  </div>
                  <div className='flex'>
                  {notif?.notificationType === 'InviteNotification' ? <img style={{  }}
              src="/images/icons/addblack.svg"
              alt="img"
              className="cursor-pointer"
            /> : <img className='h-10 w-10 mt-1' src={notif?.assignor?.avi || notif?.icon} style={{ borderRadius: 60, aspectRatio: 1, objectFit: 'cover' }} />}
                  <div className='ml-3'>
                    {notif?.assignor?.fullName && notif?.notificationType !== 'InviteNotification' && <div className='max-w-[90%] font-18 text-slate-900'><HtmlRenderer html={notif?.assignor?.fullName} /></div>}
                    {notif?.notificationType === 'ChatReplyNotification' && notif?.comment?.messageDescription && <div className='font-10'><HtmlRenderer truncate={true} html={notif?.comment?.messageDescription?.replace(/^<p>/, '<p>replied to: ') || notif?.comment?.messageDescription} /></div>}
                    {notif?.description && <div className={`max-w-[90%] ${notif?.notificationType === 'InviteNotification' ? 'font-18' : 'font-14'} font-light text-slate-900`} style={{maxWidth: '100%'}}><HtmlRenderer html={notif?.description} truncate={true} /></div>}
                  </div></div>
                </div>
              )
            }) : (
              <EmptyState
                header={"You're All Caught Up!"}
                description={'Sit back and relax, nothing to see here.'}
                src='/images/icons/no-mail.svg' />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCardTasks;
