import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'antd';

import IconAndName from '../../atoms/side-navbar/IconAndName';
import FilterItems from '../../atoms/dropdown-items/FilterItems';
import GroupAvatar from '../../atoms/side-navbar/GroupAvatar';

import { dueDateFilterOptions, taskStatus } from '../../../../js/constants';
import { setFilters, toggleNewTaskModal, toggleWorkspaceFieldsModal, openInvitePanel } from '../../../../actions';

const SubNavBar = (props) => {
  const dispatch = useDispatch();

  const isTicketOpened = useSelector((state) => state.toggleReducer.isTicketOpen);
  const isChatOpened = useSelector((state) => state.toggleReducer.isChatOpen);
  const filters = useSelector((state) => state.taskReducer.filters);
  const selectedProject = useSelector((state) => state.projectReducer.getProjects);

  const teamDrawerState = useSelector(
    (state) => state?.toggleReducer?.teamDrawerState
  );

  const [assigneeList, setAssigneeList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(taskStatus.allTasks.label);
  const [selectedDueDateOption, setSelectedDueDateOption] = useState('Due date')

  const {
    project,
    showFilters,
    setShowSection
  } = props;

  useEffect(() => {
    let items = [{
      label: <div onClick={() => dispatch(setFilters({
        ...filters, assignedTo: [], projectId: selectedProject?._id
      }))}>
        -- Default --
      </div>,
      key: 1
    }];
    project?.participants?.map((participant, i) => {
      items.push({
        label:
          <div onClick={() => dispatch(setFilters({
            ...filters, assignedTo: [participant], projectId: selectedProject?._id
          }))}>
            {participant.fullName}
          </div>,
        key: participant._id,
      });
    });
    setAssigneeList(items);
  }, [project?.participants]);

  const updateFilters = (status, type) => {
    let x = filters;
    x.projectId = selectedProject?._id;

    if (type == 'priority') {
      x.priority = status;
    } else if(type == 'status') {
      x.status = status.key;
      setSelectedStatus(status.label);
    } else if(type == 'dueDate') {
      x.dueDate = status.key;
      setSelectedDueDateOption(status.label === dueDateFilterOptions?.allTasks?.label ? 'Due date' : status.label);
    }
    dispatch(setFilters(x));
  }

  const resetFilters = () => {
    dispatch(setFilters({ projectId: selectedProject?._id }, true));
  }

  const statusFilters = [
    { ...taskStatus.allTasks, onClick: () => updateFilters(taskStatus.allTasks, 'status') },
    { ...taskStatus.completedTasks, onClick: () => updateFilters(taskStatus.completedTasks, 'status') },
    { ...taskStatus.incompletedTasks, onClick: () => updateFilters(taskStatus.incompletedTasks, 'status') },
  ]

  const dueDateFilters = [
    { ...dueDateFilterOptions.allTasks, onClick: () => updateFilters(dueDateFilterOptions.allTasks, 'dueDate')},
    { ...dueDateFilterOptions.dueToday, onClick: () => updateFilters(dueDateFilterOptions.dueToday, 'dueDate')},
    { ...dueDateFilterOptions.dueThisWeek, onClick: () => updateFilters(dueDateFilterOptions.dueThisWeek, 'dueDate')},
    { ...dueDateFilterOptions.dueThisMonth, onClick: () => updateFilters(dueDateFilterOptions.dueThisMonth, 'dueDate')},
  ]

  return (
    <div className='d-flex sticky top-0'>
      <div className='flex align-center justify-between sub-nav-area pt-3 px-4'>
        {(!isTicketOpened && !isChatOpened && !teamDrawerState) || showFilters ? (
          <ul className="flex flex-wrap -m-1">
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'add-task',
                    label: <div className='font-14 py-2'>Add Task</div>,
                    onClick: () => dispatch(toggleNewTaskModal({ value: true }))
                  },
                  {
                    key: 'add-section',
                    label: <div className='font-14 py-2'>Add Section</div>,
                    onClick: () => setShowSection(true)
                  },
                  {
                    key: 'add-custom-field',
                    label: <div className='font-14 py-2'>Add Custom Field</div>,
                    onClick: () => dispatch(toggleWorkspaceFieldsModal(true))
                  },
                ],
              }}
              placement='bottomRight'
              trigger={['click']}
            >
              <li className="m-1">
                <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out">
                  Add to project
                </button>
              </li>
            </Dropdown>
            <div className='vertical-line' style={{ height: 40 }} />
            <Dropdown
              menu={{
                items: FilterItems({ assignees: assigneeList, updateFilters, resetFilters, statusFilters, dueDateFilters }),
              }}
              placement='bottomRight'
              trigger={['click']}
            >
              <li className="m-1">
                <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out">
                  Filters
                </button>
              </li>
            </Dropdown>
            <div className='easy-filters'>
              <Dropdown
                menu={{
                  items: statusFilters,
                }}
                placement='bottomRight'
                trigger={['click']}
              >
                <li className="m-1">
                  <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
                    {selectedStatus}
                  </button>
                </li>
              </Dropdown>
              <Dropdown
                menu={{
                  items: dueDateFilters,
                }}
                placement='bottomRight'
                trigger={['click']}
              >
                <li className="m-1">
                  <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
                    {selectedDueDateOption}
                  </button>
                </li>
              </Dropdown>
              <Dropdown
                menu={{
                  items: assigneeList,
                }}
                trigger={['click']}
                placement='bottomRight'
              >
                <li className="m-1 flex align-center">
                  <button className="mr-3 inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 hover:border-slate-300 shadow-sm bg-white text-slate-500 duration-150 ease-in-out">
                    Assigned to
                  </button>
                  <GroupAvatar
                    participants={
                      filters.assignedTo
                    }
                    size={18}
                  />
                </li>
              </Dropdown>
            </div>
          </ul>
        ) : ''}
        <div className='flex align-center project-share-container'>
          <GroupAvatar participants={(project?.participants)} size={30} />
          <div
            className='cursor-pointer ml-2 inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out'
            onClick={() => {
              dispatch(openInvitePanel(true, {
                pastInvites: project?.pendingInvites || [],
                existingMembers: project?.participants || [],
                selectWorkspaceMembers: true,
                type: 'Project'
              }));
            }}>
              Share
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubNavBar;
