import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { useDispatch } from 'react-redux';
import { Dropdown } from 'antd';

import ProductNavBar from '../../UI/molecules/upper-navbar/ProductNavBar'

import '../../../assets/style/adminConsole.css';

import BillingInvoice from '../molecules/admin-console/BillingInvoice';
import MyPlans from '../molecules/admin-console/MyPlans';
import MyWorkspaces from '../molecules/admin-console/MyWorkspaces';
import MyAccount from '../molecules/admin-console/MyAccount';
import OrgDetails from '../molecules/admin-console/OrgDetails';

import {
  getOrganizationById,
} from '../../../actions';

const AdminConsole = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="flex flex-col md:flex-row md:-mr-px">
      <main className="grow">
        <ProductNavBar button='Back to Workflo' />
        <div className="mt-10 px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

          {/* Page header */}
          <div className="mb-8">
            {/* Title */}
            <h1 className="text-2xl md:text-3xl text-slate-800  font-bold">Settings</h1>
          </div>

          {/* Content */}
          <div className="bg-white shadow-lg rounded-sm mb-8">
            <div className="flex flex-col md:flex-row md:-mr-px">
              <Sidebar setSelectedIndex={setSelectedIndex} selectedIndex={selectedIndex} />
              <Dashboard selectedIndex={selectedIndex} user={props.user} workspaces={props.workspaces} currentWorkspace={props?.currentWorkspace} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

const Sidebar = ({ selectedIndex, setSelectedIndex }) => {
  const location = useLocation();
  const { pathname } = location;

  const options = [
    {
      label: 'My Account',
      icon: <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${selectedIndex === 0 ? 'text-indigo-500' : 'text-slate-400'}`} viewBox="0 0 16 16">
        <path d="M12.311 9.527c-1.161-.393-1.85-.825-2.143-1.175A3.991 3.991 0 0012 5V4c0-2.206-1.794-4-4-4S4 1.794 4 4v1c0 1.406.732 2.639 1.832 3.352-.292.35-.981.782-2.142 1.175A3.942 3.942 0 001 13.26V16h14v-2.74c0-1.69-1.081-3.19-2.689-3.733zM6 4c0-1.103.897-2 2-2s2 .897 2 2v1c0 1.103-.897 2-2 2s-2-.897-2-2V4zm7 10H3v-.74c0-.831.534-1.569 1.33-1.838 1.845-.624 3-1.436 3.452-2.422h.436c.452.986 1.607 1.798 3.453 2.422A1.943 1.943 0 0113 13.26V14z" />
      </svg>,
    },
    {
      label: 'Workspaces',
      icon: <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${selectedIndex === 1 ? 'text-indigo-500' : 'text-slate-400'}`} viewBox="0 0 16 16">
        <path d="M3.414 2L9 7.586V16H7V8.414l-5-5V6H0V1a1 1 0 011-1h5v2H3.414zM15 0a1 1 0 011 1v5h-2V3.414l-3.172 3.172-1.414-1.414L12.586 2H10V0h5z" />
      </svg>,
    },
  ]

  const orgOptions = [
    {
      label: 'Storage and Users',
      icon: <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${selectedIndex === 2 ? 'text-indigo-500' : 'text-slate-400'}`} viewBox="0 0 16 16">
        <path d="M14.3.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-8 8c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l8-8zM15 7c.6 0 1 .4 1 1 0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8c.6 0 1 .4 1 1s-.4 1-1 1C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6c0-.6.4-1 1-1z" />
      </svg>,
    },
    {
      label: 'Plans',
      icon: <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${selectedIndex === 3 ? 'text-indigo-500' : 'text-slate-400'}`} viewBox="0 0 16 16">
        <path d="M5 9h11v2H5V9zM0 9h3v2H0V9zm5 4h6v2H5v-2zm-5 0h3v2H0v-2zm5-8h7v2H5V5zM0 5h3v2H0V5zm5-4h11v2H5V1zM0 1h3v2H0V1z" />
      </svg>,
    },
    {
      label: 'Billing & Invoices',
      icon: <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${selectedIndex === 4 ? 'text-indigo-500' : 'text-slate-400'}`} viewBox="0 0 16 16">
        <path d="M15 4c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3h7c.6 0 1 .4 1 1v3h4zM2 3v1h7V2H3c-.6 0-1 .4-1 1zm12 11V6H2v7c0 .6.4 1 1 1h11zm-3-5h2v2h-2V9z" />
      </svg>,
    }
  ]

  return (

    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-slate-200 min-w-[15rem] md:space-y-3">
      {/* Group 1 */}
      <div>
        <div className="text-xs font-semibold text-slate-400 uppercase mb-3">Admin Console</div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          {options.map((option, i) => (
            <li className="cursor-pointer hover:bg-indigo-50 mr-0.5 md:mr-0 md:mb-0.5">
              <div onClick={() => setSelectedIndex(i)} className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${selectedIndex === i && 'bg-indigo-50'}`}>
                {option.icon}
                <span className={`text-sm font-medium ${selectedIndex === i ? 'text-indigo-500' : 'text-slate-600 hover:text-slate-700'}`}>{option.label}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* Org */}
      <div>
        <div className="text-xs font-semibold text-slate-400 uppercase mb-3">Organization</div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          {orgOptions.map((option, i) => (
            <li className="cursor-pointer hover:bg-indigo-50 mr-0.5 md:mr-0 md:mb-0.5">
              <div onClick={() => setSelectedIndex(i + 2)} className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${selectedIndex === i + 2 && 'bg-indigo-50'}`}>
                {option.icon}
                <span className={`text-sm font-medium ${selectedIndex === i + 2 ? 'text-indigo-500' : 'text-slate-600 hover:text-slate-700'}`}>{option.label}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* Experience and Feedback */}
      <div>
        <div className="text-xs font-semibold text-slate-400 uppercase mb-3">Experience</div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="cursor-pointer hover:bg-indigo-50 mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="https://workflo.canny.io/bugs-and-feature-requests" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/settings/feedback') && 'bg-indigo-50'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/settings/feedback') ? 'text-indigo-500' : 'text-slate-400'}`} viewBox="0 0 16 16">
                <path d="M7.001 3h2v4h-2V3zm1 7a1 1 0 110-2 1 1 0 010 2zM15 16a1 1 0 01-.6-.2L10.667 13H1a1 1 0 01-1-1V1a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1zM2 11h9a1 1 0 01.6.2L14 13V2H2v9z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/settings/feedback') ? 'text-indigo-500' : 'text-slate-600 hover:text-slate-700'}`}>Give Feedback</span>
            </NavLink>
          </li>
          <li className="cursor-pointer hover:bg-indigo-50 mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="https://workflo.com/contact/" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/settings/feedback') && 'bg-indigo-50'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/settings/feedback') ? 'text-indigo-500' : 'text-slate-400'}`} viewBox="0 0 16 16">
                <path d="M7.001 3h2v4h-2V3zm1 7a1 1 0 110-2 1 1 0 010 2zM15 16a1 1 0 01-.6-.2L10.667 13H1a1 1 0 01-1-1V1a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1zM2 11h9a1 1 0 01.6.2L14 13V2H2v9z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/settings/feedback') ? 'text-indigo-500' : 'text-slate-600 hover:text-slate-700'}`}>Contact Us</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

const Dashboard = ({ selectedIndex, user, workspaces, currentWorkspace }) => {
  const dispatch = useDispatch();
  const orgs = user?.organizations || [];

  const [selectedOrg, setSelectedOrg] = useState(orgs[0]);

  const onOrgChange = async (org) => {
    const orgById = await dispatch(getOrganizationById(org?._id));
    setSelectedOrg(orgById);
  }

  useEffect(() => {
    onOrgChange(orgs[0]);
  }, [user?.organizations])

  return (
    <div className="grow">
      {selectedIndex == 0 && <MyAccount user={user} />}
      {selectedIndex == 1 && <MyWorkspaces workspaces={workspaces} user={user} currentWorkspace={currentWorkspace} />}

      {selectedIndex > 1 && <div className='px-6'>
        <h2 className="text-2xl text-slate-800 font-bold m-4">Organization</h2>
        <Dropdown
          className='truncate mb-3 w-72 flex items-center bg-white text-sm font-medium text-slate-800 p-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-ou'
          trigger={['click']}
          menu={{
            items: orgs?.map(org => {
              return {
                key: org?.name,
                label: org?.name,
                onClick: () => onOrgChange(org)
              }
            })
          }}
          placement='bottom'
        // disabled={role === 'owner'}
        >
          <div className='flex align-center'>
            {selectedOrg?.name}
          </div>
        </Dropdown>
        <div className="text-sm">Select the organization for more information</div>
      </div>}

      {selectedIndex == 3 &&
        <MyPlans selectedOrg={selectedOrg} />
      }
      {selectedIndex == 4 &&
        <BillingInvoice selectedOrg={selectedOrg} />
      }
      {selectedIndex == 2 &&
        <OrgDetails
          selectedOrg={selectedOrg}
          user={user}
          currentWorkspace={currentWorkspace} />
      }
    </div>
  );
}

const mapStateToProp = (state) => {
  return {
    user: state.userReducer.user,
    workspaces: state.workspaceReducer.getWorkspaces,
    currentWorkspace: state.workspaceReducer.currentWorkspace
  };
};

export default connect(mapStateToProp)(AdminConsole);