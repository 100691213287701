import React from 'react';

import { Skeleton } from 'antd';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Icon from '../../atoms/global/Icon';
import { isTicketOpen, clearSingleTask } from '../../../../actions';

const TaskPanelSkeleton = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className='w-full h-full'>
      <div className='d-flex align-center justify-content-between' style={{ padding: '10px 15px', borderBottom: '1px solid #F3F3F3' }}>
        <Skeleton.Button
          style={{
            width: 166,
            height: 26,
            borderRadius: 6,
            background: '#fcfcfc'
          }}
          active={true} />
        <div>
          <Icon
            name='close.svg'
            height='14px'
            onClick={() => {
              dispatch(isTicketOpen(false));
              const params = new URLSearchParams(location.search);
              params.delete('selectedTask'); // Remove the specified parameter

              // Construct the new URL with updated parameters
              const newUrl = `${location.pathname}?${params.toString()}`;

              // Use navigate to replace the current URL
              navigate(newUrl);
              dispatch(clearSingleTask());
            }}
          />
        </div>
      </div>
      <div className='d-flex h-full' style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
        <div style={{ padding: '20px 15px' }}>
          <Skeleton.Button
            style={{
              width: 400,
              height: 22,
              borderRadius: 6,
              margin: 10,
              background: '#fcfcfc'
            }}
            active={true} />
        </div>
        <div className='task-comment-section sticky bottom-0' style={{ height: '226px', padding: 18, flexDirection: 'column' }}>
          <div className='d-flex align-center'>
            <Skeleton.Avatar
              style={{
                width: 26,
                height: 26,
                marginRight: 10,
              }}
              active={true} />
            <Skeleton.Button
              style={{
                width: 200,
                height: 22,
                borderRadius: 6,
              }}
              active={true} />
          </div>
          <Skeleton.Button
            style={{
              width: 470,
              height: 118,
              borderRadius: 6,
              marginTop: 20,
            }}
            active={true} />
        </div>
      </div>
    </div>
  );
};

export default TaskPanelSkeleton;
