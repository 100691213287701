import React, { useState, useEffect } from "react";
import { Modal, Switch, Dropdown } from 'antd';
import { connect, useDispatch } from 'react-redux';

import { getProjectTemplates, addProject, getWorkspaceById } from '../../../../actions';

import '../../../../assets/style/modal.css';

const CreateProjectModal = (props) => {
  const dispatch = useDispatch();

  const {
    templates,
    setCreateProjectModalOpen,
    createProjectModalOpen,
    workspaceId,
  } = props;

  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [projectName, setProjectName] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);

  const isButtonDisabled = !selectedTemplate || !projectName;

  useEffect(() => {
    dispatch(getProjectTemplates());
  }, []);

  const createProject = async (name, templateId, isPrivate) => {
    await dispatch(
      addProject(
        { title: name, workspace: workspaceId, template: templateId, access: isPrivate ? 'private' : 'public' },
        async () => {
          setSelectedTemplate({ title: 'Select Team' });
          setSelectedTemplate(undefined);
          setProjectName('');
          dispatch(getWorkspaceById());
        }
      )
    );
  };

  const footer = () => {
    return (
      <div
        className={`${isButtonDisabled && 'disabled'}`}
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        onClick={(e) => {
          if (!isButtonDisabled) {
            e.preventDefault();
            setCreateProjectModalOpen(false);
            createProject(projectName, selectedTemplate._id, isPrivate);
            setProjectName('');
          }
        }}
      >
        <div className='w-full text-center px-4 py-2 bg-indigo-400 font-16 text-white border-radius10 hover:bg-indigo-300 cursor-pointer'>
          Create Project
        </div>
      </div>
    );
  };

  return (
    <Modal title={`New project ${projectName && '> ' + projectName}`}
      open={createProjectModalOpen}
      onCancel={() => {
        setSelectedTemplate(undefined);
        setCreateProjectModalOpen(false);
        setProjectName('');
      }}
      bodyStyle={{
        backgroundColor: '#FFFFFF',
        padding: '1rem'
      }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={footer()}>
      <div className='px-2'>
        <div className='flex-col align-center justify-between mb-6'>
          <div className="space-y-3 w-full">
            <label>Project Name</label>
            <input
              type='text'
              value={projectName}
              placeholder='Name your new project'
              onChange={e => setProjectName(e?.target?.value)}
              className="w-full flex items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out" />
          </div>
          <div className="w-full my-5 flex align-center justify-center">
            <div className="w-full px-2 space-y-3">
              <label>Template</label>
              <Dropdown menu={{
                items: templates.map(template => {
                  return ({
                    key: template._id,
                    label: template.name,
                    onClick: () => setSelectedTemplate(template)
                  })
                })
              }}
                trigger={'click'}>
                <div className={`cursor-pointer w-full flex items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out`}>
                  <div>{!!selectedTemplate ? selectedTemplate.name : 'Select Template'}</div>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        {selectedTemplate &&
          <>
            <label className='font-16 font-bold'>Project sections:</label>
            <div className="overflow-x-scroll">
              <div className="d-flex mt-2">
                {selectedTemplate.sections?.map((section) =>
                  <div style={{ marginRight: 15 }} className="mini-section">
                    {section.title}
                  </div>
                )}
              </div>
            </div>
          </>
        }
        <div className="px-2 py-4 mt-2">
          <div className="flex align-center justify-between">
            <div className="font-16 font-bold">
              Make private?
            </div>
            <Switch checked={isPrivate} onChange={() => setIsPrivate(!isPrivate)} />
          </div>
          <div className="pr-20 font-14 mt-2">Privatizing the project shields sensitive data from individuals who are not part of the project</div>
        </div>
      </div>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    templates: state.projectReducer.publicTemplates,
  };
}

export default connect(mapStateToProps)(CreateProjectModal);
