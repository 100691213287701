import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from 'antd';
import EmojiPicker from 'emoji-picker-react';
import { FileDrop } from 'react-file-drop';
import { useSelector, useDispatch } from 'react-redux';

import Icon from '../../atoms/global/Icon';
import socket from '../../../../js/socket';
import '../../../../assets/style/chatTextArea.css';
import '../../../../assets/style/taskDrawer.css';
import TextAreaFileHolder from '../../organisms/TextAreaFileHolder';
import TextEditor from '../editor/TextEditor';

import {
  addMessageToChatRoom,
  isChatOpen as hasChatOpened,
  msgId as messageId,
} from '../../../../actions';
import { UPDATE_CURRENT_RECEIVER } from '../../../../actions/types/chat.types';
import {
  putMessage,
} from '../../../../actions/messageAction';
import _, { throttle } from 'lodash';
import { useCallback } from 'react';

const ChatTextArea = (props) => {
  const param = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [comment, setComment] = useState(props?.draft || '');
  const [selectedEmoji, setSelectedEmoji] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDragNdropVisible, setIsDragNdropVisible] = useState(false);
  const [isPasting, setIsPasting] = useState(false);


  const chats = useSelector((state) => state.chatReducer.chats);
  const isChatOpen = useSelector((state) => state.toggleReducer.isChatOpen);
  const isTicketOpen = useSelector((state) => state.toggleReducer.isTicketOpen);
  const { user, users } = useSelector((state) => state.userReducer);

  const isSidePanelOpen = isChatOpen || isTicketOpen;

  const paramsId = param.chatId || param.projectid;

  const { msgId, roomTitle } = props?.reply;
  
  // const throttledSaveDraftRef = useRef(
  //   throttle((content) => {
  //     saveDraft(content);
  //   }, 2000)
  // );

  useEffect(() => {
    setComment(props?.draft?.messageDescription || '')
  }, [props?.draft]);

  useEffect(() => {
    return () => {
      console.log('ChatTextArea Cleaning up...');
      props?.clearDraft()
    };
  }, []);

  const mentionOptions = users.map(user => ({
    id: user._id,
    value: user.fullName,
    icon: user.avi
  }));

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleEmoji = (emojiData) => {
    let sym = emojiData.unified.split('-');
    let codesArray = [];
    sym.forEach((el) => codesArray.push('0x' + el));
    let emoji = String.fromCodePoint(...codesArray);
    setSelectedEmoji(emojiData.unified);
    setComment(comment + emoji);
  };

  const onFileDrop = async (files) => {
    setIsDragNdropVisible(false);
    try {
      changeHandler(files, true)
    } catch (error) {
      console.error(error);
    }
  };

  // Handle attachments
  const changeHandler = async (e, isDragDrop = false) => {
    try {
      let fileList = [];
      let getFile = isDragDrop ? e : e.target.files;
      for (const file of getFile) {
        setIsLoading(true);
        const fileId = await props.setImageUrl(file);
        if(fileId) {
          fileList.push({
            fileId,
            file
          });
        }
      }

      setIsLoading(false);
      setFile(fileList);
      props?.setChatAreaHeight(166);
    } catch (error) {
      console.error(error);
    }
    !isDragDrop && document.getElementById('file').remove();
  };

  const openChat = () => {
    dispatch(messageId(msgId));
    dispatch(putMessage(paramsId, msgId));
    dispatch(hasChatOpened(true, msgId));
    const params = new URLSearchParams(location.search);
    params.delete('selectedTask'); // Remove the specified parameter
    params.set('msgId', msgId)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    // Use navigate to replace the current URL
    navigate(newUrl);

  };

  useEffect(() => {
    socket.on('chat-received', (msg) => {
      console.log("--- CHAT RECEIVED ON CHAT TEXT AREA", msg)
      if(msg?._id?.toString() === props?.draft?._id?.toString()) {
        props?.clearDraft();
        setComment('');  
      }
    });
  }, [dispatch]);

  const handleSubmit = () => {

    if (!!msgId) {
      dispatch(hasChatOpened(false));
      props?.setReply({});
      openChat();
      dispatch({
        type: UPDATE_CURRENT_RECEIVER,
        payload: roomTitle,
      });
      handleReplySubmit();
      return;
    }

    let messageDescription = comment?.trim();
    const extraP = messageDescription.slice(-11);
    const extraBlock = messageDescription.slice(-29);
    if (extraP == '<p><br></p>') {
      messageDescription = messageDescription.substring(0, messageDescription.length - 11);
    } 
    if (extraBlock == '<blockquote><br></blockquote>') {
      messageDescription = messageDescription.substring(0, messageDescription.length - 29);
    }

    if (messageDescription || file.length) {
      const fileIds = file.map(fileObj => ({ _id: fileObj.fileId }));
      console.log("----- props?.draft", props?.draft)
      const msg = {
        _id: props?.draft?._id,
        isDraft: false,
        sender: user,
        messageDescription,
        roomId: paramsId,
        attachments: [],//file,
        files: fileIds,
        members: chats?.find((chat) => chat._id === paramsId)?.members.map((mem) => mem._id),
      };

      setFile([]);
      socket.emit('chat', msg);
      // textarea.style.height = 'auto';
      props?.setChatAreaHeight(75);
      // props?.clearDraft();
      // console.log("------ handleSubmuit comment", comment)
      // setComment('');
    } else {
      setComment();
    }
  };

  const throttledSaveDraft = useCallback(
    _.debounce((content) => {
      saveDraft(content);
    }, 2000), // Adjust the debounce delay as needed
    []
  );

  const saveDraft = async(content) => {
    if (!!msgId) {
      return;
    }

    let messageDescription = content?.trim() || '';

    const extraP = messageDescription.slice(-11);
    const extraBlock = messageDescription.slice(-29);
    if (extraP == '<p><br></p>') {
      messageDescription = messageDescription.substring(0, messageDescription.length - 11);
    } 
    if (extraBlock == '<blockquote><br></blockquote>') {
      messageDescription = messageDescription.substring(0, messageDescription.length - 29);
    }
    // console.log('[saveDraft] messageDescription', messageDescription, messageDescription?.length > 0 || file.length)

    if (messageDescription?.length > 0 || file.length) {
      const activeChat = chats?.find((chat) => chat?._id === paramsId);
      const members = activeChat?.members.map((mem) => mem._id);
      const fileIds = file.map(fileObj => ({ _id: fileObj.fileId }));
      const msg = {
        _id: props?.draft?._id,
        // sender: user,
        messageDescription,
        roomId: paramsId,
        attachments: [],//file,
        files: fileIds,
        isDraft: true,
        members
      };

      // setComment('');
      // setFile([]);
      const draft = await dispatch(addMessageToChatRoom(msg, paramsId));
      if(!props?.draft?._id) {
        props.setDraftVal(draft);
      }
      // socket.emit('chat', msg);
      // textarea.style.height = 'auto';
      // props?.setChatAreaHeight(75);
    } else {
      setComment('');
    }
  };

  const handleReplySubmit = () => {
    let messageDescription = comment?.trim();
    const extraP = messageDescription.slice(-11);
    const extraBlock = messageDescription.slice(-29);
    if (extraP == '<p><br></p>') {
      messageDescription = messageDescription.substring(0, messageDescription.length - 11);
    } 
    if (extraBlock == '<blockquote><br></blockquote>') {
      messageDescription = messageDescription.substring(0, messageDescription.length - 29);
    }

    if (messageDescription || file.length) {
      const fileIds = file.map(fileObj => ({ _id: fileObj.fileId }));
      const msg = {
        sender: { _id: localStorage.getItem('Id') },
        repliedMsg: comment,
        messageId: msgId,
        attachments: [],
        files: fileIds
      };
      socket.emit('update-chatReply', msg);
    }
    setComment('');
    setFile([]);
    props?.scrollToBottom();
  };


  const handleKeyUp = (e) => {
    if (e?.target?.textContent?.trim() === '') {
      return;
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      // setComment('');
      handleSubmit(e.target?.textContent);
    }
  };

  const deleteFile = (fileId) => {
    props.delAttachment(fileId);
    const updatedFiles = file.filter((f) => f?.fileId !== fileId);
    setFile(updatedFiles);
  }

  return (
    <>
      {<div className='chat-area' style={{ position: 'relative' }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            color: 'black',
            borderRadius: '5px',
            position: 'absolute',
            backgroundColor: 'none',
            left: 0,
            top: 0
          }}
          className={`fileDropDiv ${isDragNdropVisible ? '' : 'd-none'}`}
        >

          <FileDrop
            frame={document}
            onDrop={onFileDrop}
            onFrameDragEnter={() => {
              setIsDragNdropVisible(true);
            }}
            onFrameDragLeave={() => {
              setIsDragNdropVisible(false);
            }}
            onFrameDrop={() => {
              setIsDragNdropVisible(false);
            }}
          >
            Attach file to thread
          </FileDrop>

        </div>
        <TextEditor
        placeholder="Reply..."
        onChange={(content) => {
          setComment(content);
          // throttledSaveDraftRef.current(content);
          throttledSaveDraft(content)
        }}
        onKeyUp={(e) => handleKeyUp(e)}
        comment={comment}
        mentionOptions={mentionOptions}
      />
        <div className='input-controls'>
          <div className='d-flex mb-2'>
            <TextAreaFileHolder fileImages={file} delAttachment={deleteFile} isLoading={isLoading} />
          </div>
          <div className='d-flex justify-content-between align-center'>
            <div className='d-flex align-center'>
              <Icon
                name='blueAdd.svg'
                height='19px'
                pr='13px'
                onClick={() => {
                  let input = document.createElement('INPUT');
                  input.setAttribute('type', 'file');
                  input.setAttribute('id', 'file');
                  input.style.display = 'none';
                  document.body.appendChild(input);
                  input.click();
                  input.addEventListener('change', changeHandler);
                }}
              />
              <div className='small-vertical-line' />
              {/* <Icon name='video.svg' height='13px' pr='13px' />
              <Icon name='mice.svg' height='12px' pr='13px' />
              <div className='small-vertical-line' /> */}
              <Icon name='smiley.svg' onClick={showModal} height='13px' pr='13px' />
              <Modal
                className='emojiModal'
                width='21.9rem'
                title='Basic Modal'
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <EmojiPicker onEmojiClick={handleEmoji} autoFocusSearch={false} />
              </Modal>
              <div></div>
              <Icon name='at-the-rate.svg' height='13px' pr='13px' />
              <Icon name='Aa.svg' height='12px' />
              {!isSidePanelOpen && <div className="ai-summary-button">
                <Button onClick={props.openAiPanel}>Try our AI chat summary!</Button>
              </div>}
            </div>
            <div onClick={handleSubmit}>
              <Icon name='send.svg' height='13px' />
            </div>
          </div>
        </div>
      </div>
      }
    </>
  );
};

export default ChatTextArea;
