import React from 'react';

import getCustomFieldDisplayValue from '../../../../js/getCustomFieldDisplayValue';
import { fieldTypes } from '../../../../js/constants';

/**
 * Returns the custom field component for list view or task card.
 * If the custom field is falsy or has no value, returns null.
 * If the type is 'dropdown', looks up the label in the possibleValues array based on the value.
 * @param {object} customField - The custom field object containing possibleValues, type, and value.
 * @param {array} customField.possibleValues - An array of possible values for the dropdown.
 * @param {string} customField.type - The type of the custom field.
 * @param {string} customField.value - The value of the custom field.
 * @returns {string|null} The display value of the custom field, or null if customField is falsy or has no value.
 */
const ShowCustomField = ({ type, task, customFields, isListView }) => {

  console.info('Custom Fields', customFields);

  if (!customFields || customFields?.length == 0) {
    return
  }

  return (
    <div className='flex align-center'>
      {customFields?.map((field, i) => {
        const cost = field?.type == 'cost';
        const fieldValue = getCustomFieldDisplayValue(field);

        if (i > 1 || fieldValue === '' || !fieldValue) {
          return;
        }

        return (
          <div className={`font-14 mr-2 w-fit py-1 px-2 rounded bg-slate-100 font-bold ${cost && 'text-emerald-500 bg-emerald-100'}`}>
            {fieldValue}
          </div>
        );
      })}
    </div>
  )
};

export default ShowCustomField;