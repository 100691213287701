import React from 'react';

import '../../assets/style/global.css';

import MyTasks from '../UI/molecules/home/MyTasks';

const Home = () => {
  return (
    <MyTasks />
  );
};

export default Home;
