import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  monthlyStarterPlanDetails,
  teamPlanDetails,
  businessPlanDetails,
  enterprisePlanDetails
} from '../UI/molecules/pricing/data';

import Plan from '../UI/molecules/pricing/Plan';
import ProductNavBar from '../UI/molecules/upper-navbar/ProductNavBar'

import { setPaymentDetails } from '../../actions';
import { APPLICATION_NAME } from '../../js/constants';

const Pricing = ({ hideHeader = false }) => {

  const navigate = useNavigate();
  document.title = `Pricing - ${APPLICATION_NAME}`
  const dispatch = useDispatch();

  const [isMonthly, setIsMonthly] = useState(true);

  let price = 12.99;
  const planPrice = price - (isMonthly ? 0 : 2);

  const priceTable = () => (
    <div className="pricing-table" style={{ color: hideHeader && 'black' }}>
      <Plan
        name="Free"
        description="Tailored for small teams with big needs"
        price={"Free"}
        buttonLabel="Go to Workflo"
        buttonAction={() => navigate('/', { replace: true })}
        details={monthlyStarterPlanDetails}
      />
      <Plan
        name={`Team (${isMonthly ? 'Monthly' : 'Yearly'})`}
        description={isMonthly ? "For mid-sized teams managing diverse projects efficiently" : "For mid-sized teams managing diverse projects efficiently"}
        buttonLabel="Upgrade"
        buttonAction={async () => {
          await dispatch(setPaymentDetails({ isMonthly: isMonthly, price: planPrice }), navigate('/pricing/users'));
        }}
        price={<div>${planPrice}</div>}
        details={teamPlanDetails}
        cadence={'MONTH'}
      />
      <Plan
        name="Business"
        description="Best for large scale needs"
        buttonLabel="Join the Waitlist"
        buttonAction={() => {
          // Dispatch an action that calls a REST API that stores email address in a mongodb collection.
        }}
        price={""}
        details={businessPlanDetails}
      />
      <Plan
        name="Enterprise"
        description="Best for enterprise needs"
        buttonLabel="Contact Sales"
        buttonAction={() => {
          // Dispatch an action that calls a REST API that stores email address in a mongodb collection.
        }}
        price={""}
        details={enterprisePlanDetails}
      />
    </div>
  )

  if (hideHeader) {
    return priceTable();
  }

  return (
    <div
      className='grow'
      style={{ background: '#fafafa', flexDirection: 'column', height: '100vh' }}>
      <ProductNavBar />
      <div className='pricing-container' style={{ flexDirection: 'column', overflowY: 'scroll' }}>
        <h3 className="pricing-title">Choose The Plan That Suits You</h3>
        <div className='font-18'>Billing Cycle: </div>
        <div className='d-flex mb-2'>
          <div className={`price-toggle ${isMonthly && 'selected'}`} onClick={() => setIsMonthly(true)}>
            Monthly
          </div>
          <div className={`price-toggle ${!isMonthly && 'selected'}`} style={{ borderLeft: 'none' }} onClick={() => setIsMonthly(false)}>
            Yearly
          </div>
        </div>
        {priceTable()}
      </div>
    </div>
  );
};

export default Pricing;
