import React from "react";
import { Image, Tooltip } from "antd";
import Loader from '../atoms/global/Loader';

const TextAreaFileHolder = ({ fileImages, delAttachment, isLoading }) => {
  const videoExtensions = ['.mp4', '.avi', '.mkv', '.mov', '.wmv', '.webm'];
  const imgExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp'];
  const docExtensions = ['.doc', '.docx', '.txt', '.rtf'];
  const pptExtensions = ['.ppt', '.pptx'];
  const excelExtensions = ['.xls', '.xlsx'];


  const iconMap = {
    pdf: '/images/icons/pdf.svg',
    doc: '/images/icons/document.svg',
    video: '/images/icons/video2.svg',
    csv: '/images/icons/document.svg',
    ppt: '/images/icons/ppt.svg',
    excel: '/images/icons/excel.svg'
  }

  return (<div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {isLoading ? (
      <Loader size={15} isLoading={isLoading} type='clip' />
    ) : (
      fileImages
        ? fileImages.map((file, key) => {
          const fileName = file?.file?.name;
          const isVideo = videoExtensions.some(ext => fileName.includes(ext));
          const isImage = imgExtensions.some(ext => fileName.includes(ext));
          const isCsv = fileName.includes('.csv');
          const isPdf = fileName.includes('.pdf');
          const isDoc = docExtensions.some(ext => fileName.includes(ext));
          const isPpt = pptExtensions.some(ext => fileName.includes(ext));
          const isExcel = excelExtensions.some(ext => fileName.includes(ext));

          const icon = isVideo ? iconMap.video : isCsv ? iconMap.csv : isPdf ? iconMap.pdf : isDoc ? iconMap.doc : isPpt ? iconMap.ppt : isExcel ? iconMap.excel : iconMap.doc;

          return (
            <div
              key={key}
              className='add-subject-img'
              style={{ radius: 'none' }}
            >
              <Tooltip key={key} title={fileName}>
                <div style={{ position: 'relative' }}>
                  {isImage ?
                    <Image
                      width={'100%'}
                      height={'100%'}
                      style={{ marginRight: 20 }}
                      src={URL.createObjectURL(file?.file)}
                      preview={{ mask: '' }}
                    /> :
                    isVideo ?
                      <video controls width="50" height="47">
                        <source src={file?.signedUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video> :
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '5px',
                          border: '1px solid #ccc',
                          borderRadius: '5px',
                          marginRight: '10px',
                        }}
                      >
                        <img
                          src={icon}
                          alt="PDF Icon"
                          style={{
                            width: 120,
                            height: 120,
                            marginRight: 20
                          }}
                        />
                      </div>

                  }
                  <div className="file-options">
                    <img
                      className='cursor-pointer'
                      src='/images/icons/close.svg'
                      alt=''
                      style={{
                        height: '10px',
                        verticalAlign: 'text-top',
                      }}
                      onClick={(e) => {
                        delAttachment(file?.fileId);
                      }}
                    />
                  </div>
                </div>
              </Tooltip>
            </div>
          );
        })
        : ''
    )}</div>)
};

export default TextAreaFileHolder;
