import axios from "axios";
import { notification } from "antd";
import { get } from "lodash";
const baseUrl = process.env.REACT_APP_API_URL;

const config = {
  headers: { Authorization: "" },
};

export const getTeamAccessRequests = (id) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/teams/${id}/accessRequests`, config);
      dispatch({
        type: "get-team-requests",
        payload: res.data.data,
      });
      return res.data.data || [];
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};


export const getTeamInvites = (id) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/teams/${id}/invite`, config);
      dispatch({
        type: "get-team-invites",
        payload: res.data.data,
      });
      return res.data.data || [];
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const getTeam = (id, allProjects) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const queryString = allProjects ? `?allProjects=true` : '';
      const res = await axios.get(`${baseUrl}/teams/${id}${queryString}`, config);
      dispatch({
        type: "get-current-team",
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const getTeamsByUserId = () => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/teams`, config);
      dispatch({
        type: "get-teams",
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const getTeamsByWorkspaceId = (workspaceId, getAll = false, callBack) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      if(!workspaceId) workspaceId = sessionStorage.getItem('workspaceId') || localStorage.getItem('workspaceId');

      const res = await axios.get(`${baseUrl}/teams/workspaces/${workspaceId}${getAll ? '?all=true' : ''}`, config);
      dispatch({
        type: getAll ? "all-workspace-teams" : "get-teams",
        payload: res.data.data,
      });
      callBack && callBack(res?.data?.data);
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const inviteToTeam = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/teams/invite`, data, config);
      dispatch({
        type: "get-team-invites",
        payload: res.data.data,
      });
      callback && callback();
      notification.success({
        message: "Email invitations sent to team members",
        placement: "bottomRight",
      });
      return res.status;
    } catch (error) {
      notification.error({
        message: "Cannot invite to team!",
        placement: "bottomRight",
      });
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const createTeam = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/teams`, data, config);
      await dispatch({
        type: "team-creation-success",
        payload: res.data.data,
      });
      notification.success({
        message: "You just added a new team",
        placement: "bottomRight",
      });
      callback && callback();
    } catch (error) {
      const isDuplicate = get(error, "response.data.isDuplicateField");
      dispatch({
        type: "team-error",
        payload: error,
      });
      if (isDuplicate) {
        notification.error({
          message: "team name already used",
          placement: "bottomRight",
        });
      }
    }
  };
};

export const updateTeam = (id, data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.put(`${baseUrl}/teams/${id}`, data, config);
      await dispatch({
        type: "team-update-success",
        payload: res.data.data,
      });
      notification.success({
        message: "Team updated!",
        placement: "bottomRight",
      });
      callback && callback();
    } catch (error) {
      dispatch({
        type: "team-error",
        payload: error,
      });
    }
  };
};

export const deleteTeam = (id) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
      await axios.delete(`${baseUrl}/teams/${id}`, config);
      dispatch({
        type: "team-deletion-success",
        payload: id,
      });
      notification.success({
        message: "Team deleted",
        placement: "bottomRight",
      });
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });

      notification.error({
        message: error?.response?.data?.message || "Oops! something went wrong",
        placement: "bottomRight",
      });
    }
  };
};

export const leaveTeam = (data) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
      await axios.post(`${baseUrl}/teams/leave`, data ,config);
      dispatch({
        type: "team-deletion-success",
        payload: data?.teamId || '',
      });
      notification.success({
        message: "Team Left",
        placement: "bottomRight",
      });
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });

      notification.error({
        message: error?.response?.data?.message || "Oops! something went wrong",
        placement: "bottomRight",
      });
    }
  };
};

export const joinTeam = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/teams/join`, data, config);

      await dispatch({
        type: "team-creation-success",
        payload: res?.data?.data,
      });

      notification.success({
        message: "Team Joined!",
        placement: "bottomRight",
      });

      callback && callback();
    } catch (error) {
      notification.error({
        message: "Cannot join team!",
        placement: "bottomRight",
      });
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const removeMemberFromTeam = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/teams/removeMembers`, data, config);
      notification.success({
        message: "Member removed from team!",
        placement: "bottomRight",
      });
      return res.data;
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const updateTeamUsers = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      await axios.post(`${baseUrl}/teams/updateTeamUsers`, data, config);
      callback && callback();
      notification.success({
        message: "User Role Updates",
        placement: "bottomRight",
      });
    } catch (e) {
      notification.error({
        message: e?.message || "Cannot update role!",
        placement: "bottomRight",
      });
    }
  };
};