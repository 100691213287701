import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Dropdown
} from 'antd';

import Icon from '../../atoms/global/Icon';
import { getRandomColor } from '../../../../js/helper';

import {
  allocateOrgLicense
} from '../../../../actions';
import MyWorkspaces from './MyWorkspaces';


function OrgDetails(props) {
  const dispatch = useDispatch();

  const { user, currentWorkspace } = props;
  const [selectedOrg, setSelectedOrg] = useState(props?.selectedOrg);

  useEffect(() => {
    setSelectedOrg(props?.selectedOrg);
  }, [props?.selectedOrg])

  let percent = selectedOrg?.size?.split('MB')?.[0];
  let divider = selectedOrg?.license?.name === 'free' ? 100 : 25000;
  percent = (percent / divider) * 100


  const allocateLicense = (license, userId) => {
    dispatch(allocateOrgLicense({
      orgId: selectedOrg?._id,
      userId: userId,
      license: {
        name: license
      }
    }))
  }

  return (
    <div className="p-6 space-y-6">
      <div className=''>
        <div className='mb-6'>
          <div className="pb-4 border-b border-slate-200">
            <div className="flex justify-between text-sm mb-2">
              <div className="font-18 font-semibold text-slate-800 mb-4">Total Storage Used</div>
              <div className="italic">
                {selectedOrg?.size} <span className="text-slate-400">/</span> {divider} MB
              </div>
            </div>
            <div className="relative w-full h-2 bg-slate-300">
              <div className="absolute inset-0 bg-emerald-500" aria-hidden="true" style={{ width:`${Math.floor(percent)}%` }} />
            </div>
          </div>

        </div>
        <h2 className="text-xl text-slate-800 font-bold mb-4">Organization's Workspaces</h2>
        <MyWorkspaces isOrg={true} workspaces={selectedOrg?.workspaces} user={user} currentWorkspace={currentWorkspace} />
      </div>
      <div className=''>
        <h2 className="text-xl text-slate-800 font-bold mb-4">Users</h2>
        <div className="w-full overflow-x-auto" style={{ maxHeight: 350 }}>
          <table className="table-auto w-full" >
            {/* Table header */}
            <thead className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm">
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left">Name</div>
                </th>
                {/* <th className="p-2">
                  <div className="font-semibold text-center">Email</div>
                </th> */}
                <th className="p-2">
                  <div className="font-semibold text-center">License</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Actions</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm font-medium divide-y divide-slate-100 overflow-y-scroll">
              {/* Row */}
              {selectedOrg?.users?.map((member, i) => {
                const iconLetters = member?.fullName?.slice(0, 2).toUpperCase();

                if (member?.fullName === '' || !member?.fullName) {
                  return;
                }

                return (
                  <tr className='cursor-pointer' onClick={(e) => { }}>
                    <td className="p-2">
                      <div className="flex items-center">
                        <div
                          className={'square-letter-large font-14'}
                          style={{ background: getRandomColor(i + 1), height: 36, width: 36 }}>
                          {iconLetters}
                        </div>
                        <div className="text-slate-800">{member?.fullName}</div>
                        {/* <img
                          onClick={() => {
                            navigator.clipboard.writeText(member?.email || '');
                            notification.success({
                              message: 'Email copied to clipboard',
                              placement: 'bottomRight',
                            });
                          }}
                          className='ml-3'
                          src={'/images/icons/copy.svg'}
                          height={14}
                          width={14}
                          style={{ cursor: 'pointer' }}
                          alt='icon'
                        /> */}
                      </div>
                    </td>
                    {/* <td className="p-2">
                      <div className="text-center">{member?.fullName}</div>
                    </td> */}
                    <td className="p-2">
                      <Dropdown
                        trigger={['click']}
                        menu={{
                          items: [
                            {
                              key: 'guest',
                              label: 'Guest',
                              onClick: () => allocateLicense('guest', member?._id)
                            },
                            {
                              key: 'free',
                              label: 'Free',
                              onClick: () => allocateLicense('free', member?._id)
                            },
                            {
                              key: 'team',
                              label: 'Team',
                              onClick: () => allocateLicense('team', member?._id)
                            },
                          ],
                        }}
                        placement='bottom'
                        arrow
                      >
                        <div className='flex align-center justify-center'>
                          <div
                            style={{
                              color: '#747474',
                              fontSize: '14px',
                              fontWeight: 400,
                            }}>
                            {member?.license?.name}
                          </div>
                          <img
                            src={'/images/icons/show-more.svg'}
                            style={{ margin: "10px" }}
                            alt="icon"
                          />
                        </div>
                      </Dropdown>
                    </td>
                    <td className="p-2">
                      <div className='flex align-center justify-center'>
                        <Icon
                          name='delete-red.svg'
                          height='20px'
                          width='20px'
                        // onClick={() => {
                        //   setOpen(true);
                        //   setUser(member._id);
                        //}}
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default OrgDetails;