import React from 'react';

import moment from 'moment-timezone';

function WelcomeBanner({ name = '', onPress, projects, workspace, user }) {

  const today = new Date();
  const curHr = today.getHours();

  let message = 'Good evening';

  if (curHr < 12) {
    message = 'Good morning'
  } else if (curHr < 17) {
    message = 'Good afternoon'
  } else {
    message = 'Good evening'
  }

  const url = projects?.[0]?._id;

  const me = workspace?.members?.find(m => m?._id?.toString() === user?._id?.toString());
  const isOwnerOrAdmin = me?.role == 'owner' || me?.role == 'admin';

  return (
    <div className="mb-5 ml-2 flex align-center justify-between">
      <div>
        <h1 className="text-2xl md:text-3xl text-slate-700 font-bold mb-1">{message}, {name} 👋</h1>
        <p className="font-16">
          <span className="text-indigo-700 font-bold mr-2">{moment().format('dddd, MMMM Do').toString()}.</span> Here is a recap of your workspace today
        </p>
      </div>
      <div className='flex align-center'>
        {isOwnerOrAdmin && <div className="bg-[#0091CC] hidden sm:flex align-center px-4 py-2 rounded-lg lg:w-[250px] w-16 sm:w-20 shadow-xl cursor-pointer hover:bg-[#364952]" onClick={onPress}>
          <img
            src={'/images/icons/custom-fields-white.svg'}
            style={{
              height: '40px',
              width: '40px',
            }}
            alt='icon'
          />
          <div className='sm:hidden hidden lg:block'>
            <div className='ml-3'>
              <div className='font-18 text-white'>Custom Fields</div>
              <div className='font-12 text-white'>Browse collection</div>
            </div>
          </div>
        </div>}
        <div
          className="bg-[#476B7C] hidden sm:flex ml-3 flex align-center px-4 py-2 rounded-lg lg:w-[250px] w-16 sm:w-20 shadow-xl cursor-pointer hover:bg-[#364952]"
          onClick={() => window.open('https://workflo.canny.io/bugs-and-feature-requests', '_blank').focus()}>
          <svg className={`w-6 h-6 shrink-0 fill-white mr-2 text-slate-100'`} viewBox="0 0 16 16">
            <path d="M7.001 3h2v4h-2V3zm1 7a1 1 0 110-2 1 1 0 010 2zM15 16a1 1 0 01-.6-.2L10.667 13H1a1 1 0 01-1-1V1a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1zM2 11h9a1 1 0 01.6.2L14 13V2H2v9z" />
          </svg>
          <div className='sm:hidden hidden lg:block'>
            <div className='ml-3'>
              <div className='font-18 text-white'>Leave Feedback</div>
              <div className='font-12 text-white'>Share your feedback with us!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeBanner;
