import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL;
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
const config = {
  headers: { Authorization: '' },
};

export const signedUploadUrl = (url, file, callBack) => {
  return async (dispatch) => {
    try {
      const headers = {
        'content-type': file?.type || 'application/octet-stream',
        'Cache-Control': 'no-cache'
      };
      // config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      // config.headers['Content-Type'] = 'application/octet-stream';
      const res = await axios.put(url, file, {headers});

      callBack && callBack();
    } catch (error) {
      dispatch({
        type: "user-errorr",
        payload: error,
      });
    }
  };
};

export const getFileUsingSignedUrl = (url, file, callBack) => {
  return async (dispatch) => {
    try {
      const headers = {
        'content-type': 'application/octet-stream',
        'Cache-Control': 'no-cache'
      };
      // config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      // config.headers['Content-Type'] = 'application/octet-stream';
      const res = await axios.get(url, file, {headers});

      callBack && callBack();
      return res?.data;
    } catch (error) {
      dispatch({
        type: "user-errorr",
        payload: error,
      });
    }
  };
};


