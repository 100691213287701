import React from 'react';
import { setTeamsState } from '../../../../actions/toggleAction';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notification } from 'antd';

const TeamDrawerNav = (props) => {

  const { inviteDetails } = props;

  return (
    <div className='team-drawer-nav'>
      <div className='font-20'>{inviteDetails?.type} Members</div>
      <div className='drawer-nav-right-flex'>
        {inviteDetails?.type === 'Project' && <CopyToClipboard text={window.location.href}>
          <div
            className='flex cursor-pointer hover:text-indigo-600'
            onClick={() => {
              notification.success({
                message: 'Link copied to clipboard',
                placement: 'bottomRight',
              });
            }}
          >
            <img src='/images/icons/ticket-pin2.svg' alt='link' />
            <div className='font-16 ml-2'>Share via Link</div>
          </div>
        </CopyToClipboard>}
        <img
          src='/images/icons/close.svg'
          alt='close'
          className='cursor-pointer'
          onClick={() => props?.teamsOpener(false)}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  teamsOpener: (arg) => dispatch(setTeamsState(arg)),
});
const mapStateToProps = (state) => ({
  teamDrawerState: state?.toggleReducer?.teamDrawerState,
  inviteDetails: state?.inviteReducer?.inviteDetails
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamDrawerNav);
