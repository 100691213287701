import React from 'react';
import TeamDrawerSearchBar from './TeamDrawerSearchBar';

const TeamDrawerBody = (props) => {
  return (
    <div className='team-drawer-body'>
      <TeamDrawerSearchBar
        allUsers={props?.allUsers}
        projectId={props?.projectId}
        participants={props?.participants}
        workspaceId={props?.workspaceId}
        inviteDetails={props?.inviteDetails}
      />
    </div>
  );
};

export default TeamDrawerBody;
