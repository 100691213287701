import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Switch } from 'antd';
import socket from '../../../../js/socket';

import { editUser, getUser, signedUploadUrl, uploadUserAvi } from '../../../../actions';

function MyAccount({ user }) {
  const [notifications, setNotifications] = useState(user?.preferences?.notifications);
  const [newPicture, setNewPicture] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on('user-updated', (id) => {
      dispatch(getUser(id));
    });

    dispatch(getUser(user._id));
  }, [dispatch]);

  const handlePreferencesUpdate = (e) => {
    setNotifications(!notifications);
    dispatch(
      editUser(
        {
          _id: user._id,
          preferences: {
            notifications: !notifications
          }
        },
        async () => {
          await dispatch(getUser(user._id));
        }
      )
    );
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (event) => {
    const file = event?.target?.files[0];
    const newFile = await toBase64(event.target.files[0]);
    setNewPicture(newFile);
    const { signedUrl, path } = await dispatch(uploadUserAvi({ fileName: file?.name }));
    if (signedUrl) {
      await dispatch(signedUploadUrl(signedUrl, file));
    }
    await dispatch(
      editUser(
        {
          _id: user._id,
          avi: path,
        },
        async () => {
          await dispatch(getUser(user._id));
        }
      )
    );
  };

  return (
    <div className="p-6 space-y-6">
      <h2 className="text-2xl text-slate-800 font-bold mb-5">My Account</h2>
      {/* Picture */}
      <section>
        <div className="flex items-center">
          <div className="mr-4">
            <div className="photo">
              <img className="w-40 h-40 rounded-full" style={{ objectFit: 'cover' }} src={newPicture || user.avi} width="80" height="80" alt="User upload" />
              {(
                <div
                  className="overlay"
                  onClick={() => {
                    const fileInput = document.createElement('input');
                    fileInput.type = 'file';
                    fileInput.accept = 'image/jpeg,image/png';
                    fileInput.onchange = handleFileChange;
                    fileInput.click();
                  }}
                >
                  <p className="overlay-text">Change Profile Picture</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* Name */}
      <section>
        <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">Full Name</h2>
        <div className="text-sm">{user?.fullName}</div>
      </section>
      {/* Email */}
      <section className='pt-3 border-t border-slate-200'>
        <h2 className="text-xl leading-snug text-slate-800 font-bold mb-1">Email</h2>
        <div className="text-sm">{user?.email}</div>
      </section>
      {/* Notifications */}
      <section>
        <ul>
          <li className="flex justify-between items-center py-3 border-t border-slate-200">
            {/* Left */}
            <div>
              <div className="text-slate-800 font-semibold">Notifications</div>
              <div className="text-sm">Receive notifications for tasks, chats, and mentions?</div>
            </div>
            {/* Right */}
            <div className="flex items-center ml-4">
              <div className="text-sm text-slate-400 italic mr-2">{notifications ? 'Allow' : 'Block'}</div>
              <div className="form-switch">
                <Switch checked={notifications} onClick={(e) => handlePreferencesUpdate(e)} />
              </div>
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default MyAccount;