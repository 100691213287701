import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUser } from '../../../../actions';
import { connectGmailCalendar, disconnectCalendar } from '../../../../actions/calendarAction';
import socket from '../../../../js/socket';

const CalendarTab = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on('user-updated', (id) => {
      dispatch(getUser(id));
    });
  }, [dispatch]);


  const handleSyncCalendar = (event) => {
    const buttonId = event.target.getAttribute('data-button-id');

    // Start loading
    setIsLoading(true);
    dispatch(connectGmailCalendar(user && user._id, buttonId));

    // Simulate API call or loading process
    setTimeout(() => {
      // Stop loading
      setIsLoading(false);
    }, 2000);
  };

  const handleDisconnect = (event) => {
    const spanId = event.target.getAttribute('data-span-id');
    dispatch(disconnectCalendar(user && user._id, spanId));
  }

  return (
    <div>
      <p>Synchronize your calendar to view all your upcoming meetings.</p>
      {user.calendars && user.calendars.includes('gmail') ?
        <div>
        <span className="connected-span">
          Connected with Google Calendar
        </span>
        <span className="disconnect-text" data-span-id="gmail" onClick={handleDisconnect}>Disconnect</span>
      </div> : <button
          className={isLoading ? 'sync-button loading' : 'sync-button'}
          onClick={handleSyncCalendar}
          disabled={isLoading}
          data-button-id="gmail"
          >
          {isLoading ? 'Loading...' : 'Sync with Google Calendar'}
        </button>

      }
    </div>
  );
};

export default CalendarTab;
