import { Avatar, Tooltip } from 'antd';
import React from 'react';
const GroupAvatar = ({ participants, size = 24 }) => {
  const projectParticipants = [];
  return (
    <>
      <Avatar.Group
        maxCount={5}
        maxPopoverTrigger='click'
        size='medium'
        maxStyle={{
          color: 'white',
          backgroundColor: '#4077EE',
          cursor: 'pointer',
          height: size,
          width: size,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '12px'
        }}
      >
        {participants &&
          participants.forEach((participant, key) => {
            projectParticipants.push(
              <Tooltip
                key={key}
                title={participant && participant.fullName}
                placement='top'
              >
                <Avatar
                  style={{
                    backgroundColor: '#87d068',
                    height: size,
                    width: size
                  }}
                  icon={<img src={participant && participant.avi} alt='icon' />}
                />
              </Tooltip>
            );
          })}
        {projectParticipants}
      </Avatar.Group>
    </>
  );
};
export default GroupAvatar;
