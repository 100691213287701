import React, { useState, useEffect, useRef } from 'react';
import { Tooltip } from 'antd';
import { addTask, clearSingleTask, editTask, isChatOpen, isTicketOpen, setTaskProperties } from '../../../../actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Calendar } from 'antd';

import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { setTeamsState } from '../../../../actions/toggleAction';

const AddTask = (props) => {
  const projectData = useSelector((state) => state.projectReducer.getProjects);
  const workSpaceData = useSelector(
    (state) => state.workspaceReducer.getWorkspaces
  );

  const { isListView = false, isTop = false, isEdit = false } = props;

  const [isCalender, setIsCalender] = useState(false);
  const [isTaskLoading, setIsTaskLoading] = useState(false);

  const [calendarPosition, setCalendarPosition] = useState({ x: 0, y: 0 });

  const [hover, setHover] = useState('');
  const [calendarDate, setCalendarDate] = useState(moment(new Date()));
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const openNewTicket = (newTask) => {
    const params = new URLSearchParams(location.search);
    if(!newTask) {
      dispatch(isTicketOpen(false));
      params.delete('selectedTask');
      const newUrl = `${location.pathname}?${params.toString()}`;
      navigate(newUrl);
      return;
    }
    dispatch(clearSingleTask())
    params.delete('msgId'); // Remove the specified parameter

    params.set('selectedTask', newTask?.titleIdentifier)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    // Use navigate to replace the current URL
    navigate(newUrl);

    dispatch(isTicketOpen(true));
    dispatch(isChatOpen(false));
    dispatch(setTeamsState(false));
  };

  const handleSubmit = async (e) => {
    let workSpaceTitle;
    let workspaceId
    e.preventDefault();

    if (!isTaskLoading) {
      workSpaceData.forEach((workspace) => {
        if (projectData.workspace === workspace._id) {
          workSpaceTitle = workspace.title;
          workspaceId = workspace._id
        }
      });
      if (props.taskProperties.taskTitle !== '') {
        if (isEdit) {
          dispatch(
            editTask({
              _id: props.taskProperties._id,
              taskTitle: props.taskProperties.taskTitle,
              dueDate: props.taskProperties.dueDate,
            }, () => {
              setIsCalender(false);
              setIsTaskLoading(false);
              props.setAddTaskVisible(false);
            })
          );
        } else {
          setIsTaskLoading(true);
          openNewTicket({titleIdentifier: 'loading'})

          const newTask = await dispatch(
            addTask({ ...props.taskProperties, workspace: workSpaceTitle, workspaceId }, () => {
              setIsCalender(false);
              setIsTaskLoading(false);
              props.setAddTaskVisible(false);
            })
          );
          openNewTicket(newTask);
        }
      }
      dispatch(
        setTaskProperties({
          ...props.taskProperties,
          taskTitle: '',
          dueDate: '',
        })
      );
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    return dispatch(
      setTaskProperties({
        ...props.taskProperties,
        [e.target.name]: e.target.value,
        parentTaskId: null,
      })
    );
  };

  const handlePaste = async (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    if (props.taskProperties) {
      props.taskProperties.taskTitle =
        props.taskProperties.taskTitle + pastedText;
      return dispatch(
        setTaskProperties({
          ...props.taskProperties,
          parentTaskId: null,
        })
      );
    }
  };

  useEffect(() => {
    if (props.taskProperties.dueDate) {
      setCalendarDate(moment(new Date(props.taskProperties.dueDate)));
    } else {
      setCalendarDate(null);
    }
  }, [props.taskProperties.dueDate]);

  const addTaskRef = useRef(null);
  const inputRef = useRef(null);
  const formRef = useRef(null);
  const calendarRef = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (addTaskRef.current && !addTaskRef.current.contains(e.target)) {
        if (inputRef.current?.value !== '') {
          formRef.current &&
            formRef.current.dispatchEvent(
              new Event('submit', { bubbles: true, cancelable: true })
            );
        }
        props.setAddTaskVisible(false);
      }

      if (calendarRef.current && !calendarRef.current.contains(e.target)) {
        setIsCalender(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, []);

  return (
    <div
      className={isListView ? `${!isTop && !isEdit && 'add-task-list-view'} list-view-card position-sticky left-0` : "task-card"}
      ref={addTaskRef}
      style={{ border: '2px solid #6C6D6E', backgroundColor: 'white' }}
    >
      <div style={{ width: isListView && '100%' }}>
        <form
          onSubmit={handleSubmit}
          // onBlur={handleSubmit}
          ref={formRef}
        >
          <div className={'d-flex align-center'} style={{ cursor: 'pointer' }}>
            <div className="font-14" style={{ paddingLeft: '8px', width: '100%' }}>
              <textarea
                name="taskTitle"
                value={props.taskProperties.taskTitle}
                rows={isListView ? 1 : (props.taskProperties?.taskTitle?.split("\n").length || 1)}
                placeholder="Task name"
                onChange={handleChange}
                required
                autoFocus
                ref={inputRef}
                type="text"
                style={{ width: '100%', border: 'none', outline: 'none', resize: 'none' }}
                onPaste={handlePaste}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault(); // Prevent the default behavior (adding a newline)
                    handleSubmit(e); // Call your form submission logic
                  }
                }}
              />
            </div>
          </div>
          {!isListView && <div
            className="d-flex"
            style={{ marginTop: '15px' }}
          >
            {isCalender ? (
              <div
                className="task-card"
                style={{
                  position: 'absolute',
                  width: '300px',
                  top: calendarPosition?.y < 550 ? calendarPosition?.y :  calendarPosition?.y - 300,
                  left: calendarPosition?.x || 0,
                  background: 'white',
                }}
                ref={calendarRef}
              >
                <div className="site-calendar-demo-card">
                  <Calendar
                    fullscreen={false}
                    value={calendarDate}
                    onChange={(e) => {
                      setCalendarDate(e);
                      dispatch(
                        setTaskProperties({
                          ...props.taskProperties,
                          dueDate: e.format('YYYY-MM-DD'),
                        })
                      );
                    }}
                  />
                </div>
              </div>
            ) : props.taskProperties.dueDate !== '' ? (
              <div
                className="font-12"
                style={{ color: 'black', cursor: 'pointer' }}
                onClick={e => {
                  dispatch(
                    setTaskProperties({
                      ...props.taskProperties,
                      dueDate: '',
                    })
                  );
                  setCalendarPosition({  
                    x: e.clientX,
                    y: e.clientY
                  })
                  setIsCalender(true);
                }}
              >
                {moment(new Date(props.taskProperties.dueDate)).format(
                  'DD MMM'
                )}
              </div>
            ) : (
              ''
            )}
            <>
              {props.taskProperties.dueDate === '' && (
                <div
                  style={{
                    marginLeft: '-4px',
                    cursor: 'pointer',
                    height: '30px',
                    paddingRight: '20px',
                  }}
                  onClick={e => {
                    setCalendarPosition({  
                      x: e.clientX,
                      y: e.clientY
                    });
                    setIsCalender(!isCalender);
                  }}
                  onMouseEnter={() => setHover('calender')}
                  onMouseLeave={() => setHover('')}
                >
                  <Tooltip title="Add due date">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.6109 5.8335H12.7774V5H12.2219V5.8335H7.77764V5H7.22211V5.8335H6.38907C5.62316 5.8335 5 6.4566 5 7.22249V13.611C5 14.3769 5.62314 15 6.38907 15H13.6109C14.3768 15 15 14.3769 15 13.611V7.22207C14.9995 6.4562 14.3764 5.83358 13.6109 5.83358V5.8335ZM14.444 13.6104C14.444 14.0696 14.0701 14.4434 13.6109 14.4434H6.38907C5.92935 14.4434 5.55553 14.0696 5.55553 13.6104V8.61097H14.444V13.6104ZM14.444 8.05548H5.55553V7.22198C5.55553 6.76229 5.92935 6.38848 6.38907 6.38848H7.22262V6.94398H7.77766V6.38897H12.2219V6.94447H12.7774V6.38897H13.6109C14.0702 6.38897 14.444 6.76229 14.444 7.22196L14.444 8.05548Z"
                        fill={hover === 'calender' ? 'black' : '#ADADAD'}
                      />
                      <circle
                        cx="10"
                        cy="10"
                        r="9.75"
                        stroke={hover === 'calender' ? 'black' : '#ADADAD'}
                        strokeWidth="0.5"
                        strokeDasharray="2 2"
                      />
                    </svg>
                  </Tooltip>
                </div>
              )}
            </>
          </div>}
        </form>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    taskProperties: state.taskReducer.taskProperties,
  };
}
export default connect(mapStateToProps)(AddTask);
