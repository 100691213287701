import React from "react";
import HtmlRenderer from "../../organisms/HtmlRenderer";

const ProfileAndName = ({ name, src, height, width, imageOverwrite }) => {
  return (
    <div className="align-center flex">
      <div className="profile">
        <img
          src={`${imageOverwrite ? src : "/images/icons/" + src}`}
          style={{ height: height, width: width, objectFit: 'cover' }}
          alt="icon"
        />
      </div>
      <div className="flex align-center">
        <div className="font-18 mr-3">{name}</div>
        <img
          className='hover:opacity-60'
          src={'/images/icons/info.svg'}
          style={{
            cursor: 'pointer',
            height: '20px',
            width: '20px',
          }}
          alt='icon'
        />
      </div>
    </div>
  );
};

export default ProfileAndName;
