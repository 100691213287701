import DOMPurify from 'dompurify';

const HtmlRenderer = ({ html, truncate }) => {
  const convertMentionsToLinks = (text) => {
    // Regular expression to match mentions like @[@Name](id)
    const mentionRegex = /@\[@(.*?)\]\((.*?)\)/g;

    // Replace mentions with <a> tags
    // const htmlText = text.replace(mentionRegex, '<a href="/user/$2">@$1</a>');
    const htmlText = text?.replace(mentionRegex, '<a href="javascript:void(0)">@$1</a>');

    return htmlText;
  }

  const linkifyText = (text) => {
    let result = text || '';
    try {
      const urls = detectURLs(text);
      urls.forEach((url) => {
        const appURL = process.env.REACT_APP_URL;
        var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);


        // Check if the URL should open in a new tab (external link) or within the app
        const internalLink = url.startsWith(appURL);
        const target = internalLink ? '_self' : '_blank';
        const refinedUrl = url;

        let linkName = url;
        let classname = 'box-link';

        // if (url.split(window.location.origin).length > 1) {
        //   let taskIdentifer = url.split('selectedTask=');

        //   if (taskIdentifer.length > 1) {
        //     const selectedTask = taskIdentifer[1];
        //     linkName = selectedTask;
        //   } else if (url.includes('board') || url.includes('list')) {
        //     linkName = 'Project';
        //   } else if (url.includes('chat')) {
        //     linkName = 'Chat room';
        //   } else {
        //     linkName = url;
        //   }
        // }

        const link = `<a href="${res?.[0]}"  class=${classname} target="${target}" rel="noopener noreferrer">${'Osaama'}</a>`;
        const xyz = `<a href="${url}" rel="noopener noreferrer" target="_blank">${res?.[0]}</a>`
        result = result?.replace('<a href="', '<a class=box-link href="');
      });
    }

    catch (error) {
      console.info(error);
    }

    return result;
  };

  // Function to detect URLs using a regular expression
  const detectURLs = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = text?.match(urlRegex);
    return matches || [];
  };

  const sanitizeHTML = (htmlString) => {
    htmlString.replace('<br>', '');
    return DOMPurify.sanitize(htmlString);
  };

  const processInternalLinks = (html) => {
    const appHost = process.env.REACT_APP_URL;
    // Create a temporary div element to manipulate the HTML string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
  
    // Get all anchor elements within the HTML
    const links = tempDiv.getElementsByTagName('a');
  
    // Loop through all anchor elements to find internal app links
    for (let link of links) {
      let url;
      try {
        url = new URL(link?.href);
      } catch (e) {
        console.log('processInternalLinks', link?.href, e);
        continue;
      }
  
      // Check if the link is an internal app link
      if (url.host === appHost) {
        // Check if the selectedTask query parameter is present
        const selectedTask = url.searchParams.get('selectedTask');
        if (selectedTask) {
          // Replace the link text with the value of selectedTask
          link.innerHTML = selectedTask;
        }
      }
    }
  
    // Return the modified HTML as a string
    return tempDiv.innerHTML;
  }
      

  const formattedComment = linkifyText(convertMentionsToLinks(html));
  const sanitizedHTML = sanitizeHTML(formattedComment);

  return <div className={`space-y-2 chat-message ${truncate ? 'truncate-inner-html' : ''}`} dangerouslySetInnerHTML={{ __html: processInternalLinks(sanitizedHTML) }}></div> ;

};

export default HtmlRenderer;