
import '../../../../../../assets/style/taskFilter.css';

const ProjectIcon = () => {
    return (
        <svg
        className="filter-icon"
            style = {{marginRight: '8px'}}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 14"
            fill="currentColor"
            aria-hidden="true">
            <path d="M5.948 2H2.623A.623.623 0 0 0 2 2.623v3.325c0 .344.28.623.623.623h3.325c.344 0 .623-.279.623-.623V2.623A.623.623 0 0 0 5.948 2ZM13.377 2h-3.325a.623.623 0 0 0-.623.623v3.325c0 .344.279.623.623.623h3.325c.344 0 .623-.279.623-.623V2.623A.623.623 0 0 0 13.377 2ZM5.948 9.429H2.623a.623.623 0 0 0-.623.623v3.325c0 .344.28.623.623.623h3.325c.344 0 .623-.28.623-.623v-3.325a.623.623 0 0 0-.623-.623ZM13.377 9.429h-3.325a.623.623 0 0 0-.623.623v3.325c0 .344.279.623.623.623h3.325c.344 0 .623-.28.623-.623v-3.325a.623.623 0 0 0-.623-.623Z"></path>
        </svg>
    )
}

export default ProjectIcon;
