import '../../../../../../assets/style/taskFilter.css';

const ResolutionIcon = () => {
  return (
    <svg
      className="filter-icon"
      style={{ marginRight: '8px' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M13.354 4.354a.5.5 0 0 1 .708 0l1 1a.5.5 0 0 1 0 .708l-9 9a.5.5 0 0 1-.708 0L2.5 9.707a.5.5 0 0 1 0-.708l1-1a.5.5 0 0 1 .708 0L7 10.586l4.646-4.646z"
      />
    </svg>
  );
};
export default ResolutionIcon;
