const arrMonth = ["January","February","March","April","May","June","July","August","September","October","November","December"];

export const getDate = (UTF) => {
    if(!UTF) return;
    let date = UTF instanceof Date ? UTF : new Date(UTF);
    date.setDate(date.getDate());
    date = date.toISOString().slice(0, 10);
    return date.split('-').reverse().join('/');
}

export const getDateForCard = (UTF) => {
  if(!UTF) return;
  const dateText = getDateText(UTF);
  if(dateText === "Today" || dateText === "Tomorrow" || dateText === "Yesterday") {
    return dateText;
  }
  let date = UTF instanceof Date ? UTF : new Date(UTF);
  date.setDate(date.getDate());
  const splitDate = date.toISOString().slice(0, 10).split('-');
  const month = arrMonth[parseInt(splitDate[1]) - 1].substring(0,3);
  const day = splitDate[2];
  return `${day} ${month}`;
}

export const getDateWithoutChangingTZ = (UTF) => {
  if(!UTF) return;
  const date = new Date(UTF);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export const dayMonth = (UTF) => {
  let day = new Date(UTF).getDate()
  let diMonth = new Date(UTF).getMonth()
  let month = arrMonth[diMonth].substring(0,3)
  let date = day +" "+ month
  return date
}

export const getToday = () => {
  return getDateWithoutChangingTZ(new Date());
}

export const getYesterday = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return getDateWithoutChangingTZ(yesterday);
}

export const getTomorrow = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return getDateWithoutChangingTZ(tomorrow);
}

export const getDateColor = (UTF) => {
  if(!UTF) return;
  let date = new Date(UTF)?.getTime();
  let today = new Date()?.getTime();
  
  let color = '';
  if(getDate(UTF) === getToday() || getDate(UTF) === getTomorrow()) color = 'orange';
  else if(date > today) color = '';
  else color = 'red';

  return color;
}

export const getDateText = (date) => {
  if(!date) return 'No due date';
  date = getDate(date);

  return date === getToday() ? 'Today' : date === getTomorrow() ? 'Tomorrow' : date === getYesterday() ? 'Yesterday' : date;
}

export const getDateTextLongFormat = (date) => {
  if(!date) return 'No date specified';
  const shortDate = getDate(date);

  return shortDate === getToday() ? 'Today' : shortDate === getTomorrow() ? 'Tomorrow' : shortDate === getYesterday() ? 'Yesterday' : date;
}

export const getUserLicense = (user, organizationId) => {
  const userOrg = user?.organizations?.find(o => o?._id?.toString() == organizationId?.toString());
  if(!userOrg){
    console.log("---- User is not part of selected org");
    return "Free Plan";
  }

  const licenseMap = {
    "enterprise": "Enterprise Plan",
    "business": "Business Plan",
    "free": "Free Plan",
    "team": "Team Plan"
  }

  return licenseMap[userOrg?.license?.name];
}
