import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import EmailConfirmation from "../molecules/EmailConfirmation";
import SignUpForm from "../molecules/SignUpForm";
import Icon from '../atoms/global/Icon';

const SignUp = () => {
  const [signup, setSignup] = useState(false);
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  if (localStorage.getItem('token') != undefined) {
    setTimeout(() => {
      navigate('/select-workspace', { replace: true });
    }, 100);
  }

  return (
    <>
      <div
        className='sign-in-page'>
        <div style={{ padding: 15, float: 'left', cursor: 'pointer' }} onClick={() => navigate('/')}>
          <Icon
            name='logo.svg'
            height='16px'
            pr='20px' />
        </div>
        <div className="login-page d-flex align-center" style={{ top: signup ? 0 : 50 }}>
          <img src='images/icons/loginIcon.png' width={200} />
          {/* {!signup ? */}
            <div className="login-container">
              <h1 className="text-darkGray font-40 text-center mb-2">Welcome to Workflo!</h1>
              <SignUpForm setSignup={setSignup} setEmail={setEmail} />
            </div>
            {/* : <EmailConfirmation name={email} setSignup={setSignup} /> */}
          {/* } */}
        </div>
      </div>
    </>
  );
};

export default SignUp;
