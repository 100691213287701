import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import { Dropdown, Tooltip } from 'antd';

import {
  toggleNewTeamChatsModal,
} from '../../actions';

import GroupAvatar from '../UI/atoms/side-navbar/GroupAvatar';

const Teams = (props) => {

  const { teams, currentWorkspace, myProjects } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [localTeams, setLocalTeams] = useState(teams);

  useEffect(() => {
    setLocalTeams(teams);
  }, [teams])

  return (
    <div style={{ background: '#fafafa' }} className='p-5 flex flex-col'>
      <div
        className="bg-[#0091CC] flex align-center self-end px-4 py-2 rounded-lg lg:w-[250px] w-16 sm:w-20 shadow-xl cursor-pointer hover:bg-opacity-90"
        onClick={() => {
          dispatch(toggleNewTeamChatsModal({
            value: true,
            isTeams: true
          }));
        }}>
        <img src="/images/icons/teams.svg" alt="img" className='h-8' />
        <div className='sm:hidden hidden lg:block'>
          <div className='ml-3 py-2'>
            <div className='font-18 text-white'>Create New Team</div>
          </div>
        </div>
      </div>
      <div className='mt-5'>
        <div className='grid grid-cols-5 gap-6 px-5 pb-2 font-14 font-bold text-slate-500'>
          <div className='col-span-1'>
            Team Name
          </div>
          <div className='col-span-1'>
            Projects
          </div>
          <div className='col-span-1'>
            Access
          </div>
          <div className='col-span-1'>
            Members
          </div>
          <div className='col-span-1'>
            Created At
          </div>
        </div>
        {localTeams?.map(team => {
          const count = myProjects?.filter(project => project?.team === team?._id)?.length || 0;

          const isPublic = team?.access === 'public';

          var date = new Date(team.createdAt);
          TimeAgo.addLocale(en);
          const timeAgo = new TimeAgo('en-US');
          const time = timeAgo.format(date);

          return (
            <div className='relative grid grid-cols-5 gap-6 bg-white hover:bg-slate-50 rounded border-slate-100 border shadow mb-2 px-5 h-20 flex flex-col align-center'>
              <div className='col-span-1 font-bold text-slate-600 font-18'>
                {team?.title}
              </div>
              <div className='col-span-1 flex align-center'>
                <div className='font-14 mr-2' >{count} Project{count > 0 && 's'}</div>
                <img
                  className='mt-0.5'
                  src={`/images/icons/black-arrow-down.svg`}
                  style={{
                    cursor: 'pointer',
                    height: '10px',
                    width: '10px',
                  }}
                  alt='icon'
                />
              </div>
              <div className='col-span-1 flex align-center'>
                <img
                  src={`/images/icons/${isPublic ? 'unlock' : 'lock'}.svg`}
                  style={{
                    cursor: 'pointer',
                    height: '18px',
                    width: '18px',
                  }}
                  alt='icon'
                />
                <div className={`font-14 ml-3 capitalize ${isPublic ? 'text-emerald-600' : 'text-indigo-400'}`}>{team?.access}</div>
              </div>
              <div className='col-span-1 mt-2 flex flex-col'>
                <GroupAvatar
                  participants={
                    team?.members
                  }
                  size={26}
                />
                <div className='font-14 pt-1'>{team?.members?.length || 0} Members</div>
              </div>
              <div className='col-span-1 font-14'>
                {time}
              </div>
              <div className='absolute right-3'>
                <img
                  className='rotate-90'
                  src={'/images/icons/threeDots.svg'}
                  style={{
                    cursor: 'pointer',
                    height: '18px',
                    width: '18px',
                  }}
                  alt='icon'
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    teams: state.teamReducer.getTeams,
    currentWorkspace: state.workspaceReducer.currentWorkspace,
    myProjects: state.projectReducer.myProjects,
  };
}
export default connect(mapStateToProps)(Teams);

