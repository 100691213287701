import React, { useEffect, useState } from 'react';
import { Dropdown, notification } from 'antd';
import { connect, useDispatch } from 'react-redux';
import {
  editSection,
  getSectionTasks,
  isTicketOpen,
  setTaskProperties,
  updateQueryState,
  deleteTask,
  addTask,
  editTask
} from '../../../../actions';

import Icon from '../../atoms/global/Icon';

import ListViewCard from './ListViewCard';
import AddTask from './AddTask';
import IconAndName from '../../atoms/side-navbar/IconAndName';
import { useLocation, useNavigate } from 'react-router-dom';
import { Droppable } from 'react-beautiful-dnd';
import MenuContext from '../right-click-menu/MenuContext';
import Loader from '../../atoms/global/Loader';

import ConfirmationModal from '../../molecules/modals/ConfirmationModal';

const ListView = (props) => {
  const navigate = useNavigate();

  const {
    tasks,
    query,
    id,
    taskFilters,
    title,
    sectionHandler,
    setphase,
    phase,
    setSectionTitle,
    projectId,
    workspaceTitle,
    sectionTitle,
    sectionKey,
    taskProperties,
    editSectionByProjectId,
    color,
    sectionCounts
  } = props;

  const [isAddTaskVisible, setAddTaskVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();
  const [showLess, setShowLess] = useState(false);
  const [addTaskObj, setAddTaskObj] = useState({
    addTaskPosition: '',
  });

  const taskCount = tasks?.filter((task) => {
    const section_id = task?.sectionId;
    return section_id === id;
  }).length || 0;

  const [hasMoreData, setHasMoreData] = useState((sectionCounts && sectionCounts[id] || 0) > 9);
  const [isLoading, setIsLoading] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const loadTasks = async () => {
    // Prevent loading if already loading or all notifications are loaded
    if (isLoading || !hasMoreData) {
      return;
    }

    setIsLoading(true);

    // Make your API call to fetch more notifications
    const sectionTasks = await dispatch(getSectionTasks(id, query[id] || 1, taskFilters));
    if (sectionTasks?.length === 0) {
      // If the API returns an empty array, there is no more data
      setHasMoreData(false);
    } else {
      setHasMoreData(sectionTasks?.length > 9);
      dispatch(updateQueryState(id));
    }

    // if (sectionTasks.length === taskCount) {
    //   setHasMoreData(false);
    // }

    setIsLoading(false);
  };

  useEffect(() => {
    if (sectionCounts > 9) {
      setHasMoreData(true);
    }
  }, [sectionCounts]);

  const enableEditting = () => {
    setSectionTitle(title);
    setphase(id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      _id: id,
      title: sectionTitle,
    };
    dispatch(
      editSection(obj, () => {
        setSectionTitle('');
        setphase('display');
        editSectionByProjectId();
      })
    );
  };

  const deleteHandler = () => {
    setOpen(false);
    sectionHandler('deleteSection', id);
    dispatch(isTicketOpen(false));
    navigate(`/${projectId}/list`);
  };

  const handleDeleteTask = (taskId) => {
    dispatch(
      deleteTask(taskId, () => {
        dispatch(isTicketOpen(false));
        const params = new URLSearchParams(location.search);
        params.delete('selectedTask'); // Remove the specified parameter  
        const newUrl = `${location.pathname}?${params.toString()}`;
        navigate(newUrl, { replace: true });
      })
    );
  };

  const items = [
    {
      key: 0,
      label: <div className='list-section-dropdown-item' onClick={() => {
        dispatch(
          setTaskProperties({
            ...taskProperties,
            projects: [
              {
                section: id,
                projectId: projectId,
                position: 'top',
              }
            ],
            addedTo: 'top',
            workspace: workspaceTitle,
          })
        );
        addTaskHadler(sectionKey, 'top');
      }} >Add Task</div>
    },
    {
      key: '1',
      label: <div className='list-section-dropdown-item' onClick={enableEditting}>Edit Section</div>,
    },
    {
      key: '2',
      label: <div className='list-section-dropdown-item' onClick={() => setOpen(true)}>Delete Section</div>,
    },
  ];

  const addTaskHadler = (sectionKey, position) => {
    setAddTaskVisible(true);
    setAddTaskObj({
      ...addTaskObj,
      selectedSection: sectionKey,
      addTaskPosition: position,
    });
  };

  const hasCustomFields = (tasks && tasks?.length > 0 && tasks[0]?.customFields?.length || 0) + 12;

  return (
    <div className='list-view-container'>
      <ConfirmationModal
        title='Delete Section?'
        description='Are you sure you want to delete this section?'
        isModalOpen={open}
        onCancel={() => setOpen(false)}
        onYesClick={deleteHandler}
      />

      <div
        className="sticky left-5 bg-white rounded-sm border border-slate-200 w-80 h-14 flex align-center justify-between"
        style={{ borderTop: `2px solid ${color}`, borderRadius: '10px 10px 0 0' }}
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}>
        <div className='px-3 d-flex justify-content-between align-center w-full'>
          <div className='d-flex align-center'>
            {phase === id ? (
              <form onSubmit={handleSubmit} onBlur={handleSubmit}>
                <input
                  autoFocus
                  value={sectionTitle}
                  onChange={(e) => setSectionTitle(e.target.value)}
                  className='font-16'
                  style={{
                    width: '10em',
                    border: 'none',
                    outline: 'none',
                  }}
                  type='text'
                />
              </form>
            ) : (
              <>
                <div className='card-number d-flex mr-2'>
                  <div>{sectionCounts && sectionCounts[id] || 0}</div>
                </div>
                <div className={`font-16 truncate max-w-${isHover ? '40' : '60'}`} onClick={enableEditting}>
                  {title}
                </div>
              </>
            )}
          </div>
          {isHover &&
            <div className='d-flex align-center mr-2'>
              <Dropdown
                menu={{
                  items,
                }}
                placement='bottom'
                trigger={['click']}
                arrow
              >
                <div>
                  <img
                    src={'/images/icons/threeDots.svg'}
                    style={{
                      cursor: 'pointer',
                      height: '16px',
                      width: '16px',
                    }}
                    alt='icon'
                  />
                </div>
              </Dropdown>
              <Icon
                name='black-arrow-down.svg'
                height='10px'
                pl='10px'
                onClick={() => {
                  setShowLess(!showLess);
                }}
              />
            </div>}
        </div>
      </div>

      {/* // ............................................? */}
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <>
            {!showLess ? <div
              className='list-view'
              style={{ overflowX: 'scroll' }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <div className={``}>
                <div className={`grid gap-0 shrink-0 md:w-[100vw] w-[300vw]`} style={{ gridTemplateColumns: 'repeat(13, minmax(0, 1fr))' }}>
                  <div className='col-span-1 pl-4 py-2 font-14 sticky left-0 z-10 bg-white border-slate-200 border-r'>
                    Completed
                  </div>
                  <div className='col-span-6  pl-4 border-slate-200 border-r py-2 font-14'>
                    Title
                  </div>
                  <div className='col-span-1 pl-4 border-slate-200 border-r py-2 font-14'>
                    Date
                  </div>
                  <div className='col-span-1 pl-4 border-slate-200 border-r py-2 font-14'>
                    Assignee
                  </div>
                  <div className='col-span-1 pl-4 border-slate-200 border-r py-2 font-14'>
                    Priority
                  </div>
                  <div className='col-span-1 pl-4 border-slate-200 border-r py-2 font-14'>
                    Comments
                  </div>
                  <div className='col-span-1 pl-4 border-slate-200 border-r py-2 font-14'>
                    Subtasks
                  </div>
                  {tasks && tasks?.length > 0 && tasks[0]?.customFields && <div className='col-span-1 pl-4 py-2 font-14'>
                    {tasks[0]?.customFields?.[0]?.name}
                  </div>}
                </div>
                {tasks
                  && tasks
                    .filter((task) => {
                      const sectionId = task?.sectionId;
                      return sectionId === id;
                    })
                    .sort((a, b) => b.position - a.position)
                    .map((task, taskKey) => {
                      return (
                        <MenuContext
                          items={[
                            {
                              name: 'Copy Link',
                              onClick: () => {
                                navigator.clipboard.writeText(window.location.href + '?selectedTask=' + task?.titleIdentifier);
                                notification.success({
                                  message: 'Task link copied to clipboard',
                                  placement: 'bottomRight',
                                });
                              }
                            },
                            {
                              name: 'Edit',
                              onClick: () => {
                                dispatch(
                                  setTaskProperties({
                                    ...task,
                                  })
                                );
                                //setIsEdit(task._id);
                              }
                            },
                            {
                              name: `Mark as ${task?.isCompleted ? 'incomplete' : 'complete'}`,
                              onClick: async () => {
                                const collaboratorsIds = task.participants && task.participants.map((member) => member._id);
                                await dispatch(
                                  editTask(
                                    {
                                      _id: task._id,
                                      isCompleted: !task?.isCompleted,
                                      collaborators: collaboratorsIds
                                    })
                                )
                              }
                            },
                            {
                              name: 'Duplicate Task',
                              onClick: () => {
                                dispatch(addTask({
                                  ...props?.taskProperties,
                                  taskTitle: task?.taskTitle,
                                  taskDescription: task?.taskDescription,
                                  cost: 0,
                                  dueDate: task?.dueDate,
                                  section: task?.sectionId,
                                  sectionCounts: {},
                                  isCompleted: false,
                                  documentPath: '',
                                  projects: [{
                                    section: task?.sectionId,
                                    projectId: task?.projectId,
                                    position: 'bottom'
                                  }],
                                  addedTo: 'bottom',
                                  parentTaskId: null
                                }));
                              }
                            },
                            {
                              name: <div className='text-red-600 font-14'>Delete</div>,
                              onClick: () => handleDeleteTask(task._id)
                            }
                          ]}>
                          <ListViewCard task={task} index={taskKey} count={hasCustomFields} />
                        </MenuContext>
                      );
                    })}
              </div>
              <>
                {hasMoreData &&
                  <div
                    className='show-more-task-row sticky left-0'
                    style={{ padding: 0, height: 30 }}
                    onClick={() => {
                      loadTasks();
                    }}>
                    {isLoading ? <Loader isLoading={isLoading} type='clip' size={10} /> : 'Show more'}
                  </div>
                }
                {isAddTaskVisible &&
                  addTaskObj.addTaskPosition === 'bottom' &&
                  sectionKey === addTaskObj.selectedSection ? (
                  <AddTask setAddTaskVisible={setAddTaskVisible} isListView={true} />
                ) : (
                  <>
                    <div className='add-task-row pt-4'
                      style={{
                        position: 'sticky', left: 0,
                      }}
                      onClick={() => {
                        dispatch(
                          setTaskProperties({
                            ...taskProperties,
                            projects: [
                              {
                                section: id,
                                projectId: projectId,
                                position: 'bottom',
                              }
                            ],
                            addedTo: 'bottom'
                          })
                        );
                        addTaskHadler(sectionKey, 'bottom');
                      }}>

                      <IconAndName
                        name='Add task'
                        src='addblack.svg'
                        height='16px'
                        class='font-16 m'
                        color='#434343'
                      />
                    </div>
                  </>
                )}
              </>
              {provided.placeholder}
            </div>
              : <></>}
          </>
        )}
      </Droppable>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isTicketOpen: state.toggleReducer.isTicketOpen,
    tasks: state.taskReducer.tasks,
    taskProperties: state.taskReducer.taskProperties,
    projectData: state.projectReducer.getProjects,
    getTask: state.taskReducer.tasks,
    query: state.taskReducer.query,
    sectionCounts: state.taskReducer.sectionCounts,
    taskFilters: state.taskReducer.filters
  };
}

export default connect(mapStateToProps)(ListView);
