import { useNavigate } from "react-router-dom";
import Icon from '../../atoms/global/Icon';

const ProductNavBar = ({ showPricing, button = 'Launch Workflo' }) => {
  const navigate = useNavigate();

  return (
    <div className='product-nav'>
       <div onClick={() => navigate('/')}>
        <Icon
          name='logo.svg'
          height='16px' />
      </div>
      <div className='d-flex align-center'>
        {showPricing && <div
          onClick={() => navigate('/pricing')}
          className='product-link-button mr-30 font-16'>
          Pricing
        </div>}
        <div
          onClick={() => {
            if (!localStorage.getItem('token')) {
              navigate('/signup');
            } else {
              navigate('/');
            }
          }}
          className='launch-button'
          style={{ width: 120 }}>
          {button}
        </div>
      </div>
    </div>
  );
};

export default ProductNavBar;
