import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';

import {
  Avatar,
  Row,
  Dropdown,
  Tooltip,
  Divider,
} from 'antd';

import Icon from '../../atoms/global/Icon';
import AssigneeOptionLabel from '../../atoms/ticket-drawer/AssigneeOptionLabel';

const AssigneeSelect = (props) => {
  const { projectUsers, editHandler, task, iconSize } = props;

  const [isAssigneeDropdownOpen, setIsAssigneeDropdownOpen] = useState(false);
  const [assigneeValue, setAssigneeValue] = useState([]);
  const [check, setCheck] = useState(false);

  const options = projectUsers && projectUsers.map(((user) => {
    return {
      value: user._id,
      label: user.fullName,
      email: user.email,
      avi: user.avi,
    }
  }));

  useEffect(() => {
    const x = [];
    task?.assignee?.map(assignee => {
      // const option = options.find(option => option.value === assignee.assigneeId);
      // x.push(option);
      if (assignee?.assigneeId?._id) {
        x.push({
          value: assignee.assigneeId?._id,
          label: assignee.assigneeId?.fullName,
          email: assignee.assigneeId?.email,
          avi: assignee.assigneeId?.avi
        })
      }
    })
    setAssigneeValue(x);
  }, [task]);


  const assigneeSelectRef = useRef(null);

  const handleAssignee = (users) => {
    console.info(users);
    setAssigneeValue(users);
    // handleSubmit(users);
  };

  const handleSubmit = (optional) => {
    let users = [];
    const assignees = optional || assigneeValue;
    // assignees.forEach((user) => {
    //   users = [...users, { assigneeId: user.value }];
    // });

    let updateNeeded = false;
    assignees.forEach((user) => {
      if (!task?.assignee?.find(a => a?.assigneeId?._id?.toString() === user?.value)) {
        updateNeeded = true;
      }
      users = [...users, { assigneeId: { _id: user.value, email: user.email, fullName: user.label, avi: user.avi } }];
    });


    if (users?.length == 0 || users?.length != task?.assignee?.length) {
      updateNeeded = true;
    }

    if (updateNeeded) {
      editHandler(
        {
          _id: task._id,
          taskTitle: task.taskTitle,
          assignee: users,
        },
        () => {
          setCheck(false);
        }
      );
    }
  };

  const hasAssignees = task && task?.assignee?.length !== 0;

  return (
    <>
      <Row gutter={[1, 3]} className='flex align-center'>
        <div>
          <Dropdown
            trigger={['click']}
            placement='bottom'
            arrow
            open={isAssigneeDropdownOpen}
            onOpenChange={() => {
              assigneeSelectRef?.current?.focus();
            }}
            dropdownRender={() => (
              <div className={`assignee-dropdown-content-div w-[300px]`}>
                <div className='dropdown-content'>
                  <Divider
                    style={{
                      margin: 0,
                    }}
                  />
                  {/* --content of dropdown--- */}

                  <div className='p-3'>
                    <Select
                      className='text-slate-500'
                      value={assigneeValue}
                      isMulti
                      isClearable={false}
                      options={[]}
                      formatOptionLabel={AssigneeOptionLabel}
                      onChange={handleAssignee}
                      showSearch={false}
                      //defaultMenuIsOpen={true}
                      ref={assigneeSelectRef}
                      filterOption={false}
                      // onBlur={handleSubmit}
                      onBlur={() => {
                        setIsAssigneeDropdownOpen(false);
                        handleSubmit();
                      }
                      }
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          // handleSubmit();
                          setIsAssigneeDropdownOpen(false);
                        }
                      }}
                      classNames={{
                        menuList: (state) => {
                          return 'display-none hidden h-0';
                        },
                      }}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: state.isFocused
                            ? 'transparent'
                            : 'transparent',
                        }),
                        menu: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: 'transparent',
                          display: 'none'
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: '#007fff',
                            color: 'black',
                            display: 'none',
                            fontSize: 20
                          },
                        }),
                        menuList: (baseStyles, state) => ({
                          ...baseStyles,
                          maxHeight: 0,
                          display: 'none'
                        }),
                      }}
                    />
                    <div className='overflow-y-scroll w-full max-h-44 mt-2'>
                      <div onClick={() => setAssigneeValue([])} className='flex align-center pl-2 py-2 hover:bg-slate-200 w-full cursor-pointer'>
                        <div className='card-image bg-indigo-200 h-10 w-10 flex align-center justify-center font-16'>U</div>
                        <div className='ml-3'>
                          <div className='font-16 font-bold'>Unassigned</div>
                        </div>
                      </div>
                      {options?.map(option => {

                        const isSelected = !!assigneeValue.find(user => user?.value == option?.value);

                        return (
                          <div
                            onClick={() => {
                              let x = assigneeValue.slice();
                              if (!isSelected) {
                                x.push(option);
                                setAssigneeValue(x);
                              } else {
                                x = assigneeValue.filter(user => user?.value !== option?.value);
                                setAssigneeValue(x);
                                return;
                              }
                            }}
                            className={`flex align-center pl-2 py-2 my-1 hover:bg-slate-200 ${isSelected && 'bg-blue-100'} w-full cursor-pointer`}>
                            <img src={option?.avi} alt={option?.label} className='card-image object-cover h-10 w-10' style={{ aspectRatio: 1 }} />
                            <div className='ml-3 flex justify-between align-center w-full'>
                              <div>
                                <div className='font-16 font-bold'>{option?.label}</div>
                                <div className='font-12'>{option?.email}</div>
                              </div>
                              {isSelected && <svg className={`w-7 h-7 fill-current text-blue-500 shrink-0 mr-2`} viewBox="0 0 16 16">
                                <path d="m2.457 8.516.969-.99 2.516 2.481 5.324-5.304.985.989-6.309 6.284z" />
                              </svg>}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  {/* --content end--- */}
                </div>
              </div>
            )}
          >
            {hasAssignees ? (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setIsAssigneeDropdownOpen(true);
                }}
              >
                {/* <Space>add assignee</Space> */}
              </a>
            ) : (
              <div className='flex align-center cursor-pointer' onClick={() => {
                setIsAssigneeDropdownOpen(true);
              }}>
                <Tooltip title='Add Assignee' className='flex align-center'>
                  <button className="p-2 flex align-center justify-center shrink-0 rounded-full bg-slate-100 border-slate-100 hover:border-slate-300 text-indigo-500 transition duration-150" style={{ height: iconSize, width: iconSize }}>
                    <svg className="w-2 h-2 fill-current" viewBox="0 0 12 12">
                      <path d="M11 5H7V1a1 1 0 00-2 0v4H1a1 1 0 000 2h4v4a1 1 0 002 0V7h4a1 1 0 000-2z" />
                    </svg>
                  </button>
                </Tooltip>
                {props?.showNames && <div className='font-14 ml-2'>Unassigned</div>}
              </div>
            )}
          </Dropdown>
        </div>
        {hasAssignees && (
          <Avatar.Group
            style={{ cursor: 'pointer' }}
            maxStyle={{
              color: 'white',
              backgroundColor: '#4077EE',
              cursor: 'pointer',
              height: iconSize,
              width: iconSize,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px'
            }}>
            <Tooltip title={assigneeValue?.map(x => x.label)?.join(' ')} className='flex align-center justify-center'>
              {assigneeValue && assigneeValue?.map((assign, key) => {
                return (
                  assign?.value ? <React.Fragment key={key}>
                    {!check &&
                      options &&
                      options.map((Id, key) => {
                        if (assign?.value === Id?.value) {

                          if (props?.showNames) {
                            return (
                              <div className='flex align-center p-1 hover:bg-slate-100 hover:rounded' onClick={() => {
                                setIsAssigneeDropdownOpen(true);
                              }}>
                                <Avatar
                                  key={key}
                                  src={Id.avi}
                                  style={{
                                    height: iconSize,
                                    width: iconSize,
                                    background: 'white'
                                  }}
                                />
                                <div className='font-16 px-2'>{assign?.label || ''}</div>
                              </div>
                            );
                          }

                          return (
                            <Avatar
                              key={key}
                              src={Id.avi}
                              onClick={() => {
                                setIsAssigneeDropdownOpen(true);
                              }}
                              style={{
                                height: iconSize,
                                width: iconSize,
                                background: 'white'
                              }}
                            />
                          );
                        } else {
                          return '';
                        }
                      })}
                  </React.Fragment> : ''
                );
              })}
            </Tooltip>
          </Avatar.Group>
        )}
        {props?.showNames && hasAssignees && <div className='mx-2'>
          <Tooltip title='Add another assignee'>
            <button
              onClick={() => setIsAssigneeDropdownOpen(true)}
              className="p-2 shrink-0 rounded-full bg-slate-100 border-slate-100  hover:border-slate-300 text-indigo-500 transition duration-150">
              <svg className="w-2 h-2 fill-current" viewBox="0 0 12 12">
                <path d="M11 5H7V1a1 1 0 00-2 0v4H1a1 1 0 000 2h4v4a1 1 0 002 0V7h4a1 1 0 000-2z" />
              </svg>
            </button>
          </Tooltip>
        </div>}
      </Row>
    </>
  );
};

export default AssigneeSelect;


