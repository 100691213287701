import React from 'react';

const EmptyState = ({
  header = '',
  description = '',
  button = {
    text: '',
    color: 'bg-orange-200',
  },
  onPress,
  src,
  iconSize = 'h-20 w-20',
  isLeftNav = false
}) => {
  return (
    <div className='flex flex-col align-center justify-center h-full text-center'>
      {src && <img src={src} className={`${iconSize} mb-5`} />}
      <div className='mb-1 font-20'>{header}</div>
      <div className='font-16 font-light'>{description}</div>
      {onPress && <div className={`${button.color} px-8 py-2 mt-10 rounded-xl text-black shadow hover:opacity-80 cursor-pointer`} onClick={() => onPress()}>
        {button?.text}
      </div>}
    </div>
  );
};

export default EmptyState;
