import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Tooltip } from 'antd';

import IconAndName from '../atoms/side-navbar/IconAndName';
import NavTooltip from '../atoms/side-navbar/NavTooltip';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  collapse,
  toggleNewTaskModal,
  toggleNewProjectModal,
  toggleNewTeamChatsModal,
  openInvitePanel,
} from '../../../actions';
import NavBarContainer from '../molecules/side-navbar/NavBarContainer';
import SwitchWorkspacePanel from '../molecules/side-navbar/SwitchWorkspacePanel';
import { truncateString } from '../../../js/helper';

import NotificationTooltip from '../molecules/tooltips/NotificationTooltip';
import ChatsTooltip from '../molecules/tooltips/ChatsTooltip';
import ProjectsTooltip from '../molecules/tooltips/ProjectsTooltip';
import { logout } from '../../../actions/logoutAction';
import Icon from '../atoms/global/Icon';

const LeftNavBar = (props) => {
  const {
    defaultResize,
    workspaces,
    myProjects,
    teams,
    chats,
    isMobile,
    setSidebarOpen,
    setIsLoading
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggle = useSelector((state) => state.navReducer.toggle);
  const loggedUser = useSelector((state) => state.userReducer.user);
  const modalState = useSelector((state) => state.modalReducer);

  const [nav, setNav] = useState(false);
  const [selectedLeftNav, setSelectedLeftNav] = useState(localStorage.getItem('SelectedNav') || "Home");
  const [currentWorkspace, setCurrentWorkspace] = useState(props.currentWorkspace);
  const [isWorkspaceDowndownOpen, setIsWorkspaceDowndownOpen] = useState(false);

  const [notifications, setNotifcations] = useState(props.notifications?.notifications || props.notifications);
  const [unredNotifications, setUnredNotifications] = useState(0);
  const [unredChatNotifications, setUnredChatNotifications] = useState(0);

  const [localChats, setLocalChats] = useState(chats);
  const [localTeams, setLocalTeams] = useState(teams);
  const [localProjects, setLocalProjects] = useState(myProjects);

  const toggleWorkspacePanel = () => {
    setNav((nav) => !nav);
  };

  const navCollaps = (reset) => {
    if (defaultResize) defaultResize();
    dispatch(collapse(reset != undefined ? reset : !toggle));
  };

  useEffect(() => {
    setCurrentWorkspace(props?.currentWorkspace);
    setLocalTeams(teams);
    setLocalChats(chats);
    setLocalProjects(myProjects);
  }, [props?.currentWorkspace, workspaces, chats, teams, myProjects]);

  useEffect(() => {
    const handleAsync = async () => {
      await Promise.all([
        setNotifcations(props.notifications?.notifications || props.notifications),
        setUnredChatNotifications(props.notifications?.mentionRepliesNotifications),
        setUnredNotifications(props.notifications?.unreadNotifications)
      ]);
    };

    handleAsync();
  }, [props.notifications])

  const workspaceName = truncateString(currentWorkspace?.title, 22) || "My workspace";
  const iconLetters = workspaceName.slice(0, 2).toUpperCase();

  const me = currentWorkspace?.members?.find(m => m?._id?.toString() === loggedUser?._id?.toString());
  const isOwnerOrAdmin = me?.role == 'owner' || me?.role == 'admin';

  const workspaceDropdownItems = isOwnerOrAdmin ? [
    {
      key: '1',
      label: (
        <div className='flex flex-col align-center justify-center px-2 cursor-pointer hover:bg-slate-100 w-100' onClick={() => {
          navigate('/');
          setNav(false);
        }}>
          <div
            className={'square-letter-large my-3 mx-0 w-10 h-10 font-12'}
            style={{ background: '#F4BF00' }}>
            {iconLetters}
          </div>
          <div className="grow flex items-center border-b border-slate-200 text-sm py-2">
            <div className="grow flex justify-between">
              <div className='d-flex align-center' style={{ flexDirection: 'column' }}>
                <div className='font-20'>{workspaceName}</div>
                <div className='font-12-600'>Go to workspace dashboard</div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      key: '2',
      label: (
        <div className='flex p-2 cursor-pointer hover:bg-slate-100 w-1000' onClick={async () => {
          await dispatch(openInvitePanel(true, {
            pastInvites: currentWorkspace?.pendingInvites || [],
            existingMembers: currentWorkspace?.members || [],
            selectWorkspaceMembers: false,
            type: 'Workspace'
          }));
          setNav(false);
        }}>
          <div className='font-16'>Invite others to workspace</div>
        </div>
      )
    },
    {
      key: '3',
      label: (
        <div className='flex p-2 cursor-pointer hover:bg-slate-100 w-500' onClick={() => navigate('/create-workspace')}>
          <div className='font-16'>
            Create new workspace
          </div>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div className='flex p-2 cursor-pointer hover:bg-slate-100 w-500' onClick={() => navigate('/select-workspace')}>
          <div className='font-16'>
            Change Workspace
          </div>
        </div>
      ),
    },
    {
      key: '5',
      label: (
        <div className='flex p-2 cursor-pointer hover:bg-slate-100 w-500' onClick={() => navigate('/admin-console')}>
          <div className='font-16'>
            Workspace settings
          </div>
        </div>
      ),
    },
  ] : [
    {
      key: '1',
      label: (
        <div className='flex flex-col align-center justify-center px-2 cursor-pointer hover:bg-slate-100 w-100' onClick={() => {
          navigate('/');
          setNav(false);
        }}>
          <div
            className={'square-letter-large my-3 mx-0 w-10 h-10 font-12'}
            style={{ background: '#F4BF00' }}>
            {iconLetters}
          </div>
          <div className="grow flex items-center border-b border-slate-200 text-sm py-2">
            <div className="grow flex justify-between">
              <div className='d-flex align-center' style={{ flexDirection: 'column' }}>
                <div className='font-20'>{workspaceName}</div>
                <div className='font-12-600'>Go to workspace dashboard</div>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      key: '3',
      label: (
        <div className='flex p-2 cursor-pointer hover:bg-slate-100 w-500' onClick={() => navigate('/create-workspace')}>
          <div className='font-16'>
            Create new workspace
          </div>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <div className='flex p-2 cursor-pointer hover:bg-slate-100 w-500' onClick={() => navigate('/select-workspace')}>
          <div className='font-16'>
            Change Workspace
          </div>
        </div>
      ),
    },
    {
      key: '5',
      label: (
        <div className='flex p-2 cursor-pointer hover:bg-slate-100 w-500' onClick={() => navigate('/admin-console')}>
          <div className='font-16'>
            Workspace settings
          </div>
        </div>
      ),
    },
  ];


  const navTooltipOnClick = (name) => {
    setSelectedLeftNav(name);
  }

  const NavIcon = (name, src, css, path, height = 16) => {
    return (
      <IconAndName
        name={name}
        phase='sideNav'
        src={`${src}.svg`}
        height={height}
        classesForParentDiv={css}
        class={'font-14 leading-3 align-center'}
        path={path}
        navigate={() => {
          navigate(path);
        }}
      />
    );
  };

  const homeLinks = () => {
    return (
      <div style={{ padding: 10 }}>
        {NavIcon('Dashboard', 'home', 'nav-item dashboardNavLink', '/', 20)}
        {NavIcon('All Tasks', 'tasks-tooltip', 'nav-item homeNavLink', '/all-tasks', 20)}
        {NavIcon('Teams', 'teams', 'nav-item teamsyNavLink', '/teams', 20)}
      </div>
    );
  }

  const setShowTaskModal = (value) => {
    dispatch(toggleNewTaskModal({
      value: value
    }));
  };

  const setShowProjectModal = (value) => {
    dispatch(toggleNewProjectModal({
      value: value
    }));
  };

  const setIsTeamsChatModalOpen = (value, isTeams) => {
    dispatch(toggleNewTeamChatsModal({
      value: value,
      isTeams: isTeams
    }));
  }

  return (
    <div className='side-bar-container d-flex' style={{ height: '100%' }}>
      <div className='quick-side-menu'>
        {!toggle &&
          <Tooltip title='Show Menu'>
            <div className='hover:opacity-60 mt-2'>
              <Icon name='minimize.svg' onClick={() => navCollaps()} />
            </div>
          </Tooltip>}
        <div className='square-letter'
          onClick={toggleWorkspacePanel}
          style={{
            color: 'black',
            fontWeight: 600,
            background: 'white',
            border: '2px solid white',
            height: 40,
            width: 40,
            fontSize: '14px',
            marginBottom: '1rem',
            boxShadow: 'rgb(255 255 255 / 24%) 0px 1px 4px',
            marginTop: toggle ? '0' : '1.5rem'
          }}>
          {iconLetters}
        </div>
        {nav ?
          <SwitchWorkspacePanel toggleWorkspacePanel={toggleWorkspacePanel} workspaces={workspaces} setIsLoading={setIsLoading} />
          :
          <div className='flex flex-col h-full justify-between align-center w-full'>
            <div>
              <NavTooltip
                name={'Home'}
                src={'home'}
                classesForParentDiv={`side-nav-item ${(selectedLeftNav === 'Home' && 'selected')}`}
                onClick={() => {
                  navTooltipOnClick('Home');
                }} />
              <div className='h-0.5 bg-gray-400 mx-3 mt-3' />
              <NavTooltip
                name={'Activity'}
                src={'mail'}
                classesForParentDiv={`side-nav-item ${(selectedLeftNav === 'Activity' && 'selected')}`}
                onClick={() => {
                  navTooltipOnClick('Activity');
                }}
                notificationCount={unredNotifications}
                tooltipComponent={<NotificationTooltip notifications={notifications} />} />
              <NavTooltip
                name={'Projects'}
                src={'projects'}
                classesForParentDiv={`side-nav-item ${(selectedLeftNav === 'Projects' && 'selected')}`}
                onClick={() => navTooltipOnClick('Projects')}
                tooltipComponent={<ProjectsTooltip projects={myProjects} />} />
              <NavTooltip
                name={'Chats'}
                src={'chat-tooltip'}
                classesForParentDiv={`side-nav-item ${(selectedLeftNav === 'Chats' && 'selected')}`}
                onClick={() => navTooltipOnClick('Chats')}
                notificationCount={unredChatNotifications || 0}
                tooltipComponent={<ChatsTooltip chats={chats} />} />
            </div>
            {!toggle && <Dropdown
              trigger={['click']}
              className='mb-6 h-7'
              placement='topRight'
              menu={{
                items: [
                  {
                    key: 'projects-modal',
                    label: <div className='font-16 p-2'>New Project</div>,
                    onClick: () => setShowProjectModal(true)
                  },
                  {
                    key: 'chats-modal',
                    label: <div className='font-16 p-2'>New Chat</div>,
                    onClick: () => setIsTeamsChatModalOpen(true, false)
                  },
                  {
                    key: 'teams-modal',
                    label: <div className='font-16 p-2'>New Team</div>,
                    onClick: () => setIsTeamsChatModalOpen(true, true)
                  },
                  {
                    key: 'task-modal',
                    label: <div className='font-16 p-2'>New Task</div>,
                    onClick: () => setShowTaskModal(true)
                  }
                ],
              }}
            >
              <img
                className='nav-button nav-settings-icon'
                src={'/images/icons/add.svg'}
                alt='icon'
              />
            </Dropdown>}
          </div>}
      </div>
      {toggle && <div className={'sideNavBar sideNavBarRef'}>
        <div>
          <div className={`nav-section justify-between mt-3.5 ${selectedLeftNav !== 'Home' && 'border-none'}`}>
            <div className='flex align-center'>
              <div className='workspace-header'>{workspaceName}</div>
              <Dropdown
                style={{
                  padding: 0,
                  margin: 0,
                }}
                overlayClassName=''
                trigger={['click']}
                menu={{
                  items: workspaceDropdownItems,
                }}
                placement='bottomLeft'
                onOpenChange={() => {
                  setIsWorkspaceDowndownOpen(!isWorkspaceDowndownOpen);
                }}
              >
                <div>
                  <img
                    className='ml-18'
                    src='/images/icons/downArrowWhite.svg'
                    height={12}
                    width={12}
                  />
                </div>
              </Dropdown>
            </div>
            {toggle && <Tooltip title='Hide Menu'>
              <div className='hover:opacity-60'>
                <Icon name='minimize.svg' onClick={() => {
                  if (isMobile) {
                    setSidebarOpen(false);
                  } else {
                    navCollaps();
                  }
                }} />
              </div>
            </Tooltip>}
          </div>
          <div className='scroll-y' style={{ height: 'calc(100vh - 105px)' }}>
            {(selectedLeftNav === "Home") && homeLinks()}
            <NavBarContainer
              chats={localChats}
              teams={localTeams}
              myProjects={localProjects}
              workspaces={workspaces}
              notifications={notifications}
              currentWorkspace={currentWorkspace}
              selectedLeftNav={selectedLeftNav} />
          </div>
        </div>
        <div className='d-flex align-center justify-center mb-10' style={{ width: '98%' }}>
          <div className='nav-button new-task-nav-button h-8 font-16' onClick={() => setShowTaskModal(true)}>
            Create new task
          </div>
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  key: 'projects-modal',
                  label: <div className='font-16 p-2'>New Project</div>,
                  onClick: () => setShowProjectModal(true)
                },
                {
                  key: 'chats-modal',
                  label: <div className='font-16 p-2'>New Chat</div>,
                  onClick: () => setIsTeamsChatModalOpen(true, false)
                },
                {
                  key: 'teams-modal',
                  label: <div className='font-16 p-2'>New Team</div>,
                  onClick: () => setIsTeamsChatModalOpen(true, true)
                }
              ],
            }}
            placement='top'
            arrow
          >
            <div className='nav-button new-task-nav-button w-8 h-8'>
              <img
                className='h-3 w-3'
                src={'/images/icons/add.svg'}
                alt='icon'
              />
            </div>
          </Dropdown>
        </div>
      </div>}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentWorkspace: state.workspaceReducer.currentWorkspace,
    myProjects: state.projectReducer.myProjects,
    workspaces: state.workspaceReducer.getWorkspaces,
    projectCustomFields: state.projectReducer.projectCustomFields,
    chats: state.chatReducer.chats,
    teams: state.teamReducer.getTeams,
    notifications: state.notificationReducer.notifications,
  };
}

export default connect(mapStateToProps)(LeftNavBar);
