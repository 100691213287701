import axios from "axios";
import { UnauthorizedHandler } from "../js/helper/UnauthorizedHandler.js";
import * as aiTypes from "./types/ai.types.js";

const baseUrl = process.env.REACT_APP_API_URL;
const config = {
  headers: { Authorization: "" },
};

export const getMessageSummary = (roomId, duration) => {
  return async (dispatch) => {
    let res;
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      res = await axios.get(`${baseUrl}/chatrooms/${roomId}/summary?duration=${duration}`, config);
      console.log('summary', res.data.data);
      dispatch({
        type: aiTypes.CHAT_SUMMARY,
        payload: res.data.data
      });
    } catch (error) {
      console.log(error);
      UnauthorizedHandler(dispatch, error);
    }
  };
};

export const getCustomFieldsByAI = (data, callback) => {
  return async (dispatch) => {
    let res;
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      res = await axios.post(`${baseUrl}/customField/suggestions`, data, config);
      dispatch({
        type: aiTypes.CUSTOM_FIELD_SUGGESTIONS,
        payload: res?.data?.data?.customFields
      });
      callback && callback();
    } catch (error) {
      console.log(error);
      UnauthorizedHandler(dispatch, error);
    }
  };
}

export const clearUnreadMessageSummary = (roomId) => {
  return async (dispatch) => {
    dispatch({
      type: aiTypes.CHAT_SUMMARY,
      payload: null,
    });
  };
};
