import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Switch } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import {
  getWorkspaceNotifications,
  updateViewNotification,
  deleteNotifications
} from "../../../../actions/notificationAction";

import { isTicketOpen } from "../../../../actions";
import { isChatOpen, setTeamsState } from '../../../../actions/toggleAction';
import HtmlRenderer from '../../organisms/HtmlRenderer';
import EmptyState from '../../atoms/empty-state/EmptyState';

const NotificationTooltip = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [notifications, setNotifications] = useState(props.notifications);
  const [showUnread, setShowUnread] = useState(false);

  const user = useSelector((state) => state.userReducer.user);
  const isListView = useSelector((state) => state.toggleReducer.isListView);

  useEffect(() => {
    setShowUnread(false);
  }, [])

  useEffect(() => {
    setNotifications(props.notifications);
  }, [props.notifications])

  const toggleReadUnread = () => {
    if (!showUnread) {
      const x = notifications.filter(notification => notification.assignee?.isViewed === showUnread);
      setNotifications(x);
    } else {
      setNotifications(props.notifications);
    }
    setShowUnread(!showUnread);
  }

  const handleNotificationClick = (notification) => {
    const notificationType = notification.notificationType;
    const projectId = notification.projects && notification.projects?.[0] || '';
    const taskIdentifier = notification.task?.titleIdentifier;

    dispatch(updateViewNotification({ id: notification._id }));
    dispatch(getWorkspaceNotifications());
    dispatch(isTicketOpen(true, taskIdentifier));


    try {
      const searchParams = new URLSearchParams(window.location.search);
       if (searchParams.has('selectedTask')) {
        searchParams.delete('selectedTask');
      }

      // Remove the 'msgId' parameter if it exists
      if (searchParams.has('msgId')) {
        searchParams.delete('msgId');
      }
      if (
        notificationType === 'TaskNotification' ||
        notification.notificationType == 'ChatNotification'
      ) {
        // const url = notification?.notificationType == 'TaskNotification' ? `/${projectId}/${isListView ? 'list' : 'board'}?selectedTask=${taskIdentifier}` : `/chat/${notification?.comment?.roomId}`
        if(notification?.notificationType == 'TaskNotification') {
          searchParams.set('selectedTask', taskIdentifier);
          const newUrl = `${location.pathname}?${searchParams.toString()}`;
          navigate(newUrl, { replace: true });
        } else {
          navigate(`/chat/${notification?.comment?.roomId}`);
        }
        dispatch(setTeamsState(false))
      } else if (notification.notificationType == 'ChatReplyNotification') {
        dispatch(isChatOpen(true, notification?.repliedMessage?._id));
        searchParams.set('msgId', notification?.comment?._id);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl, { replace: true });
        // const url = `/chat/${notification?.comment?.roomId}?msgId=${notification?.comment?._id}`
        // navigate(url);
        dispatch(setTeamsState(false))
      } else if (notification.notificationType == 'InviteNotification') {
        const url = `/${notification?.contextId}/board`;
        navigate(url);
      } else {
        searchParams.set('selectedTask', taskIdentifier);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl, { replace: true });
        // navigate(`/${projectId}/${isListView ? 'list' : 'board'}?selectedTask=${taskIdentifier}`);
      }
    } catch (error) {
      navigate(`/`);
    }
  }

  const noNotifcations = notifications == undefined || notifications?.length === 0;

  return (
    // <div>
    //   <div className='d-flex align-center mr-2 mb-2' style={{ justifyContent: 'space-between' }}>
    //     <div className={`font-16 ml-5`}>
    //       Notifications
    //     </div>
    //     <div className='d-flex align-center'>
    //       <div className='mr-2'>Show Unread</div>
    //       <Switch size='small' checked={showUnread} onChange={toggleReadUnread} />
    //     </div>
    //   </div>

    //   <div className='notification-tooltip-content'>
    //     {noNotifcations ?
    //       <div style={{ margin: 10, fontSize: 16, height: '200px' }} className='d-flex align-center justify-center'>
    //         {showUnread ? 'All caught up!' : 'No notifications'}
    //       </div> :
    //       <div className='d-flex justify-space-between align-center' style={{ flexDirection: 'column' }}>
    //         <div style={{ width: '100%', height: '280px', overflowY: 'scroll' }}>
    //           {notifications?.map(notification => {

    //             const { assignee } = notification;

    //             const currentMessageDate = new Date(notification?.createdAt).toLocaleDateString('en-us',
    //               {
    //                 weekday: "short",
    //                 month: "short",
    //                 day: "numeric"
    //               });
    //             const isViewed = assignee?.isViewed;

    //             return (
    //               <div
    //                 onClick={() => handleNotificationClick(notification)}
    //                 className={isViewed ? 'nav-tooltip-row-viewd' : 'nav-tooltip-row'}>
    //                 <div>
    //                   <img
    //                     src={notification?.icon}
    //                     className='mr-2 notifcation-icon'
    //                     alt='icon'
    //                     width={30}
    //                     height={30}
    //                   />
    //                 </div>
    //                 <div className='ml-5' style={{ maxWidth: '230px' }}>
    //                   <div className='d-flex aign-center' style={{ width: '220px', justifyContent: 'space-between' }}>
    //                     <div className='font-14-600' style={{ maxWidth: '150px' }}>{notification.title}</div>
    //                     <div className="font-10">{currentMessageDate}</div>
    //                   </div>
    //                   <div className='d-flex aign-center'>
    //                     <div className='tooltip-notifications-desc'><HtmlRenderer html={notification?.description} /></div>
    //                   </div>
    //                 </div>
    //               </div>
    //             )
    //           })}
    //         </div>
    //         <div
    //           className='clear-notifications'
    //           onClick={() => {
    //             dispatch(deleteNotifications(user?._id))
    //           }}>
    //           Clear All Notifications
    //         </div>
    //       </div>}
    //   </div>
    // </div>
    <div className="col-span-full xl:col-span-6 bg-white w-[20vw]">
      <header className="flex align-center justify-between px-5 py-4 border-b border-slate-100">
        <div>
          <h2 className="mr-5 font-20 font-light text-slate-800">Recent Activity</h2>
          <div
            className='font-14 text-blue-500 underline cursor-pointer hover:text-blue-600'
            onClick={() => {
              dispatch(deleteNotifications(user?._id))
            }}>
            Clear All Notifications
          </div>
        </div>
        <div className='flex align-center font-14'>
          <div className='mr-2'>{showUnread ? 'All' : 'Unread'}</div>
          <Switch checked={showUnread} onChange={toggleReadUnread} />
        </div>
      </header>
      <div className="p-3">
        {/* Card content */}
        <ul className="my-1">

          {!noNotifcations ? notifications?.length && notifications?.map((notification, i) => {
            const { assignee } = notification;

            const currentMessageDate = new Date(notification?.createdAt).toLocaleDateString('en-us',
              {
                weekday: "short",
                month: "short",
                day: "numeric"
              });
            const isViewed = assignee?.isViewed;

            let span = document.createElement('span');
            span.innerHTML = notification?.description;
            const htmlContent = span.textContent || span.innerText;

            if (!notification?.description || notification?.description === 'undefined') {
              return
            }

            return (
              <div
                onClick={() => handleNotificationClick(notification)}
                className={`px-1 my-2 flex justify-between ${!isViewed && 'bg-indigo-50 rounded-lg hover:opacity-80'} hover:bg-indigo-50 hover:rounded-lg cursor-pointer`}>
                <div
                  className="block p-2"
                  to="#0"
                >
                  <div className='flex mb-2'>
                    <img className='h-10 w-10 mt-1' src={notification?.assignor?.avi || notification?.icon} style={{ borderRadius: 60, aspectRatio: 1, objectFit: 'cover' }} />
                    <div className='ml-3'>
                      {notification?.description && <div className='font-18 font-light text-slate-900'><HtmlRenderer html={notification?.description} /></div>}
                      <div className='font-12'>{notification?.title}</div>
                    </div>
                  </div>
                  <span className="block text-xs font-medium text-slate-400">{currentMessageDate}</span>
                </div>
              </div>
            )
          }) :
            <div className='my-4'>
              <EmptyState
                header={"You're All Caught Up!"}
                description={'Sit back and relax, nothing to see here.'}
                src='/images/icons/no-mail.svg' />
            </div>
          }
        </ul>
      </div>
    </div>
  );
};

export default NotificationTooltip;
