import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { FileDrop } from 'react-file-drop';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import moment from 'moment';

import {
  Tooltip,
  Dropdown,
  Tabs,
  Skeleton,
  DatePicker,
  Select,
  notification
} from 'antd';

import {
  editTask,
  getSingleTask,
  setTaskProperties,
  uploadTaskUrl,
  signedUploadUrl,
  deleteTaskFile,
  reorderTasks,
  addTaskToProject,
  removeTaskFromProject,
  toggleWorkspaceFieldsModal,
  getRecurringTasks
} from '../../../../actions';

import {
  isChatOpen,
  isTicketOpen,
} from '../../../../actions';

import socket from '../../../../js/socket';
import { getDateTextLongFormat } from '../../../../js/functions';
import { fieldTypes } from '../../../../js/constants';

import Loader from '../../atoms/global/Loader';
import TaskCommentSection from './TaskCommentSection';
import AssigneeSelect from '../assignee/AssigneeSelect';
import AddSubTask from '../../molecules/ticket-drawer/AddSubTask';
import SubTask from '../../molecules/ticket-drawer/SubTask';
import useComponentVisible from '../../../../js/useComponentVisible';
import TaskFileHolder from '../../organisms/TaskFileHolder';
import DrawerCommentSection from '../global/DrawerCommentSection';
import { getTaskByIdentifier, updateSingleTask, updateTaskInState } from '../../../../actions/taskAction';
import ActivityViewer from '../../atoms/ticket-drawer/ActivityViewer';
import TextEditor from '../editor/TextEditor';
import PriorityDropdown from '../task-components/PriorityDropdown';
import CalendarDropdown from '../task-components/CalendarDropdown';

import HtmlRenderer from '../../organisms/HtmlRenderer';

const TaskDetailSection = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    task,
    isModal = false,
    selectedTab,
    workspaceProjects
  } = props;

  const [editPhase, setEditPhase] = useState('');
  const [taskTitle, setTaskTitle] = useState(task?.taskTitle);
  const [recurringTasks, setRecurringTasks] = useState([]);
  const [isEditDesc, setIsEditDesc] = useState(false);

  const [activityView, setActivityView] = useState('comments');
  const [taskComments, setTaskComments] = useState(props?.task?.comments?.filter(c => c?.type !== 'activity') || []);

  const [isLoading, setIsLoading] = useState(false);

  const [isBottom, setIsBottom] = useState(false);
  const [localPriority, setLocalPriority] = useState('--');
  const [description, setDescription] = useState(task?.taskDescription);

  const [isDragNdropVisible, setIsDragNdropVisible] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState('1');
  const [customFieldsComponent, setCustomFieldsComponent] = useState('');
  const [customFields, setCustomFields] = useState({});

  const [showProjectDropdown, setShowProjectDropdown] = useState(false);
  const [projects, setProjects] = useState(task?.projects || []);

  // TODO: Osama please uncomment the line below this when you start working on workpace tags integration
  // const [workspaceTags, setWorkspaceTags] = useState([]);

  const taskScrollRef = useRef();
  const params = useParams();
  const fileInputRef = useRef(null);
  const titleRef = useRef();

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { filters } = useSelector((state) => state.taskReducer);
  const location = useLocation();

  const loggedUser = useSelector((state) => state.userReducer.user);
  const isListView = useSelector((state) => state.toggleReducer.isListView);
  const isRecurring = task?.recurringConfig?.recurring;

  useEffect(() => {
    const updatedTaskComments = activityView == 'all' ? props?.task?.comments : activityView == 'comments'
      ? props?.task?.comments?.filter(c => c?.type !== 'activity') : activityView == 'activity'
        ? props?.task?.comments?.filter(c => c?.type === 'activity') : [];
    setTaskComments(updatedTaskComments || []);
    const keyValueObject = task?.customFields?.reduce((result, item) => {
      result[item._id] = item.value;
      return result;
    }, {});

    task?.customFields?.[0]?.possibleValues && setCustomFields(keyValueObject);
    task?.projects?.length > 0 && setProjects(task?.projects);
  }, [task])

  useEffect(() => {
    socket.on('UpdateTaskEvent', function (task) {
      const selectedTaskParam = new URLSearchParams(location.search).get('selectedTask');
      const taskIdentifier = task?.titleIdentifier;
      const projectIdInUrl = window.location.pathname.split('/')[1];
      const taskId = task?._id;
      if (taskIdentifier?.toString() === selectedTaskParam?.toString()) {
        if (taskId !== 'board' || taskId !== 'chat') {
          dispatch(updateSingleTask(task))
        }
      }
      const isSelectedProjectOpen = projects?.find(p => p?.projectId?.toString() === projectIdInUrl?.toString());
      if (isSelectedProjectOpen) {
        // dispatch(getTasks(projectIdInUrl));
        dispatch(updateTaskInState(task));
      }
    });
  }, []);

  useEffect(() => {
    // if (editPhase != 'description') {
    setDescription(task?.taskDescription);
    setEditPhase('');
    // }
    setLocalPriority(task?.priority === 'nill' ? '--' : task?.priority)
    setProjects(task?.projects);

    if (isRecurring) {
      dispatch(getRecurringTasks(task?._id, (res) => {
        setRecurringTasks(res);
      }));
    }
  }, [task?._id]);

  // TO DO
  useEffect(() => {
    socket.on('AddedSubTask', (id) => {
      dispatch(getSingleTask(id, 'TD'));
    });
  }, [dispatch]);

  useEffect(() => {
    socket.on('message-received', (msg) => {
      if (msg?.taskId?.toString() === task?._id?.toString()) {
        const scrollHeight = taskScrollRef?.current?.scrollHeight;
        const height = taskScrollRef?.current?.clientHeight;
        const maxScrollTop = scrollHeight - height;
        setTimeout(() => {
          taskScrollRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop + 100 : 0;
        }, 100);
      }
    });
  }, []);

  useEffect(() => {
    setSelectedTabIndex(selectedTab);
  }, [selectedTab]);

  const updateTaskTitle = () => {
    if (taskTitle.trim().length <= 0) {
      setTaskTitle(task.taskTitle);
      setEditPhase('');
      return;
    }

    if (taskTitle === task.taskTitle) {
      setEditPhase('');
      return;
    }

    editHandler(
      {
        _id: task._id,
        taskTitle: taskTitle,
      },
      () => {
        setEditPhase('');
        // setTaskTitle('');
      }
    );
  };

  const editHandler = async (data, callback) => {
    dispatch(
      editTask(data, null, () => {
      })
    );

    if (typeof callback === 'function') callback();
  };

  const onFileInputChange = async (e) => {
    try {
      const files = fileInputRef.current.files;
      uploadFiles(files);
    } catch (error) {
      console.error(error);
    }
  };

  const onFileDrop = async (files, e) => {
    setIsLoading(true);
    setIsDragNdropVisible(false);
    document
      .querySelector('.onResize-ticket-drawer')
      ?.classList?.remove('disable-pointer-events-div');
    try {
      uploadFiles(files);
    } catch (error) {
      console.error(error);
    }
  };

  const openTicket = (newId) => {
    const params = new URLSearchParams(location.search);
    params.delete('msgId'); // Remove the specified parameter
    params.set('selectedTask', newId)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    // Use navigate to replace the current URL
    navigate(newUrl);

    dispatch(isTicketOpen(true));
  };

  const uploadFiles = async (files) => {
    const fileList = [];
    for (const file of files) {
      setIsLoading(true);
      const uploadTaskResponse = await dispatch(uploadTaskUrl({
        fileName: file.name,
        taskId: task?._id,
        type: file?.type,
        fileSize: file?.size
      }));
      const fileId = uploadTaskResponse?.fileId;
      const signedUrl = uploadTaskResponse?.signedUrl;
      if (signedUrl) {
        fileList.push(fileId);
        await dispatch(signedUploadUrl(signedUrl, file));
      }
    }

    let allFiles = fileList;

    if (task?.files) {
      allFiles = fileList.concat(task?.files || []);
    }

    editHandler(
      {
        _id: task._id,
        taskTitle: task.taskTitle,
        files: allFiles,
        attachments: []
      }, () => {
        setIsLoading(false);
      }
    );
  }

  const handleChange = (evt) => {
    setDescription(evt);
    setEditPhase('description')
  };

  const delAttachment = (fileId) => {
    dispatch(deleteTaskFile(task._id || params.taskid, fileId, () => dispatch(getSingleTask(task._id || params.taskid))));
  };

  const handleChangePriority = (priority) => {
    if (localPriority !== priority.rank) {
      setLocalPriority(priority.rank);
      editHandler({
        _id: task._id,
        taskTitle: task.taskTitle,
        priority: priority.rank,
      });
    }
  };

  const handleChangeCustomField = (customFieldId, val, type, e) => {
    if (e) {
      e.preventDefault()
    }
    const cfState = { ...customFields };
    cfState[customFieldId] = val;
    // setCustomFields(cfState);
    setEditPhase('');

    dispatch(
      editTask({
        _id: task._id,
        customFieldId,
        customFieldValue: val,
      },
        () => {
          // if (task?.titleIdentifier) {
          //   dispatch(getTaskByIdentifier(task?.titleIdentifier));
          // } else {
          //   dispatch(getSingleTask(task?._id, 'TD3'));
          // }
        })
    );
  }

  // TODO: Osama please uncomment the line(s) below this when you start working on workpace tags integration
  // const handleChangeTags = (tags, e) => {
  //   if (e) {
  //     e.preventDefault()
  //   }

  //   setEditPhase('');

  //   dispatch(
  //     editTask({
  //       _id: task._id,
  //       tags
  //     },
  //       () => {
  //         if (task?.titleIdentifier) {
  //           dispatch(getTaskByIdentifier(task?.titleIdentifier));
  //         } else {
  //           dispatch(getSingleTask(task?._id, 'TD3'));
  //         }
  //       })
  //   );
  // }

  const descriptionHandler = (e) => {
    setTimeout(() => {
      if (task.taskDescription !== description) {
        editHandler(
          {
            _id: task._id,
            taskDescription: description,
          },
          () => {
            setEditPhase('');
            setIsEditDesc(false);
            notification.success({
              placement: 'bottomRight',
              message: 'Task Description Updated!'
            })
          }
        );
      }
    }, 100);
  };

  const handleScroll = (e) => {
    const bottom = Math.ceil(e?.target?.scrollHeight - e?.target?.scrollTop) <= e?.target?.clientHeight;
    if (bottom) {
      setIsBottom(true);
    } else {
      isBottom && setIsBottom(false);
    }
  }

  const createSectionDropdown = (sectionsData, proj) => {
    const items = [];
    sectionsData && sectionsData.map((section, i) => {
      items.push({
        key: i,
        label:
          <div
            className='d-flex'
            onClick={async () => {
              const projectId = proj?.project?._id;

              const data = {
                _id: task?._id,
                taskTitle: task?.taskTitle,
                preSection: currentSection?._id,
                section: section?._id,
                projectId: projectId,
                lastIndex: 0,
                newIndex: 0,
                returnTask: true,
                filters: filters,
                userId: loggedUser?._id
              };

              dispatch(reorderTasks(currentSection?._id, section?._id, task?._id, 0, 0, projectId));
              socket.emit("reorder-section", data);
            }}>
            <div style={{ width: 3, marginRight: 10, background: section?.color || '#fff' }} />
            <div className='truncate max-w-60'>
              {section.title}
            </div>
          </div>,
      })
    });
    return items;
  }

  const onMultiChange = (e, customField) => {
    const cf = { ...customFields };
    cf[customField?._id] = e;
    setCustomFields(cf);
    handleChangeCustomField(customField?._id, e, customField?.type);
  }

  useEffect(() => {
    // TODO: Osama please uncomment the line(s) below this when you start working on workpace tags integration
    // const workspaceTagsOptions = task?.workspaceTags?.map((val) => ({
    //   value: val?._id?.toString(),
    //   label: (
    //     <div className='d-flex'>
    //       <div style={{ width: 3, marginRight: 10, background: val?.metadata?.color || '#fff' }} />
    //       <div className='font-14'>#{val?.tag?.toUpperCase()}</div>
    //     </div>
    //   )
    // }));
    // setWorkspaceTags(workspaceTagsOptions);

    const isEditting = editPhase !== '';
    const isCustomFieldEditting = !!task?.customFields?.find(customField => customField?.name === editPhase)
    if (isEditting && !isCustomFieldEditting) return;
    const csComp = task?.customFields?.map((customField) => {
      const possibleValues = customField?.possibleValues || [];
      const selectedVal = customFields && customFields[customField?._id?.toString()];
      let selected = { label: 'Select' };
      if (customField?.type === "dropdown") {
        selected = selectedVal
          ? possibleValues.find((pv) => pv?._id?.toString() === selectedVal?.toString())
          : (selectedVal || selected);
      }

      if (!customField?.possibleValues) {
        return (
          <div className='col-span-full'>
            <Skeleton.Button
              style={{
                width: 230,
                height: 30,
                borderRadius: 6,
                marginTop: 2,
                marginLeft: 14
              }}
              active={true} />
          </div>
        )
      }

      const icon = fieldTypes?.find(f => f.key == customField?.type)?.icon || 'custom-fields.svg';
      const leftSide = (
        <div className={colm1}>
          <img
            src={`/images/icons/${icon}`}
            alt=''
            style={{
              height: '16px',
              width: '16px',
              marginRight: 10
            }}
          />
          <div className='font-14'>{customField?.name + ':'}</div>
        </div>
      );

      switch (customField?.type) {
        case 'dropdown':
        case 'status':
          if (customField?.multi) {
            const options = customField?.possibleValues.map((val) => ({
              value: val?._id?.toString(),
              label: (
                <div className='d-flex'>
                  <div style={{ width: 3, marginRight: 10, background: val?.metadata?.color || '#fff' }} />
                  <div className='font-14'>{val?.label?.toUpperCase()}</div>
                </div>
              )
            }));
            return (
              <>
                {leftSide}
                <div className={colm1}>
                  <Select
                    mode="multiple"
                    placeholder="Select options"
                    onChange={(e) => onMultiChange(e, customField)}
                    value={customFields[customField?._id] || []}
                  >
                    {options.map(option => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className={isModal ? 'col-span-2' : 'col-span-1'}>
                </div>
              </>
            )
          }
          return (
            <>
              {leftSide}
              <div className={colm1}>
                <Dropdown
                  menu={{
                    items: customField?.possibleValues.map((val) => ({
                      value: val?._id?.toString(),
                      label: (
                        <div className='d-flex'>
                          <div style={{ width: 3, marginRight: 10, background: val?.metadata?.color || '#fff' }} />
                          <div className='font-14'>{val?.label?.toUpperCase()}</div>
                        </div>
                      ),
                      onClick: () => {
                        const cf = { ...customFields };
                        cf[customField?._id] = val?._id?.toString();
                        setCustomFields(cf);
                        handleChangeCustomField(customField?._id, val?._id?.toString(), customField?.type);
                      }
                    })),
                  }}
                  trigger={['click']}
                >
                  <div className='btn-2 flex align-center justify-between'>
                    <div className='font-14 truncate mr-3'>{selected?.label}</div>
                    <img
                      src={'/images/icons/black-arrow-down.svg'}
                      style={{
                        cursor: 'pointer',
                        height: '10px',
                        width: '10px',
                      }}
                      alt='icon'
                    />
                  </div>
                </Dropdown>
              </div>
              <div className={isModal ? 'col-span-2' : 'col-span-1'}>
              </div>
            </>
          );
        case 'text':
        case 'number':
        case 'cost':
          return (
            <>
              {leftSide}
              <div className={colm1}>
                {customFields[customField?._id] && editPhase !== customField?.name ? (
                  <div className='font-14'>
                    {
                      <div
                        className='font-14'
                        onClick={() => {
                          setEditPhase(customField?.name);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {customField?.type === 'cost' ? '$' : ''}{customFields[customField?._id]}
                      </div>
                    }
                  </div>
                ) : editPhase === customField?.name ? (
                  <form onSubmit={(e) => handleChangeCustomField(customField?._id, customFields[customField?._id], customField?.type, e)} onBlur={(e) => handleChangeCustomField(customField?._id, customFields[customField?._id], customField?.type, e)}>
                    {/* <form>  */}
                    <input
                      autoFocus
                      value={customFields[customField?._id] || ''}
                      onChange={(e) => {
                        const cf = { ...customFields };
                        cf[customField?._id] = e.target.value;
                        setCustomFields(cf);
                      }}
                      className='font-14'
                      style={{
                        width: '100%',
                        border: 'none',
                        outline: 'none',
                      }}
                      placeholder={`Input ${customField?.name}`}
                      type={customField?.type == 'text' ? 'text' : 'float'}
                    />
                  </form>
                ) : (
                  <div
                    className='font-14 review-property'
                    onClick={() => {
                      if (editPhase !== customField?.name) {
                        setEditPhase(customField?.name);
                      }
                    }}
                  >
                    {`Add ${customField?.name}`}
                  </div>
                )}
              </div>
              <div className={isModal ? 'col-span-2' : 'col-span-1'}>
              </div>
            </>
          );
        case 'date':
          return (
            <>
              {leftSide}
              <div className={colm1}>
                <DatePicker
                  style={{ borderRadius: 5 }}
                  onChange={(e) => onMultiChange(e, customField)}
                  defaultValue={moment(customField?.value || new Date())}
                  picker="day"
                  placeholder='Select Date' />
              </div>
              <div className={isModal ? 'col-span-2' : 'col-span-1'}>
              </div>
            </>
          );
        case 'priority':
          return (
            <>
              {leftSide}
              <div className={colm1}>
                <PriorityDropdown
                  onClick={e => {
                    onMultiChange(e?.rank, customField);
                  }}
                  task={{ priority: customField?.value || 'nill' }} />
              </div>
              <div className={isModal ? 'col-span-2' : 'col-span-1'}>
              </div>
            </>
          );
        case 'assignee':
          if (customField?.multi) {
            const options = customField?.possibleValues.map((val) => ({
              value: val?._id?.toString(),
              label: (
                <div className='d-flex'>
                  <div style={{ width: 3, marginRight: 10, background: val?.metadata?.color || '#fff' }} />
                  <div className='font-14'>{val?.label?.toUpperCase()}</div>
                </div>
              )
            }));
            return (
              <>
                {leftSide}
                <div className={colm1}>
                  <Select
                    mode="multiple"
                    placeholder="Select options"
                    onChange={(e) => onMultiChange(e, customField)}
                    value={customFields[customField?._id] || []}
                  >
                    {options.map(option => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className={isModal ? 'col-span-2' : 'col-span-1'}>
                </div>
              </>
            )
          }
          return (
            <>
              {leftSide}
              <div className={colm1}>
                <Dropdown
                  menu={{
                    items: customField?.possibleValues.map((val) => ({
                      value: val?._id?.toString(),
                      label: (
                        <div className='d-flex'>
                          <div style={{ width: 3, marginRight: 10, background: val?.metadata?.color || '#fff' }} />
                          <div className='font-14'>{val?.label?.toUpperCase()}</div>
                        </div>
                      ),
                      onClick: () => {
                        const cf = { ...customFields };
                        cf[customField?._id] = val?._id?.toString();
                        setCustomFields(cf);
                        handleChangeCustomField(customField?._id, val?._id?.toString(), customField?.type);
                      }
                    })),
                  }}
                  trigger={['click']}
                >
                  <div className='btn-2 flex align-center justify-between'>
                    <div className='font-14 truncate mr-3'>{selected?.label}</div>
                    <img
                      src={'/images/icons/black-arrow-down.svg'}
                      style={{
                        cursor: 'pointer',
                        height: '10px',
                        width: '10px',
                      }}
                      alt='icon'
                    />
                  </div>
                </Dropdown>
              </div>
              <div className={isModal ? 'col-span-2' : 'col-span-1'}>
              </div>
            </>
          );
        default:
          return (
            <>
              {leftSide}
              <div className={colm1}>
                NOT IMPLEMENTED
              </div>
              <div className={isModal ? 'col-span-2' : 'col-span-1'}>
              </div>
            </>
          );
      }
    });

    setCustomFieldsComponent(csComp);

  }, [task, customFields, editPhase]);

  var date = new Date(task.createdAt);
  TimeAgo.addLocale(en);
  const timeAgo = new TimeAgo('en-US');
  const taskCreationTime = timeAgo.format(date);

  const currentProject = props?.task?.projects?.find(prj => prj?.project?._id?.toString() === props?.projectId?.toString()) || props?.task?.projects?.length > 0 ? props?.task?.projects[0] : null;
  const currentSection = currentProject?.section || props?.task?.projects?.sections?.find(section => section._id?.toString() === currentProject?.section?._id?.toString());

  let messageDate = '';
  const commentCount = props?.task?.comments?.filter(c => c?.type !== 'activity')?.length;
  const tabsContent = [
    {
      label: `Comments ${commentCount > 0 ? `(${commentCount})` : ''}`,
      key: '1',
      children: (
        <>
          <DrawerCommentSection
            height='26px'
            width='26px'
            containerWidth='auto'
            name={`${task.createdBy.fullName} created this task.`}
            nameClass='font-16 text-gray-600'
            commentClass='font-12'
            src={task.createdBy.avi}
            comment=''
            showName={true}
            parentClass="align-center"
            hours={taskCreationTime}
            context='task'
            roomId={task?.room}
            taskId={task?._id}
          />
          {taskComments?.map((chat, key) => {
            var date = new Date(chat?.createdAt);
            TimeAgo.addLocale(en);
            const timeAgo = new TimeAgo('en-US');
            const time = timeAgo.format(date);

            const sender = chat?.sender;

            let showDateRow = false;
            const currentMessageDate = new Date(chat?.createdAt).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });

            if (currentMessageDate !== messageDate) {
              showDateRow = true;
              messageDate = currentMessageDate;
            }


            return (
              <div key={key}>
                {showDateRow && (
                  <div className='date-row mx-10 mt-2'>
                    <div className='date-row-pill px-4'>
                      {getDateTextLongFormat(messageDate)}
                    </div>
                    <div className='date-row-line border-slate-200' />
                  </div>)}
                <DrawerCommentSection
                  height='26px'
                  width='26px'
                  containerWidth='auto'
                  name={sender?.fullName}
                  nameClass='font-18 font-bold hover:underline cursor-pointer'
                  commentClass='font-16'
                  src={sender?.avi}
                  hours={time}
                  comment={chat?.messageDescription}
                  attachments={chat?.attachments}
                  showName={true}
                  msgId={chat?._id}
                  senderId={chat?.sender?._id}
                  files={chat?.files}
                  context='task'
                  roomId={task?.room}
                  taskId={task?._id}
                />
              </div>
            );
          })}
        </>
      ),
    },
    {
      label: 'Activity',
      key: '2',
      children: (
        <div className="space-y-4 w-full">
          {/* Block 1 */}
          <div className="p-4">
            <ul>
              {
                [{
                  type: 'activity',
                  action: ' creatd this task',
                  createdAt: task?.createdAt,
                  actor: task?.createdBy,
                  field: 'createdBy'
                }].map((chat, key) => {
                  var date = new Date(chat?.createdAt);
                  TimeAgo.addLocale(en);
                  const timeAgo = new TimeAgo('en-US');
                  const time = timeAgo.format(date);
                  return (
                    <ActivityViewer chat={chat} time={time} />
                  )
                })
              }
              {taskComments?.map((chat, key) => {
                var date = new Date(chat?.createdAt);
                TimeAgo.addLocale(en);
                const timeAgo = new TimeAgo('en-US');
                const time = timeAgo.format(date);
                return (
                  <ActivityViewer chat={chat} time={time} />
                )
              })}
            </ul>
          </div>
        </div>
      ),
    },
    {
      label: `Subtasks ${task?.subtasks?.length > 0 ? `(${task?.subtasks?.length})` : ''}`,
      key: '3',
      children: (
        <div id='addSubtaskBtn' className='flex flex-col p-2'>
          <div className='font-14 font-bold mb-2'>{task.subtasks?.length === 0 && !isComponentVisible && 'No'} Subtasks</div>
          {task?.subtasks?.map((subTask, key) => {
            return (
              <div key={key}>
                <SubTask subTask={subTask} />
              </div>
            );
          })}
          <div ref={ref}>
            {isComponentVisible && (
              <AddSubTask
                id={task._id}
                setIsComponentVisible={setIsComponentVisible}
              />
            )}
          </div>
        </div>
      ),
    },
    {
      label: `Files ${task?.files?.length > 0 ? `(${task?.files?.length})` : ''}`,
      key: '4',
      children: (
        <div className='flex flex-wrap'>
          {task?.files?.length > 0 ?
            <div className='flex flex-col'>
              <TaskFileHolder fileImages={task?.files || []} taskId={task._id} delAttachment={delAttachment} />
            </div> : <div className='font-14 font-bold p-2'>No Files</div>}
        </div>
      ),
    },
  ];

  const generateProjects = () => {
    return projects?.map((prj, i) => {

      if (!prj?.task) {
        return (
          <div className='col-span-full'>
            <Skeleton.Button
              style={{
                width: 230,
                height: 30,
                borderRadius: 6,
                marginTop: 2
              }}
              active={true} />
          </div>
        )
      }

      return (
        <div
          className={`cursor-pointer rounded-sm border bg-white border-slate-200`}
        >
          <div className="flex justify-between items-center">
            {/* Left side */}
            <div
              onClick={() => {
                const selectedTask = new URLSearchParams(location.search).get('selectedTask');

                const id = prj.project?._id
                let path = `/${id}/${isListView ? 'list' : 'board'}`;

                if (selectedTask) {
                  path = `/${id}/${isListView ? 'list' : 'board'}?selectedTask=${selectedTask}`;
                }
                navigate(path);
              }}
              className={`flex items-start space-x-3 hover:bg-slate-50 hover:underline px-5 py-2 border-t-indigo-${i + 3}00 border-t-2 w-full`}>
              <div className="font-14 truncate max-w-40 whitespace-nowrap">{prj?.project?.title}</div>
            </div>
            {/* Right side */}
            <div
              className='w-full flex align-center'
              style={{
                borderTop: `2px solid ${prj?.section?.color}`
              }}>
              <Dropdown
                className={`flex align-center justify-between space-x-4 hover:bg-slate-100 px-5 py-2 w-full`}
                trigger={['click']}
                menu={{
                  items: createSectionDropdown(prj?.project?.sections, prj),
                }}
                placement='bottom'
              >
                <div className='flex align-center'>
                  <div className="font-14 text-slate-500 italic whitespace-nowrap">{prj?.section?.title?.substring(0, 20)}</div>
                  <img
                    src={'/images/icons/black-arrow-down.svg'}
                    style={{
                      cursor: 'pointer',
                      height: '10px',
                      width: '10px',
                    }}
                    alt='icon'
                  />
                </div>
              </Dropdown>
              {projects?.length > 1 &&
                <div className='py-3 hover:hover:bg-slate-100 w-10 align-center flex justify-center' onClick={async () => {
                  const x = projects.splice(i + 1, 1);
                  setProjects(x);
                  await dispatch(removeTaskFromProject(task?._id, prj?.project?._id));
                }}>
                  <img
                    className='text-slate-700'
                    src={'/images/icons/close.svg'}
                    style={{
                      cursor: 'pointer',
                      height: '10px',
                      width: '10px',
                    }}
                    alt='icon'
                  />
                </div>}
            </div>
          </div>
        </div>
      );
    })
  }

  const commentSection = () => {
    return (
      <div className={isModal ? 'task-modal-reply-section' : 'task-reply-section'}>
        <Tabs
          activeKey={selectedTabIndex}
          items={tabsContent}
          onChange={index => {
            if (index == 3) {
              setActivityView('subtasks');
            } else if (index == 2) {
              setActivityView('activity');
              setTaskComments(props?.task?.comments?.filter(c => c?.type === 'activity') || []);
            } else if (index == 1) {
              setActivityView('comments');
              setTaskComments(props?.task?.comments?.filter(c => c?.type !== 'activity') || []);
            } else if (index == 4) {
              setActivityView('files');
            }
            props?.setSelectedTab(index);
            setSelectedTabIndex(index);
          }}
          tabBarStyle={{ padding: '0 15px' }}
          className='m-0 h-full' />
      </div>
    )
  }

  const colm1 = isModal ? 'col-span-2 flex align-center' : 'col-span-3 flex align-center';
  const colm2 = isModal ? 'col-span-5' : 'col-span-4';

  return (
    <div className={isModal ? 'task-detail-modal' : 'task-detail-section'}>
      <div
        ref={taskScrollRef}
        style={{
          overflowY: 'scroll',
          height: '100%',
          background: isModal ? 'white' : '#f6f6f6',
          scrollBehavior: 'smooth',
          paddingBottom: 40,
          width: isModal && '100%',
          scrollBehavior: 'smooth'
        }}
        onScroll={handleScroll} >
        {/* ------------------Drag and drop file --------------------- */}
        <div
          style={{
            width: '100%',
            color: 'black',
            borderRadius: '5px',
            padding: '5px 20px',
            height: isModal ? '100%' : '45vh'
          }}
          className={`fileDropDiv ${isDragNdropVisible ? '' : 'd-none'}`}
        >
          <FileDrop
            onDragLeave={() => {
              setIsDragNdropVisible(false);
            }}
            frame={document}
            onDrop={onFileDrop}
            onFrameDragEnter={() => {
              setIsDragNdropVisible(true);
              document
                .querySelector('.onResize-ticket-drawer')
                ?.classList?.add('disable-pointer-events-div');
            }}
            onFrameDragLeave={() => {
              setIsDragNdropVisible(false);
              document
                .querySelector('.onResize-ticket-drawer')
                ?.classList?.remove('disable-pointer-events-div');
            }}
            onFrameDrop={() => {
              setIsDragNdropVisible(false);
              document
                .querySelector('.onResize-ticket-drawer')
                ?.classList?.remove('disable-pointer-events-div');
            }}
          >
            Add to task
          </FileDrop>
        </div>
        <div className='p-4 bg-white'>
          {editPhase === 'title' ? (
            <textarea
              autoFocus
              value={taskTitle}
              onChange={(e) => setTaskTitle(e.target.value)}
              onBlur={() => updateTaskTitle()}
              className='font-16 task-detail-title h-8'
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  updateTaskTitle();
                }
              }}
              style={{
                border: 'none',
                outline: 'none',
                fontWeight: '500',
                width: '100%',
                resize: 'none',
                minHeight: titleRef.current?.clientHeight + 5
              }}
            />
          ) : (
            <div
              className='task-detail-title'
              onClick={() => {
                setTaskTitle(task.taskTitle);
                setEditPhase('title');
              }}
              ref={titleRef}
            >
              <Tooltip title='Click to edit'>
                {taskTitle}
              </Tooltip>
            </div>
          )}
          <div className='ml-3 font-14 mt-2 mb-8'>Added by <span className='text-indigo-600 mx-1'>{task.createdBy.fullName}</span> {taskCreationTime}</div>

          <div className="grid grid-cols-7 gap-4 ml-4">
            <div className={task?.assignee?.length > 2 ? 'col-span-full flex align-center' : colm1}>
              <img
                src='/images/icons/User.svg'
                alt=''
                style={{
                  height: '16px',
                  width: '16px',
                  marginRight: 10
                }}
              />
              <div className='font-14'>Assigned to</div>
            </div>
            <div className={task?.assignee?.length > 2 ? 'col-span-full' : colm2}>
              <AssigneeSelect
                projectUsers={task?.projectUsers || []}
                task={props.task}
                iconSize={28}
                showNames
                editHandler={editHandler} />
            </div>
            <div className={colm1}>
              <img
                src='/images/icons/date.svg'
                alt=''
                style={{
                  height: '16px',
                  width: '16px',
                  marginRight: 10
                }}
              />
              <div className='font-14'>Due Date</div>
            </div>
            <div className='col-span-4 ml-2'>
              <CalendarDropdown task={task} y={isModal ? -10 : -60} x={isModal && 800} isLarge />
            </div>

            {isRecurring && <div className={`${colm1} ml-3`}>
              <img
                src='/images/icons/down-right.svg'
                alt=''
                style={{
                  height: '16px',
                  width: '16px',
                  marginRight: 10
                }}
              />
              <div className='font-14'>Recurring Tasks</div>
            </div>}
            {isRecurring && <div className={isModal ? 'col-span-2' : 'col-span-3'}>
              <Dropdown
                menu={{
                  items: recurringTasks.map((val) => {
                    if (val?.titleIdentifier === task?.titleIdentifier) {
                      return;
                    }

                    return ({
                      value: val?._id,
                      label: (
                        <div className={`d-flex p-1`}>
                          <div className='font-14 truncate w-full'><span className='font-12'>{val?.titleIdentifier}</span> | {val?.taskTitle}</div>
                        </div>
                      ),
                      onClick: () => {
                        openTicket(val?.titleIdentifier);
                      }
                    })
                  }),
                }}
                trigger={['click']}
              >
                <div className='btn-2 flex align-center justify-between'>
                  <div className='font-14 truncate mr-3'>{'Select Task'}</div>
                  <img
                    src={'/images/icons/black-arrow-down.svg'}
                    style={{
                      cursor: 'pointer',
                      height: '10px',
                      width: '10px',
                    }}
                    alt='icon'
                  />
                </div>
              </Dropdown>
            </div>}
            {isRecurring && isModal && <div className='col-span-2' />}

            <div className={colm1}>
              <img
                src='/images/icons/Flag.svg'
                alt=''
                style={{
                  height: '16px',
                  width: '16px',
                  marginRight: 10
                }}
              />
              <div className='font-14'>Priority</div>
            </div>
            <div className={colm2}>
              <div className='flex align-start task-priority-pill'>
                <PriorityDropdown onClick={handleChangePriority} task={task} />
              </div>
            </div>

            {// TODO: Osama please uncomment the line(s) below this when you start working on workpace tags integration
            }
            {/* <div className={colm1}>
              <img
                src='/images/icons/Chart.svg'
                alt=''
                style={{
                  height: '16px',
                  width: '16px',
                  marginRight: 10
                }}
              />
              <div className='font-14'>Tags</div>
            </div>
            <div className={colm2}>
              <div className='flex align-start task-priority-pill'>
              <Select
                mode="multiple"
                placeholder="Select options"
                onChange={(e) => handleChangeTags(e)}
                value={task?.tags?.length > 0 ? task?.tags?.map(t => t?._id) : []}
              >
                {workspaceTags.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
              </div>
            </div> */}

            <div className='col-span-2 flex align-center mt-2'>
              <div className='font-18 font-bold'>Projects</div>
            </div>
            <div className='col-span-5'>
            </div>

            <div className={isModal ? 'col-span-6 mb-2 mr-20' : 'col-span-6 mb-2'}>
              {generateProjects()}

              {showProjectDropdown ?
                <div className={`cursor-pointer rounded-sm border bg-white border-slate-200 mt-2`}>
                  <Dropdown
                    onBlur={() => setShowProjectDropdown(false)}
                    className={`flex items-center space-x-4 hover:bg-slate-100 px-5 py-2`}
                    trigger={['click']}
                    menu={{
                      items: workspaceProjects?.map(project => ({
                        key: project?._id,
                        label: project?.title,
                        onClick: async () => {
                          const x = projects;
                          x.push({
                            project: project,
                            section: project?.sections?.[0] || {}
                          });
                          await dispatch(addTaskToProject(task?._id, project?._id))
                          setProjects(x);
                        }
                      })),
                    }}
                    placement='bottom'
                  >
                    <div className="font-14 text-slate-500 italic whitespace-nowrap">Select Project</div>
                  </Dropdown>
                </div> : <div
                  onClick={() => setShowProjectDropdown(true)}
                  className='mt-4 font-14 text-indigo-600 underline cursor-pointer hover:text-indigo-400'>
                  Add to another project
                </div>}
            </div>

            <div className={`${colm1} mb-2`}>
              <div className='font-18 font-bold'>Fields</div>
            </div>
            <div className={colm2}>
              <div
                onClick={() => {
                  dispatch(toggleWorkspaceFieldsModal(true, props?.projectId));
                }}
                className='font-14 mt-1 text-indigo-600 underline cursor-pointer hover:text-indigo-400'>
                Add new field
              </div>
            </div>

            {customFieldsComponent}

            <div className='col-span-2 flex align-center mt-3'>
              <div className='font-18 font-bold'>Description</div>
            </div>
            <div className='col-span-7'>
              <div>
                {isEditDesc ? <div
                  className='font-12'
                  style={{
                    border: '1px solid #D6D6D6',
                    padding: '10px 10px',
                    textAlign: 'justify',
                    borderRadius: '10px',
                  }}
                >
                  <div>
                    <form
                      className='h-full flex flex-col'
                    >
                      <TextEditor
                        large
                        isTask
                        placeholder='Add a description...'
                        onChange={handleChange}
                        onKeyUp={(e) => { }}
                        comment={description}
                        mentionOptions={[]}
                        handleBlur={() => descriptionHandler()}
                      />
                      {/* <div className='self-end flex'>
                        <div className='font-14 py-1.5 cursor-pointer px-3 hover:opacity-60' onClick={() => setIsEditDesc(false)}>Cancel</div>
                        <div
                          onClick={() => {
                            descriptionHandler();
                          }}
                          className='font-14 py-1.5 cursor-pointer px-3 ml-2 bg-indigo-400 hover:bg-indigo-300 rounded text-white font-bold'>Save</div>
                      </div> */}
                    </form>
                  </div>
                </div> :
                  <div
                    onClick={() => setIsEditDesc(true)}
                    className={`w-full font-16 p-3 px-4 bg-gray-50 border-radius10 text-gray-600 hover:border-gray-300 border border-white ${isModal && 'min-h-40'}`}>
                    {description?.length > 0 ? <HtmlRenderer html={description} /> : 'Add description'}
                  </div>}
              </div>
            </div>

            {isModal && task?.files?.length > 0 && (
              <div className='flex align-center col-span-7'>
                <TaskFileHolder fileImages={task?.files || []} taskId={task._id} delAttachment={delAttachment} />
              </div>
            )}
          </div>


          <input
            style={{ display: 'none' }}
            id="file-input"
            ref={fileInputRef}
            type='file'
            multiple
            onChange={onFileInputChange}
          />
        </div>
        {!isModal && commentSection()}
      </div>
      <div className={isModal ? 'modal-comment-section' : 'p-3 sticky bottom-0'} style={{ background: '#F6F6F6', zIndex: 10 }}>
        {isModal && commentSection()}
        {selectedTabIndex === '1' && <TaskCommentSection task={props.task} isBottom={isModal ? true : isBottom} />}
        {selectedTabIndex === '3' && <div
          className='d-flex align-center mx-2 w-full mt-1'
          style={{
            height: '50px',
            borderRadius: 10,
            border: '1px solid #D6D6D6',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
          onClick={() => {
            dispatch(
              setTaskProperties({
                ...props.taskProperties,
                section: task.section,
                workspace: task.workspace,
                parentTaskId: task._id,
                projectId: task.projectId,
              })
            );
            setIsComponentVisible(true);
          }}
        >
          <div className='flex align-center'>
            <img
              src='/images/icons/addblack.svg'
              alt=''
              style={{
                height: '15px',
                width: '15px',
                marginRight: 10
              }}
            />
            <div className='font-14'>
              Add Subtask
            </div>
          </div>
        </div>}
        {selectedTabIndex === '4' && <div
          className='d-flex align-center mx-2 w-full mt-1'
          style={{
            height: '50px',
            borderRadius: 10,
            border: '1px solid #D6D6D6',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
          onClick={() => {
            fileInputRef.current.click();
          }}
        >
          <div className='d-flex'>
            {isLoading ?
              <Loader size={15} isLoading={isLoading} type='clip' /> :
              <>
                <img
                  src='/images/icons/Upload.svg'
                  alt=''
                  style={{
                    height: '20px',
                    width: '20px',
                    marginRight: 10
                  }}
                />
                <div className='font-14'>
                  Drop files to attach, or browse
                </div>
              </>
            }
          </div>
          <input
            style={{ display: 'none' }}
            id="file-input"
            ref={fileInputRef}
            type='file'
            multiple
            onChange={onFileInputChange}
          />
        </div>}
      </div>
    </div>
  );
};

export default TaskDetailSection;
