import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Resizable } from 're-resizable';
import { useNavigate, useParams } from 'react-router-dom';

import Select from 'react-select';
import { notification, Tooltip } from 'antd';

import {
  isTicketOpen,
  setDrawerWidth,
  isChatSettingsOpen,
  addChatRoom,
  updateChatRoomPreferences,
  addUserToChat,
  removeUserFromChat,
  getChatRoomFiles
} from '../../../actions';

import { setRoomId } from '../../../actions/roomAction';

import {
  RESET_COUNTER,
  UPDATE_CURRENT_RECEIVER,
} from '../../../actions/types/chat.types';

import {
  updateChatRoomMessagesReadBy,
} from '../../../actions/chatRoomAction';

import {
  clearMessagesState,
  updateQueryState,
} from '../../../actions/messageAction';

import Icon from '../atoms/global/Icon';
import ConfirmationModal from '../molecules/modals/ConfirmationModal';
import RoomPicture from '../molecules/global/RoomPicture';
import TaskFileHolder from '../organisms/TaskFileHolder';

import AssigneeOptionLabel from '../atoms/ticket-drawer/AssigneeOptionLabel';

const UserChatPanel = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();

  const {
    customClass,
    drawerWidth,
    allUsers,
    senderId,
    chats,
    me,
    currentWorkspace
  } = props;

  const resizableRef = useRef(null);
  const ticketDrawerRef = useRef(null);

  const chat = chats && chats.find((chat) => senderId ? senderId == chat?._id : chat._id === param.chatId);
  const user = allUsers?.find(user => user?._id == senderId)

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [showAddMember, setShowAddMember] = useState(false);
  const [assigneeValue, setAssigneeValue] = useState([]);
  const [chatMembers, setChatMembers] = useState([]);

  useEffect(() => {
    if (!user && !chat) {
      dispatch(isChatSettingsOpen(false));
    }

    if (chat?._id) {
      dispatch(getChatRoomFiles(chat?._id)).then(files => {
        setFiles(files)
      });

      setChatMembers(chat?.members);
    }
  }, [user, chat]);

  const updateChatPreferences = () => {
    dispatch(updateChatRoomPreferences({ notifications: !chat?.notifications, chatId: chat?._id }));
  }

  const openChat = async (userId) => {
    const existingChat = chats?.find(chat => chat?.roomType === 'direct' && chat?.members?.find(mem => mem?._id === userId));

    if (existingChat) {
      // navigate to chat
      dispatch(updateQueryState(1));
      dispatch(clearMessagesState());
      dispatch(setRoomId(existingChat?._id));
      dispatch({ type: RESET_COUNTER, payload: existingChat?._id });
      navigate(`/chat/${existingChat?._id}`, { replace: true });
      dispatch({
        type: UPDATE_CURRENT_RECEIVER,
        payload: existingChat?.roomName,
      });

      dispatch(updateChatRoomMessagesReadBy(existingChat?._id, 'ChatsNavBar1'));
    } else {
      // create Chat
      const participants = [userId];
      dispatch(addChatRoom({ members: participants, roomName: null, workspaceId: currentWorkspace?._id }));
    }

    dispatch(isChatSettingsOpen(false));
  }

  const isDirectChat = chat?.roomType == 'direct';
  const directMessageRecipient = isDirectChat && chat?.members?.find(member => member._id !== user?._id);


  const isMe = me?._id == user?._id;
  const header = user ? `User Info ${isMe ? '( Me )' : ''}` : `${chat?.roomType} Chat - Settings`;
  const cardImage = user?.avi || chat?.roomAvi || directMessageRecipient?.avi;

  return (
    <>
      <ConfirmationModal
        title='Delete Task?'
        description='Are you sure you want to delete this task?'
        isModalOpen={isDeleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onYesClick={() => { }}
      />

      <Resizable
        className={customClass}
        enable={{
          top: false,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        ref={resizableRef}
        defaultSize={{
          width: drawerWidth,
          height: '100%',
        }}
        maxWidth='750px'
        minWidth='510px'
        onResizeStart={(e, direction, ref, delta) => {
          ref.classList.add('border-left-blue');
        }}
        onResizeStop={(e, direction, ref, delta) => {
          ref.classList.remove('border-left-blue');
          dispatch(setDrawerWidth(ref.offsetWidth));
        }}
      >
        <div className='open-ticket' ref={ticketDrawerRef}>
          <div className='task-nav-header'>
            <div className='flex align-center justify-between p-3 mt-1'>
              <div className='flex align-center'>
                <Icon
                  name='arrow-left.svg'
                  height='30px'
                  className='mx-2 mr-4 hover:opacity-60'
                  onClick={() => {
                    dispatch(isChatSettingsOpen(false));
                    dispatch(isTicketOpen(true));
                  }}
                />
                <div className='font-18 font-bold capitalize'>{header}</div>
              </div>
              <div className='flex align-center'>
                {/* <Icon
                  name='ticket-pin.svg'
                  height='22px'
                  className='mx-2 mr-4 hover:opacity-60'
                  onClick={() => {
                    dispatch(isChatSettingsOpen(false));
                    dispatch(isTicketOpen(true));
                  }}
                /> */}
                {chat &&
                  <Tooltip title={chat?.notifications ? 'Mute' : 'Unmute'}>
                    <>
                      <Icon
                        name='noti-black.svg'
                        height='22px'
                        className={`mx-2 mr-4 hover:opacity-60 ${!chat?.notifications && 'opacity-60'}`}
                        onClick={() => {
                          updateChatPreferences();
                        }}
                      />
                    </>
                  </Tooltip>
                }
                {/* <Icon
                  name='threeDots.svg'
                  height='18px'
                  className='hover:opacity-60 rotate-90'
                  onClick={() => {
                    dispatch(isChatSettingsOpen(false));
                    dispatch(isTicketOpen(true));
                  }}
                /> */}
              </div>
            </div>
            <div className='flex flex-col max-h-[85vh] overflow-y-scroll'>
              <div className='my-5 self-center'>
                {chat && chat?.roomAvi ?
                  <RoomPicture room={chat} /> :
                  cardImage ? <img src={cardImage} className='rounded-full h-[300px] w-[300px] object-cover' /> :
                    <div className='rounded-full h-[200px] w-[200px] flex align-center justify-center font-40 capitalize text-white' style={{ background: 'red' }}>
                      {chat?.roomName?.substring(0, 2)}
                    </div>}
              </div>
              {user && (
                <div>
                  <div className='font-24 text-center'>
                    {user?.fullName}
                  </div>
                  <div className='d-flex align-center pb-10 justify-center'>
                    <div className='font-16 text-center mr-3'>
                      {user?.email}
                    </div>
                    <img
                      onClick={() => {
                        navigator.clipboard.writeText(user?.email || '');
                        notification.success({
                          message: 'Email copied to clipboard',
                          placement: 'bottomRight',
                        });
                      }}
                      src={'/images/icons/copy.svg'}
                      height={18}
                      width={18}
                      style={{ cursor: 'pointer' }}
                      alt='icon'
                    />
                  </div>
                  {!isMe && <div className='flex justify-center'>
                    <div className='btn-2 p-5 w-1/3 mr-4 shadow-md font-14' onClick={() => openChat(user?._id)} >Send Message</div>
                    <div className='btn-2 p-5 w-1/3 shadow-md font-14'>View Files</div>
                  </div>}
                </div>
              )}
              {chat && (
                <div>
                  <div className='font-24 text-center px-2'>
                    {chat?.roomName}
                  </div>
                  <div className='font-16 mt-3 text-center'>{chat?.members?.length || 0} Members</div>
                  {files && <div className='h-full px-5 mt-5'>
                    <div className='font-16 mb-3'>
                      Files
                    </div>
                    <div className=''>
                      <TaskFileHolder fileImages={files} />
                    </div>
                  </div>}
                  <div className='overflow-y h-full px-5 mt-10'>
                    <div className='flex align-center mb-5'>
                      <div className='font-16'>{showAddMember ? 'Add' : ''} Members</div>
                      {/* <div className='flex align-center ml-3'>
                        <Icon
                          name='search-black.svg'
                          height='20px'
                          className='hover:opacity-60'
                          onClick={() => {
                            setShowInput(true);
                          }}
                        />
                        {showInput && <input
                          // value={inputName}
                          // onInput={(e) => inputOnChange(e.target.value)}
                          className='ml-3 bg-white px-2 py-1 border-slate-300 border border-radius10 font-14 w-60'
                          type='text'
                          placeholder='Name'
                          maxLength={30}
                        />}
                      </div> */}
                    </div>
                    {!showAddMember && !isDirectChat && <div onClick={() => setShowAddMember(true)} className='d-flex align-center mb-3 cursor-pointer hover:opacity-60'>
                      <div
                        className='flex align-center justify-center bg-indigo-400'
                        style={{
                          height: 50,
                          width: 50,
                          borderRadius: 40,
                        }}>
                        <Icon
                          name='add.svg'
                          height='16px'
                        />
                      </div>
                      <div className='font-18 ml-5'>Add Member</div>
                    </div>}
                    {showAddMember &&
                      <div className='flex align-center mb-8 ml-2'>
                        <Icon
                          name='close.svg'
                          height='14px'
                          className='hover:opacity-60 mr-5'
                          onClick={() => {
                            setShowAddMember(false);
                          }}
                        />
                        <Select
                          value={assigneeValue}
                          isMulti
                          isClearable={false}
                          // Only users that are not already in team
                          options={allUsers
                            ?.filter(
                              (newUser) =>
                                !chat?.members?.some(
                                  (existingUser) => existingUser?._id === newUser?._id
                                )
                            )
                            ?.map((participant) => {
                              return {
                                value: participant?._id,
                                label: participant?.fullName,
                                email: participant?.email,
                                avi: participant?.avi,
                                role: 'collaborator'
                              };
                            })}
                          formatOptionLabel={AssigneeOptionLabel}
                          onChange={(participants) => setAssigneeValue(participants)}
                          autoFocus={true}
                          placeholder='Add to chat...'
                          classNames={{
                            menuList: () => 'scroll-y',
                            control: () => 'flex mr-5 items-center bg-white text-sm font-medium text-slate-800 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out',
                            container: () => 'search-container'
                          }}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          styles={{
                            menu: (baseStyles, state) => ({
                              ...baseStyles,
                              width: '80%'
                            }),
                          }}
                        />
                        <div
                          onClick={() => {
                            const userIds = assigneeValue.map(val => val?.value) || [];
                            // assigneeValue.map(val => {
                            dispatch(addUserToChat({
                              // userId: val?.value,
                              users: userIds,
                              chatId: chat?._id,
                              accessTime: new Date()
                            }));
                            setShowAddMember(false);
                            // })
                          }}
                          className='bg-indigo-400 py-2.5 font-14 px-4 text-white cursor-pointer hover:bg-indigo-300'>
                          Add
                        </div>
                      </div>
                    }
                    {chatMembers?.map((member, i) => {
                      return (
                        <div key={i + member?._id} className='d-flex align-center justify-content-between mb-3'>
                          <div className='d-flex align-center'>
                            <img
                              className='lg:h-[50px] lg:w-[50px] h-[30px] w-[30px]'
                              src={member?.avi}
                              style={{
                                borderRadius: 40,
                                objectFit: 'cover'
                              }}
                            />
                            <div className='ml-5'>
                              <div className='sm:font-16 lg:font-18'>{member?.fullName}</div>
                              <div className='d-flex align-center'>
                                <div className='sm:font-12 lg:font-14 mr-2'>{member?.email || ''}</div>
                                <Tooltip title='Copy email'>
                                  <img
                                    onClick={() => {
                                      navigator.clipboard.writeText(member?.email || '');
                                      notification.success({
                                        message: 'Email copied to clipboard',
                                        placement: 'bottomRight',
                                      });
                                    }}
                                    src={'/images/icons/copy.svg'}
                                    height={18}
                                    width={18}
                                    style={{ cursor: 'pointer' }}
                                    alt='icon'
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          {user?._id !== member?._id && !directMessageRecipient && <div className='flex align-center'>
                            <Tooltip title='Message'>
                              <>
                                <Icon
                                  name='message.svg'
                                  height='20px'
                                  className='mr-4 hover:opacity-60'
                                  onClick={() => {
                                    const x = chats?.find(chat => chat?.roomType === 'direct' && chat?.members?.find(mem => mem?._id === member?._id));
                                    //openChat(x?._id, x?.roomName, member?._id);
                                    openChat(member?._id);
                                  }}
                                />
                              </>
                            </Tooltip>
                            <Tooltip title='Remove'>
                              <>
                                <Icon
                                  name='delete-red.svg'
                                  height='24px'
                                  className='hover:opacity-60'
                                  onClick={() => {
                                    dispatch(removeUserFromChat({
                                      chatId: chat?._id,
                                      users: [member?._id]
                                    }, () => {
                                      const x = chatMembers.slice();
                                      x.splice(i, 1);
                                      setChatMembers(x || []);
                                    }))
                                  }}
                                />
                              </>
                            </Tooltip>
                          </div>}
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Resizable>
    </>
  );
};
function mapStateToProps(state) {
  return {
    chats: state.chatReducer.chats,
    drawerWidth: state.toggleReducer.drawerWidth,
    allUsers: state?.userReducer?.users,
    currentWorkspace: state.workspaceReducer.currentWorkspace,
  };
}

export default connect(mapStateToProps)(UserChatPanel);
