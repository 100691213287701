import '../../../../../../assets/style/taskFilter.css';

const CraetorIcon = () => {
  return (
    <svg className="filter-icon"
      style={{ marginRight: '8px' }}
      width="16" height="16" viewBox="0 0 16 16" fill="#DCD8FE93"><path d="M5.885 13.967c-.278.325-.735.443-1.113.246a7 7 0 1 1 9.798-8.634c.17.463-.198.921-.692.921-.346 0-.645-.232-.774-.554A5.502 5.502 0 0 0 2.5 8c0 1.519.616 2.894 1.61 3.89l1.011-1.011A3 3 0 0 1 7.243 10h1.514c.218 0 .313.252.171.417l-3.043 3.55Z"></path><path d="M6 6a2 2 0 0 1 4 0v.5a2 2 0 0 1-4 0V6ZM13.263 8.138l-3.885 3.91a2.594 2.594 0 0 1 1.672 1.547l3.826-3.847c.293-.266.008-1.107-.258-1.37-.279-.275-1.103-.552-1.355-.24ZM10.25 14.364a1.622 1.622 0 0 0-1.587-1.456c-.247.491-.5 1.249-.65 1.73a.277.277 0 0 0 .329.355c.56-.127 1.48-.363 1.909-.63Z"></path></svg>
  )
}

export default CraetorIcon;
