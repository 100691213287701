import axios from 'axios';
// import { useStripe } from '@stripe/react-stripe-js';
import { notification } from "antd";

const baseUrl = process.env.REACT_APP_API_URL;

const config = {
  headers: { Authorization: '' },
};

export const createCheckoutSession = (data) => {
  return async () => {
    try {
      // const stripe = useStripe();
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      const res = await axios.post(`${baseUrl}/payments/initiate`, data, config);
      const url = res?.data?.data?.url;
      window.location.href = url
      // await axios.post('/create-checkout-session', async (req, res) => {
      //   const prices = await stripe.prices.list({
      //     lookup_keys: [req.body.lookup_key],
      //     expand: ['data.product'],
      //   });
      //   const session = await stripe.checkout.sessions.create({
      //     billing_address_collection: 'auto',
      //     line_items: [
      //       {
      //         price: prices.data[0].id,
      //         // For metered billing, do not pass quantity
      //         quantity: 1,

      //       },
      //     ],
      //     mode: 'subscription',
      //     success_url: `${baseUrl}/?success=true&session_id={CHECKOUT_SESSION_ID}`,
      //     cancel_url: `${baseUrl}?canceled=true`,
      //   });

      //   res.redirect(303, session.url);
      // }, config);


      // notification.success({
      //   message: "Works!",
      //   placement: "bottomRight",
      // });

    } catch (error) {
      console.info(error);
    }
  };
};

export const setPaymentDetails = (paymentData, callback) => {
  return async (dispatch) => {
    dispatch({
      type: 'set-selected-plan',
      payload: paymentData
    });

    callback && callback();
  }
}
