import axios from "axios";
import { notification } from "antd";
import { getChatRooms } from "./chatRoomAction";
const baseUrl = process.env.REACT_APP_API_URL;

const config = {
  headers: { Authorization: "" },
};

export const getProjects = (callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/projects`, config);

      console.info(res);

      dispatch({
        type: "get-users-projects",
        payload: res?.data?.data,
      });

      callback && callback(res.data.data);
    } catch (error) {
      const status = error?.response?.status;
      dispatch({
        type: "error",
        payload: error,
      });
      return status;
    }
  };
};

export const getProjectById = (id, callback) => {
  return async (dispatch) => {
    try {
      if(!id) return;
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/projects/${id}?members=true`, config);
      if (res.data.status !== "Not Found") {
        dispatch({
          type: "get-projects",
          payload: res.data.data,
        });
        dispatch({
          type: "initializeQuery",
          payload: res.data.data?.sections,
        });
      }
      callback && callback(res.data.data);
    } catch (error) {
      const status = error?.response?.status;
      notification.error({
        message: status === 403 ? "You do not have access to this project" : "Something went wrong",
        placement: "bottomRight",
      });
      dispatch({
        type: "error",
        payload: error,
      });
      return status;
    }
  };
};

export const editProject = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.put(`${baseUrl}/projects/edit`, data, config);
      
      const projectId = data?._id;
      dispatch(getProjectById(projectId));

      notification.success({
        message: `Project set to ${data?.access === 'public' ? 'public' : 'private'}`,
        placement: "bottomRight",
      });

      callback && callback();
      return res.data.data;
    } catch (error) {
      const status = error?.response?.status;
      notification.error({
        message: status === 403 ? "You do not have access to this project" : "Something went wrong",
        placement: "bottomRight",
      });
      dispatch({
        type: "error",
        payload: error,
      });
      return status;
    }
  };
};

export const getProjectTemplates = () => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/projects/templates`, config);
      dispatch({
        type: "set-project-templates",
        payload: res.data.data,
      });
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};


export const addProject = (data={}, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      if(!data?.workspaceId) {
        data.workspace = sessionStorage.getItem("workspaceId");
      }
      const res = await axios.post(`${baseUrl}/projects`, data, config);
      console.log("===== addProject", res.data.data)
      await dispatch({
        type: "add-project",
        payload: res.data.data,
      });
      notification.success({
        message: "You just added a new project",
        placement: "bottomRight",
      });

      callback && callback();
      // dispatch(getChatRooms());
      return res.data.data;
    } catch (error) {
      dispatch({
        type: "workspace-error",
        payload: error,
      });
      notification.error({
        message: error?.response?.data?.message || "Oops! something went wrong",
        placement: "bottomRight",
      });
    }
  };
};

export const updateProjectUsers = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      await axios.post(`${baseUrl}/projects/updateProjectUsers`, data, config);
      callback && callback();
      notification.success({
        message: "Project roles have been updated",
        placement: "bottomRight",
      });
      dispatch({
        type: 'updateRolesInProject',
        payload: data
      })
    } catch (e) {
      notification.error({
        message: e?.message || "Cannot update role!",
        placement: "bottomRight",
      });
    }
  };
};

export const duplicateProject = (socket, data, callback) => {
  return async (dispatch) => {
    try {
      socket.emit("duplicate-project", data);
      // config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      // const res = await axios.post(`${baseUrl}/projects/duplicate`, data, config);
      callback && callback();
      notification.success({
        message: "Project duplication initiated",
        placement: "bottomRight",
      });
    } catch (e) {
      notification.error({
        message: e?.message || "Cannot duplicate project!",
        placement: "bottomRight",
      });
    }
  };
};

export const unsetActiveProject = () => {
  return {
    type: "unset-active-project"
  };
};

export const deleteProject = (id) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.delete(`${baseUrl}/projects/${id}`, config);
      dispatch({
        type: "delete-project",
        payload: id,
      });
      notification.success({
        message: "You just deleted a project",
        placement: "bottomRight",
      });
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
      notification.error({
        message: "Oops! something went wrong",
        placement: "bottomRight",
      });
    }
  };
};

export const editPreSection = (data, project) => {
  const newSection = project.sections.map((section) => {
    if (section._id === data.section) {
      section.tasks.push(data._id);
    }
    if (section._id === data.preSection) {
      const index = section.tasks.indexOf(data._id);
      section.tasks.splice(index, 1);
    }
    return section;
  });
  project.sections = newSection;
  return {
    type: "pre-section-update",
    payload: project,
  };
};

export const updateProjectPreferences = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/projects/user`, data, config);
      console.log("===== updateProjectPreferences", res.data.data);
      dispatch(getProjectById(data?.projectId));
      notification.success({
        message: `Project notifications ${res.data?.data?.notifications ? 'enabled' : 'muted'}`,
        placement: "bottomRight",
      });

      callback && callback();
      return res.data.data;
    } catch (error) {
      dispatch({
        type: "workspace-error",
        payload: error,
      });
      notification.error({
        message: "Oops! something went wrong",
        placement: "bottomRight",
      });
    }
  };

};

export const updateSectionTasksCount = (from, to, taskId, projectId) => {
  return (dispatch) => {
    dispatch({
      type: "update-section-count",
      payload: {
        from,
        to,
        taskId,
        projectId
      }
    })
  }
}

export const updateProjectSections = (sections, projectId) => {
  return (dispatch) => {
    dispatch({
      type: 'update-project-sections',
      payload: {
        projectId,
        sections
      }
    })
  }
}

export const getProjectCustomField = (projectId, callback) => {
  return async (dispatch) => {
    try {
      if(!projectId) return;
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/customField/project/${projectId}`, config);
      dispatch({
        type: "set-project-custom-fields",
        payload: res.data.data,
      });
      callback && callback();
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const addCustomFieldToWorkspace = (data, callback) => {
  return async(dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/customField`, data, config);
      console.log("===== addCustomFieldToWorkspace", res?.data?.data);
      dispatch({
        type: "add-workspace-field",
        payload: res?.data?.data,
      });
      callback && callback();
      return res?.data?.data;
    } catch (error) {
      console.log("----->>>> error", error)
      dispatch({
        type: "workspace-error",
        payload: error,
      });
      notification.error({
        message: error?.response?.data?.message || "Oops! something went wrong",
        placement: "bottomRight",
      });
    }
  }
}

export const addCustomFieldToProject = (data, callback) => {
  return async(dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/customField`, data, config);
      console.log("===== addCustomFieldToProject", res?.data?.data);
      dispatch(getProjectById(data?.entityId));
      callback && callback();
      notification.success({
        message: 'Custom Field Added!',
        placement: "bottomRight",
      });
      return res?.data?.data;
    } catch (error) {
      console.log("----->>>> error", error)
      dispatch({
        type: "workspace-error",
        payload: error,
      });
      notification.error({
        message: error?.response?.data?.message || "Oops! something went wrong",
        placement: "bottomRight",
      });
    }
  }
}

export const updateWorkspaceCustomField = (customFieldId, data, callback) => {
  return async(dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.put(`${baseUrl}/customField/${customFieldId}`, data, config);
      console.log("===== updateWorkspaceCustomField", res);
      callback && callback();
      return res;
    } catch (error) {
      dispatch({
        type: "workspace-error",
        payload: error,
      });
      notification.error({
        message: error?.response?.data?.message || "Oops! something went wrong",
        placement: "bottomRight",
      });
    }

  }
}

export const updateProjectCustomField = (customFieldId, data, callback) => {
  return async(dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.put(`${baseUrl}/customField/${customFieldId}`, data, config);
      console.log("===== updateProjectCustomField", res);
      dispatch(getProjectById(data?.entityId));
      callback && callback();
      notification.success({
        message: 'Custom Field Updated!',
        placement: "bottomRight",
      });
      return res;
    } catch (error) {
      dispatch({
        type: "workspace-error",
        payload: error,
      });
      notification.error({
        message: error?.response?.data?.message || "Oops! something went wrong",
        placement: "bottomRight",
      });
    }

  }
}

export const deleteProjectCustomField = (customFieldId, projectId, callback) => {
  return async(dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.delete(`${baseUrl}/customField/project/${projectId}/${customFieldId}`, config);
      console.log("===== deleteProjectCustomField", res);
      // dispatch(getProjectById(data?.entityId));
      callback && callback();
      notification.success({
        message: 'Custom Field Deleted!',
        placement: "bottomRight",
      });
      return res;
    } catch (error) {
      dispatch({
        type: "workspace-error",
        payload: error,
      });
      notification.error({
        message: "Oops! something went wrong",
        placement: "bottomRight",
      });
    }

  }
}

export const deleteWorkspaceCustomField = (customFieldId, workspaceId, callback) => {
  return async(dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.delete(`${baseUrl}/customField/workspace/${workspaceId}/${customFieldId}`, config);
      console.log("===== deleteProjectCustomField", res);
      // dispatch(getProjectById(data?.entityId));
      callback && callback();
      dispatch({
        type: "delete-workspace-field",
        payload: customFieldId,
      });
      notification.success({
        message: 'Custom Field Deleted!',
        placement: "bottomRight",
      });
      return res;
    } catch (error) {
      dispatch({
        type: "workspace-error",
        payload: error,
      });
      notification.error({
        message: "Oops! something went wrong",
        placement: "bottomRight",
      });
    }

  }
}

export const leaveProject = (data) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
      await axios.post(`${baseUrl}/projects/leave`, data ,config);
      dispatch({
        type: "delete-project",
        payload: data?.projectId || '',
      });
      notification.success({
        message: "Project Left",
        placement: "bottomRight",
      });
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });

      notification.error({
        message: error?.response?.data?.message || "Oops! something went wrong",
        placement: "bottomRight",
      });
    }
  };
};

export const removeMemberFromProject = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/projects/removeMembers`, data, config);
      notification.success({
        message: "Member removed from project!",
        placement: "bottomRight",
      });
      if(callback)  callback();
      return res.data;
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const joinProject = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/projects/join`, data, config);
      
      await dispatch({
        type: "add-project",
        payload: res?.data?.data,
      });

      notification.success({
        message: res?.data?.message || 'Project Joined!',
        placement: "bottomRight",
      });

      callback && callback();
      return res?.data?.data;
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const setProjectLoading = (isLoading, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "projects-loading",
        payload: isLoading,
      });
      callback && callback();
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};


export const approveAccessRequest = (request, callback) => {
  return async (dispatch) => {
    try {
      const requestId = request?._id;
      const entity = request?.entityType+'s';
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/${entity}/approve`, {requestId}, config);
      

      notification.success({
        message: 'Access Request Approved!',
        placement: "bottomRight",
      });

      callback && callback();
      return res.data.data;
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const denyAccessRequest = (request, callback) => {
  return async (dispatch) => {
    try {
      const requestId = request?._id;
      const entity = request?.entityType+'s';

      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/${entity}/decline`, {requestId}, config);
      

      notification.success({
        message: 'Access Request Denied!',
        placement: "bottomRight",
      });

      callback && callback();
      return res.data.data;
    } catch (error) {
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const updateProject = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.put(`${baseUrl}/projects/edit`, data, config);
      await dispatch({
        type: "project-update-success",
        payload: res?.data?.data,
      });
      notification.success({
        message: "Project updated!",
        placement: "bottomRight",
      });
      callback && callback();
    } catch (error) {
      dispatch({
        type: "team-error",
        payload: error,
      });
    }
  };
};