import { taskStatus } from '../js/constants';

const data = {
  success: {},
  error: {},
  singleTaskError: null,
  tasks: [],
  originalTasks: [],
  singleTask: {},
  userTasks: [],
  loading: true,
  filters: {
    status: taskStatus.allTasks.key,
    priority: '',
    assignedTo: []
  },
  userFilters: {
    status: taskStatus.allTasks.key,
    priority: [],
    assignedTo: [],
    projects: [],
    creator: [],
    customFields: {}
  },
  taskProperties: {
    taskTitle: '',
    taskDescription: '',
    cost: 0,
    dueDate: '',
    section: '',
    isCompleted: false,
    documentPath: '',
    sectionCounts: {},
    sectionDoneCounts: {}
  },
  query: { page: 1 },
  userQuery: { page: 1 },
  lruProjectTasks: {},
  selectedUserFilter: {},
  userTasksCount: 0,
  dashboardTasks: {}
};

const removeLeastRecentlyAccessed = (tasks) => {
  const totalKeys = Object.keys(tasks).length;

  if (totalKeys >= 3) {
    const leastRecentlyAccessedProject = Object.keys(tasks).reduce((minProject, projectId) => {
      const currentProject = tasks[projectId];
      if (!minProject || currentProject.accessed < minProject.accessed) {
        return currentProject;
      }
      return minProject;
    }, null);

    if (leastRecentlyAccessedProject) {
      delete tasks[leastRecentlyAccessedProject.projectId];
    }
  }
};

const updateTasksInLru = (task, lru = {}) => {
  const tskprjs = task?.projects || [];
  for (let tp of tskprjs) {
    const existingTaskProject = lru[tp?.projectId?.toString()];
    if (existingTaskProject) {
      const updatedTasks = existingTaskProject?.tasks?.map((t) => {
        if (t?._id === task?._id) {
          // If the task's _id matches the payload's _id, update the task
          return { ...t, ...task };
        }
        return t;
      });
      existingTaskProject.tasks = updatedTasks;
      lru[tp?.projectId?.toString()] = existingTaskProject;
    }
  }

  return lru;
}

const addTaskToLru = (projectId, tasks, lru = {}) => {
  const existingTaskProject = lru[projectId?.toString()];
  if (existingTaskProject) {
    existingTaskProject.tasks = tasks;
    lru[projectId?.toString()] = existingTaskProject;
  }
  return lru;
}

const moveTaskToNewSection = (task, projectId, sectionId) => {

  const taskPrj = task?.projects?.find(p => p?.project?._id?.toString() === projectId?.toString());
  
  const toSection = taskPrj?.project.sections?.find(s => s?._id?.toString() === sectionId?.toString());
  taskPrj.section = {
    _id: toSection?._id,
    title: toSection?.title,
    color: toSection?.color
  };
}

const deleteProjectTasksFromLru = (projectId, lru) => {
  if (lru[projectId]) {
    delete lru[projectId];
  }
  return lru;
}

const taskReducer = (state = data, action) => {
  switch (action.type) {
    case 'taskProperties':
      return {
        ...state,
        taskProperties: action.payload,
      };

    case 'task-loading':
      return {
        ...state,
        loading: action.payload,
      };

    case 'task-success':
      return {
        ...state,
        success: action.payload,
      };

    case 'task-error':
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case 'single-task-error':
      return {
        ...state,
        singleTaskError: action.payload,
        loading: false,
      };

    case 'clear-single-task-error':
      return {
        ...state,
        singleTaskError: null,
        loading: false,
      };


    case 'tasks':
      return {
        ...state,
        tasks: action.payload?.tasks,
        originalTasks: action.payload?.tasks,
        sectionCounts: action.payload?.sectionCounts,
        sectionDoneCounts: action.payload?.sectionDoneCounts
      };

    case "add-to-lru-tasks":
      let prjId = action.payload?.projectId?.toString();
      let prjTasks = action.payload?.tasks || [];
      let sectionCounts = action.payload?.sectionCounts;
      let sectionDoneCounts = action.payload?.sectionDoneCounts;
      let existingProjectTasks = state.lruProjectTasks;
      existingProjectTasks[prjId] = { tasks: prjTasks, sectionCounts, sectionDoneCounts, accessed: new Date() };
      removeLeastRecentlyAccessed(existingProjectTasks);

      return {
        ...state,
        lruProjectTasks: existingProjectTasks
      }

    case "delete-from-lru-tasks":
      return {
        ...state,
        lruProjectTasks: deleteProjectTasksFromLru(action.payload?.toString(), { ...state.lruProjectTasks })
      }

    case "empty-lru":
      return {
        ...state,
        lruProjectTasks: {}
      }

    case 'initializeQuery':
      const sectionObjects = action.payload;

      // Extract unique _ids from the section objects
      const uniqueSectionIds = Array.from(new Set(sectionObjects.map(section => section._id)));

      // Create the initial query object with section _ids and default pageNumber
      const initialQuery = uniqueSectionIds.reduce((query, sectionId) => {
        return { ...query, [sectionId]: 1 };
      }, {});

      return {
        ...state,
        query: initialQuery,
      };


    case 'set-filters':
      return {
        ...state,
        filters: action.payload.filters,
        query: {
          page: 1
        }
        // tasks: filteredTasks
      };

    case 'set-user-filters':
      if (action.payload.reset) {
        return {
          ...state,
          userFilters: {
            status: taskStatus.allTasks.key,
            priority: [],
            assignedTo: [],
            projects: [],
            creator: [],
            customFields: {}
          },
          userQuery: {
            page: 1
          }
        }
      } else {
        return {
          ...state,
          userFilters: action.payload.filters,
          userQuery: {
            page: 1
          }
          // tasks: filteredTasks
        };
      }

    case 'appendtasks':
      const newTasks = action.payload;
      const existingTasks = state.tasks || [];

      // Create a set of existing task IDs for quick deduplication checking
      const existingTaskIds = new Set(existingTasks.map(task => task._id));

      // Filter out duplicates from new tasks based on _id
      const uniqueNewTasks = newTasks?.filter(task => !existingTaskIds.has(task._id)) || [];

      return {
        ...state,
        tasks: [...existingTasks, ...uniqueNewTasks], // Append unique tasks
      };


    case 'add-task':
      const taskExists = state?.tasks?.some((task) => task?._id && task?._id === action?.payload?._id) || false;

      if (!state.tasks) {
        state.tasks = [];
      }
      let updatedTasksAdd = taskExists ? state.tasks : [action.payload, ...state.tasks];

      // Sort the updated tasks array by the 'position' field
      updatedTasksAdd = updatedTasksAdd.sort((a, b) => a.position - b.position);
      
      const incrementedSectionCounts = {...state.sectionCounts};
      if(action.payload?.sectionId && incrementedSectionCounts[action.payload?.sectionId]) {
        incrementedSectionCounts[action.payload?.sectionId] = Math.max(incrementedSectionCounts[action.payload?.sectionId] + 1, 0);
      }

      return {
        ...state,
        tasks: updatedTasksAdd,
        lruProjectTasks: addTaskToLru(action.payload?.projectId, updatedTasksAdd, { ...state.lruProjectTasks }),
        sectionCounts: incrementedSectionCounts
      };

    case 'update-tasks':
      if (!state.tasks) {
        // If state.tasks is not defined, there's nothing to update
        return state;
      }

      // const updatedTasks = state.tasks.map((task) => {
      //   if (task._id === action.payload._id) {
      //     // If the task's _id matches the payload's _id, update the task
      //     return { ...task, ...action.payload };
      //   }
      //   return task;
      // });

      console.log("------ update-tasks action.payload", action.payload);
      const iDoneSectionCounts = {...state.sectionDoneCounts} || {};
      const updatedTasks = state.tasks.map((task) => {
        if (task._id === action.payload._id) {
          // If the task's _id matches the payload's _id, update only the specified properties
          if(!task?.isCompleted && action.payload?.isCompleted) {
            const projects = task?.projects || [];
            const sections = projects.map(p => p?.section?._id);
            for(let s of sections) {
              if(typeof iDoneSectionCounts[s] == 'number') {
                iDoneSectionCounts[s] = Math.max(iDoneSectionCounts[s] + 1, 0);
              }
            }  
          } else if(task?.isCompleted && typeof action.payload?.isCompleted === 'boolean' && !action.payload?.isCompleted) {
            const projects = task?.projects || [];
            const sections = projects.map(p => p?.section?._id);
            for(let s of sections) {
              if(typeof iDoneSectionCounts[s] == 'number') {
                iDoneSectionCounts[s] = Math.max(iDoneSectionCounts[s] - 1, 0);
              }
            }  
          }
          return { ...task, ...action.payload}//...Object.fromEntries(Object.entries(action.payload).filter(([key]) => task.hasOwnProperty(key))) };
        }
        return task;
      });

      return {
        ...state,
        tasks: updatedTasks,
//        singleTask: state?.singleTask?._id === action.payload._id ? { ...state.singleTask, ...Object.fromEntries(Object.entries(action.payload).filter(([key]) => state.singleTask.hasOwnProperty(key))) } : state.singleTask,
        singleTask: state?.singleTask?._id === action.payload._id ? {
          ...state.singleTask,
          ...action.payload
        } : state.singleTask,
        lruProjectTasks: updateTasksInLru(action.payload, { ...state.lruProjectTasks }),
        sectionDoneCounts: iDoneSectionCounts
      };

    case 'remove-task':
      const removedTask = state.tasks.find((task) => task?._id?.toString() === action?.payload?.toString());
      const updatedTasksRemove = state.tasks.filter((task) => task?._id !== action?.payload);

      const decrementedSectionCounts = {...state.sectionCounts};
      const decrementedDoneSectionCounts = {...state.sectionDoneCounts};
      if(removedTask?.sectionId && decrementedSectionCounts[removedTask?.sectionId]) {
        decrementedSectionCounts[removedTask?.sectionId] = Math.max(decrementedSectionCounts[removedTask?.sectionId] - 1, 0);
        if(removedTask?.isCompleted) {
          decrementedDoneSectionCounts[removedTask?.sectionId] = Math.max(decrementedDoneSectionCounts[removedTask?.sectionId] - 1, 0);
        }
      }

      // Sort the updated tasks array by the 'position' field
      // updatedTasksRemove.sort((a, b) => a.position - b.position);

      return {
        ...state,
        tasks: updatedTasksRemove,
        lruProjectTasks: {},
        sectionCounts: decrementedSectionCounts,
        sectionDoneCounts: decrementedDoneSectionCounts
      };


    case 'singleTaskRequest':
      return {
        ...state,
        loading: true,
      };

    case 'singleTask':
      const updatedTasksState = state.tasks.map((task) => {
        if (task._id === action.payload._id) {
          // If the task's _id matches the payload's _id, update only the specified properties
          return { ...task, ...Object.fromEntries(Object.entries(action.payload).filter(([key]) => task.hasOwnProperty(key))) };
        }
        return task;
      });
      return {
        ...state,
        singleTask: action.payload,
        tasks: updatedTasksState,
        loading: false,
      };

    case 'singleTaskLean':
      return {
        ...state,
        singleTask: state.singleTask?._id?.toString() !== action.payload?._id?.toString() ? action.payload : state.singleTask,
        loading: false,
      };

    case 'remove-comment-from-single-task':
      const selectedTask = { ...state.singleTask };
      if (selectedTask?._id?.toString() === action.payload?.taskId?.toString()) {
        selectedTask.comments = selectedTask.comments.filter(cmnt => cmnt?._id?.toString() !== action.payload?.messageId?.toString())
      }
      return {
        ...state,
        singleTask: selectedTask,
        loading: false,
      };

    case 'clearSingleTask':
      return {
        ...state,
        singleTask: {},
        loading: false,
      };
    case 'userTasks':
      if (state?.userQuery?.page > 1) {
        const newTasks = action.payload.tasks;
        const existingTasks = state.userTasks;

        // Create a set of existing task IDs for quick deduplication checking
        const existingTaskIds = new Set(existingTasks.map(task => task._id));

        // Filter out duplicates from new tasks based on _id
        const uniqueNewTasks = newTasks?.filter(task => !existingTaskIds.has(task._id)) || [];

        return {
          ...state,
          userTasks: [...existingTasks, ...uniqueNewTasks], // Append unique tasks
        };
      }
      else {
        return {
          ...state,
          userTasks: action.payload.tasks,
          userTasksCount: action.payload.count
        };
      }

      case 'clearUserTasks':
        console.log("clearUserTasks")
        return {
          ...state,
          userTasks: [],
          userTasksCount: 0
        };

    case 'logout':
      return {
        ...state,
        singleTask: {},
        tasks: [],
        userTasks: [],
        userTasksCount: 0
      };

    case 'pre-task-update':
      return {
        ...state,
        tasks: action.payload,
      };

    case 'updateQueryState':
      const sectionId = action.payload;
      return {
        ...state,
        query: {
          ...state.query,
          [sectionId]: (state.query[sectionId] || 0) + 1, // Increment the page
        },
      };

    case 'updateUserTaskQueryState':
      return {
        ...state,
        userQuery: {
          page: state?.userQuery?.page + 1 || 1
        }
      };

    case 'add-comment-to-task':
      const comment = action.payload;
      const taskIndex = state.tasks.findIndex(task => task._id?.toString() === comment?.taskId)?.toString();
      let uTasks = [...state.tasks];

      // If a task with the matching _id is found, update its totalComments
      if (taskIndex != -1) {
        uTasks[taskIndex].totalComments += 1;
      }
      if (comment.taskId === state.singleTask._id) {
        // Check if the comment with the same _id exists in the comments array
        const commentIndex = state.singleTask.comments.findIndex((c) => c?._id === comment?._id);

        if (commentIndex !== -1) {
          // Comment with the same _id exists, update it
          const updatedComments = [...state.singleTask.comments];
          updatedComments[commentIndex] = comment;
    
          return {
            ...state,
            singleTask: {
              ...state.singleTask,
              comments: updatedComments,
              totalComments: updatedComments?.length
            },
            tasks: uTasks
          };
        } else {
          // Comment with the same _id doesn't exist, add it

          return {
            ...state,
            singleTask: {
              ...state.singleTask,
              comments: [...state.singleTask.comments, comment],
              totalComments: state.singleTask.comments?.length + 1
            },
            tasks: uTasks
          };
        }
      }
    case 'section-counts':
      return {
        ...state,
        sectionCounts: action.payload
      }

    case 'selectUserFilter':
      return {
        ...state,
        selectedUserFilter: action.payload
      }

    case 'reorder-tasks':
      const { fromSection, toSection, taskId, fromIndex, toIndex, projectId } = action.payload;
      const tasks = state?.tasks || [];
      const task = tasks.find(tsk => tsk?._id?.toString() === taskId?.toString());
      if(state?.singleTask?._id?.toString() === taskId) {
        const sT = {...state?.singleTask};
        moveTaskToNewSection(sT, projectId, toSection);
        return {
          ... state,
          singleTask: sT
        }
      }
      if(!task) {
        return state;
      }
      const currentPosition = task?.position;
      const reorderedTasks = [...tasks];
      const tasksInSection = tasks.filter(tsk => tsk?.sectionId?.toString() === toSection?.toString());
      let newPosition;
      if (fromSection?.toString() === toSection?.toString()) {
        console.log("----- Moving within same section");
        let movement = fromIndex - toIndex;
        if (movement > 0) {
          //TODO: Readjust of frontend using legacy logic
          console.log('Task is moving up within same section - ' + movement);
          const higherTasks = tasksInSection.filter(task => task?.position > currentPosition);
          console.log('higherTasks', higherTasks);
          if (higherTasks?.length === 0) {
            console.log("This task is already at the top");
            return state;
          } else if (movement >= higherTasks?.length) {
            // Get task at the top
            const firstTask = higherTasks[0];

            console.log('Task is moving to the top of the section', firstTask);

            // Get position of top task and add 1 to it and assign it to given task
            const ftPosition = firstTask?.position;
            console.log('ftPositionn', ftPosition);

            newPosition = ftPosition + 1;
            console.log('newPosition', newPosition);

            task.position = newPosition;
            console.log('task', task);

          } else {
            console.log('Task is moving up in the section');
            // Get the task which is currently occupying current task place. Assign this task position to newPosition.
            const middleTask = higherTasks[higherTasks?.length - movement];
            console.log('middleTask', middleTask);

            // Get position of middle task and add 1 to it and assign it to given task
            const mtPosition = middleTask?.position;
            newPosition = mtPosition + 1;
            task.position = newPosition;

            console.log('new TASK position', newPosition);


            // Get the tasks above the one where new task should be moved
            const tasksToBeUpdated = higherTasks?.slice(0, movement * -1);
            console.log('tasksToBeUpdated', tasksToBeUpdated);

            // Update the posiiton of these task
            for (let task of tasksToBeUpdated) {
              task.position = task?.position + 1;
            }
          }
        } else if (movement < 0) {
          movement = movement * -1;
          // Task is moving down within same section
          console.log('Task is moving down within same section - ' + movement);

          // Get tasks currently higher than the given task in the section
          const lowerTasks = tasksInSection.filter(task => task?.position < currentPosition);

          let newPosition;
          if (lowerTasks?.length === 0) {
            console.log("This task is already at the bottom");
            return state;
          }
          // If movement is equal to or greater than the number of lower tasks then task is moving to the bottom.
          else if (movement >= lowerTasks?.length) {
            console.log('Task is moving to the bottom of the section');
            // Get task at the bottom
            const lastTask = lowerTasks[0];
            // Get position of bottom task and substract 1 from it and assign it to given task
            const ltPosition = lastTask?.position;
            newPosition = ltPosition - 1;
            task.position = newPosition;
          } else {
            // Get the task which is currently occupying current task place. Assign this task position to newPosition.
            const middleTask = lowerTasks[movement - 1];
            const mtPosition = middleTask?.position;
            newPosition = mtPosition - 1;
            task.position = newPosition;

            // Get the tasks above the one where new task should be moved
            const tasksToBeUpdated = lowerTasks?.slice(movement * -1);
            // Update the posiiton of these task
            for (let task of tasksToBeUpdated) {
              task.position = task?.position - 1;
            }
          }
        }
        // Update the position in the copied array
        const taskIndex = reorderedTasks.findIndex(t => t?._id?.toString() === taskId?.toString());
        console.log("----- taskIndex", taskIndex);
        reorderedTasks[taskIndex] = task;
        console.log("----- reorderedTasks", reorderedTasks);

        const sortedUpdatedTasks = [...reorderedTasks].sort((a, b) => b.position - a.position);
        console.log("----- sortedUpdatedTasks", sortedUpdatedTasks);

        return {
          ...state,
          tasks: [...sortedUpdatedTasks]
        }
      } else {
        console.log('Task is moving to a new section!');
        let movement = toIndex;
        const firstTask = tasksInSection[0];
        const lastTask = tasksInSection[tasksInSection?.length - 1];
        const ltPosition = lastTask?.position;
        const middleTask = tasksInSection[movement - 1];
        const middlePosition = middleTask?.position;
        if (tasksInSection?.length === 0) {
          console.log('Moving to an empty section');
          task.sectionId = toSection;
        } else if (movement === 0) {
          console.log('Moving to the top of new section');
          task.sectionId = toSection;
          const ftPosition = firstTask?.position;
          newPosition = ftPosition + 1;
          task.position = newPosition;
        } else if (movement === tasksInSection?.length) {
          console.log('Moving to the bottom of new section');
          task.sectionId = toSection;
          newPosition = ltPosition - 1;
          task.position = newPosition;
        } else {
          console.log('Moving to the middle of new section');
          const higherTasks = tasksInSection.filter(task => task?.position > middlePosition);
          const lowerTasks = tasksInSection.filter(task => task?.position < middlePosition);
          if (higherTasks?.length > lowerTasks?.length) {
            console.log('Decrement the tasks below pivot');
            for (let task of lowerTasks) {
              task.position = task?.position - 1;
            }
            middleTask.position = middleTask?.position - 1;

            task.sectionId = toSection;
            newPosition = middlePosition;
            task.position = newPosition;
          } else {
            console.log('Increment the tasks above pivot');
            for (let task of higherTasks) {
              task.position = task?.position + 1;
            }
            middleTask.position = middleTask?.position + 1;

            task.sectionId = toSection;
            newPosition = middlePosition;
            task.position = newPosition;
          }
        }

        // Update the position in the copied array
        const taskIndex = reorderedTasks.findIndex(t => t?._id?.toString() === taskId?.toString());
        reorderedTasks[taskIndex] = task;

        const sortedUpdatedTasks = [...reorderedTasks].sort((a, b) => b.position - a.position);

        const sectionCounts = state?.sectionCounts;
        sectionCounts[fromSection] = Math.max(sectionCounts[fromSection] - 1, 0);
        sectionCounts[toSection] = sectionCounts[toSection] + 1;
        const sectionDoneCounts = state?.sectionDoneCounts;
        if(task?.isCompleted) {
          sectionDoneCounts[fromSection] = Math.max(sectionDoneCounts[fromSection] - 1, 0);
          sectionDoneCounts[toSection] = sectionDoneCounts[toSection] + 1;
        }
        return {
          ...state,
          tasks: [...sortedUpdatedTasks],
          sectionCounts,
          sectionDoneCounts
        }
      }

    case 'update-positions':
      const modifiedTasks = action.payload || [];
      const tasksWithUpdatedPositions = [...state?.tasks];

      for (let mt of modifiedTasks) {
        const isTaskInState = tasksWithUpdatedPositions?.findIndex(task => task?._id?.toString() === mt?.task?.toString() && task?.projectId?.toString() === mt?.project?.toString());

        if (isTaskInState < 0) continue;
        tasksWithUpdatedPositions[isTaskInState].position = mt?.position;
        tasksWithUpdatedPositions[isTaskInState].sectionId = mt?.section;
      }

      return {
        ...state,
        tasks: [...tasksWithUpdatedPositions]
      }

    case 'update-user-filter-results':
      const copyUserTasksForUpdate = [...state?.userTasks];
      const tIndex = copyUserTasksForUpdate?.findIndex(ut => ut?._id?.toString() === action?.payload?._id?.toString());
      if (tIndex >= 0 && tIndex < copyUserTasksForUpdate?.length) {
        copyUserTasksForUpdate[tIndex] = action.payload;
      }

      return {
        ...state,
        userTasks: copyUserTasksForUpdate,
        userTasksCount: copyUserTasksForUpdate?.length || 0
      }

    case 'delete-user-filter-results':
      const copyUserTasksForDelete = [...state?.userTasks];
      const dIndex = copyUserTasksForDelete?.findIndex(ut => ut?._id?.toString() === action?.payload?.toString());
      if (dIndex >= 0 && dIndex < copyUserTasksForDelete?.length) {
        copyUserTasksForDelete.splice(dIndex, 1);
      }
      return {
        ...state,
        userTasks: copyUserTasksForDelete,
        userTasksCount: copyUserTasksForDelete?.length || 0
      }

    case 'add-to-user-filter-results':
      const selectedFilters = {...state?.userFilters};
      const copyUserTasksForAddition = [...state?.userTasks];
      const exitingIndex = copyUserTasksForAddition?.findIndex(t => t?._id?.toString() === action?.payload?._id?.toString());
      const isTaskInState = exitingIndex>=0 && exitingIndex < copyUserTasksForAddition?.length;
      const afterRemovingTask = [...copyUserTasksForAddition];
      afterRemovingTask.splice(exitingIndex, 1);

      let matchMap = {
        projects: false,
        assignedTo: false,
        priority: false,
        creator: false,
        resolution: false,
        customFields: false
      };

      if(selectedFilters?.projects?.length > 0){
        for(let p of selectedFilters?.projects) {
          if(action.payload?.projects.find(pr => pr?.projectId?.toString() === p?._id?.toString())) {
            matchMap.projects = true;
          }
        }
        if(!matchMap.projects) {
          return {
            ...state,
            userTasks: isTaskInState ? afterRemovingTask : copyUserTasksForAddition,
            userTasksCount: isTaskInState ? afterRemovingTask?.length : copyUserTasksForAddition?.length
          }  
        }
      }

      if(selectedFilters?.priority?.length > 0) {
        for(let p of selectedFilters?.priority) {
          if(action.payload?.priority === p?._id) {
            matchMap.priority = true;
          }
        }
        if(!matchMap.priority) {
          return {
            ...state,
            userTasks: isTaskInState ? afterRemovingTask : copyUserTasksForAddition,
            userTasksCount: isTaskInState ? afterRemovingTask?.length : copyUserTasksForAddition?.length
          }  
        }
      }

      if(selectedFilters?.creator?.length > 0) {
        for(let p of selectedFilters?.creator) {
          if(action.payload?.createdBy?.toString() === p?._id?.toString()) {
            matchMap.creator = true;
          }
        }
        if(!matchMap.creator) {
          return {
            ...state,
            userTasks: isTaskInState ? afterRemovingTask : copyUserTasksForAddition,            
            userTasksCount: isTaskInState ? afterRemovingTask?.length : copyUserTasksForAddition?.length

          }  
        }
      }

      if(selectedFilters?.resolution?.length > 0) {
        for(let p of selectedFilters?.resolution) {
          if(action.payload?.isCompleted === p?._id) {
            matchMap.resolution = true;
          }
        }
        if(!matchMap.resolution) {
          return {
            ...state,
            userTasks: isTaskInState ? afterRemovingTask : copyUserTasksForAddition,
            userTasksCount: isTaskInState ? afterRemovingTask?.length : copyUserTasksForAddition?.length
          }  
        }
      }

      console.log("------- selectedFilters", selectedFilters)
      if(Object.keys(selectedFilters?.customFields || {})?.length > 0) {
        for(let [key, value] of Object.entries(selectedFilters?.customFields)) {
          const taskCustomField = action.payload?.customFields?.find(cf => cf?._id?.toString() === key?.toString());
          if(taskCustomField) {
            // Proves task has the filter custom field
            if(value?.find(val => val?._id?.toString() === taskCustomField?.value?.toString())) {
              matchMap.customFields = true;
            }
          }
        }

        if(!matchMap.customFields) {
          return {
            ...state,
            userTasks: isTaskInState ? afterRemovingTask : copyUserTasksForAddition,
            userTasksCount: isTaskInState ? afterRemovingTask?.length : copyUserTasksForAddition?.length
          }  
        }
      }

      if(isTaskInState) {
        // Task is already in state. Update it and return.
        copyUserTasksForAddition[exitingIndex] = action.payload;
        return {
          ...state,
          userTasks: copyUserTasksForAddition,
          userTasksCount: copyUserTasksForAddition?.length
        }  
      }

      copyUserTasksForAddition.push(action.payload);
      
      return {
        ...state,
        userTasks: copyUserTasksForAddition,
        userTasksCount: copyUserTasksForAddition?.length
      }

    case 'update-single-task':
      if(state?.singleTask?._id?.toString() !== action.payload?._id?.toString()) {
        return state;
      }
      return {
        ...state,
        singleTask: {...state.singleTask, ...action.payload}
      }

    case 'get-dashboard-tasks':
      return {
        ...state,
        dashboardTasks: action.payload
      }

    default:
      return state;
  }
};
export default taskReducer;
