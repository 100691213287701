import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';

import { toggleCustomFieldsModal, deleteProjectCustomField, getProjectCustomField } from '../../../../actions';
import { fieldTypes } from '../../../../js/constants';

import ConfirmationModal from '../../molecules/modals/ConfirmationModal';

const ProjectCustomFields = (props) => {
  const dispatch = useDispatch();
  const { project, editOverride } = props;

  const [open, setOpen] = useState(false);
  const [fieldToRemove, setFieldToRemove] = useState();
  const [customFields, setCustomFields] = useState();


  useEffect(() => {
    dispatch(getProjectCustomField(project?._id));
  }, [project?._id]);


  useEffect(() => {
    setCustomFields(props?.customFields);
  }, [project, props?.customFields]);

  return (
    <div className="pt-2">
      <ConfirmationModal
        title={`Delete Field?`}
        description={`Are you sure you want to delete '${fieldToRemove?.name}'?`}
        isModalOpen={open}
        onCancel={() => {
          setOpen(false);
          setFieldToRemove();
        }}
        onYesClick={() => {
          dispatch(deleteProjectCustomField(fieldToRemove?._id, project?._id, () => {
            const x = customFields?.filter(field => field?._id !== fieldToRemove?._id);
            setCustomFields(x);
            setFieldToRemove();
          }));
          setOpen(false);
        }}
      />
      {customFields?.map((item, i) => {
        const icon = fieldTypes?.find(f => f.key == item?.type)?.icon || 'custom-fields.svg';
        return (
          <div className='flex align-center justify-between col-span-full border border-radius10 p-2 border-slate-300 mb-2'>
            <div className='flex align-center'>
              <div className='bg-blue-200 border-radius10'>
                <img
                  src={`/images/icons/${icon}`}
                  style={{
                    cursor: 'pointer',
                    height: '50px',
                    width: '50px',
                    margin: '10px',
                  }}
                  alt='icon'
                />
              </div>
              <div className='ml-3'>
                <div className='font-16 font-bold'>
                  {item?.name}
                </div>
                <div className='font-12 mt-1 font-bold'>
                  Field Type: {item?.type}
                </div>
              </div>
            </div>
            <div className="flex align-center">
              <div
                onClick={() => {
                  if (editOverride) {
                    editOverride(item);
                  } else {
                    dispatch(toggleCustomFieldsModal(true, {
                      type: item?.type,
                      name: item?.name,
                      possibleValues: item?.possibleValues,
                      _id: item?._id,
                      isUpdate: true
                    }));
                  }
                }}
                className='pr-5 cursor-pointer hover:underline font-16'>
                Edit
              </div>
              <div
                onClick={() => {
                  setFieldToRemove(item);
                  setOpen(true);
                }}
                className='pr-5 text-red-500 cursor-pointer hover:underline font-16'>
                Delete
              </div>
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default ProjectCustomFields;