import axios from 'axios';
import { notification } from 'antd';
import { getProjectById } from './projectActions.js'
import { isTicketOpen } from './toggleAction.js';

const baseUrl = process.env.REACT_APP_API_URL;

const config = {
  headers: { Authorization: '' },
};

export const getTasks = (projectId, filters = {}) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      // Filter out null or empty values
      const filteredFilters = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => {
          if (key === 'assignedTo' && Array.isArray(value) && value.length === 0) {
            return false; // Skip empty assignedTo array
          } else if (key === 'assignedTo' && Array.isArray(value)) {
            // Extract only _id from assignedTo array
            return true;
          }
          return value !== null && value !== '' && value !== undefined;
        })
      );

      // Build query string based on filteredFilters
      let queryString = '';
      if (Object.keys(filteredFilters).length > 0) {
        queryString = '?' + Object.entries(filteredFilters)
          .map(([key, value]) => {
            if (Array.isArray(value) && key === 'assignedTo') {
              // Extract only _id from assignedTo array
              const assignedToIds = value.map(item => item._id);
              return `${key}=${assignedToIds.join(',')}`;
            }
            return `${key}=${value}`;
          })
          .join('&');
      }
      const res = await axios.get(`${baseUrl}/alltasks/${projectId}${queryString}`, config);
      dispatch({
        type: 'tasks',
        payload: res?.data?.data,
      });
      dispatch({
        type: 'add-to-lru-tasks',
        payload: {
          projectId,
          tasks: res?.data?.data?.tasks,
          sectionCounts: res?.data?.data?.sectionCounts
        }
      })
    } catch (error) {
      dispatch({
        type: 'task-error',
        payload: error,
      });
    }
  };
};

export const setTasks = (tasks = {}) => {
  return (dispatch) => {
    dispatch({
      type: 'tasks',
      payload: tasks
    })
  }
}

export const getSectionTasks = (sectionId, query, filters = {}) => {
  config.params = { page: query };

  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      // Filter out null or empty values
      const filteredFilters = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => {
          if (key === 'assignedTo' && Array.isArray(value) && value.length === 0) {
            return false; // Skip empty assignedTo array
          } else if (key === 'assignedTo' && Array.isArray(value)) {
            // Extract only _id from assignedTo array
            return true;
          }
          return value !== null && value !== '' && value !== undefined;
        })
      );

      // Build query string based on filteredFilters
      let queryString = '';
      if (Object.keys(filteredFilters).length > 0) {
        queryString = '?' + Object.entries(filteredFilters)
          .map(([key, value]) => {
            if (Array.isArray(value) && key === 'assignedTo') {
              // Extract only _id from assignedTo array
              const assignedToIds = value.map(item => item._id);
              return `${key}=${assignedToIds.join(',')}`;
            }
            return `${key}=${value}`;
          })
          .join('&');
      }

      const res = await axios.get(`${baseUrl}/tasks/section/${sectionId}${queryString}`, config);
      dispatch({
        type: 'appendtasks',
        payload: res.data.data,
      });
    } catch (error) {
      console.log("--- error", error)
      dispatch({
        type: 'task-error',
        payload: error,
      });
    }
  };
};

export const getSectionCounts = (projectId, query) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      const res = await axios.get(`${baseUrl}/tasks/${projectId}/sections/count`, config);
      dispatch({
        type: 'section-counts',
        payload: res.data?.data,
      });
    } catch (error) {
      console.log("--- error", error)
      dispatch({
        type: 'task-error',
        payload: error,
      });
    }
  };
};

export const updateQueryState = (state) => {
  return (dispatch) => {
    dispatch({
      type: 'updateQueryState',
      payload: state,
    });
  };
};

export const updateUserTaskQueryState = () => {
  return (dispatch) => {
    dispatch({
      type: 'updateUserTaskQueryState'
    });
  };
};

export const getSingleTask = (id, trigger) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'singleTaskRequest',
      });
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      const res = await axios.get(`${baseUrl}/tasks/${id}`, config);
      dispatch({
        type: 'singleTask',
        payload: res.data.data,
      });
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Oops! something Went Wrong',
        placement: 'bottomRight',
      });

      dispatch({
        type: 'single-task-error',
        payload: error?.response?.data,
      });
    }
  };
};

export const getTaskByIdentifier = (identifier, trigger, lean, callback) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: 'task-loading',
        payload: true,
      });

      const workspaceId = sessionStorage.getItem('workspaceId') || localStorage.getItem('workspaceId');
      dispatch({
        type: 'singleTaskRequest',
      });
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      const url = lean ? `${baseUrl}/tasks/${workspaceId}/identifier/${identifier}?lean=true` : `${baseUrl}/tasks/${workspaceId}/identifier/${identifier}`;
      const res = await axios.get(url, config);
      if(lean) {
        dispatch({
          type: 'singleTaskLean',
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: 'singleTask',
          payload: res.data.data,
        });  
      }
      if(callback)  callback();
    } catch (error) {
      console.log("---- error", error)
      const message = error?.response?.status === 404 ? 'Task not found!' : 'Oops! something went wrong 123';
      notification.error({
        message,
        placement: 'bottomRight',
      });

      if(callback)  callback(error?.response?.status);

      dispatch({
        type: 'single-task-error',
        payload: error?.response?.data,
      });
      dispatch(isTicketOpen(false));

    }
  };
};

export const getRecurringTasks = (taskId, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      const url =`${baseUrl}/tasks/${taskId}/recurring`;
      const res = await axios.get(url, config);
      callback && callback(res?.data?.data?.tasks);
      dispatch({
        type: 'get-recurring-tasks',
        payload: res.data?.data?.tasks,
      });  
    } catch (error) {
      console.log("---- error", error);
    }
  };
};

export const addTask = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      let taskProperties = data;
      const workspaceId = sessionStorage.getItem('workspaceId') || localStorage.getItem('workspaceId');
      taskProperties.workspace = workspaceId;
      const res = await axios.post(
        `${baseUrl}/tasks/add`,
        taskProperties,
        config
      );
      dispatch({
        type: 'task-success',
        payload: res,
      });
      dispatch({
        type: 'singleTask',
        payload: res.data.data
      });
      notification.success({
        message: 'You just added a new task',
        placement: 'bottomRight',
      });
      callback && callback(res?.data?.data);
      return res?.data?.data;
    } catch (error) {
      console.log('---- error', error)
      dispatch({
        type: 'task-error',
        payload: error,
      });
      notification.error({
        message: error?.response?.data?.message || 'Oops! somthing Went Wrong',
        placement: 'bottomRight',
      });
    }
  };
};

export const deleteTask = (id, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      const res = await axios.delete(`${baseUrl}/tasks/${id}`, config);
      dispatch({
        type: 'task-success',
        payload: res,
      });
      notification.success({
        message: 'You just deleted a task',
        placement: 'bottomRight',
      });
      // dispatch({
      //   type: 'empty-lru'
      // })
      callback();
    } catch (error) {
      dispatch({
        type: 'task-error',
        payload: error,
      });
      notification.error({
        message: 'Oops! somthing Went Wrong',
        placement: 'bottomRight',
      });
    }
  };
};

export const editTask = (data, callback, errorcallback) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: 'update-tasks',
        payload: data
      })
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      if(data?.assignee?.length > 0) {
        data.assignee.map(a => {
          return {assigneeId: a?.assigneeId?._id}
        })
      }
      const res = await axios.put(`${baseUrl}/tasks/edit`, data, config);
      // await dispatch({
      //   type: 'task-success',
      //   payload: res,
      // });
      // dispatch({
      //   type: 'update-tasks',
      //   payload: res?.data?.data
      // })

      if (callback) callback();

      if (res?.data?.data?.projectId) {
        dispatch(getProjectById(res?.data?.data?.projectId, 'editTask'))
      }
    } catch (error) {
      console.log("---- err", error)
      dispatch({
        type: 'task-errorr',
        payload: error,
      });
      if(errorcallback) errorcallback();
      dispatch(getSingleTask(data?._id));
    }
  };
};

export const setTaskProperties = (val) => {
  return {
    type: 'taskProperties',
    payload: val,
  };
};

export const getUserTasks = (filters = {}, query, wsId) => {
  config.params = query;
  return async (dispatch) => {
    try {
      const workspaceId = wsId || sessionStorage.getItem('workspaceId') || localStorage.getItem('workspaceId');

      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      const updatedFilters = {
        ...filters,
        ...filters.customFields,
      };
      delete updatedFilters.customFields;

      // Filter out null or empty values
      const filteredFilters = Object.fromEntries(
        Object.entries(updatedFilters).filter(([key, value]) => {
          if (Array.isArray(value) && value.length === 0) {
            return false; // Skip empty assignedTo array
          } else if (Array.isArray(value)) {
            // Extract only _id from assignedTo array
            return true;
          }
          return value !== null && value !== '' && value !== undefined;
        })
      );

      // Build query string based on filteredFilters
      let queryString = '';
      if (Object.keys(filteredFilters).length > 0) {
        queryString = '?' + Object.entries(filteredFilters)
          .map(([key, value]) => {
            if (Array.isArray(value)) {
              // Extract only _id from assignedTo array
              const vals = value.map(item => item._id);
              return `${key}=${vals.join(',')}`;
            }
            return `${key}=${value}`;
          })
          .join('&');
      }


      // const res = await axios.get(`${baseUrl}/tasks/${workspaceId}/user`, config);
      const res = await axios.get(`${baseUrl}/tasks/${workspaceId}/filter${queryString}`, config);
      dispatch({
        type: 'userTasks',
        payload: res.data.data,
      });
      dispatch({
        type: 'updateUserTaskQueryState'
      })
    } catch (error) {
      dispatch({
        type: 'task-error',
        payload: error,
      });
    }
  };
};

export const saveUserFilter = (filters = {}, defaultFilter) => {
  return async (dispatch) => {
    try {
      const workspaceId = sessionStorage.getItem('workspaceId');

      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      const body = {
        query: filters,
        workspace: workspaceId,
        default: defaultFilter
      }
      const res = await axios.post(`${baseUrl}/filter`, body, config);
      dispatch({
        type: 'selectUserFilter',
        payload: res.data.data,
      });
      notification.success({
        message: 'The filter was saved successfully!',
        placement: 'bottomRight',
      });

    } catch (error) {
      notification.error({
        message: 'Unable to save filter',
        placement: 'bottomRight',
      });
    }
  };
};


export const editPreTaskSections = (data, tasks) => {
  const newTasks = tasks.map((task) => {
    if (task._id === data._id) {
      task.section = data.section;
    }
    return task;
  });
  return {
    type: 'pre-task-update',
    payload: newTasks,
  };
};

export const uploadTaskUrl = (data={}, callback) => {
  return async () => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      data.workspaceId = sessionStorage.getItem("workspaceId");
      const res = await axios.post(`${baseUrl}/tasks/uploadUrl`, data, config);
      callback && callback();
      return res.data?.data || {};
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message || 'Oops! something Went Wrong',
        placement: 'bottomRight',
      });
    }
  };
};

export const downloadTaskUrl = (data, callback) => {
  return async () => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/tasks/downloadUrl`, data, config);
      callback && callback();
      return res.data?.data || {};
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteTaskFile = (taskId, fileId, callback) => {
  return async () => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.delete(`${baseUrl}/tasks/${taskId}/files/${fileId}`, config);
      callback && callback();
      return res.data?.data || {};
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteProjectTasksFromLRU = (projectId) => {
  return async (dispatch) => {
    dispatch({
      type: 'delete-from-lru-tasks',
      payload: projectId
    })
  }
}

export const addTaskToProject = (taskId, projectId, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.put(`${baseUrl}/tasks/project/add`, { projectId: projectId, taskId, taskId }, config);
      callback && callback();
      dispatch({
        type: 'delete-from-lru-tasks',
        payload: projectId
      })
      dispatch(getTaskByIdentifier(res.data?.data?.updatedTask?.titleIdentifier));
      // dispatch(getProjectById(projectId, 'addTaskToProject'))
      return res.data?.data || {};
    } catch (error) {
      console.log(error);
    }
  };
}

export const removeTaskFromProject = (taskId, projectId, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.put(`${baseUrl}/tasks/project/remove`, { projectId: projectId, taskId }, config);
      // const res = await axios.delete(`${baseUrl}/tasks/${taskId}/project/${projectId}`, config);
      callback && callback();
      dispatch(getTaskByIdentifier(res.data?.data?.titleIdentifier));
      return res.data?.data || {};
    } catch (error) {
      console.log(error);
    }
  };
}

export const addCommentToTask = (comment) => {
  return {
    type: 'add-comment-to-task',
    payload: comment,
  };
}

export const updateTaskInState = (task) => {
  return async (dispatch) => {
    dispatch({
      type: 'update-tasks',
      payload: task
    })
  }

}

export const addSingleTaskToState = (task) => {
  return async (dispatch) => {
    dispatch({
      type: 'add-task',
      payload: task
    })
  }
}

export const removeSingleTaskFromState = (taskId) => {
  return async (dispatch) => {
    dispatch({
      type: 'remove-task',
      payload: taskId
    })
  }
}

export const setFilters = (filters, resetFilters = false) => {
  return async (dispatch) => {
    dispatch({
      type: 'set-filters',
      payload: {
        filters: filters,
        reset: resetFilters
      }
    })
    const projectId = filters?.projectId;
    dispatch(getTasks(projectId, filters));
  }
}

export const setUserTaskFilters = (filters, resetFilters = false) => {
  return (dispatch) => {
    try {
      dispatch({
        type: 'set-user-filters',
        payload: {
          filters: filters,
          reset: resetFilters
        }
      })
      dispatch(getUserTasks(filters));  
    } catch (e) {
      console.log("----- e", e);
    }
  }
}

export const reorderTasks = (fromSection, toSection, taskId, fromIndex, toIndex, projectId) => {
  return (dispatch) => {
    dispatch({
      type: 'reorder-tasks',
      payload: {
        taskId,
        fromSection,
        toSection,
        fromIndex,
        toIndex,
        projectId
      }
    });
    // dispatch(updateSectionTasksCount(fromSection, toSection, taskId, projectId));
  }
}

export const updateTaskPositions = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'update-positions',
      payload: data?.tasks
    });
  }
}

export const clearSingleTask = () => {
  return (dispatch) => {
    dispatch({
      type: 'clearSingleTask'
    })
  }
}

export const deleteCommentFromTask = (messageId, taskId) => {
  return (dispatch) => {
    dispatch({
      type: 'remove-comment-from-single-task',
      payload: {
        messageId,
        taskId
      }
    })
  }
}

export const deleteUserFilterResults = (taskId) => {
  return (dispatch) => {
    dispatch({
      type: 'delete-user-filter-results',
      payload: taskId
    })
  }
}

export const addToUserFilterResults = (task) => {
  return (dispatch) => {
    const currentWS = sessionStorage.getItem("workspaceId");
    if(task?.workspace?.toString() === currentWS?.toString()) {
      dispatch({
        type: 'add-to-user-filter-results',
        payload: task
      })  
    }
  }
}

export const updateSingleTask = (task) => {
  return (dispatch) => {
    dispatch({
      type: 'update-single-task',
      payload: task
    })
  }
}

export const getWorkspaceTasks = (workspaceId, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      workspaceId = workspaceId || sessionStorage.getItem("workspaceId");
      if(!workspaceId) {
        return;
      }
      const res = await axios.get(`${baseUrl}/workspaces/${workspaceId}/tasks`, config);
      callback && callback();
      dispatch({
        type: 'get-dashboard-tasks',
        payload: res.data?.data
      });
      return res.data?.data || {};
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message || 'Oops! something Went Wrong',
        placement: 'bottomRight',
      });
    }
  };
};