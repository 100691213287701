import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Dropdown } from 'antd';
import moment from 'moment-timezone';

import { logout } from '../../../../actions/logoutAction';

import ProjectModal from '../global/ProjectModal';
import RoomPicture from '../global/RoomPicture';
import ConfirmationModal from '../modals/ConfirmationModal';
import { getWorkspaceNotifications } from '../../../../actions/notificationAction';
import {
  getSingleTask,
  getUser,
  deleteProject
} from '../../../../actions';
import UserPreferences from './UserPreferences';
import {
  isChatOpen,
  isChatSettingsOpen,
  isTicketOpen,
  setTeamsState,
} from '../../../../actions/toggleAction';

const MobileTopNavbar = (props) => {
  const notificationOverlayRef = useRef(null);
  const bellIconRef = useRef(null);
  const calenderOverlayRef = useRef(null);
  const param = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { chats } = useSelector((state) => state.chatReducer);
  const chat = chats && chats.find((chat) => chat._id === param.chatId);
  const [userPrefModal, setUserPrefModal] = useState(false);
  const [roomPrefModal, setRoomPrefModal] = useState(false);

  const [navTitle, setNavTitle] = useState('');
  const [navDropdown, setNavDropdown] = useState('');

  const [deleteProjectConfirmationModal, setDeleteProjectConfirmationModal] =
    useState(false);

  const { user: loggedInUser, sidebarOpen, setSidebarOpen } = props;
  const URL = window.location.pathname;

  useEffect(() => {
    const checkIfClickedOutside = (event) => {
      if (
        isNotificationOpen &&
        notificationOverlayRef?.current &&
        !notificationOverlayRef?.current.contains(event.target) &&
        !bellIconRef?.current.contains(event.target)
      ) {
        setIsNotificationOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isNotificationOpen]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isCalendarOpen &&
        calenderOverlayRef.current &&
        !calenderOverlayRef.current.contains(e.target)
      ) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isCalendarOpen]);

  let member = chat?.members.find(
    (member) => member._id !== localStorage.getItem('Id')
  );

  let primaryTimezone =
    loggedInUser &&
    loggedInUser.timezones &&
    loggedInUser.timezones.find((tz) => tz.isPrimary);
  primaryTimezone = primaryTimezone && primaryTimezone.name;
  const sortedTimezones =
    loggedInUser &&
    loggedInUser.timezones &&
    loggedInUser.timezones
      .sort((a, b) => {
        if (a.isPrimary === b.isPrimary) {
          return 0;
        }
        return a.isPrimary ? -1 : 1;
      })
      .map((timezone) => timezone.name);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('workspaceId');
    localStorage.removeItem('organization');
    localStorage.removeItem('toggle');
    localStorage.removeItem('inviteUrl');
    localStorage.removeItem('Id');


    sessionStorage.removeItem('workspaceId');
    sessionStorage.removeItem('organization');

    dispatch(logout());
    window.location.href = '/signin';
  };

  useEffect(() => {
    props.getWorkspaceNotifications();
  }, [dispatch]);


  const items = [
    {
      key: '1',
      label: <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
        <div className="font-medium text-slate-800">{loggedInUser?.fullName}</div>
        <div className="text-xs text-slate-500 italic">{loggedInUser?.email}</div>
      </div>
    },
    {
      key: '2',
      label: <div onClick={() => navigate('/admin-console')}>Settings</div>,
    },
    {
      key: '3',
      label: <div onClick={handleLogout}>Sign Out</div>,
    },
  ];

  const projectDropdownItems = [
    {
      key: '1',
      label:
        <Link
          to={`/${URL.split('/')[1]}/List`}
          className='topNavbarLink'
        >
          List
        </Link>,
      onClick: () => setNavDropdown('List')
    },
    {
      key: '2',
      label:
        <Link
          to={`/${URL.split('/')[1]}/board`}
          className='topNavbarLink'
        >
          Board
        </Link>,
      onClick: () => setNavDropdown('Board')
    },
    {
      key: '3',
      label:
        <Link
          to={`/${URL.split('/')[1]}/chat`}
          className='topNavbarLink'
        >
          Chat
        </Link>,
      onClick: () => setNavDropdown('Chat')
    },
    {
      key: '4',
      label:
        <Link
          to={`/${URL.split('/')[1]}/members`}
          className='topNavbarLink'
        >
          Members
        </Link>,
      onClick: () => setNavDropdown('Dashboard')
    },
    {
      key: '5',
      label:
        <Link
          to={`/${URL.split('/')[1]}/settings`}
          className='topNavbarLink'
        >
          Settings
        </Link>,
      onClick: () => setNavDropdown('Dashboard')
    },
  ];

  const mobileBackButton = () => {
    return (
      <button
        className="text-slate-500 hover:text-slate-600 lg:hidden"
        aria-controls="sidebar"
        aria-expanded={sidebarOpen}
        onClick={(e) => { e.stopPropagation(); setSidebarOpen(!sidebarOpen); }}
      >
        <span className="sr-only">Open sidebar</span>
        <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="5" width="16" height="2" />
          <rect x="4" y="11" width="16" height="2" />
          <rect x="4" y="17" width="16" height="2" />
        </svg>
      </button>
    );
  }

  const isChat = props.phase === 'chat';
  const isProject = props.phase === 'project';
  const isTeam = props.phase === 'team';
  const isWorkspace = props.phase === 'workspace';

  useEffect(() => {
    let name = '';

    if (isChat) {
      name = chat?.roomName !== null && chat?.roomName !== ''
        ? chat?.roomName
        : member?.fullName;
      setNavDropdown(' ');
    } else if (isTeam || isWorkspace) {
      name = (isWorkspace ? 'Workspace' : 'Team') + ' Dashboard';
    } else if (isProject) {
      name = props?.projectData?.title;
    } else {
      name = primaryTimezone && moment().tz(primaryTimezone).format('dddd, MMMM Do YYYY');
    }

    if (isProject) {
      setNavDropdown('List');
    }

    if (name !== navTitle) {
      setNavTitle(name);
    }
  }, [props.phase, props?.projectData]);

  return (
    <div>
      <UserPreferences
        title={'User Preferences'}
        open={userPrefModal}
        onCancel={() => {
          setUserPrefModal(false);
        }}
        user={props.user}
        timezone={primaryTimezone}
        width={'700px'}
        height={'500px'}
      />
      <ProjectModal
        title={'Room Preferences'}
        open={roomPrefModal}
        onCancel={() => {
          setRoomPrefModal(false);
        }}
        body={<RoomPicture room={chat} />}
      />


      <ConfirmationModal
        isModalOpen={deleteProjectConfirmationModal}
        onYesClick={() => {
          // dispatch action for Yes button click
          dispatch(deleteProject(props.projectData && props.projectData._id));
          setDeleteProjectConfirmationModal(false);
          navigate('/');
        }}
        onCancel={() => setDeleteProjectConfirmationModal(false)}
        title={'Delete Project'}
        description={'Are you sure you want to delete this project?'}

        message={'Are you sure you want to delete this project?'}
        project={props.projectData}
        onClose={() => setDeleteProjectConfirmationModal(false)}
      />

      <div className='mobile-top-nav-bar'>
        <div className='d-flex align-center'>
          {!sidebarOpen && mobileBackButton()}
          <div className={`mobile-center-content ${isChat && 'mobile-chat-dropdown'}`} onClick={() => {
            isChat && dispatch(isChatSettingsOpen(true));
          }}>
            <div className='mr-5 font-18 truncate'>
              {navTitle}
            </div>
            <div>
              {isProject && <Dropdown
                trigger={['click']}
                menu={{
                  items: projectDropdownItems,
                }}
                placement='bottom'
                arrow
              >
                <div className='d-flex align-center'>
                  <div className='mr-5 font-14'>
                    {navDropdown}
                  </div>
                  <img
                    src={'/images/icons/black-arrow-down.svg'}
                    style={{
                      cursor: 'pointer',
                      height: '16px',
                      width: '16px',
                    }}
                    alt='icon'
                  />
                </div>
              </Dropdown>}
            </div>
          </div>
        </div>
        <div className='mobile-right-content'>
          <div className='d-flex align-center'>
            <div className=''>
              {/* <Icon name="profile2.png" height="30px" /> */}
              <Dropdown
                trigger={['click']}
                menu={{
                  items,
                }}
                placement='bottomRight'
                arrow
              >
                <div className='flex align-center cursor-pointer'>
                  <img
                    src={`${loggedInUser.avi}`}
                    style={{
                      height: '25px',
                      width: '25px',
                      borderRadius: '50%', // Apply circular mask
                      overflow: 'hidden', // Hide the overflow
                    }}
                    alt='icon'
                  />
                  <div className='font-14 ml-1'>{loggedInUser?.fullName}</div>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  const pathName = window.location.pathname.split('/')[2];
  return {
    getWorkspaceNotifications: () => dispatch(getWorkspaceNotifications()),
    teamsOpener: (arg) => dispatch(setTeamsState(arg)),
    chatOpener: (arg) => dispatch(isChatOpen(arg)),
    taskOpener: (arg) => dispatch(isTicketOpen(arg)),
    getUser: () => dispatch(getUser()),
    getCurrentTask: () =>
      pathName !== 'board' && dispatch(getSingleTask(pathName)),
  };
};

const mapStateToProp = (state) => {
  return {
    user: state.userReducer.user,
    notifications: state.notificationReducer.notifications,
    currentTask: state.taskReducer.singleTask,
    currentWorkspace: state.workspaceReducer.currentWorkspace,
    tasks: state.taskReducer.originalTasks
  };
};
export default connect(mapStateToProp, mapDispatchToProps)(MobileTopNavbar);