import { taskPriority } from '../../../../js/constants';

const subPriorityMenu = (updateFilters) => {
  const items = [];
  taskPriority.map((priority, i) => {
    items.push({
      key: i + 3,
      label: priority.name,
      onClick: () => updateFilters(priority.rank, 'priority')
    });
  });
  return items;
}

const FilterItems = (props) => {

  const {
    statusFilters,
    assignees,
    updateFilters,
    resetFilters,
    dueDateFilters
  } = props;

  return (
    [
      {
        key: 0,
        label: 'Reset filters',
        onClick: () => resetFilters()
      },
      {
        key: 1,
        label: 'Priority',
        children: subPriorityMenu(updateFilters),
      },
      {
        key: 2,
        label: 'Assigned to',
        children: assignees
      },
      {
        key: 3,
        label: 'Status',
        children: statusFilters
      },
      {
        key: 4,
        label: 'Due date',
        children: dueDateFilters
      }
    ]
  );
};

export default FilterItems;
