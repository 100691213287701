import React from "react";

import { BeatLoader, ClipLoader } from 'react-spinners'

import Icon from '../../atoms/global/Icon';

const Loader = (props) => {

  if (props?.newLoader) {
    return (
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
        background: props?.fullScreen && "#d5cfcf5c",
      }}>
        <div className={`absolute top-[40%] left-1/2 ${props?.className}`}>
          <Icon
            name='logo.svg'
            height='30px' />
          <div className="container-loader mt-10 mx-2"></div>
        </div>
      </div>
    )
  }

  return (
    <>
      {
        props.isLoading &&
        <div className='loader-container'>
          {props.type === 'clip' ?
            <ClipLoader
              color={'black'}
              loading={props.isLoading}
              size={props.size}
              aria-label="Loading Spinner"
              data-testid="loader"
            /> :
            <BeatLoader
              color={'black'}
              loading={props.isLoading}
              size={props.size}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          }
        </div>
      }
    </>
  );
};

export default Loader;
