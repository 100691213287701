import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';

import {
  getUserTasks,
  editTask,
  isTicketOpen,
  toggleNewTaskModal,
  isChatOpen
} from '../../../../actions';
import TaskFilter from './TaskFilter/TaskFilter.jsx';
import AssigneeIcon from './TaskFilter/Icons/Assignee.jsx';
import CloseIcon from './TaskFilter/Icons/Close';

import TaskRow from '../ListViews/TaskRow';

import { addToUserFilterResults, deleteUserFilterResults, saveUserFilter, setUserTaskFilters } from '../../../../actions/taskAction';
import { setTeamsState } from '../../../../actions/toggleAction';
import UserIcon from './TaskFilter/Icons/UserIcon';
import HighPriorityIcon from './TaskFilter/Icons/HighPriority';
import MediumPriorityIcon from './TaskFilter/Icons/MediumPriority';
import LowPriorityIcon from './TaskFilter/Icons/LowPriority';
import LowestPriorityIcon from './TaskFilter/Icons/LowestPriority';
import PriorityIcon from './TaskFilter/Icons/Priority';
import CraetorIcon from './TaskFilter/Icons/Creator';
import ProjectIcon from './TaskFilter/Icons/Project';
import ResolutionIcon from './TaskFilter/Icons/Resolution';
import LabelIcon from './TaskFilter/Icons/Label';
import socket from '../../../../js/socket';

import Button from "../../atoms/global/Button";
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

const MyTasks = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedLabels, setSelectedLabels] = useState([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [filteredTasks, setFilteredTasks] = useState(props?.userTasks || []);
  const filters = useSelector((state) => state.taskReducer.userFilters);
  const [isHover, setIsHover] = useState(false);
  const [rowColor, setRowColor] = useState(false);

  useEffect(() => {
    socket.on("TaskAdded", function (task) {
      dispatch(addToUserFilterResults(task));
    });
    socket.on("TaskRemoved", function (taskId) {
      dispatch(deleteUserFilterResults(taskId));
    });

    socket.on('UpdateTaskEvent', function (task) {
      dispatch(addToUserFilterResults(task));
    })

  }, []);

  const handleLabelSelection = async (labels) => {
    let assignees = [];
    let priority = [];
    let projects = [];
    let customFields = {};
    let creator = [];
    let resolution = [];


    if (labels?.length === 0) {
      setFilteredTasks(props?.userTasks || []);
      setSelectedLabels([]);
      dispatch(setUserTaskFilters({
        ...filters, assignedTo: assignees, priority, projects, customFields, creator
      }));
      return;
    }

    labels.forEach((label) => {
      if (label?.type == 'Priority') {
        priority.push({ _id: label?.id });
      } else if (label?.type == 'Assignee') {
        assignees.push({ _id: label?.id });
      } else if (label?.type == 'Creator') {
        creator.push({ _id: label?.id });
      } else if (label?.type == 'Project') {
        projects.push({ _id: label?.id });
      } else if (label?.type == 'Resolution') {
        resolution.push({ _id: label?.id });
      } else if (label?.type == 'customField') {
        const existing = customFields[label?.customFieldId];
        if (Array.isArray(existing)) {
          existing.push({ _id: label?.id });
          customFields[label.customFieldId] = existing;
        } else {
          customFields[label.customFieldId] = [{
            _id: label?.id
          }]
        }
      }

    });

    dispatch(setUserTaskFilters({
      ...filters, assignedTo: assignees, priority, projects, customFields, creator, resolution
    }));
    setSelectedLabels(labels);
  };


  const handlePopupSelection = (isVisible) => {
    setPopupVisible(isVisible);
  };

  const getPriorityData = (p) => {
    const priorityTextMap = {
      p1: { text: 'P1', icon: <HighPriorityIcon /> },
      p2: { text: 'P2', icon: <MediumPriorityIcon /> },
      p3: { text: 'P3', icon: <LowPriorityIcon /> },
      p4: { text: 'P4', icon: <LowestPriorityIcon /> }
    };

    return priorityTextMap[p];
  }

  useEffect(() => {
    if (props?.workspaceFilter) {
      const selectedFilter = props?.workspaceFilter;
      const labels = selectedFilter?.labels || [];
      const updatedLabels = labels.map(label => {
        switch (label?.type) {
          case 'Priority':
            const pData = getPriorityData(label?.id);
            return {
              ...label,
              icon: pData?.icon,
              parentIcon: <PriorityIcon />,
            };

          case 'Creator':
          case 'Assignee':
            return {
              ...label,
              icon: <UserIcon avi={label?.avi} />,
              parentIcon: label?.type === 'Creator' ? <CraetorIcon /> : <AssigneeIcon />,
            };

          case 'Project':
            return {
              ...label,
              parentIcon: <ProjectIcon />,
            };

          case 'Resolution':
            return {
              ...label,
              parentIcon: <ResolutionIcon />,
            };

          case 'customField':
            return {
              ...label,
              parentIcon: <LabelIcon />,
            };

          default:
            return label;
        }
      });

      setSelectedLabels(updatedLabels);

      dispatch(setUserTaskFilters(props?.workspaceFilter?.query));
    }

  }, [props?.workspaceFilter]);

  useEffect(() => {
    setFilteredTasks(props?.userTasks);
  }, [props?.userTasks])

  const markDone = (taskId, isCompleted) => {
    dispatch(
      editTask(
        {
          _id: taskId,
          isCompleted,
        },
        () => {
          setTimeout(() => {
            dispatch(setUserTaskFilters(props.userFilters));
          }, 1000); // 1000 milliseconds (1 second)
          // dispatch(setUserTaskFilters(props.userFilters));
        }
      )
    );
  };


  const handleScroll = (e) => {
    const scrollableElement = e.target;

    // Check if the scrollbar is at the bottom
    if (
      scrollableElement?.scrollTop + scrollableElement?.clientHeight >=
      (scrollableElement?.scrollHeight - 10)
    ) {
      dispatch(getUserTasks(props?.userFilters, props?.userQuery));
    }
  };

  const handleSaveUserFilter = (defaultFilter) => {
    dispatch(saveUserFilter(props?.userFilters, defaultFilter))
  }

  const handleResetFilter = () => {
    setSelectedLabels([]);
    dispatch(setUserTaskFilters({}, true))
    notification.success({
      message: 'The filters were reset!',
      placement: 'bottomRight',
    });
    // dispatch(saveUserFilter(props?.userFilters, defaultFilter))
  }

  const handleTaskClick = async (e, task) => {
    if (isHover) {
      return;
    }

    setRowColor(true)
    const params = new URLSearchParams(location.search);
    params.delete('msgId'); // Remove the specified parameter
    params.set('selectedTask', task.titleIdentifier)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    await dispatch(isChatOpen(false));
    await dispatch(setTeamsState(false));

    // Use navigate to replace the current URL
    navigate(newUrl);

    await dispatch(isTicketOpen(true));
  };

  return (
    <div className="flex flex-col h-full" style={{ overflow: 'hidden' }}>
      {/* Content */}

      <div className="pl-4 sm:pl-6 lg:pl-8 py-8 w-full max-w-9xl mx-auto">
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-5 mr-8">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">All Tasks ✨</h1>
            <div className='font-16 text-slate-500'>Task Count: <span className='ml-2 font-20 text-slate-800'>{props?.userTasksCount || 0}</span></div>
          </div>

          {/* Add card button */}
          <Button
            name="Add Task"
            src="addblack.svg"
            height="12px"
            class="font-14"
            onClick={() => dispatch(toggleNewTaskModal({
              value: true
            }))}
            style={{ width: 'max-content', height: 10 }}
          />
        </div>

        {/* Filters */}
        <div className="flex justify-between">
          <TaskFilter
            selectedLabels={selectedLabels}
            onLabelSelection={handleLabelSelection}
            onPopupSelection={handlePopupSelection}
            users={props.users}
            teams={props.teams}
            projects={props?.currentWorkspace?.myProjects}
            handleSaveUserFilter={handleSaveUserFilter}
            handleResetFilter={handleResetFilter}
          />
          <ul className="flex flex-wrap -m-1">
            {selectedLabels.map((item, index) => (
              <li className="m-1 flex align-center">
                <button
                  onClick={() => {
                    const indexToRemove = selectedLabels.findIndex(
                      (it) => it.id === item.id && it.type === item.type
                    );

                    if (indexToRemove !== -1) {
                      selectedLabels.splice(indexToRemove, 1);
                    }
                    handleLabelSelection(selectedLabels)
                  }}
                  className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-black shadow-sm duration-150 ease-in-out">
                  {item.name || item.type} is {item.text}                    <CloseIcon />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Credit cards */}
      <div className="flex flex-col w-full max-w-9xl mx-auto ml-3" style={{ overflow: 'scroll', height: "calc(100vh - 260px)" }} onScroll={(e) => handleScroll(e)}>
        <div className={`grid gap-0 shrink-0 md:w-[80vw] w-[300vw] sticky top-0 bg-white border-slate-200 border-t border-b`} style={{ gridTemplateColumns: 'repeat(12, minmax(0, 1fr))', zIndex: 9 }}>
          <div className='col-span-1 pl-4 py-2 font-14 sticky left-0 top-0 z-20 z-10 bg-white border-slate-200 border-r border-l' style={{ zIndex: 9 }}>
            Completed
          </div>
          <div className='col-span-6  pl-4 border-slate-200 border-r py-2 font-14'>
            Title
          </div>
          <div className='col-span-1 pl-4 border-slate-200 border-r py-2 font-14'>
            Date
          </div>
          <div className='col-span-1 pl-4 border-slate-200 border-r py-2 font-14'>
            Assignee
          </div>
          <div className='col-span-1 pl-4 border-slate-200 border-r py-2 font-14'>
            Priority
          </div>
          <div className='col-span-1 pl-4 border-slate-200 border-r py-2 font-14'>
            Comments
          </div>
          <div className='col-span-1 pl-4 border-slate-200 border-r py-2 font-14'>
            Subtasks
          </div>
        </div>

        {/* Row */}
        {filteredTasks?.map(task => {

          const calendarDate = task.dueDate ? moment.utc(new Date(task.dueDate)) : '';
          const openedTask = window.location?.search?.split('=')?.[1] || '';
          const isTaskOpen = task.titleIdentifier === openedTask;

          return (
            <div
              key={task?._id}
              onMouseOver={() => setRowColor(task?._id)}
              onMouseLeave={() => setRowColor(false)}
              className={`grid grid-cols-12 gap-0 shrink-0 md:w-[80vw] w-[300vw] border-slate-200 border`}
              onClick={(e) => handleTaskClick(e, task)}>
              <TaskRow
                setIsHover={setIsHover}
                task={task}
                rowColor={task?._id == rowColor}
              />
            </div>
          )
        })}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userTasks: state.taskReducer.userTasks,
    users: state.userReducer.users,
    user: state.userReducer.user,
    userQuery: state.taskReducer.userQuery,
    teams: state.teamReducer.getTeams,
    userFilters: state.taskReducer.userFilters,
    userTasksCount: state.taskReducer.userTasksCount,
    workspaceFilter: state.workspaceReducer.workspaceFilter,
    currentWorkspace: state.workspaceReducer.currentWorkspace
  };
}

export default connect(mapStateToProps)(MyTasks);
