const MAX_STEPS = 3;

export const toggleOnboarding = (val) => {
  return async (dispatch) => {
    dispatch({
      type: "set-toggle-onboarding",
      payload: val,
    });
  };
};

export const setOnboardingStep = (step, callBack) => {
  return async (dispatch) => {
    dispatch({
      type: "set-onboarding-step",
      payload: step,
    });
    if(step >= MAX_STEPS && callBack) {
      callBack();
    }
  };
};