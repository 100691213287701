import React from 'react';

import { deleteProjectUser } from '../../../../actions/projectUserActions';
import { connect, useDispatch } from 'react-redux';
import { getProjectById, updateProjectUsers, updateTeamUsers, updateWorkspaceUsers } from '../../../../actions';

import { Dropdown } from 'antd';

const ParticipantCard = (props) => {

  const dispatch = useDispatch();

  const { avi = '', fullName = '', email = '', role = '', _id } = props?.participant;

  const updateRole = async (role, userId) => {
    let newRole = {
      "users": [
        {
          "userId": userId,
          "role": role
        }
      ]
    }

    if (props?.phase === 'Workspace') {
      newRole.workspaceId = props?.workspaceId;
      await dispatch(updateWorkspaceUsers(newRole));
    } else if (props?.phase === 'Team') {
      newRole.teamId = props.teamId;
      await dispatch(updateTeamUsers(newRole));
    } else {
      newRole.projectId = props?.projectId;
      await dispatch(updateProjectUsers(newRole));
    }
  }

  return (
    <div className='participant-card hover:bg-slate-100 px-2'>
      <div className='card-left-flex'>
        <img src={avi} alt='' className='card-image object-cover h-10 w-10' />
        <div className='card-text'>
          <div style={{ color: '#434343', fontSize: '18px' }}>{fullName}</div>
          <div style={{ color: '#747474', fontSize: '14px' }} >{email}</div>
        </div>
      </div>

      <div className='card-right-flex'>
        <Dropdown
          trigger={['click']}
          menu={{
            items: [
              {
                key: 'guest',
                label: 'Guest',
                onClick: () => updateRole('guest', _id)
              },
              {
                key: 'member',
                label: 'Member',
                onClick: () => updateRole('member', _id)
              },
              {
                key: 'admin',
                label: 'Admin',
                onClick: () => updateRole('admin', _id)
              },
            ],
          }}
          placement='bottom'
          arrow
          disabled={role === 'owner'}
        >
          <div className='flex align-center justify-center'>
            <div
              className='mr-2'
              style={{
                color: '#747474',
                fontSize: '14px',
                fontWeight: 400,
              }}>{role}</div>
            {role !== 'owner' && <img
              src={'/images/icons/show-more.svg'}
              alt="icon"
            />}
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteProjectUser: (userId, callback) => {
    dispatch(deleteProjectUser(userId), callback());
  },
  getProjectById: (projectId) => dispatch(getProjectById(projectId)),
});

export default connect('', mapDispatchToProps)(ParticipantCard);
