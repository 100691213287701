import React from 'react';
import { Dropdown } from 'antd';

import { getRandomColor } from '../../../../js/helper';
import '../../../../assets/style/global.css';

const RoleSelect = (props) => {

  const setNewMemberRole = (role, i) => {
    const newMembers = [...props.members];
    if (newMembers[i] !== undefined) {
      newMembers[i].role = role;
      props.setMembers(newMembers);
    }
  };

  const getMenuItems = (index) => {
    const items = [];
    props.roles.map(role => {
      items.push({
        key: index + role,
        label: <div onClick={() => setNewMemberRole(role, index)}>{role}</div>,
      })
    });
    return items;
  };

  return (
    <div style={{ maxHeight: props.maxHeight || 'auto', overflowY: 'scroll' }}>
      {props.members.map((data, i) => {
        const name = data.fullName || data.label || '';
        return (
          <div className='roles-row d-flex'>
            <div className='card-left-flex'>
              {data?.avi ?
                <img src={data?.avi} alt='' className='card-image object-cover h-10 w-10' /> :
                <>
                
                </>}
              <div className='card-text'>
                <div style={{ color: '#434343', fontSize: '18px' }}>{name}</div>
                <div style={{ color: '#747474', fontSize: '14px' }} >{data?.email}</div>
              </div>
            </div>
            <Dropdown
              trigger={['click']}
              menu={{
                items: getMenuItems(i),
              }}
              placement='bottom'
              arrow
            >
              <div className='flex align-center justify-center'>
                <div
                  className='mr-2'
                  style={{
                    color: '#747474',
                    fontSize: '14px',
                    fontWeight: 400,
                  }}>{data?.role}</div>
                {data?.role !== 'owner' && <img
                  src={'/images/icons/show-more.svg'}
                  alt="icon"
                />}
              </div>
            </Dropdown>
          </div>
        );
      })}
    </div>
  );
};


export default RoleSelect;
