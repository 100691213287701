import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { InputNumber, Slider } from 'antd';

import {
  teamPlanDetails,
} from '../UI/molecules/pricing/data';

import Plan from '../UI/molecules/pricing/Plan';
import ProductNavBar from '../UI/molecules/upper-navbar/ProductNavBar'

import { createCheckoutSession } from '../../actions';
import { APPLICATION_NAME } from '../../js/constants';

const PricingUsers = () => {

  const navigate = useNavigate();
  document.title = `Pricing - ${APPLICATION_NAME}`
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState(1);

  const onChange = (newValue) => {
    setInputValue(newValue);
  };

  const isMonthly = useSelector((state) => state.paymentReducer?.plan?.isMonthly);
  const planPrice = useSelector((state) => state.paymentReducer?.plan?.price);

  const priceTable = () => (
    <div className="pricing-table">
      <Plan
        name={`Team (${isMonthly ? 'Monthly' : 'Yearly'})`}
        description={isMonthly ? "For mid-sized teams managing diverse projects efficiently" : "For mid-sized teams managing diverse projects efficiently"}
        buttonLabel="Go Back"
        buttonAction={() => {
          navigate('/pricing', { replace: true });
        }}
        price={<div>${planPrice}</div>}
        details={teamPlanDetails}
        cadence={'MONTH'}
      />
      <div className='user-quanitity-select' style={{ paddingTop: 30 }}>
        <div>
          <div className='font-24'>Select the amount of Seats</div>
          <div className='font-12' style={{ margin: '20px 0' }}>Seats are the amount of people who you are part of your company</div>
          <div className='price-checkout'>
            ${Math.round((planPrice * inputValue) * 100) / 100} <span className='font-12'>USD</span>
            <div className='font-14'>
              Per member, per month (before tax)
            </div>
          </div>
          <div className='d-flex align-center' style={{ justifyContent: 'center' }}>
            <div className='font-16'>Seats:</div>
            <InputNumber
              min={1}
              max={60}
              style={{ margin: '0 16px' }}
              value={inputValue}
              onChange={onChange}
            />
          </div>
          <Slider
            min={1}
            max={60}
            onChange={onChange}
            value={typeof inputValue === 'number' ? inputValue : 0}
          />
        </div>
        <div>
          <div
            onClick={() => {
              document.title = `Payment - ${APPLICATION_NAME}`

              if (!localStorage.getItem('token')) {
                navigate('/signin');
              } else {
                dispatch(createCheckoutSession({
                  organizationId: sessionStorage.getItem('organization') || localStorage.getItem('organization'),
                  license: 'team',
                  frequency: isMonthly ? 'monthly' : 'yearly',
                  seats: inputValue
                }));
              }
            }}
            className="product-button-primary">
            Go to Checkout
          </div>
          <div className='font-10 mt-5'>
            By clicking 'Go to Checkout' you agree to our
            <span className='link-button'
              onClick={() => window.open('https://workflo.com/terms-and-conditions/', '_blank').focus()} >
              Terms of Service
            </span>
          </div>
        </div>
      </div>
    </div >
  )

  return (
    <div
      className='w-full'
      style={{ background: '#fafafa', flexDirection: 'column', height: '100vh' }}>
      <ProductNavBar />
      <div className='pt-10 pricing-container w-full h-full' style={{ flexDirection: 'column', overflowY: 'scroll' }}>
        <h3 className="pricing-title">Choose The Plan That Suits You</h3>
        {priceTable()}
      </div>
    </div>
  );
};

export default PricingUsers;
