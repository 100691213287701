import axios from 'axios';
import { notification } from 'antd';
import { UnauthorizedHandler } from '../js/helper/UnauthorizedHandler';

const baseUrl = process.env.REACT_APP_API_URL;
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
const config = {
  headers: { Authorization: '' },
};

export const verifyUser = (data, callBack) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      const res = await axios.put(`${baseUrl}/register/confirmEmail`, data, config);
      dispatch({
        type: 'successUser',
        payload: res,
      });
      dispatch({
        type: 'confirmEmail'
      })
      if(callBack) {
        callBack();
      }
      //   callBack();
      notification.success({
        message: 'User Verified',
        placement: 'bottomRight',
      });
    } catch (error) {
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: 'error',
        payload: error,
      });
      notification.error({
        message: 'Failed to verify user',
        placement: 'bottomRight',
      });
    }
  };
};

export const getUsers = (workspaceId) => {
  return async (dispatch) => {
    try {
      workspaceId = workspaceId || sessionStorage.getItem('workspaceId') || localStorage.getItem('workspaceId');
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      const res = await axios.get(`${baseUrl}/users/workspaces/${workspaceId}`, config);
      dispatch({
        type: 'success-get',
        payload: res.data.data,
      });
    } catch (error) {
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: 'error',
        payload: error,
      });
    }
  };
};

// get user by id
export const getUser = (userId, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      if(!userId) {
        userId = localStorage.getItem('Id');
      }
      const res = await axios.get(`${baseUrl}/user/${userId}`, config);
      dispatch({
        type: 'user',
        payload: res.data.data,
      });
      if(callback) {
        callback(res.data.data);
      }
      dispatch({
        type: "set-toggle-onboarding",
        payload: res?.data?.data?.onboarding !== 'complete',
      });
      return res.data.data;
    } catch (error) {
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: 'error',
        payload: error,
      });
    }
  };
};

export const setUnauthorized = (message) => {
  return (dispatch) => {
    dispatch({
      type: 'unauthorizedError',
      payload: message
    })
  }
}

export const clearUnauthorized = () => {
  return (dispatch) => {
    dispatch({
      type: 'clearUnauthorizedError'
    })
  }
}

export const setForbidden = (message) => {
  return (dispatch) => {
    dispatch({
      type: 'forbiddenError',
      payload: message
    })
  }
}

export const clearForbidden = () => {
  return (dispatch) => {
    dispatch({
      type: 'clearForbiddenError'
    })
  }
}

export const getUsersWithSameWorkSpace = () => {
  return async (dispatch) => {
    try {
      const workspaceId = sessionStorage.getItem('workspaceId') || localStorage.getItem('workspaceId');
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      const res = await axios.get(`${baseUrl}/users/workspaces/${workspaceId}`, config);
      dispatch({
        type: 'success-get',
        payload: res.data.data,
      });
    } catch (error) {
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: 'error',
        payload: error,
      });
    }
  };
};

export const editUser = (data, callBack) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.put(`${baseUrl}/user/edit`, data, config);
      await dispatch({
        type: "user-success",
        payload: res,
      });
      callBack();
    } catch (error) {
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: "user-errorr",
        payload: error,
      });
    }
  };
};

export const uploadUserAvi = (data, callBack) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/users/uploadAvi`, data, config);
      // await dispatch({
      //   type: "user-success",
      //   payload: res,
      // });
      callBack && callBack();
      return res.data?.data || {};
    } catch (error) {
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: "user-errorr",
        payload: error,
      });
    }
  };
};

export const getAllUsersForAdminView = () => {
    return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/users/admin/all`, config);
      dispatch({
        type: 'setAllUsers',
        payload: res?.data?.data || []
      })
      return res?.data?.data || []
    } catch (error) {
      console.log("----- error", error);
      console.log("----- error status code", error.response.status);
      
      UnauthorizedHandler(dispatch, error);
    }
  };
}