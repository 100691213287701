
import React, { useState, useRef, useEffect } from "react";

const MenuContext = (props) => {
  const [clicked, setClicked] = useState(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });

  const refr = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        clicked &&
        refr.current &&
        !refr.current.contains(e.target)
      ) {
        setClicked(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [clicked]);

  const { children, items } = props;

  return (
    <>
      <div
        onContextMenu={(e) => {
          e.preventDefault();
          setClicked(true);
          setPoints({
            x: e.pageX,
            y: e.pageY,
          });
        }}
        onBlur={() => {
          setClicked(false);
        }}
      >
        {children}
      </div>
      {clicked && (
        <div className="context-menu" style={{ top: points.y, left: `${points.x}px`, zIndex: 9999999999 }} ref={refr}>
          {items && items.map((item, key) => {
            return (
              <div
                onClick={() => {
                  item.onClick();
                  setClicked(false);
                }}
                className="d-flex align-center context-menu-item"
              >
                {item.name}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
export default MenuContext;