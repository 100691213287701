import * as roomTypes from "../actions/types/room.types";

const data = {
  rooms: [],
  room: [],
  roomId: null,
};
const roomReducer = (state = data, action) => {
  switch (action.type) {
    case roomTypes.ROOM_SUCCESS:
      return {
        ...state,
        room: action.payload,
      };
    case roomTypes.ROOM_DELETED:
      return {
        ...state,
        room: action.payload,
      };

    case roomTypes.ROOM_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case roomTypes.ROOM:
      return {
        ...state,
        rooms: action.payload,
      };
    case roomTypes.ROOM_BY_PROJECT_ID:
      return {
        ...state,
        room: action.payload,
      };
    case roomTypes.ROOM_ID:
      return {
        ...state,
        roomId: action.payload,
      };

    case roomTypes.ROOM_BY_ID:
      return {
        ...state,
        roomById: action.payload,
      };

    default:
      return state;
  }
};
export default roomReducer;
