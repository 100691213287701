import React from 'react';

import {
  Dropdown
} from 'antd';

import { PRIORITY_COLORS, PRIORITY_LABEL, taskPriority } from '../../../../js/constants';

const PriorityDropdown = (props) => {
  const { task, setIsHover, onClick, small } = props;

  return (
    <div>
      <Dropdown
        onMouseEnter={() => setIsHover && setIsHover(true)}
        onMouseLeave={() => setIsHover && setIsHover(false)}
        overlayStyle={{
          padding: 0
        }}
        trigger={['click']}
        menu={{
          items:
            taskPriority.map((priority, i) => {
              const html =
                <div
                  onMouseEnter={() => setIsHover && setIsHover(true)}
                  className={`priority-pill font-14 ${i == 0 && 'text-slate-700'}`}
                  style={{ background: PRIORITY_COLORS[priority?.rank] }}>
                  {priority?.name}
                </div>
              return ({
                key: priority?.rank,
                label: html,
                onClick: () => onClick(priority)
              })
            })
        }}
        placement='bottom'
      >
        <div
          className={`hover:opacity-90 cursor-pointer ${small ? 'h-5 border-radius10 py-1 px-3 flex align-center justfify-center' : 'priority-pill w-20 space-x-1'}`}
          style={{ background: PRIORITY_COLORS[task?.priority] }}>
          {task?.priority != 'nill' ?
            <div className={`${small ? 'font-12' : 'font-14'} w-full text-center`}>
              {PRIORITY_LABEL[task?.priority]}
            </div> :
            <div className='font-16 bg-slate-100 border-radius10 text-slate-500 w-10 cursor-pointer text-center'>
              --
            </div>}
        </div>
      </Dropdown>
    </div>
  );
};

export default PriorityDropdown;
