import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;
const config = {
  headers: { Authorization: '' },
};

//get messages
export const getMessages = (roomId, query, callback) => {
  config.params = query;
  return async (dispatch) => {
    if (!roomId) {
      return;
    }
    let res;
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      res = await axios.get(`${baseUrl}/chatrooms/${roomId}/messages`, config);
      dispatch({
        type: 'getChatMessages',
        payload: {
          roomId,
          messages: res?.data?.data?.messages,
          totalMessages: res?.data?.data?.totalMessages,
          activities: res?.data?.data?.activities,
          currentPage: query?.page
        },
      });
      callback(res?.data?.data);
    } catch (error) {
      dispatch({
        type: 'getChatMessagesFailure',
        payload: res,
      });
    }
  };
};

export const getSingleMessage = (messageId) => {
  return async (dispatch) => {
    let res;
    try {
      if (!messageId) {
        return;
      }
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      res = await axios.get(`${baseUrl}/messages/${messageId}`, config);
      dispatch({
        type: 'getSingleMessage',
        payload: res?.data?.data,
      });
      // callback(res?.data?.data);
    } catch (error) {
      console.log("----> error", error)
      dispatch({
        type: 'getSingleMessagesFailure',
        payload: res,
      });
    }
  };
};

export const addChatMessage = (chatRoomId, message) => {
  return (dispatch) => {
    dispatch({
      type: 'addChatMessage',
      payload: { chatRoomId, message },
    });
  };
};

export const setScrollPosition = () => {
  return (dispatch) => {
    dispatch({
      type: 'setScrollPosition',
      payload: 1,
    });
  };
};

export const updateQueryState = (state) => {
  return (dispatch) => {
    dispatch({
      type: 'updateQueryState',
      payload: state,
    });
  };
};

export const clearMessagesState = (roomId) => {
  return (dispatch) => {
    dispatch({
      type: 'clearMessagesState',
      payload: roomId,
    });
  };
};

export const putMessage = (roomId, msgId) => {
  return (dispatch) => {
    dispatch({
      type: 'putMessage',
      payload: { roomId, msgId },
    });
  };
};

export const editMessageDetails = ({ roomId, msgId, messageDescription }) => {
  return (dispatch) => {
    dispatch({
      type: 'editMessage',
      payload: { roomId, msgId, messageDescription },
    });
  };
};

export const updateSingleMessage = (msg) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_SINGLE_MESSAGE',
      payload: msg,
    });
  };
};

export const addChatReply = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'ADD_CHAT_REPLY',
      payload: data,
    });
  };
};

export const editChatReply = (data) => {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_CHAT_REPLY',
      payload: data,
    });
  };
};

export const deleteChatReply = (message) => {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_CHAT_REPLY',
      payload: message
    });
  };
};

export const deleteSingleMessage = (msg) => {
  return (dispatch) => {
    dispatch({
      type: 'DELETE_CHAT_MESSAGE',
      payload: msg,
    });
  };
};