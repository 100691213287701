import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';

import {
  isChatOpen,
  isTicketOpen,
} from '../../../../actions';

import { setTeamsState } from '../../../../actions/toggleAction';
import TaskRow from '../ListViews/TaskRow';

const ListViewCard = (props) => {
  const [isHover, setIsHover] = useState(false);
  const [rowColor, setRowColor] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { task, index } = props;

  const openTicket = (e) => {
    console.log("----- openTicket")
    e.preventDefault();
    if (!isHover) {
      setRowColor(true)
      const params = new URLSearchParams(location.search);
      params.delete('msgId'); // Remove the specified parameter
      params.set('selectedTask', task.titleIdentifier)
      // Construct the new URL with updated parameters
      const newUrl = `${location.pathname}?${params.toString()}`;

      // Use navigate to replace the current URL
      navigate(newUrl);

      dispatch(isTicketOpen(true));
      dispatch(isChatOpen(false));
      dispatch(setTeamsState(false));
    }
  };

  const openedTask = window.location?.search?.split('=')?.[1] || '';
  const isTaskOpen = task.titleIdentifier === openedTask;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    width: isDragging && '100vw',
    // change background colour if dragging
    background: isDragging && 'rgb(241 245 249)',
    gridTemplateColumns: 'repeat(13, minmax(0, 1fr))',
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <Draggable
      key={task._id}
      draggableId={task._id}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`grid grid-cols-13 gap-0 shrink-0 md:w-[100vw] w-[300vw] border-slate-200 border`}
          onClick={openTicket}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )        
        }

          onMouseOver={() => setRowColor(true)}
          onMouseLeave={() => setRowColor(false)}
        >
          <TaskRow
            setIsHover={setIsHover}
            task={task}
            rowColor={rowColor}
            count={props?.count}
          />
        </div>
      )
      }
    </Draggable>
  );
};

const mapStateToProps = (state) => {
  return {
    isTicketOpen: state.toggleReducer.isTicketOpen,
  };
};
export default connect(mapStateToProps)(ListViewCard);
