import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Checkbox } from "antd";

import { fieldTypes } from '../../../../js/constants';
import { getRandomColor } from '../../../../js/helper';

import Icon from '../../atoms/global/Icon';
import {
  addCustomFieldToProject,
  addCustomFieldToWorkspace,
  deleteProjectCustomField,
  getProjectCustomField,
  updateProjectCustomField
} from '../../../../actions';
import '../../../../assets/style/modal.css';

const CustomFieldPanel = (props) => {
  const {
    setShowField,
    showField,
    field,
    workspaceProjects,
    workspace,
  } = props;

  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState(field?.type || 'dropdown');

  const predefinedOptions = fieldTypes?.find(f => f.key == field?.type);

  const [options, setOptions] = useState(field?.possibleValues || predefinedOptions?.options || []);
  const [optionInput, setOptionInput] = useState('');

  const [fieldName, setFieldName] = useState(field?.name || '');
  const [isUpdate, setIsUpdate] = useState(field?.isUpdate);

  const [selectedProject, setSelectedProject] = useState();
  const [suggestion, setSuggestion] = useState(field?.suggestion || '');

  const [multi, setMulti] = useState(field?.multi || false);
  const [saveToWorkspace, setSaveToWorkspace] = useState(false);

  const projectid = selectedProject?._id;

  useEffect(() => {
    dispatch(getProjectCustomField(projectid))
  }, []);

  useEffect(() => {
    setSelectedType(field?.type || 'dropdown');
    setFieldName(field?.name || '');
    setOptions(field?.possibleValues || predefinedOptions?.options || []);
    setIsUpdate(field?.isUpdate);
    setSuggestion(field?.suggestion);
    setMulti(field?.multi);
    setSelectedProject(field?.project);
  }, [field])


  const resetOptions = () => {
    setSelectedType(field?.type || 'dropdown');
    setSaveToWorkspace(false);
    setFieldName('');
    setOptionInput('');
    setIsUpdate();
    setOptions([]);
    setSelectedProject();
  }

  const onClose = () => {
    resetOptions();
    setShowField(false);
  };

  const isButtonDisabled = false;

  const footer = () => {
    return (
      <div
        className={`${isButtonDisabled && 'disabled'} text-center border-radius10`}
        onClick={() => {

          let newOptions = [];
          if (options) {
            newOptions = options.map((obj) => {
              delete obj.key;
              return obj;
            });
          }

          if (!!isUpdate) {
            dispatch(updateProjectCustomField(field?._id, {
              name: fieldName,
              possibleValues: newOptions,
              projectId: projectid
            }, async () => {
              await dispatch(getProjectCustomField(projectid))
              onClose();
            }))
          } else {
            dispatch(addCustomFieldToProject({
              name: fieldName,
              entityId: projectid,
              entityType: "project",
              type: selectedType,
              possibleValues: newOptions,
              suggestion,
              multi
            }, async () => {
              if (saveToWorkspace) {
                dispatch(addCustomFieldToWorkspace({
                  name: fieldName,
                  entityId: workspace?._id,
                  entityType: "workspace",
                  type: selectedType,
                  possibleValues: newOptions,
                  suggestion,
                  multi
                }));
              }
              await dispatch(getProjectCustomField(projectid))
              resetOptions();
              onClose();
            }))
          }
        }}>
        <div className={`flex justify-center w-full p-2 rounded text-white bg-blue-400 hover:bg-blue-300 cursor-pointer`}>
          {`${isUpdate ? 'Update' : 'Add'}`}
        </div>
      </div>
    );
  };

  return (
    <div className={`min-h-[58vh] border-l-slate-200 border-l-2 pl-5 flex flex-col justify-between ${showField ? 'w-1/3' : 'w-0 pl-0 opacity-0'}`}>
      <div>
        <div className='w-full flex align-center justify-between mb-5'>
          <div className='flex align-center'>
            <Icon
              name='arrow-left.svg'
              height='24px'
              onClick={() => {
                onClose();
              }}
            />
            <div className='font-16 ml-5'>{`${isUpdate ? 'Update' : 'Add'}`} Custom Field</div>
          </div>
          {isUpdate && <Icon
            name='delete-red.svg'
            height='24px'
            onClick={() => {
              dispatch(deleteProjectCustomField(showField?._id, projectid));
            }}
          />}
          {!field?.isWorkspace && !isUpdate && <div className='flex align-center justify-center px-2 cursor-pointer' onClick={() => setSaveToWorkspace(!saveToWorkspace)}>
            <Checkbox checked={saveToWorkspace} />
            <div className='font-14 ml-3'>Save to workspace</div>
          </div>}
        </div>
        <div className='w-full overflow-y-scroll mt-3'>
          <div className="space-y-3 w-full mb-4">
            <label>Project</label>
            <Dropdown
              className={`flex items-center space-x-4 hover:bg-slate-50 px-5 py-2`}
              trigger={['click']}
              menu={{
                items: workspaceProjects?.map(project => ({
                  key: project?._id,
                  label: project?.title,
                  onClick: async () => {
                    setSelectedProject(project);
                    // await dispatch(getProjectById(project?._id, (newProject) => {
                    //   setSelectedProject(newProject);
                    // }));
                  }
                })),
              }}
              placement='bottom'
            >
              <div className='w-full flex align-center justify-between bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out"'>
                <div className='font-14'>{selectedProject ? selectedProject?.title : 'Select Project'}</div>
                <img
                  src={'/images/icons/show-more.svg'}
                  alt="icon"
                />
              </div>
            </Dropdown>
          </div>
          <div className='flex align-center justify-between mb-6'>
            <div className="space-y-3 w-2/3 mr-3">
              <label>Label</label>
              <input
                type='text'
                value={fieldName}
                placeholder='Name your custom field'
                onChange={e => setFieldName(e?.target?.value)}
                className="w-full flex items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out" />
            </div>
            <div className="space-y-3 w-32">
              <label>Field Type</label>
              <Dropdown
                menu={{
                  items: fieldTypes?.map(item => ({
                    key: item?.key,
                    label: item?.label,
                    onClick: () => setSelectedType(item?.key)
                  }))
                }}
                trigger={['click']}
                placement='bottom'>
                <div className='w-full flex align-center justify-between bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out"'>
                  <div className='font-14'>{selectedType}</div>
                  <img
                    src={'/images/icons/show-more.svg'}
                    alt="icon"
                  />
                </div>
              </Dropdown>
            </div>
          </div>
          {(selectedType === 'dropdown' || selectedType === 'status') && <div className="mb-8">
            <div className='font-14 mb-3'>Options:</div>

            <div style={{ maxHeight: '30vh', overflowY: 'scroll' }}>
              {options && options.map((option, i) => {
                return (
                  <div className='option-field-row py-2 hover:bg-gray-100'>
                    <div className='option-field-row-label'>{option.label || option || ''}</div>
                    <div className='d-flex align-center'>
                      <Icon
                        name='delete-red.svg'
                        height='26px'
                        pl='10px'
                        onClick={() => {
                          const x = options.slice();
                          x.splice(i, 1);
                          setOptions(x || []);
                        }}
                      />
                    </div>
                  </div>
                )
              })}
            </div>

            <div className='d-flex align-center mt-1'>
              <input
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    if (!!optionInput) {
                      const x = options.slice();
                      x.push({
                        key: Math.random(),
                        label: optionInput || '',
                        metadata: {
                          color: getRandomColor(options.length || 0)
                        }
                      })
                      setOptions(x || []);
                      setOptionInput('');
                    }
                  }
                }}
                onChange={e => setOptionInput(e?.target?.value)}
                value={optionInput}
                placeholder={`Option ${options?.length + 1}`}
                className="w-full flex items-center bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out" />

              <Icon
                name='add-square.svg'
                height='40px'
                pl='10px'
                onClick={() => {
                  const x = options?.slice();

                  if (optionInput == '') {
                    return
                  }

                  x?.push({
                    key: Math.random(),
                    label: optionInput,
                    metadata: {
                      color: getRandomColor(options.length || 0)
                    }
                  })
                  setOptions(x || []);
                  setOptionInput('');
                }}
              />
            </div>
          </div>}
        </div>
      </div>
      {footer()}
    </div>
  );
};


export default CustomFieldPanel;
