import * as notificationTypes from './types/notification.types.js';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;
const config = {
  headers: { Authorization: '' },
};

export const getWorkspaceNotifications = (query, callback) => {
  const workspaceId = sessionStorage.getItem("workspaceId") || localStorage.getItem("workspaceId");

  config.params = query;
  return async (dispatch) => {
    let res;
    try {
      dispatch({
        type: notificationTypes.GET_NOTIFICATION_REQUEST,
      });
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      res = await axios.get(`${baseUrl}/notifications/${workspaceId}`, config);
      dispatch({
        type: notificationTypes.GET_NOTIFICATION_SUCCESS,
        payload: res.data,
      });
      if(typeof callback == 'function') {
        callback(res?.data?.data);
      }
      return res.data.notifications;
    } catch (error) {
      console.log("---- error", error)
      dispatch({
        type: notificationTypes.GET_NOTIFICATION_FAILURE,
        payload: res.data,
      });
    }
  };
};

export const deleteNotifications = (userId, callback) => {
  const workspaceId = sessionStorage.getItem("workspaceId") || localStorage.getItem("workspaceId");
  return async (dispatch) => {
    let res;
    try {
      dispatch({
        type: notificationTypes.DELETE_NOTIFICATION_REQUEST,
      });
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      res = await axios.delete(`${baseUrl}/notifications/${workspaceId}`, config);
      console.log("Response : ", res)
      dispatch({
        type: notificationTypes.DELETE_NOTIFICATION_SUCCESS,
        payload: res.data.notifications,
      });
      callback()
    } catch (error) {
      dispatch({
        type: notificationTypes.DELETE_NOTIFICATION_FAILURE,
        payload: res.data,
      });
    }
  };
};

//update View Notification
export const updateViewNotification = (body) => {
  return async (dispatch) => {
    let res;
    try {
      dispatch({
        type: notificationTypes.UPDATE_NOTIFICATION_REQUEST,
      });
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      res = await axios.put(`${baseUrl}/notifications/update`, body, config);
      dispatch({
        type: notificationTypes.UPDATE_NOTIFICATION_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: notificationTypes.UPDATE_NOTIFICATION_FAILURE,
        payload: res.data,
      });
    }
  };
};

export const clearNotification = (body) => {
  return async (dispatch) => {
    let res;
    try {
      dispatch({
        type: notificationTypes.UPDATE_NOTIFICATION_REQUEST,
      });
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      res = await axios.put(`${baseUrl}/notifications/clear`, body, config);
      if(!body?.isViewed) {
        dispatch({
          type: notificationTypes.UPDATE_TOTAL_UNREAD_COUNT
        });  
      }

    } catch (error) {
      dispatch({
        type: notificationTypes.UPDATE_NOTIFICATION_FAILURE,
        payload: res.data,
      });
    }
  };
};

export const updateQueryState = (state) => {
  return (dispatch) => {
    dispatch({
      type: notificationTypes.UPDATE_QUERY_STATE,
      payload: state,
    });
  };
};

export const updateNotificationState = (state) => {
  return (dispatch) => {
    dispatch({
      type: notificationTypes.UPDATE_ONE_NOTIFICATION,
      payload: state
    })
  }
}
