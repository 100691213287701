import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Button from "../../atoms/global/Button";
import Icon from '../../atoms/global/Icon';
import ConfirmationModal from '../../molecules/modals/ConfirmationModal';

import {
  getWorkspaces,
  deleteWorkspace,
  switchWorkspace,
  getTeamsByWorkspaceId,
  getChatRooms,
  clearSingleTask,
  getWorkspaceDefaultFilter,
  getUsers,
  getWorkspaceProjects
} from '../../../../actions';

import { getWorkspaceNotifications } from "../../../../actions/notificationAction";

function MyWorkspaces({ workspaces, user, currentWorkspace, isOrg = false }) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [workspaceId, setWorkspaceId] = useState('');

  useEffect(() => {
    if (workspaces?.length <= 0) {
      dispatch(getWorkspaces('lean'));
    }
  }, [])

  const deleteWorkspaceOnClick = async () => {
    if (workspaces?.length > 1 && workspaceId) {
      await dispatch(deleteWorkspace(workspaceId));
    }
  };

  const switchWorkspaceClick = (workspace) => {
    dispatch(switchWorkspace(workspace, () => {
      sessionStorage.setItem("workspaceId", workspace._id);
      localStorage.setItem("workspaceId", workspace._id);
      sessionStorage.setItem("organization", workspace.organization);
      localStorage.setItem("organization", workspace.organization);

      // dispatch(getTeamsByWorkspaceId(workspace._id));
      // dispatch(getWorkspaceDefaultFilter(workspace._id));
      // dispatch(getChatRooms());
      // dispatch(getUsers(workspace._id));
      // dispatch(getWorkspaceProjects(workspace._id))
      // dispatch(getWorkspaceNotifications())
      // dispatch(clearSingleTask());
    }));
  }

  return (
    <div className="p-6">
      <ConfirmationModal
        title='Delete Workspace?'
        description='Are you sure you want to delete this workspace?'
        isModalOpen={open}
        onCancel={() => {
          setOpen(false);
          setWorkspaceId('');
        }}
        onYesClick={() => {
          setOpen(false);
          deleteWorkspaceOnClick();
        }}
      />
     {!isOrg && <div className='flex align-center justify-between  mb-5'>
        <h2 className="text-2xl text-slate-800 font-bold">My Workspaces</h2>
        <Button
          name="Add workspace"
          src="addblack.svg"
          height="12px"
          class="font-14"
          onClick={() => navigate('/create-workspace')}
          style={{ width: 'max-content', height: 10 }}
        />
      </div>}
      {/* Connected Apps cards */}
      <section className="pb-6 border-b border-slate-200">
        <div className="grid grid-cols-12 gap-6">
          {/* Card 1 */}
          {workspaces && workspaces.map((workspace, i) => (
            <div className="hover:bg-slate-100 col-span-full xl:col-span-6 2xl:col-span-4 bg-white shadow-md rounded-sm border border-slate-200">
              {/* Card content */}
              <div className="flex flex-col h-full p-5">
                <div className="grow">
                  <header className="flex items-center justify-between mb-4">
                    <div className='flex items-center'>
                      <div className="w-10 h-10 rounded-full shrink-0 bg-gradient-to-tr from-indigo-500 to-indigo-300 mr-3">
                        <svg className="w-10 h-10 fill-current text-white" viewBox="0 0 40 40">
                          <path d="M26.946 18.005a.583.583 0 00-.53-.34h-6.252l.985-3.942a.583.583 0 00-1.008-.52l-7 8.167a.583.583 0 00.442.962h6.252l-.984 3.943a.583.583 0 001.008.52l7-8.167a.583.583 0 00.087-.623z" />
                        </svg>
                      </div>
                      <h3 className="text-lg text-slate-800 font-semibold">{workspace?.title}</h3>
                    </div>
                    {workspace?.createdBy === user?._id && <div onClick={() => {
                      setWorkspaceId(workspace?._id);
                      setOpen(true);
                    }}>
                      <Icon
                        name='delete-red.svg'
                        height='26px' />
                    </div>}
                  </header>
                  <div className="text-sm">Short Form: {workspace?.shortForm}</div>
                </div>
                {/* Card footer */}
                <footer className="mt-4">
                  <div className="flex flex-wrap justify-between items-center">
                    {/* Left side */}
                    <div className="flex space-x-3">
                      <div className="flex items-center text-slate-400">
                        <svg className="w-4 h-4 shrink-0 fill-current mr-1.5" viewBox="0 0 16 16">
                          <path d="M14.14 9.585a2.5 2.5 0 00-3.522 3.194c-.845.63-1.87.97-2.924.971a4.979 4.979 0 01-1.113-.135 4.436 4.436 0 01-1.343 1.682 6.91 6.91 0 006.9-1.165 2.5 2.5 0 002-4.547h.002zM10.125 2.188a2.5 2.5 0 10-.4 2.014 5.027 5.027 0 012.723 3.078c.148-.018.297-.028.446-.03a4.5 4.5 0 011.7.334 7.023 7.023 0 00-4.469-5.396zM4.663 10.5a2.49 2.49 0 00-1.932-1.234 4.624 4.624 0 01-.037-.516 4.97 4.97 0 011.348-3.391 4.456 4.456 0 01-.788-2.016A6.989 6.989 0 00.694 8.75c.004.391.04.781.11 1.166a2.5 2.5 0 103.86.584z" />
                        </svg>
                        <div className="text-sm text-slate-500">{workspace?.taskCounter || 0} tasks</div>
                      </div>
                    </div>
                    {/* Right side */}
                    {currentWorkspace?._id === workspace?._id ? <button className="btn-sm flex align-center text-emerald-500">
                      <svg className="w-3 h-3 shrink-0 fill-current mr-2" viewBox="0 0 12 12">
                        <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                      </svg>
                      <span>Active</span>
                    </button> :
                      <button className="btn-sm flex align-center text-indigo-500 font-16" onClick={() => switchWorkspaceClick(workspace)}>
                        <span>Set as workspace</span>
                      </button>
                    }
                  </div>
                </footer>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default MyWorkspaces;