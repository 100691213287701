import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  editTask,
  getSingleTask,
  isTicketOpen,
  setTaskProperties,
} from '../../../../actions';
import { dayMonth } from '../../../../js/functions';
import { Calendar, Tooltip } from 'antd';
import socket from '../../../../js/socket';
import { useNavigate } from 'react-router-dom';
import Icon from '../../atoms/global/Icon';

const SubTask = (props) => {

  const {
    taskTitle,
    isCompleted,
    _id,
    parentTaskId,
    dueDate
  } = props?.subTask;

  const [iseditingTitleEnabled, setIseditingTitleEnabled] = useState(false);
  const [isCalender, setIsCalender] = useState(false);
  const [titleValue, setTitleValue] = useState(taskTitle);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   socket.on("UpdateTaskEvent", function (id) {
  //     // console.log(id);
  //     // dispatch(getSingleTask(parentTaskId));
  //   });
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      editTask(
        {
          _id: _id,
          taskTitle: titleValue,
        },
        () => {
          dispatch(getSingleTask(parentTaskId));
        }
      )
    );
  };
  const newDate = new Date();
  newDate.setHours(0, 0, 0, 0);

  const refr = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isCalender && refr.current && !refr.current.contains(e.target)) {
        setIsCalender(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isCalender]);

  return (
    <li className="flex align-center px-2 justify-between border-t border-slate-200 py-2 w-full cursor-pointer hover:bg-gray-50" onBlur={() => setIseditingTitleEnabled(false)}>
      <div className='flex align-center w-80'>
        <div
          onClick={() => {
            dispatch(
              editTask(
                {
                  _id: _id,
                  isCompleted: !isCompleted,
                },
                () => {
                  dispatch(getSingleTask(parentTaskId));
                }
              )
            );
          }}
        >
          <svg className={`w-3 h-3 shrink-0 fill-current ${isCompleted ? 'text-emerald-500' : 'text-slate-900'} mr-2 mt-1`} viewBox="0 0 12 12">
            <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
          </svg>
        </div>
        {iseditingTitleEnabled ? (
          <form
            onSubmit={handleSubmit}
            onBlur={handleSubmit}
            style={{ display: 'inline' }}
            className='w-full'
          >
            <input
              type='text'
              value={titleValue}
              onChange={(e) => setTitleValue(e.target.value)}
              className='w-full py-1 bg-gray-200'
              style={{ border: 'none', outline: 'none' }}
              autoFocus
            />
          </form>
        ) : (
          <div
            onClick={() => setIseditingTitleEnabled(true)}
            onBlur={() => setIseditingTitleEnabled(false)}
            className={`text-sm text-slate-600 ${isCompleted && 'text-slate-400 line-through'}`}>
            {taskTitle || '--'}
          </div>
        )}
      </div>
      <div className='d-flex '>
        {dueDate && !isCalender ? (
          <span
            className={`font-12 cursor-pointer`}
            style={{
              color: `${dueDate < newDate.toISOString() ? 'red' : ''
                }`,
              paddingTop: '3px',
            }}
            onClick={() => setIsCalender(true)}
          >
            {dayMonth(dueDate)}
          </span>
        ) : (
          <div
            className='font-12'
            style={{ color: '#ADADAD', position: 'relative' }}
          >
            {isCalender ? (
              <div
                className='task-card'
                style={{
                  position: 'absolute',
                  width: '300px',
                  top: '0px',
                  right: '10px',
                  zIndex: '5',
                  background: 'white'
                }}
                ref={refr}
              >
                <div className='site-calendar-demo-card'>
                  <Calendar
                    fullscreen={false}
                    onChange={(e) => {
                      dispatch(
                        editTask(
                          {
                            _id: _id,
                            taskTitle: taskTitle,
                            dueDate: e.format('YYYY-MM-DD'),
                          },
                          () => {
                            dispatch(getSingleTask(parentTaskId));
                          }
                        )
                      );
                    }}
                  />
                </div>
              </div>
            ) : props.taskProperties.dueDate !== '' ? (
              <div className='cursor-pointer'>
                <Icon name='calendar.svg' pl='10px' pr='0px' />
              </div>
            ) : (
              ''
            )}
            <>
              {props.taskProperties.dueDate === '' && (
                <div
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setIsCalender(!isCalender)}
                >
                  <div className='d-flex align-center'>
                    <Tooltip title='Add due date'>
                      <svg
                        width='25'
                        height='25'
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M13.6109 5.8335H12.7774V5H12.2219V5.8335H7.77764V5H7.22211V5.8335H6.38907C5.62316 5.8335 5 6.4566 5 7.22249V13.611C5 14.3769 5.62314 15 6.38907 15H13.6109C14.3768 15 15 14.3769 15 13.611V7.22207C14.9995 6.4562 14.3764 5.83358 13.6109 5.83358V5.8335ZM14.444 13.6104C14.444 14.0696 14.0701 14.4434 13.6109 14.4434H6.38907C5.92935 14.4434 5.55553 14.0696 5.55553 13.6104V8.61097H14.444V13.6104ZM14.444 8.05548H5.55553V7.22198C5.55553 6.76229 5.92935 6.38848 6.38907 6.38848H7.22262V6.94398H7.77766V6.38897H12.2219V6.94447H12.7774V6.38897H13.6109C14.0702 6.38897 14.444 6.76229 14.444 7.22196L14.444 8.05548Z'
                          fill={'black'}
                        />
                        <circle
                          cx='10'
                          cy='10'
                          r='9.75'
                          stroke={'black'}
                          strokeWidth='0.5'
                          strokeDasharray='2 2'
                        />
                      </svg>
                    </Tooltip>
                  </div>
                </div>
              )}
            </>
          </div>
        )}
        <div>
          {' '}
        </div>
      </div>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    taskProperties: state.taskReducer.taskProperties,
  };
};
export default connect(mapStateToProps)(SubTask);
