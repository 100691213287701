import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import DashboardCard from '../molecules/workspace/DashboardCard';
import DashboardTasks from '../molecules/workspace/DashboardTasks';
import DashboardTeamsProjects from '../molecules/workspace/DashboardTeamsProjects';
import DashboardOverview from '../molecules/workspace/DashboardOverview';
import DashboardMembers from '../molecules/workspace/DashboardMembers';

import WelcomeBanner from '../atoms/workspace/WelcomeBanner';

import {
  getActiveWorkspaceDetails,
  getTeamsByWorkspaceId,
  getUserTasks,
  getWorkspaceTasks,
  toggleWorkspaceFieldsModal,
} from '../../../actions';
import { getWorkspaceNotifications } from '../../../actions/notificationAction';

const WorkspaceDashboard = (props) => {
  const { user, currentWorkspace, isTicketOpen, dashboardTasks, allTeams, showOnboarding, onBoardingStep } = props;

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [workspace, setWorkspace] = useState(props?.currentWorkspace);
  const [viewIndex, setViewIndex] = useState(1);
  const [teams, setTeams] = useState(allTeams);

  const taskCount = dashboardTasks?.totalTasks || 0;

  useEffect(() => {
    // dispatch(getWorkspaceDashboardData());
    // dispatch(getActiveWorkspaceDetails());
    // dispatch(getUserTasks(props?.userFilters, props?.userQuery));
    // dispatch(getWorkspaceTasks(workspace._id));
    // dispatch(getTeamsByWorkspaceId(workspace._id, true));
  }, []);

  useEffect(() => {
    setWorkspace(props?.currentWorkspace);
  }, [props?.currentWorkspace]);

  let projects = [];
  const [projectData, setProjectData] = useState([]);

  useEffect(() => {

    setTeams(allTeams);

    allTeams && allTeams?.map((team, i) => {
      team?.projects?.forEach(project => {
        projects.push(project);
      })
    });

    setProjectData(currentWorkspace?.projects);

    if (user?._id && user?.onboarding === 'complete') { //currentWorkspace?.projects?.length === 0) {
      setViewIndex(1);      
    } else if(user?._id && user?.onboarding !== 'complete') {
      setViewIndex(2);
    } else if (workspace?.members?.length === 1) {
      setViewIndex(3);
    }

  }, [allTeams]);

  return (
    <div className="p-4 lg:pl-5 lg:pr-4 py-6 w-full max-w-9xl mx-auto overflow-y-scroll h-full overflow-x-hidden">

      {/* Welcome banner */}
      <WelcomeBanner name={user?.fullName} projects={projectData} onPress={() => dispatch(toggleWorkspaceFieldsModal(true))} workspace={currentWorkspace} user={user} />
      {/* Cards */}
      <div className="grid grid-cols-12 gap-4">
        {/* <DashboardCard
          isTicketOpen={isTicketOpen}
          heading='Overview'
          icon='overview.svg'
          subHeading='Highlights'
          onPress={() => setViewIndex(0)}
          isSelected={viewIndex === 0} /> */}
        <DashboardCard
          isTicketOpen={isTicketOpen}
          heading='Overview'
          icon='task.svg'
          subHeading={`${taskCount} workspace tasks`}
          onPress={() => setViewIndex(1)}
          isSelected={viewIndex === 1} />
        <DashboardCard
          isTicketOpen={isTicketOpen}
          heading='Workspace Projects'
          icon='time.svg'
          subHeading={`View details and join projects`}
          isSelected={viewIndex === 2}
          onPress={() => setViewIndex(2)} />
        <DashboardCard
          isTicketOpen={isTicketOpen}
          heading='Members'
          icon='members.svg'
          subHeading={`${workspace?.members?.length || 0} workspace members`}
          isSelected={viewIndex === 3}
          onPress={() => setViewIndex(3)}
        />
      </div>
      {viewIndex === 0 && (
        <DashboardOverview notifications={props?.notifications} dashboardTasks={dashboardTasks} />
      )}
      {viewIndex === 1 && (
        <DashboardTasks
          taskCount={taskCount}
          dashboardTasks={dashboardTasks}
          workspace={workspace}
          isTaskOpen={isTicketOpen}
          notifications={props?.notifications}
          user={user} />
      )}
      {viewIndex === 2 && (
        <DashboardTeamsProjects teams={teams} projects={projectData} userId={user?._id} showOnboarding={showOnboarding} onBoardingStep={onBoardingStep} />
      )}
      {viewIndex === 3 && (
        <DashboardMembers workspace={workspace} user={user} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  taskCount: state.taskReducer.userTasksCount,
  user: state.userReducer.user,
  currentWorkspace: state.workspaceReducer.currentWorkspace,
  teams: state.teamReducer.getTeams,
  allTeams: state.teamReducer.allTeams,
  userQuery: state.taskReducer.userQuery,
  userFilters: state.taskReducer.userFilters,
  isTicketOpen: state.toggleReducer.isTicketOpen,
  dashboardTasks: state.taskReducer.dashboardTasks,
  notifications: state.notificationReducer.notifications,
  tasks: state.taskReducer.tasks,
  showOnboarding: state.onboardingReducer.showOnboarding,
  onBoardingStep: state.onboardingReducer.onBoardingStep
});

export default connect(mapStateToProps)(WorkspaceDashboard);
