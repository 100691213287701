import '../../../../../../assets/style/taskFilter.css';

const LowestPriorityIcon = () => {
  return (
    <svg
      className="filter-icon"
      style={{ marginRight: '8px' }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="currentColor"
      aria-hidden="true"
    >
      <rect x="1" y="8" width="3" height="6" rx="1" fillOpacity="0.4"></rect>
      <rect x="6" y="5" width="3" height="9" rx="1" fillOpacity="0.4"></rect>
      <rect x="11" y="2" width="3" height="12" rx="1" fillOpacity="0.4"></rect>
    </svg>
  );
};

export default LowestPriorityIcon;
