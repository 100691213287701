const data = {
  success: '',
  error: '',
  user: {},
  users: [],
  unauthorized: false,
  forbidden: false,
  forbiddenMessage: '',
  unAuthorizedMessage: '',
  allUsers: []
};

const userReducer = (state = data, action) => {
  switch (action.type) {
    case 'successUser':
      return {
        ...state,
        success: action.payload,
      };

    case 'success-get':
      return {
        ...state,
        users: action.payload,
      };

    case 'userError':
      return {
        ...state,
        error: action.payload,
      };

    case 'user':
      return {
        ...state,
        user: action.payload,
      };
    
    case 'confirmEmail': {
      return {
        ...state,
        user: {...state.user, isVerified: true}
      }
    }
    case 'unauthorizedError':
      return {
        ...state,
        unauthorized: true,
        unAuthorizedMessage: action.payload
      }

    case 'clearUnauthorizedError':
      return {
        ...state,
        unauthorized: false
      }

    case 'forbiddenError':
      return {
        ...state,
        forbidden: true,
        forbiddenMessage: action.payload
      }

    case 'clearForbiddenError':
      return {
        ...state,
        forbidden: false
      }
    
    case 'setAllUsers': 
      return {
        ...state,
        allUsers: action.payload
      }
    default:
      return state;
  }
};
export default userReducer;
