import React from 'react';
import Icon from '../../../UI/atoms/global/Icon';

function DashboardCard(props) {

  const { heading, subHeading, onPress, icon, isSelected, isTicketOpen } = props;

  let classname = `xl:col-span-${isTicketOpen ? '12' : '4'} lg:col-span-4`;

  return (
    <div
      onClick={onPress}
      className={classname + ` cursor-pointer hover:border-indigo-400 shadow-lg col-span-full h-28 rounded-lg border-2 ${isSelected ? 'border-indigo-400 shadow-indigo-100' : 'border-slate-200'} flex align-center`}>
      <div className={`bg-indigo-200 h-16 w-16 rounded-full ml-5 lg:flex align-center justify-center hidden `}>
        <Icon
          name={icon}
          height='36px'
          width='36px' />
      </div>
      <div className={`ml-6`}>
        <div className='text-2xl font-light'>{heading}</div>
        <div className={`text-slate-500 font-14 ml-1 ${isSelected && 'underline'}`}>{subHeading}</div>
      </div>
    </div>
  );
}

export default DashboardCard;
