const monthlyStarterPlanDetails = [
  "10MB/file upload limit",
  "100MB total storage limit per workspace",
  "100 task limit",
  "Unlimited workspaces",
  "2 teams",
  "5 projects per team",
  "Chat history for 90 days",
  "3 custom fields"
];

const teamPlanDetails = [
  "All of Free Plan plus:",
  "Unlimited or 1GB/file limit",
  "25GB total storage limit per workspace",
  "Unlimited tasks",
  "Unlimited teams",
  "Unlimited projects per team",
  "Chat history forever",
  "Up to 10 custom fields",
];

const businessPlanDetails = [
  "All of Team Plan plus:",
  "Unlimited storage per workspace",
  "Unlimited custom fields",
  "Video calling",
  "Automations",
  "AI assistant",
  "Custom dashboards",
  "Single Sign-On (SSO)"
];

const enterprisePlanDetails = [
  "All of Business Plan plus:",
  "Video Calling",
  "Calendar Integration",
];

export {
  monthlyStarterPlanDetails,
  teamPlanDetails,
  businessPlanDetails,
  enterprisePlanDetails
};