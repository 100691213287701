// Layout.jsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { notification } from 'antd';

import TaskSidePanel from '../UI/organisms/TaskSidePanel';
import ChatDrawer from '../UI/organisms/ChatDrawer';
import AiSidePanel from '../UI/organisms/AiSidePanel';
import TeamDrawer from '../UI/organisms/TeamDrawer';
import UserChatPanel from '../UI/organisms/UserChatPanel';

import LeftNavBar from '../UI/organisms/LeftNavBar';
import TopNavbar from '../UI/molecules/upper-navbar/TopNavbar';
import MobileTopNavbar from '../UI/molecules/upper-navbar/MobileTopNavbar';

import WorkspaceFieldsModal from '../UI/molecules/modals/WorkspaceFieldsModal';
import DuplicateProjectModal from '../UI/molecules/modals/DuplicateProjectModal';
import TeamsAndChatModal from '../UI/molecules/modals/TeamsAndChatModal';
import CreateProjectModal from '../UI/molecules/modals/CreateProjectModal';
import NewTaskModal from '../UI/molecules/modals/NewTaskModal';
import CustomFieldsModal from '../UI/molecules/modals/CustomFieldsModal';

import {
  getWorkspaceDefaultFilter,
  resendConfirmEmail,
  acceptWorkspaceInvite,
  editUser,
  toggleNewTaskModal,
  getWorkspaceById,
  getWorkspaces,
  toggleNewProjectModal,
  createTeam,
  addChatRoom,
  toggleCustomFieldsModal,
  toggleNewTeamChatsModal,
  getSingleMessage,
  isChatOpen as chatOpener,
  isTicketOpen as ticketOpener
} from '../../actions';
import { getUser, verifyUser, setUnauthorized } from '../../actions/userAction';
import Banner from '../UI/organisms/Banner';
import { logout } from '../../actions/logoutAction';

import socket from '../../js/socket';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'; // You can add other Firebase services as needed
import { getMessaging, getToken } from 'firebase/messaging';
import { APPLICATION_NAME } from '../../js/constants';
import { getWorkspaceDashboardData } from '../../actions/WorkspaceActions';
import { getWorkspaceNotifications } from '../../actions/notificationAction';
import Loader from '../UI/atoms/global/Loader';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Initialize other Firebase services if needed

const Layout = (props) => {
  const {
    teams,
    chats,
    projectCustomFields,
    children,
    phase = 'home',
    currentWorkspace,
    myProjects
  } = props;


  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [user, setUser] = useState(true);
  const [roomName, setRoomName] = useState('');
  const [teamName, setTeamName] = useState('');

  const workspaceId = sessionStorage.getItem("workspaceId") || currentWorkspace?._id;

  const [participants, setParticipants] = useState([
    { _id: localStorage.getItem('Id'), role: 'member' },
  ]);

  const loggedUser = useSelector((state) => state.userReducer.user);
  const modalState = useSelector((state) => state.modalReducer);

  const workspaceFieldsModal = modalState.workspaceFieldsModal;
  const duplicateProjectModal = modalState.duplicateProjectModal;

  const unauthorized = useSelector((state) => state.userReducer.unauthorized);
  const forbidden = useSelector((state) => state.userReducer.forbidden);

  const setCustomFeildsModalOpen = (value) => {
    dispatch(toggleCustomFieldsModal(value));
  }

  const setShowTaskModal = (value) => {
    dispatch(toggleNewTaskModal({
      value: value
    }));
  };

  const setShowProjectModal = (value) => {
    dispatch(toggleNewProjectModal({
      value: value
    }));
  };

  const setIsTeamsChatModalOpen = (value, isTeams) => {
    dispatch(toggleNewTeamChatsModal({
      value: value,
      isTeams: isTeams
    }));
  }

  const createChatRoom = async () => {
    let members = [];
    for (var i = 0; i < participants.length; i++) {
      if (participants[i]._id !== loggedUser._id) {
        members.push(participants[i]._id);
      }
    }
    await dispatch(addChatRoom({ members, roomName, workspaceId }));
    setIsTeamsChatModalOpen(false, false);
    setRoomName('');
  };

  const createNewTeam = async () => {
    await dispatch(createTeam({
      data: {
        workspace: workspaceId,
        title: teamName,
        description: ''
      },
      members: participants
    }));
    setTeamName('');
    setIsTeamsChatModalOpen(false, false);
    await dispatch(getWorkspaceById());
    // await dispatch(getTeamsByWorkspaceId(workspaceId));
  }

  const isChatOpen = useSelector((state) => state.toggleReducer.isChatOpen);
  const isTicketOpen = useSelector((state) => state.toggleReducer.isTicketOpen);
  const isAiOpen = useSelector((state) => state.toggleReducer.isAiOpen);
  const selectedTaskParam = new URLSearchParams(location.search).get('selectedTask');
  
  const singleTask = useSelector((state) => state.taskReducer.singleTask);
  const taskIdentifier = singleTask?.titleIdentifier;
  const activeChatMessage = useSelector((state) => state.toggleReducer.activeChatMessage);
  const { singleMessage } = useSelector((state) => state.messageReducer);
  const { 1: chatId } = window.location.pathname.split("/");
  const msgId = new URLSearchParams(location.search).get('msgId');
  const { query } = useSelector((state) => state.notificationReducer);
  const [isLoading, setIsLoading] = useState(false);

  const { 1: projectId, 2: path } = window.location.pathname.split("/");
  useEffect(() => {
    console.log("----- selectedTaskParam", selectedTaskParam)
    if (isTicketOpen && !selectedTaskParam && taskIdentifier) {
      dispatch(chatOpener(false));
      navigate({ search: `?selectedTask=${taskIdentifier}` }, { replace: true });
    } else if (selectedTaskParam) {
      dispatch(chatOpener(false));
      dispatch(ticketOpener(true));
      console.log('---- singleTask', singleTask)
    } else if (!isTicketOpen) {
      const params = new URLSearchParams(location.search);
      params.delete('selectedTask'); // Remove the specified parameter
      if (isChatOpen && !msgId) {
        params.set('msgId', singleMessage?._id);
      } else if (msgId) {
        dispatch(getSingleMessage(msgId));
        dispatch(chatOpener(true, msgId));
      }

      // Construct the new URL with updated parameters
      const newUrl = `${location.pathname}?${params.toString()}`;

      // Use navigate to replace the current URL
      navigate(newUrl, { replace: true });
    }

  }, [selectedTaskParam, activeChatMessage, projectId, chatId]);

  useEffect(() => {
    const handleAsync = () => {
      if (currentWorkspace?._id) {
        dispatch(getWorkspaceById());
        // Dashboard task is already loading workspace notifications. This is a redundant call.
        // dispatch(getWorkspaceNotifications(query));
        dispatch(getWorkspaces('lean')).then(data => {
          if (data?.length > 0) {
          } else {
            navigate('/select-workspace', { replace: true });
          }
        });
      }
    };

    handleAsync();
  }, [currentWorkspace?._id]);

  useEffect(() => {
    if (unauthorized) {
      localStorage.removeItem('token');
      localStorage.removeItem('workspaceId');
      localStorage.removeItem('organization');
      localStorage.removeItem('toggle');
      localStorage.removeItem('inviteUrl');
      localStorage.removeItem('Id');


      sessionStorage.removeItem('workspaceId');
      sessionStorage.removeItem('organization');

      dispatch(logout());
      window.location.href = '/signin';
    }
  }, [unauthorized])

  // useEffect(() => {
  //   if (forbidden) {
  //     window.location.href = '/';
  //   }
  // }, [forbidden])

  useEffect(() => {
    dispatch(getWorkspaceDashboardData());
    dispatch(getUser(null, (loggedUser) => {
      setUser(loggedUser);

      const userToBeVerified = new URLSearchParams(location.search).get('verifyUser');
      if (userToBeVerified) {
        const user = {
          _id: userToBeVerified,
          isVerified: true,
        };
        dispatch(verifyUser(user, () => {
          const params = new URLSearchParams(location.search);
          params.delete('verifyUser'); // Remove the specified parameter

          // Construct the new URL with updated parameters
          const newUrl = `${location.pathname}?${params.toString()}`;

          // Use navigate to replace the current URL
          navigate(newUrl, { replace: true });

          // setIsUserVerified(true);
          setUser({ ...loggedUser, isVerified: true });
        }));
      }
    }));
    dispatch(getWorkspaceDefaultFilter());
  }, []);

  const isChatSettingsOpen = useSelector((state) => state.toggleReducer.isChatSettingsOpen);

  const toggle = useSelector((state) => state.navReducer.toggle);
  const team = useSelector((state) => state.teamReducer.selectedTeam);
  const project = useSelector((state) => state.projectReducer.getProjects);
  const isTeamDrawerState = useSelector((state) => state.toggleReducer.teamDrawerState);
  const showOnboarding = useSelector(state => state.onboardingReducer.showOnboarding);

  const showNewTaskModal = modalState.newTaskModal.show;
  const showCustomFieldsModal = modalState.customFieldsModal.show;
  const showNewProjectModal = modalState.newProjectModal.show;
  const projectModalTeam = modalState.newProjectModal?.team || '';
  const teamsChatModal = modalState.teamsChatModal

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleResendEmailClick = () => {
    // Dispatch the action when "Resend Email" is clicked
    dispatch(resendConfirmEmail(user));
  };

  const [fcmToken, setFcmToken] = useState(null);

  useEffect(() => {
    if (showOnboarding) {
      navigate('/', { replace: true })
    }
  }, [showOnboarding])

  useEffect(() => {
    document.title = `${APPLICATION_NAME}`;
    const fetchFCMData = async () => {
      try {
        console.log("[fetchFCMData] Fetching FCM Token")
        const messaging = getMessaging(app);
        const permission = await Notification.requestPermission();
        console.log("[fetchFCMData] permission", permission)
        const userId = localStorage.getItem('Id');
        const localUser = await dispatch(getUser(userId));
        console.log("[fetchFCMData] localUser", localUser)

        if (permission === 'granted' && localUser?._id) {
          const token = await getToken(messaging);
          console.log("[fetchFCMData] token", token)

          // Set the token state and send it to your server
          setFcmToken(token);
          // const userId = localStorage.getItem('Id');
          // const localUser = await dispatch(getUser(userId));
          const subscriptions = localUser?.subscriptions || {};
          const fbSubscriptions = subscriptions['firebase'] || [];
          fbSubscriptions.push(token);
          subscriptions['firebase'] = fbSubscriptions;

          await dispatch(
            editUser(
              {
                _id: userId,
                subscriptions
              },
              async () => {
                await dispatch(getUser(userId));
              }
            )
          );
        }
      } catch (error) {
        console.error('Error getting FCM token:', error);
      }
    };

    fetchFCMData(); // Call the async function immediately
  }, []);


  useEffect(() => {
    const url = window.location.href;
    if (!localStorage.getItem('token')) {
      dispatch(setUnauthorized('Session Expired!'));
    } else {
      const splitUrl = url.split('inviteId=');
      if (splitUrl.length > 1) {
        const inviteId = splitUrl[1].split('&')?.[0] || '';
        if (inviteId) {
          dispatch(
            acceptWorkspaceInvite(inviteId, () => {
              notification.success({
                message: 'Workspace Joined!',
                placement: 'bottomRight',
              });
              navigate('/select-workspace', { replace: true });
              socket.emit("invitation-accepted", inviteId)
            })
          );
        }
      }
    }
  }, [navigate]);

  useEffect(() => {
    setSidebarOpen(false);
  }, [window.location.pathname])

  let name = '';

  if (phase === 'team') {
    name = team?.title;
  } else if (phase === 'project') {
    name = project?.title;
  }

  const isMobile = window.screen?.width < 865;

  return (
    isLoading ? <Loader newLoader isLoading={isLoading} className={'left-[40%] mt-[50px]'} /> :
    <>
      
      {/* MODALS */}
      <WorkspaceFieldsModal isModalOpen={workspaceFieldsModal?.show} selectedProjectId={workspaceFieldsModal?.selectedProject} />
      <DuplicateProjectModal user={user} projects={project} isModalOpen={duplicateProjectModal?.show} project={duplicateProjectModal?.selectedProject} />
      
      <CustomFieldsModal
        projectCustomFields={projectCustomFields}
        prefilledData={modalState.customFieldsModal.data}
        isModalOpen={showCustomFieldsModal}
        onClose={() => setCustomFeildsModalOpen(false)} />

      <TeamsAndChatModal
        chats={chats}
        currentWorkspace={currentWorkspace}
        isTeamModal={teamsChatModal.isTeams}
        participants={participants}
        setParticipants={setParticipants}
        isTeamsChatModalOpen={teamsChatModal.show}
        setIsTeamsChatModalOpen={setIsTeamsChatModalOpen}
        onCreate={() => {
          if (teamsChatModal.isTeams) {
            createNewTeam();
          } else {
            createChatRoom();
          }
        }}
        inputName={teamsChatModal.isTeams ? teamName : roomName}
        inputOnChange={teamsChatModal.isTeams ? setTeamName : setRoomName} />

      <CreateProjectModal
        createProjectModalOpen={showNewProjectModal}
        teams={teams}
        defaultTeam={projectModalTeam}
        setCreateProjectModalOpen={setShowProjectModal} />

      <NewTaskModal
        isModalOpen={showNewTaskModal}
        projects={myProjects}
        defaultTitle={modalState.newTaskModal?.title}
        onClose={() => setShowTaskModal(false)}
      />
      {user && user?._id && !user?.isVerified && <Banner message={<div>{`Please verify your email address: ${user?.email}`}<a style={{
        color: 'white',
        textDecoration: 'underline',
        cursor: 'pointer',
        padding: '10px'
      }}
        onClick={handleResendEmailClick}
      >Resend Email</a></div>} />}
      {showOnboarding && <div className='absolute w-[100vw] h-[100vh] bg-slate-900 opacity-70' style={{ zIndex: 40 }}></div>}
      <div className='flex' style={{ height: '100vh', overflow: 'hidden' }}>
        {(!isMobile || (isMobile && sidebarOpen)) && <LeftNavBar setSidebarOpen={setSidebarOpen} isMobile={isMobile} setIsLoading={setIsLoading} />}
        <div className='right-content-container' style={{ width: toggle ? 'calc(100vw - 326px)' : 'calc(100vw - 70px)' }}>
          {!isMobile ?
            <TopNavbar
              name={name}
              projectData={project}
              phase={phase} />
            : (!isTicketOpen && !isChatOpen) && <MobileTopNavbar
              name={name}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              projectData={project}
              phase={phase} />}

          <div className='sub-container'>
            <div style={{ overflow: 'scroll', width: '100%', height: 'calc(100vh - 55px)', opacity: isTeamDrawerState ? '0.4' : '1' }}>
              {children}
            </div>
            {isChatOpen && <ChatDrawer />}
            {isTicketOpen && <TaskSidePanel customClass='chat-drawer' />}
            {isAiOpen && <AiSidePanel />}
            {isTeamDrawerState && <TeamDrawer projectData={project} />}
            {isChatSettingsOpen?.show && <UserChatPanel senderId={isChatSettingsOpen?.chatId} me={user} customClass='chat-drawer' />}
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    currentWorkspace: state.workspaceReducer.currentWorkspace,
    projectCustomFields: state.projectReducer.projectCustomFields,
    chats: state.chatReducer.chats,
    teams: state.teamReducer.getTeams,
    myProjects: state.projectReducer.myProjects,
  };
}

export default connect(mapStateToProps)(Layout);