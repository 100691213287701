import axios from "axios";
import _ from "lodash";
import * as chatTypes from "./types/chat.types.js";
import { notification } from 'antd';
import { getWorkspaceById } from "./WorkspaceActions.js";

const baseUrl = process.env.REACT_APP_API_URL;
const config = {
  headers: { Authorization: "" },
};

export const addChatRoom = (data) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const participants = _.get(data, "members") || [];
      if (participants.length === 1) data.roomName = null;
      const res = await axios.post(`${baseUrl}/createRoom`, data, config);
      dispatch({
        type: chatTypes.ACTIVE_CHAT,
        payload: res.data?.data?._id
      })
      // dispatch(getChatRooms());
      dispatch(getWorkspaceById());
    } catch (error) {
      console.log(error);
    }
  };
};

export const setActiveChat = (chatId) => {
  return {
    type: chatTypes.ACTIVE_CHAT,
    payload: chatId
  };
};


export const unsetActiveChat = () => {
  return {
    type: chatTypes.UNSET_ACTIVE_CHAT
  };
};

//get chatRooms - This action has been retired. Do not use it as the response is very slow. Use getWorkspaceById instead.
export const getChatRooms = () => {
  return async (dispatch) => {
    let res;
    try {
      dispatch({
        type: chatTypes.GET_CHAT_REQUEST,
      });
      const workspaceId = sessionStorage.getItem('workspaceId') || localStorage.getItem('workspaceId');
      const url = workspaceId ? `${baseUrl}/chatrooms/workspaces/${workspaceId}` : `${baseUrl}/chatrooms`;
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      res = await axios.get(url, config);
      dispatch({
        type: chatTypes.GET_CHAT_SUCCESS,
        payload: res.data?.data,
      });
    } catch (error) {
      dispatch({
        type: chatTypes.GET_CHAT_FAILURE,
        payload: res.data,
      });
    }
  };
};

//get chatRoom files
export const getChatRoomFiles = (roomId) => {
  return async (dispatch) => {
    let res;
    try {
      dispatch({
        type: chatTypes.GET_CHAT_REQUEST,
      });
      const url = `${baseUrl}/chatrooms/${roomId}/files`;
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      res = await axios.get(url, config);
      // dispatch({
      //   type: chatTypes.GET_CHAT_SUCCESS,
      //   payload: res.data?.data,
      // });
      return res?.data?.data;
    } catch (error) {
      dispatch({
        type: chatTypes.GET_CHAT_FAILURE,
        payload: res.data,
      });
    }
  };
};

export const updateChatRoomSettings = (roomId, body) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      await axios.put(`${baseUrl}/chatrooms/${roomId}`, { body }, config);
    } catch (error) {
      console.log(error);
    }
  };
};

export const getChatRoomSettings = (roomId) => {
  return async (dispatch) => {
    let res;
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      res = await axios.get(`${baseUrl}/chatrooms/${roomId}`, config);
      console.log('getChatRoomSettings', res);
    } catch (error) {
      console.log(error);
    }
  };
};

export const addMessageToChatRoom = (data, roomId) => {
  return async (dispatch) => {
    let res;
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      res = await axios.post(`${baseUrl}/chatrooms/${roomId}`, data, config);
      // console.log('addMessageToChatRoom', res);
      return res?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };
};
//update chatRooms messages
export const updateChatRoomMessagesReadBy = (chatId, trigger) => {
  console.log("---- updateChatRoomMessagesReadBy", trigger)
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      await axios.post(`${baseUrl}/chat/read`, { chatId }, config);
    } catch (error) {
      console.log(error);
    }
  };
};

export const uploadChatUrl = (data, callBack) => {
  return async () => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/chat/uploadUrl`, data, config);
      callBack && callBack();
      return res.data?.data || {};
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message || 'Oops! something Went Wrong',
        placement: 'bottomRight',
      });
    }
  };
};

export const downloadChatUrl = (data, callBack) => {
  return async () => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/chat/downloadUrl`, data, config);
      callBack && callBack();
      return res.data?.data || {};
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteChatFile = (fileId, callBack) => {
  return async () => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.delete(`${baseUrl}/files/${fileId}`, config);
      callBack && callBack();
      return res.data?.data || {};
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateChatRoomPreferences = (data, callBack) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/chat/user`, data, config);
      // dispatch(getChatRooms());
      dispatch(getWorkspaceById());
      notification.success({
        message: !data?.notifications ? 'Chat Muted' : 'Chat Unmuted',
        placement: 'bottomRight',
      });
      callBack && callBack();
      return res.data?.data || {};
    } catch (error) {
      console.log(error);
    }
  };
};

export const incrementChatUnreadCount = (chat) => {
  const chatId = chat?.roomId;
  const userId = localStorage.getItem("Id");
  const chatSender = chat?.sender?._id;
  return async (dispatch) => {
    if (userId?.toString() !== chatSender?.toString()) {
      dispatch({
        type: chatTypes.INCREMENT_COUNTER,
        payload: chatId
      })
    }
  };

}

export const addUserToChat = (data) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const participants = _.get(data, "members") || [];
      if (participants.length === 1) data.roomName = null;
      const res = await axios.post(`${baseUrl}/chat/user/add`, data, config);
      console.info(res);
      // dispatch({
      //   type: chatTypes.ACTIVE_CHAT,
      //   payload: res.data?.data?._id
      // })
      // dispatch(getChatRooms());
      dispatch(getWorkspaceById());
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeUserFromChat = (data, callback) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const participants = _.get(data, "members") || [];
      if (participants.length === 1) data.roomName = null;
      const res = await axios.post(`${baseUrl}/chat/user/remove`, data, config);
      console.info(res?.data);
      notification.success({
        message: 'User removed from chat!',
        placement: 'bottomRight',
      });

      callback && callback(res?.data);
    } catch (error) {
      console.log(error);
    }
  };
};

