import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import AssigneeSelect from '../../molecules/assignee/AssigneeSelect';
import {
  editTask,
  isChatOpen,
  isTicketOpen,
} from '../../../../actions';

import {
  Tooltip,
} from 'antd';

import { useLocation, useNavigate } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import { setTeamsState } from '../../../../actions/toggleAction';
import FileHolder from '../../organisms/FileHolder';

import { PRIORITY_COLORS, fieldTypes } from '../../../../js/constants';
import CalendarDropdown from '../task-components/CalendarDropdown';
import PriorityDropdown from '../task-components/PriorityDropdown';
import ShowCustomField from '../task-components/ShowCustomField';

const TaskCard = (props) => {

  const { task: propTask, taskProperties } = props;
  const [hoverMe, setHoverMe] = useState(false);
  const [task, setTask] = useState(propTask);
  const [isCompleted, setIsCompleted] = useState(task?.isCompleted);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const openTicket = async (e) => {
    console.log("----- openTicket")
    const params = new URLSearchParams(location.search);
    params.delete('msgId'); // Remove the specified parameter
    params.set('selectedTask', task.titleIdentifier)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    await dispatch(isChatOpen(false));
    await dispatch(setTeamsState(false));

    // Use navigate to replace the current URL
    navigate(newUrl);

    await dispatch(isTicketOpen(true));
  };

  useEffect(() => {
    setIsCompleted(propTask.isCompleted);
    setTask(propTask)
  }, [propTask])

  const data = useSelector((state) => state.userReducer);
  const editHandler = async (data, callback) => {
    await dispatch(editTask(data));

    if (typeof callback === 'function') callback();
  };

  const newDate = new Date();
  newDate.setHours(0, 0, 0, 0);

  const style = {
    hover: {
      border: '1px solid #A2A4A7',
    },
  };

  const showPill = task.priority != 'nill' || task?.isCompleted;
  const openedTask = window.location?.search?.split('=')?.[1] || '';
  const isTaskOpen = task.titleIdentifier === openedTask;

  console.info('HERE', task?.titleIdentifier)

  return (
    <Draggable
      key={task._id}
      draggableId={task._id}
      index={props.index}
    >
      {(provided) => (
        <div
          onMouseEnter={() => setHoverMe(true)}
          onMouseLeave={() => setHoverMe(false)}
          style={{ cursor: 'pointer', ...(hoverMe ? style.hover : null), background: 'red' }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`task-card draggable shadow-lg border-raidus10 ${isTaskOpen ? `${isCompleted ? 'border-emerald-600 shadow-emerald-100' : 'border-indigo-400 shadow-indigo-100'}  border-2` : 'border border-slate-200'}  p-4 pr-2  ${isCompleted && 'task-card-complete'}`}>
          {/* Body */}
          <div className="mb-3 cursor-pointer" onClick={openTicket}>
            {/* Title */}
            <div className='d-flex justify-content-between text-white font-12 text-bold'>
              <div className='flex align-center justify-center'>
                {showPill && !isCompleted ?
                  <PriorityDropdown small={true} onClick={() => { }} task={task} />
                  :
                  isCompleted ? <div
                    className='h-5 border-radius10 py-1 px-3 flex align-center justfify-center'
                    style={{
                      background: `${isCompleted ? 'green' : PRIORITY_COLORS[task.priority]}`,
                      width: 'auto'
                    }}
                  >
                    <div className='flex align-center justfify-center'>
                      <svg className="w-3 h-3 shrink-0 fill-current mr-2" viewBox="0 0 12 12">
                        <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                      </svg>
                      <div>Completed</div>
                    </div>
                  </div> : <></>}
                <span
                  className={`text-slate-400 font-weight-500 ${showPill && 'ml-2'}`}
                  style={{ lineHeight: '14px' }}
                >
                  {task?.titleIdentifier}
                </span>
              </div>
              {task?.parentTaskId && <svg className="w-9 h-9 fill-current text-slate-500" viewBox="0 0 36 36">
                <path d="M23 11v2.085c-2.841.401-4.41 2.462-5.8 4.315-1.449 1.932-2.7 3.6-5.2 3.6h-1v2h1c3.5 0 5.253-2.338 6.8-4.4 1.449-1.932 2.7-3.6 5.2-3.6h3l-4-4zM15.406 16.455c.066-.087.125-.162.194-.254.314-.419.656-.872 1.033-1.33C15.475 13.802 14.038 13 12 13h-1v2h1c1.471 0 2.505.586 3.406 1.455zM24 21c-1.471 0-2.505-.586-3.406-1.455-.066.087-.125.162-.194.254-.316.422-.656.873-1.028 1.328.959.878 2.108 1.573 3.628 1.788V25l4-4h-3z" />
              </svg>}
            </div>
            {/* Content */}
            <div className='flex align-center'>
              <div className="text-bold font-14 pt-4 pb-2">{task?.taskTitle}</div>
            </div>
            {/* {typeof customField === 'string' && customField && <div className='flex align-center m-1 mt-2'>
              <div className='font-14 text-slate-600 max-w-80 truncate mr-2'>{customField}</div>
            </div>} */}
            <ShowCustomField customFields={task?.customFields} task={task} />

            <div>
              {props.task?.files && props.task?.files?.length !== 0 && (
                <div className='mt-3 w-full' onClick={openTicket}>
                  {(() => {
                    for (const file of props.task.files) {
                      const imgExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp'];
                      if (imgExtensions.some((ext) => file?.name?.includes(ext))) {
                        return <FileHolder file={{ name: file?.name, url: file?.signedUrl }} />;
                      }
                    }
                    return <></>;
                  })()}
                </div>
              )}

            </div>

          </div>

          {/* Footer */}
          {/* Left Side */}
          {!task?.parentTaskId && <div className="flex items-center justify-between">
            <CalendarDropdown task={task} x={0} left={true} />

            {/* Right Side */}
            <div className='mr-2'>
              <Tooltip title='Add Assignees'>
                <AssigneeSelect
                  projectUsers={props?.participants || data.users || []}
                  task={task}
                  iconSize={24}
                  editHandler={editHandler} />
              </Tooltip>
            </div>
          </div>}
        </div>
      )
      }
    </Draggable >
  );
};

const mapStateToProps = (state) => {
  return {
    isTicketOpen: state.toggleReducer.isTicketOpen,
    singleTask: state.taskReducer.singleTask,
    taskProperties: state.taskReducer.taskProperties,
  };
};
export default connect(mapStateToProps)(TaskCard);
