
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { signIn, getWorkspaces, getWorkspaceInvites, getUser } from "../../../actions";
import { clearUnauthorized } from "../../../actions/userAction";

const SigninForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  const [check, setCheck] = useState(false);
  const [checkMail, setCheckMail] = useState(false);
  const [checkPass, setCheckPass] = useState(false);
  const [passError, setPassError] = useState(false);

  const [isVerifiedError, setIsVerifiedError] = useState(false);

  useEffect(() => {
    navigate("/signin");
  }, [props.verificationRes, navigate]);

  const handlePass = () => {
    setCheck((check) => !check);
  };

  const emailHandler = (e) => {
    // let regex = new RegExp(
    //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z-]+(?:\.[a-zA-Z0-9-]+)*$/
    // );
    setCheckMail(false);
    setIsVerifiedError(false);
    let mail = e.target.value;
    setEmailAddress(mail);
    // regex.test(mail) ? setCheckMail(false) : setCheckMail(true);
  };

  const passwordHandler = (e) => {
    // let regex = new RegExp(
    //   /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/
    // );
    let passWord = e.target.value;
    setPassword(passWord);
    setPassError(false);
    setCheckPass(false);
    // regex.test(passWord) ? setCheckPass(false) : setCheckPass(true);
    if (e.target.value.length === 0) {
      setCheckPass(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const user_id = localStorage.getItem('Id');
    const fetchdata = await {
      email: emailAddress,
      password: password,
    };
    dispatch(
      signIn(
        fetchdata,
        async () => {
          await dispatch(getWorkspaces('SigninForm'));
          await dispatch(getWorkspaceInvites());
          await dispatch(getUser(user_id));
          dispatch(clearUnauthorized());
          navigate('/select-workspace', { replace: true });
          setCheckMail(false);
          setPassError(false);
        },
        //add call back
        () => {
          setCheckMail(true);
          setIsVerifiedError(true);
        },
        (data) => {
          if (data.response.status === 404) {
            emailAddress ? setCheckMail(true) : setCheckMail(false);
          } else if (data.response.status === 400) {
            password ? setCheckPass(true) : setCheckPass(false);
            setPassError(true);
          }
        }
      )
    );
  };

  return (
    <div className="form-div">
      <form onSubmit={submitHandler}>
        <label className={"font-weight-500 text-darkGray"}>Email</label>
        <br />
        <div className={`flex align-center w-full mt-5`}>
          <input
            type="email"
            name="email"
            className={`w-full outline-none bg-white focus:bg-white ${checkMail ? "inputTag border-red mt-0" : "inputTag mt-0"}`}
            value={emailAddress}
            onChange={emailHandler}
            placeholder="name@company.com"
            required
          />
          {/* {checkMail && (
            <img
              src="/images/icons/exclamationMarkError.svg"
              alt="img"
            />
          )} */}
        </div>
        <div className={checkMail ? "flex align-center d-error mt-2" : "d-none"}>
          <img src="/images/icons/error.svg" className="h-4 w-4 mr-2" alt="error" />
          <label className={"font-16"}>
            {" "}
            {isVerifiedError
              ? "This user doesnt exist. Please verify the email filled. Try another account or Sign up instead"
              : "This email is invalid!"}
          </label>
        </div>

        <div className="mt-5">
          <label className={"font-weight-500 font-18 text-darkGray"}>Password</label>
          <br />
        </div>

        <div className="set-icon">
          <input
            className={checkPass ? "passTag border-red " : "passTag "}
            type={check ? "text" : "password"}
            name="password"
            value={password}
            onChange={passwordHandler}
            placeholder="minimum 8 charaters"
            required
          />
          <img
            className="password-eye"
            src={check ? "/images/icons/noEye.svg" : "/images/icons/eye.svg"}
            alt="img"
            onClick={handlePass}
          />

        </div>
        <div className={checkPass ? "flex align-center d-error mt-2" : "d-none"}>
          <img src="/images/icons/error.svg" className="h-4 w-4 mr-2" alt="error" />
          <label className={"font-16"}>
            {passError && " This password in invalid!"}
          </label>
        </div>
        <br />

        <div className="d-flex align-center justify-between">
          <span>
            <label className={"checkBox"}>
              <input className="check-mark" type="checkbox" id="check" />{" "}
              <span className="mark"></span>
            </label>
            <label className={"font-weight-500 font-16 text-darkGray ml-5"}>
              Remember password
            </label>
          </span>
          <span>
            <Link className={"text-dark font-weight-500 font-16"} to={"/resetpassword"}>
              Forgotten password?
            </Link>
          </span>
        </div>
        <button
          type="submit"
          className={"flex align-center justify-center button-tag font-18 font-weight-500 text-white"}
        >
          Sign in!
        </button>
        <br />
        <div className="d-flex font-weight-500 text-darkGray font-16">
          <label>
            Don't have an account? <Link className="text-blue-500" to={"/signup"}>Sign up</Link>
          </label>
        </div>
      </form>
    </div>
  );
};
const mapStateToProp = (state) => {
  return {
    verificationRes: state.userReducer.success
  };
};
export default connect(mapStateToProp)(SigninForm);
