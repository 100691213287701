
const data = {
  plan: {
    isMonthly: true,
    price: 0
  },
};

const paymentReducer = (state = data, action) => {
  switch (action.type) {
    case 'set-selected-plan':
      return {
        ...state,
        plan: action.payload,
      };

    default:
      return state;
  }
};
export default paymentReducer;
