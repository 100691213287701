import React, { useEffect, useRef, useState } from 'react';
import Icon from '../../atoms/global/Icon';
import { Dropdown, notification, Skeleton } from 'antd';
import { connect, useDispatch } from 'react-redux';
import {
  editSection,
  getSectionTasks,
  isTicketOpen,
  setTaskProperties,
  updateQueryState,
  deleteTask,
  editTask,
  addTask,
  setProjectLoading
} from '../../../../actions';
import ConfirmationModal from '../../molecules/modals/ConfirmationModal';

import TaskCard from './TaskCard';
import AddTask from './AddTask';
import IconAndName from '../../atoms/side-navbar/IconAndName';
import { useParams, useNavigate } from 'react-router-dom';
import { Droppable } from 'react-beautiful-dnd';
import { isEmpty } from 'lodash';
import MenuContext from '../right-click-menu/MenuContext';
import Loader from '../../atoms/global/Loader';

const HeadingCard = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  // const secRef = useRef(null);

  const [isAddTaskVisible, setAddTaskVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const dispatch = useDispatch();
  const [showLess, setShowLess] = useState(false);
  const [addTaskObj, setAddTaskObj] = useState({
    addTaskPosition: '',
  });
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(props.isLoading);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setProjectLoading(false, () => {
        setIsLoading(false);
      }));
    }, 150)
  }, [])

  const loadTasks = async (sectionId) => {
    // Prevent loading if already loading or all notifications are loaded
    console.log("----- sectionId", sectionId)
    if (!hasMoreData) {
      dispatch(setProjectLoading(false, () => {
        setIsLoading(false);
      }));
      return;
    }

    // Make your API call to fetch more notifications
    const sectionTasks = await dispatch(getSectionTasks(sectionId, props?.query[sectionId] || 1, props.taskFilters));
    if (sectionTasks?.length === 0) {
      // If the API returns an empty array, there is no more data
      setHasMoreData(false);
    } else {
      dispatch(updateQueryState(sectionId));
    }
  };

  const handleScroll = (e, sectionId) => {
    const scrollableElement = e.target;

    // Check if the scrollbar is at the bottom
    if (
      scrollableElement?.scrollTop + scrollableElement?.clientHeight >=
      (scrollableElement?.scrollHeight - 1)
    ) {
      loadTasks(sectionId);
    }
  };


  const enableEditting = () => {
    props.setSectionTitle(props.title);
    props.setphase(props.id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      _id: props.id,
      title: props.sectionTitle,
    };
    dispatch(
      editSection(obj, () => {
        props.setSectionTitle('');
        props.setphase('display');
        props.editSectionByProjectId();
      })
    );
  };

  const deleteHandler = () => {
    setOpen(false);
    props.sectionHandler('deleteSection', props.id);
    dispatch(isTicketOpen(false));
    navigate(`/${props.projectId}/board`);
  };

  const handleDeleteTask = (taskId) => {
    dispatch(
      deleteTask(taskId, () => { })
    );
    dispatch(isTicketOpen(false));
  };

  const items = [
    {
      key: '1',
      label: <div className='p-2 font-16' onClick={enableEditting}>Edit Section</div>,
    },
    {
      key: '2',
      label: <div className='p-2 font-16' onClick={() => setOpen(true)}>Delete Section</div>,
    },
  ];

  const addTaskHadler = (sectionKey, position) => {
    setAddTaskVisible(true);
    setAddTaskObj({
      ...addTaskObj,
      selectedSection: sectionKey,
      addTaskPosition: position,
    });
  };

  const taskCount = props?.sectionDoneCounts && props?.sectionDoneCounts[props?.id] || 0;//props?.tasks?.filter(task => task?.sectionId === props?.id)?.length || 0;
  const totalTasks = props?.sectionCounts && props?.sectionCounts[props?.id] || 0;

  const sectionCount = totalTasks;//totalTasks !== taskCount ? `${taskCount} / ${totalTasks}` : totalTasks;

  return (
    <div >
      {!showLess ? (
        <>
          {!isLoading ?
            <div
              onMouseOver={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              className={`heading-card ${!showLess && 'heading-card-mobile'}`}
              style={{ borderTop: `1px solid ${props.color}` }}
            >
              <ConfirmationModal
                title='Delete Section?'
                description='Are you sure you want to delete this section?'
                isModalOpen={open}
                onCancel={() => setOpen(false)}
                onYesClick={deleteHandler}
              />


              <div
                className='d-flex justify-content-between'
                style={{ alignItems: 'center' }}
              >
                <div className='d-flex align-center'>
                  {props.phase === props.id ? (
                    <form onSubmit={handleSubmit} onBlur={handleSubmit}>
                      <input
                        autoFocus
                        value={props.sectionTitle}
                        onChange={(e) => props.setSectionTitle(e.target.value)}
                        className='font-16'
                        style={{
                          width: '10em',
                          border: 'none',
                          outline: 'none',
                        }}
                        type='text'
                      />
                    </form>
                  ) : (
                    <>
                      <div className='card-number d-flex'>
                        <div>{sectionCount}</div>
                      </div>
                      <div className='font-16 truncate' style={{ maxWidth: isHover ? '8rem' : '14rem' }} onClick={enableEditting}>
                        {props?.title}
                      </div>
                    </>
                  )}
                </div>
                {isHover && <div className='d-flex align-center'>

                  <Icon
                    name='showLess.svg'
                    height='14px'
                    pr='20px'
                    onClick={() => {
                      setShowLess(true);
                    }}
                  />
                  <Icon
                    name='addblack.svg'
                    height='14px'
                    pr='20px'
                    onClick={() => {
                      dispatch(
                        setTaskProperties({
                          ...props.taskProperties,
                          projects: [
                            {
                              section: props.id,
                              projectId: props.projectId,
                              position: 'top',
                            }
                          ],
                          addedTo: 'top',
                          workspace: props.workspaceTitle,
                        })
                      );
                      addTaskHadler(props.sectionKey, 'top');
                    }}
                  />


                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement='bottomRight'
                    arrow
                  >
                    <div>
                      <img
                        src={'/images/icons/threeDots.svg'}
                        style={{
                          cursor: 'pointer',
                          height: '16px',
                          width: '16px',
                        }}
                        alt='icon'
                      />
                    </div>
                  </Dropdown>
                </div>
                }
              </div>
            </div>
            :
            <Skeleton.Button
              style={{
                width: '10rem',
                height: 12,
                marginTop: 10,
                padding: 10,
                borderRadius: 6,
                background: '#fcfdfd'
              }}
              active={true} />
          }
          {/* // ............................................? */}
          <Droppable droppableId={props.id}>
            {(provided, snapshot) => (
              <div

                className='scroll-y'
                onScroll={(e) => handleScroll(e, props?.id)}
                style={{
                  height: 'calc(100vh - 13.8rem)',
                  backgroundColor: snapshot.isDraggingOver
                    ? '#8754541b'
                    : 'transparent',
                  position: snapshot.isDraggingOver ? 'relative' : '',
                  borderRadius: '10px',
                }}
                // ref={secRef}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <>
                  {isAddTaskVisible &&
                    addTaskObj.addTaskPosition === 'top' &&
                    props.sectionKey === addTaskObj.selectedSection && (
                      <AddTask setAddTaskVisible={setAddTaskVisible} />
                    )}
                </>
                <div onScroll={handleScroll}>
                  {props.tasks
                    ? props.tasks
                      .filter((task) => {
                        //const project = task?.projects?.find(prj => prj?.project?.toString() == props?.projectId?.toString());
                        // const sectionId = project?.section;
                        const sectionId = task?.sectionId;
                        return sectionId === props.id;
                      })
                      .sort((a, b) => b.position - a.position)
                      .map((task, taskKey) => {
                        return (
                          <div key={taskKey}>
                            {!!isEdit && isEdit === task._id ?
                              <AddTask
                                isEdit={true}
                                setAddTaskVisible={setIsEdit} /> :
                              <>
                                {isLoading ?
                                  <Skeleton.Button
                                    style={{
                                      width: '18rem',
                                      height: 120,
                                      borderRadius: 6,
                                      marginTop: 15,
                                      background: '#fcfcfc'
                                    }}
                                    active={true} />
                                  :
                                  <MenuContext
                                    items={[
                                      {
                                        name: 'Copy Link',
                                        onClick: () => {
                                          navigator.clipboard.writeText(window.location.href + '?selectedTask=' + task?.titleIdentifier);
                                          notification.success({
                                            message: 'Task link copied to clipboard',
                                            placement: 'bottomRight',
                                          });
                                        }
                                      },
                                      {
                                        name: 'Edit',
                                        onClick: () => {
                                          dispatch(
                                            setTaskProperties({
                                              ...task,
                                            })
                                          );
                                          setIsEdit(task._id);
                                        }
                                      },
                                      {
                                        name: `Mark as ${task?.isCompleted ? 'incomplete' : 'complete'}`,
                                        onClick: async () => {
                                          const collaboratorsIds = task.participants && task.participants.map((member) => member._id);
                                          await dispatch(
                                            editTask(
                                              {
                                                _id: task._id,
                                                isCompleted: !task?.isCompleted,
                                                collaborators: collaboratorsIds
                                              })
                                          )
                                        }
                                      },
                                      {
                                        name: 'Duplicate Task',
                                        onClick: () => {
                                          dispatch(addTask({
                                            ...props?.taskProperties,
                                            taskTitle: task?.taskTitle,
                                            taskDescription: task?.taskDescription,
                                            cost: 0,
                                            dueDate: task?.dueDate,
                                            section: task?.sectionId,
                                            sectionCounts: {},
                                            isCompleted: false,
                                            documentPath: '',
                                            projects: [{
                                              section: task?.sectionId,
                                              projectId: task?.projectId,
                                              position: 'bottom'
                                            }],
                                            addedTo: 'bottom',
                                            parentTaskId: null
                                          }));
                                        }
                                      },
                                      {
                                        name: <div className='text-red-600 font-14'>Delete</div>,
                                        onClick: () => handleDeleteTask(task._id)
                                      }
                                    ]}>
                                    <TaskCard task={task} index={taskKey} participants={props?.projectData?.participants || []} />
                                  </MenuContext>}
                              </>}
                          </div>
                        );
                      })
                    : ''}
                  {isLoading && <Loader isLoading={false} type='clip' size={10} />}

                </div>
                <>
                  {isAddTaskVisible &&
                    addTaskObj.addTaskPosition === 'bottom' &&
                    props.sectionKey === addTaskObj.selectedSection ? (
                    <AddTask setAddTaskVisible={setAddTaskVisible} />
                  ) : (
                    <>
                      <div className='task-btn' onClick={() => {
                        dispatch(
                          setTaskProperties({
                            ...props.taskProperties,
                            projects: [
                              {
                                section: props.id,
                                projectId: props.projectId,
                                position: 'bottom',
                              }
                            ],
                            addedTo: 'bottom'
                          })
                        );
                        addTaskHadler(props.sectionKey, 'bottom');
                      }}>

                        <IconAndName
                          name='Add task'
                          src='addblack.svg'
                          height='16px'
                          class='font-16'
                          color='#434343'
                        />
                      </div>
                    </>
                  )}
                </>
                {provided.placeholder}
                {!isEmpty(props.placeholderProps) &&
                  snapshot.isDraggingOver && (
                    <div
                      style={{
                        position: 'absolute',
                        top: props.placeholderProps?.clientY,
                        left: props.placeholderProps?.clientX,
                        height: props.placeholderProps?.clientHeight,
                        background: 'transparent',
                        width: props.placeholderProps?.clientWidth,
                        borderRadius: '6px',
                      }}
                    />
                  )}
              </div>
            )}
          </Droppable>
        </>
      ) : (
        <>
          <Droppable droppableId={props.id}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div
                  className={`heading-card ${!showLess && 'heading-card-mobile'}`}
                  style={{
                    borderTop: `1px solid ${props.color}`,
                    width: '40px',
                    padding: '17px 10px',
                  }}
                  onClick={() => {
                    setShowLess(false);
                  }}
                >
                  {/* {snapshot.isDraggingOver && setShowLess(false)} */}
                  <Icon name='show-more.svg' height='16px' pr='2px' />
                </div>

                <div>
                  <div
                    className={`heading-card ${!showLess && 'heading-card-mobile'}`}
                    style={{
                      height: 'calc(100vh - 380px)',
                      width: '40px',
                      borderTop: `1px solid ${props.color}`,
                      paddingLeft: '0px',
                    }}
                  >
                    <div className='font-18 text-rotated-1 ml-2'>
                      {props.title}
                    </div>
                    <br />
                    <div className='card-number-1  text-rotated-1 d-flex'>
                      <div>{props?.sectionCounts && props?.sectionCounts[props?.id]}</div>
                    </div>
                  </div>
                  <Icon
                    name='addblack.svg'
                    height='16px'
                    pl='10px'
                    onClick={() => {
                      setShowLess(false);
                      dispatch(
                        setTaskProperties({
                          ...props.taskProperties,
                          projects: [
                            {
                              projectId: props.projectId,
                              section: props.id
                            }
                          ]
                          // section: props.id,
                          // projectId: props.projectId,
                        })
                      );
                      addTaskHadler(props.sectionKey, 'bottom');
                    }}
                  />
                </div>
              </div>
            )}
          </Droppable>
        </>
      )
      }
    </div >
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state.projectReducer.loading,
    isTicketOpen: state.toggleReducer.isTicketOpen,
    tasks: state.taskReducer.tasks,
    taskProperties: state.taskReducer.taskProperties,
    projectData: state.projectReducer.getProjects,
    getTask: state.taskReducer.tasks,
    query: state.taskReducer.query,
    sectionCounts: state.taskReducer.sectionCounts,
    sectionDoneCounts: state.taskReducer.sectionDoneCounts,
    taskFilters: state.taskReducer.filters
  };
}

export default connect(mapStateToProps)(HeadingCard);
