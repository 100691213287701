import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ChatRoom from '../UI/organisms/ChatRoom';
import {
  getSingleMessage,
  isChatOpen as chatOpener,
  isTicketOpen as ticketOpener,
  unsetActiveChat
} from '../../actions';

const Chat = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isChatOpen = useSelector((state) => state.toggleReducer.isChatOpen);
  const isTicketOpen = useSelector((state) => state.toggleReducer.isTicketOpen);
  const isAiOpen = useSelector((state) => state.toggleReducer.isAiOpen);

  const singleTask = useSelector((state) => state.taskReducer.singleTask);
  const taskIdentifier = singleTask?.titleIdentifier;
  const location = useLocation();
  const selectedTaskParam = new URLSearchParams(location.search).get('selectedTask');
  const msgId = new URLSearchParams(location.search).get('msgId');
  const activeChatMessage = useSelector((state) => state.toggleReducer.activeChatMessage);
  const { 1: chatId } = window.location.pathname.split("/");
  const { singleMessage } = useSelector((state) => state.messageReducer);


  useEffect(() => {
    // Ticket is open but param is not found. This is the case when you switch between views. You append selected task and repalce URL.
    if (isAiOpen) {
      const searchParams = new URLSearchParams(window.location.search);

      // Remove the 'selectedTask' parameter if it exists
      if (searchParams.has('selectedTask')) {
        searchParams.delete('selectedTask');
      }

      // Remove the 'msgId' parameter if it exists
      if (searchParams.has('msgId')) {
        searchParams.delete('msgId');
      }

      // Construct the new search string
      const newSearch = searchParams.toString();

      // Update the URL with the new search string
      navigate({ search: `?${newSearch}` }, { replace: true });
    } else if (isTicketOpen && !selectedTaskParam && taskIdentifier) {
      dispatch(chatOpener(false));
      navigate({ search: `?selectedTask=${taskIdentifier}` }, { replace: true });
    } else if (selectedTaskParam) {
      // selectedTask param is found. Chat should be closed and ticket should be opened.
      dispatch(chatOpener(false));
      if (!isTicketOpen) dispatch(ticketOpener(true));
    } else if (!isTicketOpen || msgId) { // Ticket is closed or message is found in params. Delete selectedTask from params. Set msgId in params. Close ticket opener and open chat opener.
      const params = new URLSearchParams(location.search);
      params.delete('selectedTask'); // Remove the specified parameter

      if (isChatOpen && !msgId) {
        params.set('msgId', activeChatMessage);
      } else if (msgId) {
        dispatch(chatOpener(true));
        dispatch(ticketOpener(false));
      }

      if (!singleMessage || !singleMessage._id) {
        dispatch(getSingleMessage(msgId || activeChatMessage))
      }

      // Construct the new URL with updated parameters
      const newUrl = `${location.pathname}?${params.toString()}`;

      // Use navigate to replace the current URL
      navigate(newUrl, { replace: true });
    }
  }, [selectedTaskParam, activeChatMessage, chatId, isAiOpen]);

  dispatch(unsetActiveChat());

  return (
    <div style={{ height: '93vh', width: '100%', overflow: 'hidden', background: '#fafafa' }}>
      <ChatRoom />
    </div>
  );
};

export default Chat;
