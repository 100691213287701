import React, { useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Resizable } from 're-resizable';

import {
  setDrawerWidth,
  isAiOpen,
  getMessageSummary,
  clearUnreadMessageSummary
} from '../../../actions';
import { useParams } from 'react-router-dom';

import '../../../assets/style/taskDrawer.css';
import { useEffect } from 'react';

const AiSidePanel = (props) => {
  const dispatch = useDispatch();
  const resizableRef = useRef(null);
  const param = useParams();


  useEffect(() => {
    return () => {
      console.log("CLEARING SUMMARY")
      dispatch(clearUnreadMessageSummary(props.roomId || param.chatId))
    };
  }, []); // Empty dependency array means this effect runs once (on mount) and cleans up on unmount


  const cloeAiPanel = () => {
    dispatch(isAiOpen(false));
  };

  const generateSummaryUnread = () => {
    dispatch(getMessageSummary(props.roomId || param.chatId, 'unread'));
  }

  const generateSummaryLast1Hour = () => {
    dispatch(getMessageSummary(props.roomId || param.chatId, '1h'));
  }

  const generateSummaryLast3Hours = () => {
    dispatch(getMessageSummary(props.roomId || param.chatId, '3h'));
  }

  const generateSummaryLast24Hours = () => {
    dispatch(getMessageSummary(props.roomId || param.chatId, '24h'));
  }

  return (
    <>
      {/* {props.summary?.summary && ( */}
      <Resizable
        enable={{
          top: false,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        ref={resizableRef}
        defaultSize={{
          width: props.drawerWidth,
          height: '100%',
        }}
        maxWidth='750px'
        minWidth='510px'
        onResizeStart={(e, direction, ref, delta) => {
          ref.classList.add('border-left-blue');
        }}
        onResizeStop={(e, direction, ref, delta) => {
          ref.classList.remove('border-left-blue');
          dispatch(setDrawerWidth(ref.offsetWidth));
        }}
      >

        <div style={{
          height: '92vh', overflowY: 'scroll',
          backgroundColor: '#f7f7f7'
        }} >
          <div className='sub-nav-area d-flex justify-content-between bg-white'>
            <div className='font-16-400'>
              AI Summary
            </div>
            <div onClick={cloeAiPanel}>
              <img src='/images/icons/close.svg' alt='close' />
            </div>
          </div>

          <div
            style={{
              overflowY: 'scroll',
              backgroundColor: '#f7f7f7'
            }}
          >
            <div className='' style={{ padding: '10px' }}>
              {props.summary?.summary && (
                <>
                  <h1>Summary</h1>
                  {props.summary?.summary}
                  <br /><br />
                  <h1>Highlights</h1>
                  {props.summary?.talkingPoints && props.summary?.talkingPoints?.length > 0 ? props.summary?.talkingPoints.map((point) => <div><li>{point?.topic} - ({point?.participant})</li></div>) : 'No talking points!'}
                  <br /><br />
                  <h1>Action Items</h1>
                  {props.summary?.actionItems && props.summary?.actionItems?.length > 0 ? props.summary?.actionItems.map((action) => <div><li>{action?.item} - ({action?.participant})</li></div>) : 'No action items!'}
                </>)}
            </div>
          </div>
          <div className="button-container">
            <button className="blue-outline-button" onClick={generateSummaryUnread}>
              Generate AI summary for all unread messages
            </button>
            <button className="blue-outline-button" onClick={generateSummaryLast1Hour}>
              Generate AI summary for messages sent in the last 1 hour
            </button>
            <button className="blue-outline-button" onClick={generateSummaryLast3Hours}>
              Generate AI summary for messages sent in the last 3 hours
            </button>
            <button className="blue-outline-button" onClick={generateSummaryLast24Hours}>
              Generate AI summary for messages sent in the last 1 day
            </button>
          </div>

        </div>
      </Resizable>
      {/* )} */}
    </>
  );
};
function mapStateToProps(state) {
  return {
    summary: state.aiReducer.summary
  };
}

export default connect(mapStateToProps)(AiSidePanel);
