import React, { useState } from "react";
import { Image } from "antd";
import Loader from "../atoms/global/Loader";

const FileHolder = ({ file }) => {
  const videoExtensions = ['.mp4', '.avi', '.mkv', '.mov', '.wmv', '.webm'];
  const imgExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp'];
  const docExtensions = ['.doc', '.docx', '.txt', '.rtf'];
  const pptExtensions = ['.ppt', '.pptx'];
  const excelExtensions = ['.xls', '.xlsx'];
  const svgExtension = ['.svg'];

  const isVideo = videoExtensions.some(ext => file?.name?.includes(ext));
  const isImage = imgExtensions.some(ext => file?.name?.includes(ext));
  const isCsv = file?.name?.includes('.csv');
  const isPdf = file?.name?.includes('.pdf');
  const isDoc = docExtensions.some(ext => file?.name?.includes(ext));
  const isPpt = pptExtensions.some(ext => file?.name?.includes(ext));
  const isExcel = excelExtensions.some(ext => file?.name?.includes(ext));
  const isSvg = svgExtension.some(ext => file?.name?.includes(ext));

  const iconMap = {
    pdf: '/images/icons/pdf.svg',
    doc: '/images/icons/document.svg',
    video: '/images/icons/video2.svg',
    csv: '/images/icons/document.svg',
    ppt: '/images/icons/ppt.svg',
    excel: '/images/icons/excel.svg'
  }

  const icon = isVideo ? iconMap.video : isCsv ? iconMap.csv : isPdf ? iconMap.pdf : isDoc ? iconMap.doc : isPpt ? iconMap.ppt : isExcel ? iconMap.excel : iconMap.doc;

  // const [loading, setLoading] = useState(isSvg ? false : true);

  // const handleImageLoad = () => {
  //   setLoading(false); // Set loading to false when the image has loaded
  // };

  return (
    <>      
      {/* <Loader size={25} isLoading={loading} type='clip' /> */}
      {isImage ?
      <Image
        src={file?.url}
        // onLoad={handleImageLoad}
        style={{
          objectFit: 'cover',
          objectPosition: 'center',
          maxHeight: '250px',
          maxWidth: '360px',
        }}
      /> :
      isVideo ?
        <video controls width="300" height="150">
          <source src={file?.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video> :
        <a
          href={file.url}
          download={file.name}
          style={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            color: 'blue', // You can style the link text color
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '5px',
              border: '1px solid #ccc',
              borderRadius: '5px',
              marginRight: '10px',
            }}
          >
            <img
              src={icon}
              alt="PDF Icon"
              style={{
                width: '20px',
                height: '20px',
                marginRight: '5px',
              }}
            />
            {file.name}
          </div>
        </a>}    </>

  );
};

export default FileHolder;
