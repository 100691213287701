import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AssigneeSelect from '../../molecules/assignee/AssigneeSelect';
import { editTask } from '../../../../actions';

import { Tooltip } from 'antd';

import PriorityDropdown from '../task-components/PriorityDropdown';
import CalendarDropdown from '../task-components/CalendarDropdown';

import getCustomFieldDisplayValue from '../../../../js/getCustomFieldDisplayValue';

const TaskRow = (props) => {
  const dispatch = useDispatch();

  const { task, setIsHover, rowColor } = props;

  const data = useSelector((state) => state.userReducer);

  const [localTask, setLocalTask] = useState(task);
  const [isTitleEdit, setIsTitleEdit] = useState(false);
  const [textHover, setTextHover] = useState(false);
  const [taskTitle, setTaskTitle] = useState(task?.taskTitle || '');

  useEffect(() => {
    setLocalTask(task);
  }, [task]);

  const handleChangePriority = (priority) => {
    if (localTask?.priority !== priority.rank) {
      localTask.priority = priority.rank;
      setLocalTask(localTask);
      editHandler({
        _id: task._id,
        taskTitle: task.taskTitle,
        priority: priority.rank,
      });
    }
  };

  const handleToggleComplete = () => {
    const newStatus = !localTask?.isCompleted;
    localTask.isCompleted = newStatus;
    setLocalTask(localTask);
    dispatch(
      editTask(
        {
          _id: localTask._id,
          isCompleted: newStatus,
        },
        null
      )
    );
  };

  const updateTaskTitle = () => {
    setIsTitleEdit(false);

    if (taskTitle.trim().length <= 0) {
      setTaskTitle(task.taskTitle);
      return;
    }

    editHandler(
      {
        _id: task._id,
        taskTitle: taskTitle,
      },
      () => {
        localTask.taskTitle = taskTitle;
        setLocalTask(localTask);
      }
    );
  };

  const editHandler = async (data, callback) => {
    await dispatch(editTask(data));

    if (typeof callback === 'function') callback();
  };

  const newDate = new Date();
  newDate.setHours(0, 0, 0, 0);

  const statusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'bg-emerald-100 text-emerald-600';
      case 'In Progress':
        return 'bg-amber-100 text-amber-600';
      default:
        return 'bg-slate-100 text-slate-500 w-10 flex align-center justify-center hover:bg-slate-200';
    }
  };

  const openedTask = window.location?.search?.split('=')?.[1] || '';
  const isTaskOpen = task.titleIdentifier === openedTask;
  const rowColorClass = `bg-${rowColor || isTaskOpen ? 'indigo-50' : 'white'}`;

  return (
    <>
      {/* <div className={`px-2 first:pl-5 last:pr-5 py-2 w-px bg-${rowColorClass ? 'indigo-50' : 'white' }`} style={{  }}>
        <div className="flex items-center">
          <label className="inline-flex">
            <span className="sr-only">Select</span>
            <input id={props.id} className="form-checkbox" type="checkbox" onChange={props.handleClick} checked={props.isChecked} />
          </label>
        </div>
      </div> */}
      <div
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={handleToggleComplete}
        className={`flex border-slate-200 border-r justify-center align-center cursor-pointer sticky left-0 ${rowColorClass} col-span-1`}
      >
        <Tooltip
          title={localTask?.isCompleted ? 'Mark Incomplete' : 'Mark Complete'}
          className="flex justify-center align-center "
        >
          {localTask?.isCompleted ? (
            <svg
              className={`w-6 h-7 fill-current text-emerald-500 shrink-0 ml-1`}
              viewBox="0 0 16 16"
            >
              <path d="m2.457 8.516.969-.99 2.516 2.481 5.324-5.304.985.989-6.309 6.284z" />
            </svg>
          ) : (
            <svg
              className={`w-6 h-7 opacity-20 hover:opacity-80 fill-current text-slate-500 shrink-0 ml-1`}
              viewBox="0 0 16 16"
            >
              <path d="m2.457 8.516.969-.99 2.516 2.481 5.324-5.304.985.989-6.309 6.284z" />
            </svg>
          )}
          <div
            className={`font-bold ${
              localTask?.isCompleted ? 'text-emerald-600' : 'text-slate-600'
            } text-nowrap text-sm sm:ml-2`}
          >
            {localTask?.titleIdentifier}
          </div>
        </Tooltip>
      </div>
      <div
        className={`border-slate-200 border-r pl-2 py-2 ${rowColorClass} col-span-6`}
        style={{ minWidth: window.screen?.width / 3 }}
      >
        {isTitleEdit ? (
          <input
            autoFocus
            value={taskTitle}
            onChange={(e) => {
              setTaskTitle(e.target.value);
              localTask.taskTitle = e.target.value;
              setLocalTask(localTask);
            }}
            onBlur={() => {
              setIsTitleEdit(false);
              setIsHover(false);
              updateTaskTitle();
            }}
            className="font-16 p-2 border border-white hover:border-slate-200 rounded h-7 cursor-text w-full"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                updateTaskTitle();
              }

              if (e.key === 'Escape') {
                setIsTitleEdit(false);
                setIsHover(false);
              }
            }}
            rows={1}
            style={{
              outline: 'none',
              width: '100%',
              resize: 'none',
              overflow: 'clip',
            }}
          />
        ) : (
          <div
            onMouseEnter={() => setTextHover(true)}
            onMouseLeave={() => setTextHover(false)}
            className="flex align-center"
          >
            <div
              className={`font-16 cursor-pointer truncate max-w-3xl font-medium text-slate-500 border rounded p-1 border-transparent hover:underline`}
            >
              <Tooltip title={localTask?.taskTitle}>
                {localTask?.taskTitle}
              </Tooltip>
            </div>
            {textHover ? (
              <Tooltip title="Edit Title">
                <div
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                  onClick={() => setIsTitleEdit(true)}
                  className="ml-2 hover:text-indigo-300 cursor-pointer text-indigo-400"
                >
                  <svg
                    className="w-4 h-4 fill-current group-hover:text-indigo-500 shrink-0 mr-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                  </svg>
                </div>
              </Tooltip>
            ) : (
              <div className="w-8" />
            )}
          </div>
        )}
      </div>
      <div
        className={`flex align-center py-2 pl-3 border-slate-200 border-r ${rowColorClass} col-span-1`}
      >
        <CalendarDropdown
          task={task}
          setIsHover={setIsHover}
          left={true}
          x={-180}
          y={10}
        />
      </div>
      <div
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className={`flex justify-center align-center py-2 cursor-pointer border-slate-200 border-r ${rowColorClass} col-span-1`}
      >
        <AssigneeSelect
          projectUsers={data.users || []}
          task={props.task}
          iconSize={28}
          editHandler={editHandler}
        />
      </div>
      <div
        className={`flex justify-center align-center py-2 cursor-pointer border-slate-200 border-r text-white ${rowColorClass} col-span-1`}
      >
        <PriorityDropdown
          task={task}
          setIsHover={setIsHover}
          onClick={(e) => handleChangePriority(e)}
        />
      </div>
      <div
        className={`flex justify-center align-center py-2 cursor-pointer border-slate-200 border-r ${rowColorClass} col-span-1`}
      >
        <div className="flex items-center text-slate-400 hover:text-indigo-600 cursor-pointer">
          {localTask?.totalComments > 0 && (
            <div className="text-sm text-slate-500">
              {localTask?.totalComments}
            </div>
          )}
          <Tooltip
            title={localTask?.totalComments ? 'View Comments' : 'Add Comment'}
          >
            <svg
              className="w-4 h-4 shrink-0 fill-current ml-[10px] mt-0.5"
              viewBox="0 0 16 16"
            >
              <path d="M8 0C3.6 0 0 3.1 0 7s3.6 7 8 7h.6l5.4 2v-4.4c1.2-1.2 2-2.8 2-4.6 0-3.9-3.6-7-8-7zm4 10.8v2.3L8.9 12H8c-3.3 0-6-2.2-6-5s2.7-5 6-5 6 2.2 6 5c0 2.2-2 3.8-2 3.8z" />
            </svg>
          </Tooltip>
        </div>
      </div>
      <div
        className={`flex justify-center align-center py-2 cursor-pointer  border-slate-200 border-r ${rowColorClass} col-span-1`}
      >
        <div className="flex items-center text-slate-400 hover:text-indigo-600 cursor-pointer">
          {localTask?.totalSubtasks > 0 && (
            <div className="text-sm text-slate-500">
              {localTask?.totalSubtasks}
            </div>
          )}
          <Tooltip title={'View Subtasks'}>
            <img
              src="/images/icons/share.svg"
              alt=""
              style={{
                height: '18px',
                width: '18px',
                marginLeft: 10,
              }}
            />
          </Tooltip>
        </div>
      </div>
      {props?.count && (
        <div
          className={`flex justify-center align-center py-2 cursor-pointer truncate font-12 px-2 ${rowColorClass} col-span-1`}
        >
          {getCustomFieldDisplayValue(task?.customFields?.[0])}
        </div>
      )}
      {/* {task?.customFields?.map(field => {

        const val = field?.value && field?.possibleValues?.find(pVal => pVal?._id === field?.value) || '';

        return (
          <div className={`px-2 first:pl-5 last:pr-5 py-2 capitalize font-14 ${rowColorClass}`}>
            {val?.label || '--'}
          </div>
        )
      })} */}
    </>
  );
};

export default TaskRow;
