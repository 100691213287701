import React, { useState, useEffect } from 'react';

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import moment from 'moment';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { setTeamsState } from '../../../../actions/toggleAction';
import { isTicketOpen, isChatOpen, toggleNewTaskModal } from '../../../../actions';

import HtmlRenderer from '../../../UI/organisms/HtmlRenderer';

const localizer = momentLocalizer(moment)

function DashboardOverview(props) {

  const { notifications, dashboardTasks } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [allNotifications, setNotifications] = useState([]);

  useEffect(() => {
    setNotifications(notifications?.notifications)
  }, [notifications]);

  const handleTaskClick = async (e, task) => {
    console.log("----- openTicket", task)

    const params = new URLSearchParams(location.search);
    params.delete('msgId'); // Remove the specified parameter
    params.set('selectedTask', task.titleIdentifier)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    await dispatch(isChatOpen(false));
    await dispatch(setTeamsState(false));

    // Use navigate to replace the current URL
    navigate(newUrl);

    await dispatch(isTicketOpen(true));
  };

  const tasks = dashboardTasks?.tasks?.map(t => ({
    start: moment(t?.dueDate).add(1, "day").toDate(),
    end: moment(t?.dueDate)
      .add(1, "day")
      .toDate(),
    allDay: true,
    title: t?.taskTitle,
    taskId: t?._id
  })) || [];

  return (
    <div className="grid grid-rows-3 grid-flow-col grid-cols-4 gap-4 mt-10 ml-2 h-[72%]">
      <div className="row-span-4 lg:col-span-3 col-span-full sm:col-span-full mr-5 overflow-hidden hidden sm:block">
        <Calendar
          localizer={localizer}
          defaultDate={new Date()}
          views={['month', 'week']}
          defaultView="week"
          onSelectEvent={(e) => {
            console.info('event', e);
          }}
          events={tasks}
        />
      </div>
      <div className="lg:col-span-1 col-span-full sm:col-span-full row-span-2 p-4 border-2 rounded-lg border-slate-600">
        <div className='flex align-center justify-between'>
          <div className='text-2xl font-light'>Recent Activity</div>
        </div>
        <div className='overflow-y-scroll h-full sm:h-[90%] mt-2 overflow-x-hidden'>
          {allNotifications?.map(notif => (
            <div className='py-2 px-1 flex hover:bg-indigo-100 hover:rounded-lg cursor-pointer'>
              <img className='h-10 w-10 image-cover mt-1' src={notif?.assignor?.avi || notif?.icon} style={{ borderRadius: 60 }} />
              <div className='ml-3'>
                {notif?.description && <div className='font-18 font-light text-slate-900 break-all'><HtmlRenderer html={notif?.description} /></div>}
                <div className='font-14'>{notif?.title}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row-span-2 lg:col-span-1 col-span-full sm:col-span-full sm:py-5 py-5 bg-[#202c3e] flex flex-col align-center justify-center rounded-lg">
        <div className='font-24 text-white mb-3 px-10'>Share your feedback with us!</div>
        <div
          onClick={() => window.open('https://workflo.canny.io/bugs-and-feature-requests', '_blank').focus()}
          className='mt-5 font-16 bg-indigo-200 px-4 py-2 rounded-lg cursor-pointer hover:opacity-90'>
          Leave Feedback
        </div>
      </div>
    </div>
  );
}

export default DashboardOverview;
