const data = {
  getTeams: [],
  allWorkspaceTeams: [],
  selectedTeam: {},
  teamInvites: [],
  accessRequests: [],
  allTeams: []
};

const teamReducer = (state = data, action) => {
  switch (action.type) {
    case "team-creation-success":
      return {
        ...state,
        success: action.payload,
        getTeams: [...state.getTeams, action.payload]
      };
    case "get-team-requests":
      return {
        ...state,
        accessRequests: action.payload,
      };

    case "get-teams":
      return {
        ...state,
        getTeams: action.payload?.filter(t => t?.isParticipant),
        allTeams: action.payload
      };
    case "all-workspace-teams":
      return {
        ...state,
        allWorkspaceTeams: action.payload,
      };
    case "get-current-team":
      return {
        ...state,
        selectedTeam: action.payload,
      };
    case "team-deletion-success":
      const newState = state.getTeams.filter(team => team._id !== action.payload)
      return {
        ...state,
        getTeams: newState
      };
    case "team-update-success":
      const newTeams = state.getTeams.map(team => {
        if ( team?._id == action?.payload?._id) {
          return action?.payload
        }
        return team;
      });
      return {
        ...state,
       getTeams: newTeams
      };
    case "team-error":
      return {
        ...state,
        error: action.payload,
      };
    case "get-team-invites":
      return {
        ...state,
        teamInvites: action.payload,
      };


    default:
      return state;
  }
};
export default teamReducer;
