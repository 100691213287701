import React from 'react';

const Button = (props) => {
  return (
    <div className="btn-1 cursor-pointer" onClick={props.onClick}>
      <div className="d-flex" style={{ alignItems: 'center', ...props.style }}>
        <img
          style={{ height: props.height }}
          src={'/images/icons/' + props.src}
          alt="icon"
        />
        <div
          className={props.class}
          style={{
            paddingLeft: '8px',
            color: props.color,
            fontSize: props.fontSize,
          }}
        >
          {props.name}
        </div>
      </div>
      <div ref={props.reference}></div>
    </div>
  );
};

export default Button;
