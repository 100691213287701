import { Modal } from "antd";
import React from "react";

function ProjectModal(props) {
  return (
    <div>
      <Modal
        title={props.title}
        bodyStyle={{
          backgroundColor: '#FFFFFF',
        }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        className="new-project-Modal-div"
        centered
        closable={props.closable}
        open={props.open}
        onCancel={props.onCancel}
        width={props.width}
        footer={props.footer}
      >
        <div>{props.body}</div>
      </Modal>
    </div>
  );
}

export default ProjectModal;
