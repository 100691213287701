import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getSingleTask,
  deleteTaskFile,
} from '../../../../actions';
import TaskChatArea from '../global/TaskChatArea';
import IconAndName from '../../atoms/side-navbar/IconAndName';

const TaskCommentSection = (props) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [chat, setChat] = useState(false);
  const [isCollaborator, setIsCollaborator] = useState(false);

  const { task } = props;

  const delAttachment = async (fileId) => {
    await dispatch(deleteTaskFile(task._id || params.taskid, fileId,
      () =>
        dispatch(getSingleTask(task._id || params.taskid))
    ));
  };

  return (
    <div>
      <TaskChatArea
        roomId={task.room}
        members={task.participants}
        taskId={task._id}
        taskTitle={task.taskTitle}
        emojiPosition={props.drawerWidth}
        delAttachment={delAttachment}
      />
      {/* <div className='watchers-row'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex align-center'>
            <div
              className='font-12'
              style={{
                marginRight: '13px',
              }}
            >
              Watchers
            </div>
            {!chat && (
              <>
                {task?.participants &&
                  task?.participants?.map((member, key) => {
                    return (
                      <div key={key}>
                        <img
                          src={member.avi}
                          alt=''
                          style={{
                            height: '19px',
                            width: '19px',
                            borderRadius: '10px'
                          }}
                          className='cursor-pointer'
                        />
                      </div>
                    );
                  })}
              </>
            )}
          </div>
          <div
            className='d-flex align-center'
            onClick={() => {

            }}
          >
            {isCollaborator ? (
              <IconAndName
                name='Unwatch Task'
                src='noti-black.svg'
                height='12px'
                width='90px'
                mr='13px'
                class='font-12'
              />
            ) : (
              <IconAndName
                name='Watch task'
                src='noti-black.svg'
                width='90px'
                height='12px'
                mr='13px'
                class='font-12'
              />
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TaskCommentSection;
