import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signUp, clearError, getWorkspaces, getWorkspaceInvites, getUser, clearUnauthorized } from "../../../actions";
import { useEffect } from "react";
import moment from 'moment-timezone';


const SignUpForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userToBeVerified = new URLSearchParams(location.search).get('email');
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState(userToBeVerified || "");
  const [password, setPassword] = useState("");
  const [passwordCopy, setPasswordCopy] = useState("");
  const [passwordCopyCheck, setPasswordCopyCheck] = useState(false);
  const [check, setCheck] = useState(false);
  const [checkName, setCheckName] = useState(false);
  const [checkMail, setCheckMail] = useState(false);
  const [checkPass, setCheckPass] = useState(false);
  const [checkPassCopy, setCheckPassCopy] = useState(false);
  const [checkError, setCheckError] = useState(false);

  const handlePass = () => {
    setCheck((check) => !check);
  };

  const nameHandler = (e) => {
    let regex = new RegExp(/^[a-zA-Z ]+$/);
    let name = e.target.value;
    setFullName(name);
    regex.test(name) ? setCheckName(false) : setCheckName(true);
    if (e.target.value.length === 0) {
      setCheckName(false);
    }
  };

  const emailHandler = (e) => {
    dispatch(clearError());
    let regex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    let mail = e.target.value;
    setEmailAddress(mail);
    setCheckError(false);
    regex.test(mail) ? setCheckMail(false) : setCheckMail(true);
    if (e.target.value.length < 5) {
      setCheckMail(false);
    }
  };

  const passwordHandler = (e) => {
    let regex = new RegExp(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
    );
    let passWord = e.target.value;
    setPassword(passWord);
    regex.test(passWord) ? setCheckPass(false) : setCheckPass(true);
    if (passWord.length < 9) {
      setCheckPass(true);
    } else {
      setCheckPass(false);
    }

    if (passWord != passwordCopy) {
      setCheckPassCopy(true);
    } else {
      setCheckPassCopy(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (checkPass || checkMail || checkName || checkPassCopy) {
      return;
    }

    await dispatch(signUp({
      fullName, emailAddress, password, timezones: [{
        name: moment.tz.guess(),
        isPrimary: true
      }]
    }));
    props.setEmail(emailAddress);
    await dispatch(getWorkspaces('SignUpForm'));
    await dispatch(getWorkspaceInvites());
    dispatch(clearUnauthorized());
    navigate('/select-workspace', { replace: true });
  };

  const data = useSelector((state) => state.signupReducer);

  useEffect(() => {
    try {
      if (data.error && data.error.response && data.error.response.status != 0) {
        emailAddress ? setCheckMail(true) : setCheckMail(false);
        setCheckError(true);
      } else if (data.response && data.response.status && data.response.status == 200) {
        setCheckMail(false);
        setCheckError(false);
        props.setSignup(true);
      }
    } catch (error) {
      console.log(error);
    }
  }, [data.error, data.response, emailAddress, props]);

  return (
    <div className="form-div">
      <form onSubmit={submitHandler}>
        <label className={"font-weight-500 font-16 text-darkGray"}>Full name</label>
        <br />
        <div className="pos-relative">
          <input
            className={checkName ? "inputTag border-red mt-0" : "inputTag mt-0"}
            type="text"
            name="fullName"
            value={fullName}
            onChange={nameHandler}
            placeholder="John Doe"
            required
          />
          {/* {checkName && (
            <img
              className="exclamation-error-icon"
              src="/images/icons/exclamationMarkError.svg"
              alt="img"
            />
          )} */}
        </div>

        <div className={checkName ? "flex align-center d-error mt-2" : "d-none"}>
          <img src="/images/icons/error.svg" className="h-4 w-4 mr-2" alt="error" />
          <label className={"font-16"}>
            {!fullName ? "Full name required!" : "No special characters"}
          </label>
        </div>

        <div className="mt-5">
          <label className={"font-16 font-weight-500 text-darkGray"}>
            Email adress
          </label>
          <br />
        </div>
        <div className="pos-relative mt-5">
          <input
            className={checkMail ? "inputTag border-red mt-0" : "inputTag mt-0"}
            type="email"
            name="email"
            value={emailAddress}
            onChange={emailHandler}
            placeholder="name@company.com"
            required
          />
          {/* {checkMail && (
            <img
              className="exclamation-error-icon"
              src="/images/icons/exclamationMarkError.svg"
              alt="img"
            />
          )} */}
        </div>
        <div className={checkMail ? "flex align-center d-error mt-2" : "d-none"}>
          <img src="/images/icons/error.svg" className="h-4 w-4 mr-2" alt="error" />
          <label className={"font-16"}>
            {checkError ? " User already exists" : " This email is invalid!"}{" "}
          </label>
        </div>

        <div className="mt-5">
          <label className={"font-weight-500 font-16 text-darkGray"}>
            Choose password
          </label>
          <br />
        </div>
        <div className="set-icon">
          <input
            className={checkPass ? "passTag border-red " : "passTag "}
            type={check ? "text" : "password"}
            name="password"
            value={password}
            onChange={passwordHandler}
            placeholder="minimum 8 charaters"
            required
          />
          <img
            className="password-eye"
            src={check ? "/images/icons/noEye.svg" : "/images/icons/eye.svg"}
            alt="img"
            onClick={handlePass}
          />
        </div>

        <div className={checkPass ? "flex align-center d-error mt-2" : "d-none"}>
          <img src="/images/icons/error.svg" className="h-4 w-4 mr-2" alt="error" />
          <label className={"font-16"}>
            {checkPass && " This password in invalid!"}
          </label>
        </div>

        <div className="mt-5">
          <label className={"font-weight-500 font-16 text-darkGray"}>
            Confirm password
          </label>
          <br />
        </div>
        <div className="set-icon">
          <input
            className={checkPassCopy ? "passTag border-red " : "passTag "}
            type={passwordCopyCheck ? "text" : "password"}
            name="password"
            onChange={(e) => {
              let passWord = e.target.value;
              if (passWord !== password) {
                setCheckPassCopy(true);
              } else {
                setCheckPassCopy(false);
              }
            }}
            placeholder="minimum 8 charaters"
            required
          />
          <img
            className="password-eye"
            src={passwordCopyCheck ? "/images/icons/noEye.svg" : "/images/icons/eye.svg"}
            alt="img"
            onClick={() => setPasswordCopyCheck(!passwordCopyCheck)}
          />
        </div>

        <div className={checkPassCopy ? "flex align-center d-error mt-2" : "d-none"}>
          <img src="/images/icons/error.svg" className="h-4 w-4 mr-2" alt="error" />
          <label className={"font-16"}>
            {checkPassCopy && " Passwords don't match!"}
          </label>
        </div>
        <br />

        <div className="font-16">
          <label className={"checkBox"}>
            <input className="check-mark" type="checkbox" id="check" />
            <span className="mark"></span>
          </label>
          <label className={"font-weight-500 text-darkGray ml-5"}>
            Remember password
          </label>
          <br />
        </div>

        <button
          type="submit"
          className={"button-tag font-20 font-weight-500 text-white"}
        >
          Sign up!
        </button>
        <br />
        <label className={"font-weight-500 text-darkGray font-16"}>
          Have account? <Link className="text-blue-500" to={"/signin"}>Log in</Link>
        </label>
        <br />
      </form>
    </div>
  );
};

export default SignUpForm;
