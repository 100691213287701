import axios from 'axios';
import { notification } from "antd";

import { ERROR, SUCCESS } from './types/projectUsers.types';

const baseUrl = process.env.REACT_APP_API_URL;
const config = {
  headers: { Authorization: '' },
};

export const addProjectUser = (data, callback) => async (dispatch) => {
  try {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    const response = await axios.post(`${baseUrl}/projects/invite`, data, config);
    const invitees = data && data.invitees && data.invitees.length > 1;
    dispatch({
      type: SUCCESS,
      payload: response,
    });
    notification.success({
      message: response?.data?.message || `Invite${invitees ? 's' : ''} sent`,
      placement: "bottomRight",
    });
    callback && callback();
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error,
    });
    notification.error({
      message: error.response?.data?.message || "Oops! something went wrong",
      placement: "bottomRight",
    });
  }
};

export const deleteProjectUser = (id, callback) => async (dispatch) => {
  try {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    const res = await axios.delete(`${baseUrl}/projects/invite/${id}`, config);
    dispatch({
      type: SUCCESS,
      payload: res,
    });
    callback();
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error,
    });
  }
};

