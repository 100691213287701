import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { editUser, getUser, signedUploadUrl, uploadUserAvi } from '../../../../actions';

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ProfileTab = ({ user, timezone }) => {
  const [newPicture, setNewPicture] = useState(null);
  const dispatch = useDispatch();

  const handleFileChange = async (event) => {
    const file = event?.target?.files[0];
    const newFile = await toBase64(event.target.files[0]);
    setNewPicture(newFile);
    const { signedUrl, path } = await dispatch(uploadUserAvi({ fileName: file?.name }));
    if (signedUrl) {
      await dispatch(signedUploadUrl(signedUrl, file));
    }
    await dispatch(
      editUser(
        {
          _id: user._id,
          avi: path,
        },
        async () => {
          await dispatch(getUser(user._id));
        }
      )
    );
  };

  return (
    <div className="user-card-preferences">
      <div className="information">
        <div>
          <span className="label">Full Name:</span>
        </div>
        <div>
          <span className="value">{user.fullName}</span>
        </div>
        <div className="gap" />
        <div>
          <span className="label">Email:</span>
        </div>
        <div>
          <span className="value">{user.email}</span>
        </div>
        <div className="gap" />
        <div>
          <span className="label">Time Zone:</span>
        </div>
        <div>
          <span className="value">{timezone}</span>
        </div>
      </div>
      <div className="photo">
        <img
          src={newPicture || user.avi}
          alt="Profile Picture"
          width="200"
          height="200"
        />
        {(
          <div
            className="overlay"
            onClick={() => {
              const fileInput = document.createElement('input');
              fileInput.type = 'file';
              fileInput.accept = 'image/jpeg,image/png';
              fileInput.onchange = handleFileChange;
              fileInput.click();
            }}
          >
            <p className="overlay-text">Change Profile Picture</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileTab;
