import * as togglerTypes from '../actions/types/toggler.types';
const data = {
  isTicketOpen: true,
  isChatOpen: false,
  isTaskModalOpen: false,
  drawerWidth: 510,
  teamDrawerState: false,
  isMobile: false,
  activeChatMessage: null,
  isAiOpen: false,
  isChatSettingsOpen: {
    show: false,
    chatId: ''
  },
  isListView: localStorage.getItem('list-view') === 'true' ? true : false,
  showTaskAsModal: localStorage.getItem('task-modal') === 'true' ? true : false
};

const toggleReducer = (state = data, action) => {
  switch (action.type) {
    case togglerTypes.TICKET:
      return {
        ...state,
        isTicketOpen: action.payload,
        isChatOpen: action.payload ? false : state.isChatOpen,
        isAiOpen: action.payload ? false : state.isAiOpen,
        isChatSettings: false,
      };

    case 'isChatSettings':
      return {
        ...state,
        isChatSettingsOpen: {
          show: action.payload?.show,
          chatId: action?.payload?.chatId
        },
        isTicketOpen: false,
        isChatOpen: false,
        isAiOpen: false,
      }

    case 'chat':
      return {
        ...state,
        isChatOpen: action.payload?.val,
        activeChatMessage: action.payload?.msgId,
        isTicketOpen: action.payload?.val ? false : state.isTicketOpen,
        isAiOpen: action.payload?.val ? false : state.isAiOpen,
        isChatSettings: false,
      };

    case togglerTypes.AI:
      return {
        ...state,
        isAiOpen: action.payload,
        isTicketOpen: action.payload ? false : state.isTicketOpen,
        isChatOpen: action.payload ? false : state.isChatOpen,
        isChatSettings: false,
      };

    case 'isListView':
      localStorage.setItem('list-view', action.payload)
      return {
        ...state,
        isListView: action.payload,
      };

    case 'showTaskAsModal':
      localStorage.setItem('task-modal', action.payload)
      return {
        ...state,
        showTaskAsModal: action.payload,
      };

    case 'isTaskModalOpen':
      return {
        ...state,
        isTaskModalOpen: action.payload,
      };

    case togglerTypes.COLLAPSE:
      return {
        ...state,
        collapse: action.payload,
      };
    case 'setDrawerWidth':
      return {
        ...state,
        drawerWidth: action.payload,
      };

    case togglerTypes.TEAMS:
      return {
        ...state,
        teamDrawerState: action.payload,
      };
    case 'set-mobile':
      return {
        ...state,
        isMobile: action.payload,
      };


    default:
      return state;
  }
};
export default toggleReducer;
