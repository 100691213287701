import '../../../../../../assets/style/taskFilter.css';

const UserIcon = (userData) => {
  return (
    <img
    className="filter-icon"
      style={{ marginRight: '8px' }}
      src={userData?.avi}
    />
  )
}

export default UserIcon;
