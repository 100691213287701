import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { setRoomId } from '../../../../actions/roomAction';

import {
  RESET_COUNTER,
  UPDATE_CURRENT_RECEIVER,
} from '../../../../actions/types/chat.types';

import {
  updateChatRoomMessagesReadBy,
} from '../../../../actions/chatRoomAction';

import {
  clearMessagesState,
  updateQueryState,
} from '../../../../actions/messageAction';


import { addChatRoom } from '../../../../actions';

import AvatarWithName from '../AvatarWithName';

const TeamsAndChatModal = (props) => {
  const {
    participants,
    setParticipants,
    onCreate,
    setIsTeamsChatModalOpen,
    inputName,
    inputOnChange,
    isTeamsChatModalOpen,
    isTeamModal = false,
    chats,
    currentWorkspace
  } = props;

  const stateUsers = useSelector((state) => state.userReducer.users);
  const [Users, setUsers] = useState(stateUsers);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();

  useEffect(() => {
    inputOnChange('');
    setUsers(stateUsers);
  }, [isTeamsChatModalOpen]);

  const showInputField = isTeamModal || participants.length > 2;
  const isButtonDisabled = (!inputName && showInputField) || participants?.length == 1; 

  return (
    <Modal
      title={
        <>
          <div className='d-flex justify-content-between'>
            <span>{isTeamModal ? 'Create Team' : 'Start a new chat'}</span>
            <span className='text-disabled-gray font-14'>
              {participants.length - 1}/{Users ? Users.length - 1 : '0'}
            </span>
          </div>
          {showInputField && (
            <div className='d-flex mt-15'>
              <div className="flex items-center mr-5">
                <svg className="shrink-0 h-7 w-7" viewBox="0 0 24 24">
                  <path
                    className={`fill-current text-indigo-500`}
                    d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
                  />
                  <path
                    className={`fill-current text-indigo-300`}
                    d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
                  />
                </svg>
              </div>
              <div className='flex-grow-1'>
                <div className='font-weight-400'>
                  {isTeamModal ? 'Team name' : 'Group chat name'}
                </div>
                <div className='d-flex'>
                  <input
                    value={inputName}
                    onInput={(e) => inputOnChange(e.target.value)}
                    className='bg-transparent'
                    style={{
                      width: '100%',
                      border: 'none',
                      borderBottom: '1px solid #747474',
                      outline: 'none',
                      fontSize: '14px',
                    }}
                    type='text'
                    placeholder='Name'
                    maxLength={30}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      }
      footer={
        <div className='d-flex justify-content-between font-16 font-weight-500'>
          <span
            className='cursor-pointer'
            onClick={() => {
              setParticipants([{ _id: localStorage.getItem('Id'), role: 'member' }]);
              inputOnChange('');
              setIsTeamsChatModalOpen(false);
            }}
          >
            Cancel
          </span>{' '}
          <button
            className={`butn-primary ${isButtonDisabled && 'disabled'}`}
            style={{ padding: '0px 20px' }}
            onClick={() => {
              if (inputName && inputName.trim().length <= 0) {
                return;
              }
              !isButtonDisabled && onCreate();
            }}
          >
            Create
          </button>
        </div>
      }
      centered
      style={{}}
      bodyStyle={{ height: 445, paddingTop: '0px' }}
      width='415px'
      open={isTeamsChatModalOpen}
      closable={false}
    >
      <div className='flex-col align-center justify-between mb-6' style={{ height: '82%' }}>
        <div className="space-y-3 w-full pt-2">
          <label className='font-bold font-14'>Add Members</label>
          <input
            className='search w-full font-14'
            type='text'
            placeholder='Search users by name or email'
            onChange={(e) => {
              const filteredItems = stateUsers.filter((a) =>
                a?.fullName?.toLowerCase().includes(e?.target?.value?.toLowerCase()) ||
                a?.email?.toLowerCase().includes(e?.target?.value?.toLowerCase())
              );

              if (e?.target?.value === '') {
                setUsers(stateUsers);
              } else {
                setUsers(filteredItems);
              }
            }}
          />
        </div>
        {Users &&
          <ul className="mt-5 overflow-scroll h-full">
            {Users?.map((user, key) => {
              if (user._id === localStorage.getItem('Id')) {
                return
              }

              return (
                <li key={key}>
                  <div className="flex items-center justify-between">
                    <AvatarWithName
                      user={user}
                      fontColor='#000'
                      setParticipants={setParticipants}
                      participants={participants}
                    />
                    <div
                      onClick={async () => {
                        const existingChat = chats?.find(chat => chat?.roomType === 'direct' && chat?.members?.find(mem => mem?._id === user?._id));

                        if (existingChat) {
                          dispatch(updateQueryState(1));
                          dispatch(clearMessagesState());
                          dispatch(setRoomId(existingChat._id));
                          dispatch({ type: RESET_COUNTER, payload: existingChat._id });
                          navigate(`/chat/${existingChat._id}`, { replace: true });
                          dispatch({
                            type: UPDATE_CURRENT_RECEIVER,
                            payload: existingChat.roomName,
                          });

                          dispatch(updateChatRoomMessagesReadBy(existingChat._id, 'ChatsNavBar1'));
                        } else {
                          dispatch(addChatRoom({ members: [user?._id], roomName: null, workspaceId: currentWorkspace?._id }));
                        }

                        setIsTeamsChatModalOpen(false);

                      }}
                      className="text-xs cursor-pointer inline-flex font-medium bg-indigo-100 hover:bg-indigo-200 text-indigo-600 rounded-full text-center px-2.5 py-1">
                      Message
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>}
      </div>
    </Modal>
  );
};


export default TeamsAndChatModal;
