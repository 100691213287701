const ROOM_TYPE = {
  PRIVATE: "Private",
  PUBLIC: "Public",
}

const APPLICATION_NAME = "Workflo";

const COLOURS = [
  "#106b82",
  "#6B93F3",
  "#f3936b",
  "#936BF3",
  "#F393F3",
];

const PRIORITY_COLORS = {
  p1: '#C20E35',
  p2: '#FF4500',
  p3: '#FFA500',
  p4: '#0074cc',
  s1: '#FE3C32',
  s2: '#F4BF00',
  s3: 'yellow',
  s4: '#0074cc',
};

const PRIORITY_LABEL = {
  p1: 'Critical',
  p2: 'High',
  p3: 'Medium',
  p4: 'Low',
};

const taskPriority = [
  {
    name: '--',
    rank: 'nill',
    color: ''
  },
  {
    name: PRIORITY_LABEL.p1,
    rank: 'p1',
    color: PRIORITY_COLORS.p1
  },
  {
    name: PRIORITY_LABEL.p2,
    rank: 'p2',
    color: PRIORITY_COLORS.p2
  },
  {
    name: PRIORITY_LABEL.p3,
    rank: 'p3',
    color: PRIORITY_COLORS.p3
  },
  {
    name: PRIORITY_LABEL.p4,
    rank: 'p4',
    color: PRIORITY_COLORS.p4
  }
]

const taskStatus = {
  allTasks: {
    key: 'all-tasks',
    label: 'All tasks'
  },
  completedTasks:
  {
    key: 'completed-tasks',
    label: 'Completed tasks'
  },
  incompletedTasks: {
    key: 'incompleted-tasks',
    label: 'Incompleted tasks',
  }
}

const dueDateFilterOptions = {
  allTasks: {
    key: 'all-tasks',
    label: 'Any time'
  },
  dueToday: {
    key: 'today',
    label: 'Today'
  },
  dueThisWeek: {
    key: 'this-week',
    label: 'This week'
  },
  dueThisMonth:
  {
    key: 'this-month',
    label: 'This month'
  }
}

const customFieldTypes = {
  dropdown: 'Dropdown',
  text: 'Text',
  assignee: 'Assignee'
}

const accessStatus = {
  view: 'View',
  join: 'Join',
  request: 'Ask to join'
}

const fieldTypes = [
  {
    label: 'Dropdown',
    key: 'dropdown',
    icon: 'dropdown.svg'
  },
  {
    label: 'Label',
    key: 'text',
    icon: 'Text.svg'
  },
  {
    label: 'Assignee',
    key: 'assignee',
    icon: 'User.svg'
  },
  {
    label: 'Date',
    key: 'date',
    icon: 'date.svg'
  },
  {
    label: 'Number',
    key: 'number',
    icon: ''
  },
  {
    label: 'Status',
    key: 'status',
    icon: 'notes.svg',
    options: [
      'To Do',
      'Blocked',
      'In Progress',
      'In Review',
      'Done'
    ]
  },
  {
    label: 'Priority',
    key: 'priority',
    icon: 'Flag.svg'
  },
  {
    label: 'Cost',
    key: 'cost',
    icon: 'dollar.svg'
  }
]

export {
  ROOM_TYPE,
  APPLICATION_NAME,
  COLOURS,
  PRIORITY_COLORS,
  PRIORITY_LABEL,
  taskPriority,
  taskStatus,
  customFieldTypes,
  accessStatus,
  fieldTypes,
  dueDateFilterOptions
}