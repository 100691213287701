const GET_CHAT_REQUEST = 'GET_CHAT_REQUEST';
const GET_CHAT_SUCCESS = 'GET_CHAT_SUCCESS';
const GET_CHAT_FAILURE = 'GET_CHAT_FAILURE';
const UPDATE_SINGLE_CHAT = 'UPDATE_SINGLE_CHAT';
const UPDATE_SINGLE_MESSAGE = 'UPDATE_SINGLE_MESSAGE';
const PUT_MESSAGE = 'PUT_MESSAGE';
const RESET_COUNTER = 'RESET_COUNTER';
const UPDATE_CURRENT_RECEIVER = 'UPDATE_CURRENT_RECEIVER';
const ACTIVE_CHAT = 'ACTIVE_CHAT';
const UNSET_ACTIVE_CHAT = 'UNSET_ACTIVE_CHAT';
const INCREMENT_COUNTER = 'INCREMENT_COUNTER'; 

export {
  GET_CHAT_REQUEST,
  GET_CHAT_SUCCESS,
  GET_CHAT_FAILURE,
  UPDATE_SINGLE_CHAT,
  UPDATE_SINGLE_MESSAGE,
  PUT_MESSAGE,
  RESET_COUNTER,
  UPDATE_CURRENT_RECEIVER,
  ACTIVE_CHAT,
  UNSET_ACTIVE_CHAT,
  INCREMENT_COUNTER
};
