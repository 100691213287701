import axios from "axios";
import { UnauthorizedHandler } from "../js/helper/UnauthorizedHandler.js";
import { notification } from 'antd';

const baseUrl = process.env.REACT_APP_API_URL;

const config = {
  headers: { Authorization: "" },
};

export const getOrganizationById = (orgId) => {
  return async (dispatch) => {
    try {
      if(!orgId) return;
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/organization/${orgId}`, config);
      dispatch({
        type: "set-oganization",
        payload: res?.data?.data,
      });
      return res?.data?.data;
    } catch (error) {
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const getOrganizationSubscription = (orgId) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/organization/${orgId}/subscriptions`, config);
      dispatch({
        type: "set-oganization-subscription",
        payload: res?.data?.data,
      });
      return res?.data?.data;
    } catch (error) {
      UnauthorizedHandler(dispatch, error);
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};

export const allocateOrgLicense = (data) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/organization/license/allocate`, data, config);
      // dispatch({
      //   type: "set-oganization-subscription",
      //   payload: res?.data?.data,
      // });
      notification.success({
        message: `User set to ${data?.license?.name}`,
        placement: 'bottomRight',
      });
      return res?.data?.data;
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Unable to allocate',
        placement: 'bottomRight',
      });
      dispatch({
        type: "error",
        payload: error,
      });
    }
  };
};