
const data = {
  showOnboarding: false,
  onBoardingStep: 0,
  maxSteps: 3
};

const onboardingReducer = (state = data, action) => {
  switch (action.type) {
    case 'set-toggle-onboarding':
      return {
        ...state,
        showOnboarding: action.payload
      };
    case 'set-onboarding-step':
      const newStep = action.payload || state.onBoardingStep + 1;
      let updatedShowOnboarding = state.showOnboarding;

      if (newStep >= state.maxSteps) {
        updatedShowOnboarding = false;
      }

      return {
        ...state,
        onBoardingStep: newStep,
        showOnboarding: updatedShowOnboarding
      };

    default:
      return state;
  }
};
export default onboardingReducer;
