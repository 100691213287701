import React, { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Tabs, Input } from 'antd';
import _debounce from 'lodash/debounce';

import DashboardMembers from '../molecules/dashboard/DashboardMembers';
import TeamProjects from '../molecules/team-dashboard/TeamProjects';

import ConfirmationModal from '../molecules/modals/ConfirmationModal';

import Button from "../atoms/global/Button";

import {
  getTeam,
  getTeamInvites,
  getTeamAccessRequests,
  getTeamsByWorkspaceId,
  updateTeam,
  deleteTeam,
  leaveTeam,
  openInvitePanel
} from '../../../actions';
import '../../../assets/style/global.css';

const TeamDashboard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [team, setTeam] = useState(props?.team);
  const [activeKey, setActiveKey] = useState('1');
  const [name, setName] = useState(props?.team?.title);
  const [isLeaving, setIsLeaving] = useState(false);

  const { 1: teamId } = window.location.pathname.split("/");

  const { teamInvites, teamRequests, teams } = props;

  useEffect(() => {
    const getCurrentTeam = async () => {
      await dispatch(getTeam(teamId, true));
    }
    getCurrentTeam();
  }, [dispatch, teamId, params]);

  const updateTeamInfo = useCallback(
    _debounce((value) => {
      dispatch(updateTeam(teamId, { title: value }));
    }, 800),
    []
  );

  const handleInputChange = (value) => {
    setName(value);
  }

  const workspaceId = sessionStorage.getItem("workspaceId");

  useEffect(() => {
    const handleAsync = async () => {
      await Promise.all([
        dispatch(getTeamInvites(teamId)),
        dispatch(getTeamAccessRequests(teamId)),
        dispatch(getTeamsByWorkspaceId(workspaceId, true))
      ]);
    };

    handleAsync();
  }, [teamId]);

  useEffect(() => {
    setTeam(props?.team);
    setName(props?.team?.title);
  }, [props?.team]);

  const tabsContent = [
    {
      label: 'Team Members',
      key: '1',
      children: (
        <DashboardMembers
          invites={teamInvites}
          data={team}
          phase='team'
          requests={teamRequests}
          onAddMembers={() => {
            dispatch(openInvitePanel(true, {
              pastInvites: team?.pendingInvites || [],
              existingMembers: team?.members || [],
              selectWorkspaceMembers: true,
              type: 'Teams',
              teamId: team?._id
            }));
          }} />
      ),
    },
    {
      label: 'Team Projects',
      key: '2',
      children: (
        <TeamProjects
          teams={teams}
          team={team}
        />
      ),
    },
    // {
    //   label: 'Team Metrics',
    //   key: '3',
    //   children: (
    //     <ChartsGraphs team={team} />
    //   ),
    // },
    // {
    //   label: 'Project Templates',
    //   key: '5',
    // },
    {
      label: 'Team Settings',
      key: '4',
      children: (
        <div className="p-6 space-y-6">
          {/* General */}
          <section>
            <h3 className="text-xl leading-snug text-slate-800  font-bold mb-1">General Settings</h3>
            <ul>
              <li className="flex justify-between items-center py-3 border-b border-slate-200">
                {/* Left */}
                <div>
                  <div className="text-slate-800  font-semibold">Team Name</div>
                  <div className='flex align-center'>
                    <Input
                      className='my-4 mr-4 h-9'
                      style={{
                        borderRadius: '7px',
                        width: '50vw'
                      }}
                      onChange={(e) => handleInputChange(e.target.value)}
                      value={name}
                    />
                    <div className='cursor-pointer px-3 py-1.5 font-16 rounded bg-indigo-400 hover:bg-indigo-300 text-white' onClick={() => updateTeamInfo(name)}>Update</div>
                  </div>
                </div>
              </li>
              <li className="flex justify-between items-center py-3 border-b border-slate-200">
                {/* Left */}
                <div>
                  <div className="text-slate-800  font-semibold">Leave Team</div>
                  <div className="text-sm">Leave this team. You can always join back!</div>
                </div>
                {/* Right */}
                <div className="flex items-center ml-4">
                  <Button
                    name="Leave Team"
                    src="Exit.svg"
                    height="16px"
                    class="font-14 text-rose-500"
                    onClick={async () => {
                      setIsLeaving(true);
                      setOpen(true);
                    }}
                    style={{ width: 'max-content', height: 10 }}
                  />
                </div>
              </li>
              <li className="flex justify-between items-center py-3 border-b border-slate-200">
                {/* Left */}
                <div>
                  <div className="text-slate-800  font-semibold">Delete Team</div>
                  <div className="text-sm">Remove this team from your workspace</div>
                </div>
                {/* Right */}
                <div className="flex items-center ml-4">
                  <Button
                    name="Delete Team"
                    src="delete-red.svg"
                    height="16px"
                    class="font-14 text-rose-500"
                    onClick={async () => setOpen(true)}
                    style={{ width: 'max-content', height: 10 }}
                  />
                </div>
              </li>
            </ul>
          </section>
        </div>
      )
    },
  ];

  const [open, setOpen] = useState(false);

  return (
    <div style={{ margin: 20, overflowY: 'scroll' }}>
      <ConfirmationModal
        title={`${isLeaving ? "Leave" : 'Delete'} Team?`}
        description={`Are you sure you want to ${isLeaving ? 'leave' : 'delete'} '${props?.team?.title}'?`}
        isModalOpen={open}
        onCancel={() => setOpen(false)}
        onYesClick={async () => {
          if (isLeaving) {
             dispatch(leaveTeam({ teamId: props?.team?._id }));
          } else {
            dispatch(deleteTeam(props?.team?._id));
          }
          setOpen(false);
          navigate('/', { replace: true });
        }}
      />
      <Tabs
        activeKey={activeKey}
        onChange={activeKey => {
          setActiveKey(activeKey);
        }}
        defaultActiveKey='1'
        items={tabsContent} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  team: state.teamReducer.selectedTeam,
  teams: state.teamReducer.allWorkspaceTeams,
  teamInvites: state.teamReducer.teamInvites,
  teamRequests: state.teamReducer.accessRequests,
  projects: state.projectReducer.myProjects,
});

export default connect(mapStateToProps)(TeamDashboard);
