import React, { useState, useCallback, useRef } from 'react';
import _debounce from 'lodash/debounce';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  isChatOpen,
  isTicketOpen,
  searchWorkspace,
} from '../../../../actions';
import { setTeamsState } from '../../../../actions/toggleAction';
import { useEffect } from 'react';

const WorkspaceSearchBar = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { tasks } = props;

  const [showSearchDropDown, setShowSearchDropDown] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [taskResults, setTaskResults] = useState([]);
  const { search } = useSelector((state) => state.workspaceReducer);
  const latestValue = useRef(searchInput);

  useEffect(() => {
    setTaskResults(search?.tasks);
  }, [search]);


  const filterTasks = (value) => {
    setSearchInput(value);
    setShowSearchDropDown(!!value);
    dispatch(searchWorkspace(value));
  }

  const debouncedFilterTasks = useCallback(
    _debounce((value) => {
      filterTasks(value);
      console.log('Filtering tasks with value:', value);
    }, 500),
    []
  );

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchInput(value);
    latestValue.current = value;
    debouncedFilterTasks(latestValue.current);
  };

  const onSearchClick = (task) => {
    const params = new URLSearchParams(location.search);
    params.delete('msgId'); // Remove the specified parameter
    params.set('selectedTask', task?.titleIdentifier)
    // Construct the new URL with updated parameters
    const newUrl = `${location.pathname}?${params.toString()}`;

    // Use navigate to replace the current URL
    navigate(newUrl);

    setSearchInput('');
    setTaskResults([]);
    setShowSearchDropDown(false);

    dispatch(isTicketOpen(true));
    dispatch(isChatOpen(false));
    dispatch(setTeamsState(false));
  }

  return (
    <div className='nav-search ml-30 mr-30'>
      <input
        className='search font-14'
        type='text'
        placeholder='Search all tasks'
        value={searchInput}
        onChange={handleInputChange}
        onBlur={() => {
          setTimeout(() => {
            setShowSearchDropDown(false);
          }, 500)
        }}
        onFocus={() => {
          !!searchInput && setShowSearchDropDown(true);
        }}
      />
      {showSearchDropDown &&
        <div className='search-results'>
          {taskResults?.length > 0 ?
            <>
              {taskResults.map((task, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => onSearchClick(task)}
                    className='search-input-row'>
                    <div className='search-task-title'>{task.taskTitle}</div>
                    <div className='font-10'>{task.titleIdentifier}</div>
                  </div>
                )
              })}
            </> :
            <div className='font-14' style={{ padding: 10 }}>
              No results
            </div>}
        </div>
      }
    </div>
  );
};

export default WorkspaceSearchBar;
