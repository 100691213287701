import axios from "axios";
import { notification } from "antd";
import * as roomTypes from "./types/room.types";

const baseUrl = process.env.REACT_APP_API_URL;
const config = {
  headers: { Authorization: "" },
};

export const setRoomId = (roomId) => {
  return async (dispatch) => {
    dispatch({
      type: roomTypes.ROOM_ID,
      payload: roomId,
    });
  };
};