const data = {
  organizations: [],
  oganizationById: {}
};

const orgReducer = (state = data, action) => {
  switch (action.type) {
    case "set-oganization":
      return {
        ...state,
        oganizationById: action.payload,
      };


    default:
      return state;
  }
};
export default orgReducer;
