import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  Switch
} from 'antd';

import {
  monthlyStarterPlanDetails,
  teamPlanDetails,
  businessPlanDetails,
  enterprisePlanDetails
} from '../pricing/data';

import { setPaymentDetails } from '../../../../actions';

function MyPlans({ selectedOrg }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [annual, setAnnual] = useState(false);

  const myPlan = selectedOrg?.license?.name || 'free';
  const isFreePlan = myPlan == 'free';

  return (
    <div className="p-6 space-y-6">

      {/* Plans */}
      <section>
        <div className="mb-8">
          <div className='flex align-center mb-4'>
            <h2 className="text-2xl text-slate-800 font-bold">Plans</h2>
            <div className='flex align-center ml-10'>
              <div className="font-14 mr-5">Pay {annual ? 'Anually' : 'Monthly'}</div>
              <Switch onChange={() => setAnnual(!annual)} checked={annual} />
            </div>
          </div>
          <div className="text-sm">This workspace’s Basic Plan is set to <strong className="font-medium">{annual ? '$10.99' : '$12.99'}</strong> per month.</div>
        </div>

        {/* Pricing */}
        <div>
          {/* Toggle switch */}
          <div className="flex items-center space-x-3 mb-6">
            <div className="text-sm text-slate-500 font-medium">Monthly</div>
          </div>
          {/* Pricing tabs */}
          <div className="grid grid-cols-12 gap-6">
            {/* Tab 1 */}
            <div className="relative col-span-full xl:col-span-3 bg-white shadow-md rounded-sm border border-slate-200">
              <div className="absolute top-0 left-0 right-0 h-0.5 bg-emerald-500" aria-hidden="true"></div>
              <div className="flex flex-col justify-between px-5 pt-5 pb-6 border-b border-slate-200 h-56">
                <header className="mb-2">
                  <div className='flex align-center'>
                    <div className="w-6 h-6 rounded-full shrink-0 bg-gradient-to-tr from-emerald-500 to-emerald-300 mr-3">
                      <svg className="w-6 h-6 fill-current text-white" viewBox="0 0 24 24">
                        <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                      </svg>
                    </div>
                    <h3 className="text-lg text-slate-800 font-semibold">FREE</h3>
                  </div>
                  <div className="text-sm mb-2">Tailored for small teams with big needs</div>
                  {/* Price */}
                  <div className="text-slate-800 font-bold mb-4">
                    <span className="text-2xl">$</span><span className="text-3xl">{0}</span><span className="text-slate-500 font-medium text-sm">/month per user </span>
                  </div>
                </header>
                {/* CTA */}
                <button
                  onClick={() => { }}
                  className={`btn border-slate-200 font-14 p-2 hover:border-slate-300 hover:bg-blue-300 ${isFreePlan ? 'bg-slate-300 disabled' : 'text-white bg-blue-400'} w-full`}>
                  {isFreePlan ? 'Current Plan' : 'Downgrade to Free'}
                </button>
              </div>
              <div className="px-5 pt-4 pb-5">
                <div className="text-xs text-slate-800 font-semibold uppercase mb-4">What's included</div>
                {/* List */}
                <ul>
                  {monthlyStarterPlanDetails?.map(details => (
                    <li className="flex items-center py-1">
                      <svg className="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-2" viewBox="0 0 12 12">
                        <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                      </svg>
                      <div className="text-sm">{details}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="relative col-span-full xl:col-span-3 bg-white shadow-md rounded-sm border border-slate-200">
              <div className="absolute top-0 left-0 right-0 h-0.5 bg-red-500" aria-hidden="true"></div>
              <div className="flex flex-col justify-between px-5 pt-5 pb-6 border-b border-slate-200 h-56">
                <header className="mb-2">
                  <div className='flex align-center'>
                    <div className="w-6 h-6 rounded-full shrink-0 bg-gradient-to-tr from-red-500 to-red-300 mr-3">
                      <svg className="w-6 h-6 fill-current text-white" viewBox="0 0 24 24">
                        <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                      </svg>
                    </div>
                    <h3 className="text-lg text-slate-800 font-semibold">Team</h3>
                  </div>
                  <div className="text-sm mb-2">For mid-sized teams managing diverse projects efficiently</div>
                  {/* Price */}
                  <div className="text-slate-800 font-bold mb-4">
                    <span className="text-2xl">$</span><span className="text-3xl">{annual ? '10.99' : '12.99'}</span><span className="text-slate-500 font-medium text-sm">/month per user </span>
                  </div>
                </header>
                {/* CTA */}
                <button
                  onClick={() => {
                    dispatch(setPaymentDetails({ isMonthly: !annual, price: annual ? '10.99' : '12.99' }), navigate('/pricing/users'));
                  }}
                  className={`btn border-slate-200 font-14 p-2 hover:border-slate-300 hover:bg-blue-300 ${!isFreePlan ? 'bg-slate-300 disabled' : 'text-white bg-blue-400'} w-full`}>
                  {!isFreePlan ? 'Current Plan' : 'Upgrade'}
                </button>
              </div>
              <div className="px-5 pt-4 pb-5">
                <div className="text-xs text-slate-800 font-semibold uppercase mb-4">What's included</div>
                {/* List */}
                <ul>
                  {teamPlanDetails?.map(details => (
                    <li className="flex items-center py-1">
                      <svg className="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-2" viewBox="0 0 12 12">
                        <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                      </svg>
                      <div className="text-sm">{details}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* Tab 2 */}
            <div className="relative col-span-full xl:col-span-3 bg-white shadow-md rounded-sm border border-slate-200">
              <div className="absolute top-0 left-0 right-0 h-0.5 bg-sky-500" aria-hidden="true"></div>
              <div className="flex-col flex justify-between px-5 pt-5 pb-6 border-b border-slate-200 h-56">
                <header className="flex flex-col mb-2">
                  <div className='flex align-center'>
                    <div className="w-6 h-6 rounded-full shrink-0 bg-gradient-to-tr from-sky-500 to-sky-300 mr-3">
                      <svg className="w-6 h-6 fill-current text-white" viewBox="0 0 24 24">
                        <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                      </svg>
                    </div>
                    <h3 className="text-lg text-slate-800 font-semibold">Business</h3>
                  </div>
                  <div className="text-sm mb-2">Ideal for individuals that need a custom solution with custom tools.</div>
                </header>
                {/* CTA */}
                <button className="flex align-center justify-center font-14 p-2 w-full bg-indigo-500 hover:bg-indigo-600 text-white disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed shadow-none" disabled>
                  <span>Coming soon</span>
                </button>
              </div>
              <div className="px-5 pt-4 pb-5">
                <div className="text-xs text-slate-800 font-semibold uppercase mb-4">What's included</div>
                {/* List */}
                <ul>
                  {businessPlanDetails?.map(details => (
                    <li className="flex items-center py-1">
                      <svg className="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-2" viewBox="0 0 12 12">
                        <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                      </svg>
                      <div className="text-sm">{details}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* Tab 3 */}
            <div className="relative col-span-full xl:col-span-3 bg-white shadow-md rounded-sm border border-slate-200">
              <div className="absolute top-0 left-0 right-0 h-0.5 bg-indigo-500" aria-hidden="true"></div>
              <div className="flex-col flex justify-between px-5 pt-5 pb-6 border-b border-slate-200 h-56">
                <header className="flex flex-col mb-2">
                  <div className='flex align-center'>
                    <div className="w-6 h-6 rounded-full shrink-0 bg-gradient-to-tr from-indigo-500 to-indigo-300 mr-3">
                      <svg className="w-6 h-6 fill-current text-white" viewBox="0 0 24 24">
                        <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                      </svg>
                    </div>
                    <h3 className="text-lg text-slate-800 font-semibold">Enterprise</h3>
                  </div>
                  <div className="text-sm mb-2">Best for large scale enterprise needs</div>
                </header>
                {/* CTA */}
                <NavLink to='https://workflo.com/enterprise/' className="text-center font-14 btn bg-indigo-500 hover:bg-indigo-600 text-white w-full p-2">Contact</NavLink>
              </div>
              <div className="px-5 pt-4 pb-5">
                <div className="text-xs text-slate-800 font-semibold uppercase mb-4">What's included</div>
                {/* List */}
                <ul>
                  {enterprisePlanDetails?.map(details => (
                    <li className="flex items-center py-1">
                      <svg className="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-2" viewBox="0 0 12 12">
                        <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                      </svg>
                      <div className="text-sm">{details}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Sales */}
      <section>
        <div className="px-5 py-3 bg-indigo-50 border border-indigo-100 rounded-sm text-center xl:text-left xl:flex xl:flex-wrap xl:justify-between xl:items-center">
          <div className="text-slate-800 font-semibold mb-2 xl:mb-0 font-16">Got more questions?</div>
          <NavLink to='https://workflo.com/contact/' className="bg-indigo-500 hover:bg-indigo-100 border-radius10 text-white font-16 p-2">Contact Sales</NavLink>
        </div>
      </section>

      {/* Plans */}
      {/* <section>
        <div className="my-8">
          <h2 className="text-2xl text-slate-800 font-bold">FAQs</h2>
        </div>
        <ul className="space-y-5">
          <li>
            <div className="font-semibold text-slate-800 mb-1">
              What is the difference between the three versions?
            </div>
            <div className="text-sm">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.
            </div>
          </li>
          <li>
            <div className="font-semibold text-slate-800 mb-1">
              Is there any difference between Basic and Plus licenses?
            </div>
            <div className="text-sm">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            </div>
          </li>
          <li>
            <div className="font-semibold text-slate-800 mb-1">
              Got more questions?
            </div>
            <div className="text-sm">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum in voluptate velit esse cillum dolore eu fugiat <a className="font-medium text-indigo-500 hover:text-indigo-600" href="#0">contact us</a>.
            </div>
          </li>
        </ul>
      </section> */}

    </div>
  );
}

export default MyPlans;