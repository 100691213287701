import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from 'antd';

import { getRandomColor } from '../../../../js/helper';
import {
  joinProject,
  toggleNewTeamChatsModal,
} from '../../../../actions';

import CreateProjectModal from '../../molecules/modals/CreateProjectModal';
import GroupAvatar from '../../atoms/side-navbar/GroupAvatar';
import EmptyState from '../../atoms/empty-state/EmptyState';

import DropdownContent from '../onboarding/DropdownContent';

function DashboardTeamsProjects(props) {

  const { teams, projects, userId, showOnboarding, onBoardingStep } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showProjectModal, setShowProjectModal] = useState(false);
  const onboarding = showOnboarding && onBoardingStep === 0;
  const isListView = useSelector((state) => state.toggleReducer.isListView);

  // const showOnboarding = onboarding?.showOnboarding && onboarding?.onBoardingStep === 0;

  const Status = {
    view: {
      name: 'View',
      action: async (project) => {
        navigate(`/${project._id}/members`);
      }
    },
    join: {
      name: 'Join',
      action: async (project) => {
        await dispatch(joinProject({
          projectId: project._id,
          role: 'member'
        }));
      }
    },
    sendRequest: {
      name: 'Ask to join',
      action: async (project) => {
        await dispatch(joinProject({
          projectId: project?._id,
          role: 'member'
        }, () => {
          // navigate(`/${project?._id}/${isListView ? 'list' : 'board'}`, { replace: true });
        }));
      }
    },
    sentRequest: {
      name: 'Request Sent',
      action: async (project) => {
      }
    },
  }

  return (
    <>
      <CreateProjectModal
        createProjectModalOpen={showProjectModal}
        teams={teams}
        setCreateProjectModalOpen={setShowProjectModal} />

      <div className="flex flex-col mt-10 ml-2 sm:h-[70%]">
        <div className='flex align-center mb-5'>
          <Dropdown
            trigger={['click']}
            dropdownRender={() => (
              <DropdownContent
                title={`Welcome to Workflo! 👋`}
                nextButtonText={'Got it'}
                descp={
                  <div>
                    <div className='font-bold my-1'>
                      Let's kickstart your journey
                    </div>
                    <div>
                      On this page, you can join as many projects within the workspace, or create your own!
                    </div>
                  </div>
                }
              />
            )}
            arrow
            placement='topLeft'
            open={onboarding}
          >
            <div className='flex align-center justify-between w-full'>
              <div className='text-2xl font-light'>Workspace Projects</div>
            </div>
          </Dropdown>
          <div className='h-full flex w-full mb-4'>
            {/* <div
              onClick={() => dispatch(toggleNewTeamChatsModal({
                value: true,
                isTeams: true
              }))}
              className='bg-[#FECDA1] h-full w-full flex flex-col align-center justify-center shadow-lg cursor-pointer hover:opacity-90 rounded-lg mr-4'>
              <div className='sm:text-2xl text-sm p-2 sm:p-0 font-light'>Add new team</div>
            </div> */}
            <div
              onClick={() => setShowProjectModal(true)}
              className='bg-[#9ECEF4] h-full w-full flex flex-col align-center justify-center shadow-lg cursor-pointer hover:opacity-90 rounded-lg'>
              <div className='sm:text-2xl text-sm p-2 sm:p-0 font-light'>Add new project</div>
            </div>
          </div>
        </div>
        <div className="p-4 border-2 rounded-lg border-slate-300 h-[90%]">
          {projects?.length > 0 ?
            <div className="overflow-scroll h-full">
              <table className="table-auto w-full" >
                {/* Table header */}
                <thead className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm">
                  <tr>
                    <th className="p-2">
                      <div className="font-semibold text-left">Project Name</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">Public / Private</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">Action</div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-sm font-medium divide-y divide-slate-100  overflow-y-scroll">
                  {/* Row */}
                  {projects?.map((project, i) => {

                    const isPublic = project.access === 'public';
                    const iconLetters = project?.title.slice(0, 2).toUpperCase() || '';

                    let status = isPublic ? Status.join : project?.requestedAccess ? Status.sentRequest : Status.sendRequest;

                    if (project?.participants?.find(member => member?._id === userId)) {
                      status = Status.view;
                    }

                    const projectTeam = teams?.find(t => t?._id === project?.team);
                    const isPartOfTeam = (projectTeam?.members?.find(member => member?._id === userId) !== undefined);

                    return (
                      <tr className='hover:bg-slate-100' key={i + project?._id}>
                        <td className="p-2">
                          <div className="flex items-center">
                            <div
                              className={'square-letter-large font-22 hidden sm:flex'}
                              style={{ background: getRandomColor(i + 1), height: 50, width: 50 }}>
                              {iconLetters}
                            </div>
                            <div className='flex flex-col'>
                              <div className="text-slate-600 font-20 mb-2 font-light">{project?.title}</div>
                              <GroupAvatar participants={(project?.participants)} size={22} />
                            </div>
                          </div>
                        </td>
                        <td className="p-2 w-32">
                          <div className={`text-center rounded-full text-center px-2.5 py-1 ${isPublic ? 'bg-emerald-100 text-emerald-600' : 'bg-slate-100 text-slate-500 hover:bg-slate-200'}`}>{isPublic ? 'Public' : 'Private'}</div>
                        </td>
                        <td className="p-2 w-28 cursor-pointer" onClick={() => status.action(project)} key={i + project?._id}>
                          <div
                            className="text-xs cursor-pointer flex align-center justify-center font-medium bg-indigo-100 hover:bg-indigo-200 text-indigo-600 rounded-full text-center px-2.5 py-1">
                            {status.name}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            :
            <EmptyState
            header={"You Have No Projects!"}
            description={'Create a new project to get started.'}
            iconSize='h-24 w-24'
            src='/images/icons/no-task.svg' />
          }
        </div>
      </div>
    </>
  );
}

export default DashboardTeamsProjects;
