import axios from 'axios';
import { getUser } from './userAction';
const baseUrl = process.env.REACT_APP_API_URL;
const config = {
  headers: { Authorization: '' },
};



export const connectGmailCalendar = (userId, type) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.post(`${baseUrl}/calendar/auth`, {type}, config);
      window.location.href = res.data;
      dispatch(getUser(userId));
      dispatch(getCalendarEvents(userId, type, new Date().toISOString()));
    } catch (error) {
      console.log(error);
    }
  };
}

export const getCalendarEvents = (userId, type, date) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/calendar/${type}/events?date=${date}`, config);
      dispatch({
        type: 'calendarEvents',
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export const getConnectionStatus = (type) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      const res = await axios.get(`${baseUrl}/calendar/${type}/status`, config);
      dispatch({
        type: 'connectionStatus',
        payload: res.data
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export const disconnectCalendar = (userId, type) => {
  return async (dispatch) => {
    try {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      await axios.post(`${baseUrl}/calendar/disconnect`, {type}, config);
      dispatch(getUser(userId));
      dispatch(getCalendarEvents(userId, type, new Date().toISOString()));
    } catch (error) {
      console.log(error);
    }
  };
}