import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import Quill from 'quill'
import MagicUrl from 'quill-magic-url'

Quill.register('modules/magicUrl', MagicUrl);

let atValues = [
  { id: 1, value: "周晓强" },
];
const hashValues = [
  { id: 3, value: "Tag All members" },
];

const mentionModuleConfig = {
  allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  mentionDenotationChars: ["@", "#"],
  source: function (searchTerm, renderList, mentionChar) {
    let values;

    if (mentionChar === "@") {
      values = atValues;
    } else {
      values = hashValues;
    }

    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches = [];
      for (let i = 0; i < values.length; i++)
        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
          matches.push(values[i]);
      renderList(matches, searchTerm);
    }
  }
};

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote", "code-block", "link", "image"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
  magicUrl: {
    // Regex used to check URLs during typing
    urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
    // Regex used to check URLs on paste
    globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
  },
  mention: mentionModuleConfig
};

const formats = [
  "header", "height", "bold", "italic",
  "underline", "strike", "blockquote",
  "list", "color", "bullet", "indent",
  "link", "image", "align", "size",
  "code-block", "mention"
];

function TextEditor({
  onChange,
  mentionOptions,
  onKeyUp,
  placeholder,
  large,
  comment,
  handleBlur
}) {

  const [value, setValue] = useState(comment || "");
  const [blockEnter, setBlockEnter] = useState(false);
  const [lastContent, setLastContent] = useState(value);

  atValues = mentionOptions;

  useEffect(() => {
    setValue(comment);
  }, [comment])

  const processInternalLinks = (html) => {
    const appHost = process.env.REACT_APP_URL;
    // Create a temporary div element to manipulate the HTML string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
  
    // Get all anchor elements within the HTML
    const links = tempDiv.getElementsByTagName('a');
  
    // Loop through all anchor elements to find internal app links
    for (let link of links) {
      const url = new URL(link.href);
  
      // Check if the link is an internal app link
      if (url.host === appHost) {
        // Check if the selectedTask query parameter is present
        const selectedTask = url.searchParams.get('selectedTask');
        if (selectedTask) {
          // Replace the link text with the value of selectedTask
          link.innerHTML = selectedTask;
        }
      }
    }
  
    // Return the modified HTML as a string
    return tempDiv.innerHTML;
  }

  const handleChange = (content, delta, source, editor) => {
    // Check if the Enter key was pressed without Shift
    if (blockEnter) {
      setBlockEnter(false);
      // Revert to the last valid content
      setValue(lastContent);
      return;
    }

    const blocks = editor.getContents().ops;

    const secondLastBlock = blocks.pop()?.insert === '\n';
    const lastBlock = blocks.pop()?.insert?.mention !== undefined;

    if (lastBlock && secondLastBlock) {
      setBlockEnter(true);
    }

    content = processInternalLinks(content);
    setValue(content);
    // // onChange(content); // should useDebounce
    setLastContent(content); // Update the last valid content
    onChange(content);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent the default new line behavior
      // if (!blockEnter) {
      //   onKeyUp(e); // Call onKeyUp only if Enter is not blocked
      // }
    }
  };

  return (
    <div style={{ width: '100%', height: '100%' }} className={large && 'large-editor'}>
      <ReactQuill
        placeholder={placeholder}
        value={value}
        style={{ maxHeight: large ? '100%' : "220px" }}
        onKeyUp={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            if (blockEnter) {
              setBlockEnter(false);
            } else {
              onKeyUp(e);
            }
          }
        }}
        onKeyDown={handleKeyDown} 
        onChange={handleChange}
        onBlur={handleBlur}
        modules={modules}
        formats={formats} />
    </div>
  );
}

export default TextEditor;
