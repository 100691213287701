const data = {
  calendarEvents: [],
  connectionStatus: {}
};

const calendarReducer = (state = data, action) => {
  switch (action.type) {
    case "calendarEvents":
      return {
        ...state,
        calendarEvents: action.payload
      }

    case "connectionStatus":
      return {
        ...state,
        connectionStatus: action.payload
      }
    
    default:
      return state;

  }
}

export default calendarReducer;
