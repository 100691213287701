import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Dropdown, Switch } from 'antd';
import moment from 'moment-timezone';


import Icon from '../../atoms/global/Icon';
import ProfileAndName from '../../atoms/ticket-drawer/ProfileAndName';

import { logout } from '../../../../actions/logoutAction';
import WorkspaceSearchBar from './WorkspaceSearchBar';

import { getWorkspaceNotifications } from '../../../../actions/notificationAction';
import {
  editProject,
  getSingleTask,
  getTeamsByWorkspaceId,
  getUser,
  updateProjectPreferences,
  isChatSettingsOpen,
  getWorkspaceById
} from '../../../../actions';
import {
  isChatOpen,
  isListView,
  isTicketOpen,
  setTeamsState,
} from '../../../../actions/toggleAction';

import { getUserLicense } from '../../../../js/functions';

const TopNavbar = (props) => {
  const notificationOverlayRef = useRef(null);
  const bellIconRef = useRef(null);
  const calenderOverlayRef = useRef(null);
  const param = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { chats } = useSelector((state) => state.chatReducer);
  const chat = chats && chats.find((chat) => chat._id === param.chatId);

  const { user: loggedInUser, projectData } = props;
  const projectUser = projectData?.participants?.find(mem => mem?._id?.toString() == loggedInUser?._id?.toString());

  const URL = window.location.pathname;

  useEffect(() => {
    const checkIfClickedOutside = (event) => {
      if (
        isNotificationOpen &&
        notificationOverlayRef?.current &&
        !notificationOverlayRef?.current.contains(event.target) &&
        !bellIconRef?.current.contains(event.target)
      ) {
        setIsNotificationOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isNotificationOpen]);

  let member = chat?.members.find(
    (member) => member._id !== localStorage.getItem('Id')
  );

  let primaryTimezone =
    loggedInUser &&
    loggedInUser.timezones &&
    loggedInUser.timezones.find((tz) => tz.isPrimary);
  primaryTimezone = primaryTimezone && primaryTimezone.name;

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('workspaceId');
    localStorage.removeItem('organization');
    localStorage.removeItem('toggle');
    localStorage.removeItem('inviteUrl');
    localStorage.removeItem('Id');


    sessionStorage.removeItem('workspaceId');
    sessionStorage.removeItem('organization');

    dispatch(logout());
    window.location.href = '/signin';
  };

  const toggleProjectAccess = async () => {
    const access = props?.projectData?.access == "private" ? "public" : "private";
    const projectId = props?.projectData?._id;
    await dispatch(editProject({ _id: projectId, access }));
    const workspaceId = sessionStorage.getItem("workspaceId");
    if (workspaceId) {
      dispatch(getWorkspaceById());
      // dispatch(getTeamsByWorkspaceId(workspaceId));
    }
  };

  const updateProjPreferences = async () => {
    await dispatch(updateProjectPreferences({ ...projectUser, notifications: !projectUser?.notifications, projectId: props?.projectData?._id }));
    const workspaceId = sessionStorage.getItem("workspaceId");
    if (workspaceId) {
      dispatch(getWorkspaceById());
      // dispatch(getTeamsByWorkspaceId(workspaceId));
    }
  }


  // useEffect(() => {
  //   props.getWorkspaceNotifications();
  // }, [dispatch]);


  // closing notifications modal on click

  const items = [
    {
      key: '1',
      label: <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
        <div className="font-medium text-slate-800">{loggedInUser?.fullName}</div>
        <div className="text-xs text-slate-500 italic">{loggedInUser?.email}</div>
      </div>
    },
    {
      key: '2',
      label: <div onClick={() => navigate('/admin-console')}>Settings</div>,
    },
    {
      key: '3',
      label: <div onClick={handleLogout}>Sign Out</div>,
    },
  ];

  const projectDropdownItems = [
    {
      key: '1',
      label:
        <div
          className='d-flex toggle-dropdown'
          onClick={toggleProjectAccess}>
          <div>Make Private</div>
          <Switch checked={props?.projectData?.access !== "public"} />
        </div>,
    },
    {
      key: '2',
      label:
        <div
          className='d-flex toggle-dropdown'
          onClick={updateProjPreferences}>
          <div>Notifications </div>
          <Switch checked={projectUser?.notifications} />
        </div>,
    },
  ];

  const projectLinks = () => {
    return (
      <div
        className='d-flex flex-grow1'
        style={{ alignSelf: 'flex-start' }}
      >
        <Link
          to={`/${URL.split('/')[1]}/members`}
          className='topNavbarLink membersTopNavbarLink'>
          Members
        </Link>
        <Link
          to={`/${URL.split('/')[1]}/calendar`}
          className='topNavbarLink calendarTopNavbarLink'
        >
          Calender
        </Link>
        <Link
          to={`/${URL.split('/')[1]}/chat`}
          className='topNavbarLink chatTopNavbarLink'
        >
          Chat
        </Link>
        <Link
          onClick={() => {
            dispatch(isListView(false))
          }}
          to={`/${URL.split('/')[1]}/board`}
          className='topNavbarLink boardTopNavbarLink'
        >
          Board
        </Link>
        <Link
          onClick={() => {
            dispatch(isListView(true))
          }}
          to={`/${URL.split('/')[1]}/list`}
          className='topNavbarLink listTopNavbarLink'
        >
          List
        </Link>
        <Link
          to={`/${URL.split('/')[1]}/settings`}
          className='topNavbarLink settingsTopNavbarLink'>
          Settings
        </Link>
      </div>
    );
  }

  const license = getUserLicense(loggedInUser, sessionStorage.getItem("organization") || localStorage.getItem("organization"))

  return (
    <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">

          {/* Header: Left side */}
          <div className="flex">
            {props.phase === 'chat' && (
              <div className='d-flex align-center'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(isChatSettingsOpen(true));
                }}>
                <div key={member ? member._id : ''}>
                  {((chat?.roomAvi || member?.avi) && chat.roomType !== 'project') ?
                    <ProfileAndName
                      height='30px'
                      width='30px'
                      name={
                        chat?.roomName !== null && chat?.roomName !== ''
                          ? chat?.roomName
                          : member?.fullName
                      }
                      src={
                        chat && chat.roomType == 'group'
                          ? chat.roomAvi
                          : member && member.avi
                      }
                      imageOverwrite={true}
                    /> :
                    <div className='d-flex align-center'>
                      <div className='square-letter-large'
                        style={{
                          background: 'red',
                        }}>
                        {(chat?.roomName !== null && chat?.roomName !== ''
                          ? chat?.roomName
                          : member?.fullName)?.slice(0, 2)}
                      </div>
                      <div className='flex align-center'>
                        <div className='font-20 truncate'>
                          {chat?.roomName !== null && chat?.roomName !== ''
                            ? chat?.roomName
                            : member?.fullName}
                        </div>
                        <img
                          className='hover:opacity-60'
                          src={'/images/icons/info.svg'}
                          style={{
                            cursor: 'pointer',
                            height: '22px',
                            width: '22px',
                          }}
                          alt='icon'
                        />
                      </div>
                    </div>}
                </div>
              </div>
            )}

            {/*............. Project top navbar............... */}
            {props.phase === 'project' && (
              <div className='d-flex ' style={{ height: '100%' }}>
                <div className='square-letter-large'
                  style={{
                    background: props?.projectData?.metadata?.colour || 'red',
                  }}>
                  {props?.projectData?.metadata?.initials || props?.projectData?.title?.slice(0, 2)}
                </div>
                <div style={{ paddingLeft: '8px', position: 'relative' }} className='d-flex flex-column'>
                  <div className='d-flex align-center'>
                    <p
                      className='font-18 project-nav-title truncate'
                      style={{
                        marginBottom: '0',
                        fontWeight: 700
                      }}
                    >
                      {props.name?.substring(0, 30)}
                    </p>
                    <div className='ml-3'>
                      <Dropdown
                        trigger={['click']}
                        menu={{
                          items: projectDropdownItems,
                        }}
                        placement='bottom'
                        arrow
                      >
                        <div>
                          <img
                            src={'/images/icons/black-arrow-down.svg'}
                            style={{
                              cursor: 'pointer',
                              height: '16px',
                              width: '16px',
                            }}
                            alt='icon'
                          />
                        </div>
                      </Dropdown>
                    </div>
                    <div className='project-setting-icons'>
                      {props?.projectData?.access == "private" && <div style={{ padding: '2px 5px' }}>
                        <Icon name='lock.svg' height='18px' width='18px' />
                      </div>}
                      {/* {projectUser && !projectUser?.notifications && <div style={{ padding: '4px 5px' }}>
                      <Icon name='no-notification.svg' height='18px' width='18px' />
                    </div>} */}
                    </div>
                  </div>
                  {projectLinks()}
                </div>
              </div>
            )}

            {/*............. Team top navbar............... */}
            {(props.phase === 'workspace') && (
              <div className='d-flex align-center' style={{ height: '100%' }}>
                <div className='square-letter-large' style={{ background: 'red' }}>
                  {props?.name?.slice(0, 2)?.toUpperCase() || props?.currentWorkspace?.title?.slice(0, 2)?.toUpperCase()}
                </div>
                <div style={{ paddingLeft: '8px' }}>
                  <div className='font-18'>{props.name || props?.currentWorkspace?.title}</div>
                  <div className='font-14-600'>{props.phase === 'workspace' ? 'Workspace' : 'Team'} Dashboard</div>
                </div>
              </div>
            )}

            {(props.phase === 'teams') && (
              <div className='d-flex align-center' style={{ height: '100%' }}>
                <div>
                  <div className='font-18'>Teams Directory</div>
                  <div className='font-14-600'>All {props?.currentWorkspace?.title}'s teams</div>
                </div>
              </div>
            )}

            {/*.............Home top navbar............... */}

            {props.phase === 'home' && (
              <>
                <div className='flex mr-5 nav-todays-date text-slate-500'>
                  {/* Home */}
                  <img src='/images/icons/calender.png' alt='img' height='18px' width='18px' />
                  <span className='font-14 ml-5'>
                    {primaryTimezone &&
                      moment().tz(primaryTimezone).format('dddd, MMMM Do YYYY')}
                  </span>
                </div>
              </>
            )}
          </div>



          {/* Header: Right side */}
          <div className="flex items-center space-x-3">
            <WorkspaceSearchBar tasks={props.tasks} />
            {/*  Divider */}
            {license === 'Free Plan' && <hr className="w-px h-6 bg-slate-200 border-none" />}
            {license === 'Free Plan' && <div style={{ margin: '0 10px' }}>
              <div className='font-14-600'>{license}</div>
              <div className='d-flex align-center' style={{ cursor: 'pointer' }} onClick={() => navigate('/pricing')}>
                <div className='font-12 link-button mr-2'>Upgrade</div>
                <img src='/images/icons/blue-arrow-right.svg' />
              </div>
            </div>}
            {/*  Divider */}
            <hr className="w-px h-6 bg-slate-200 border-none" />
            <div className=''>
              {/* <Icon name="profile2.png" height="30px" /> */}
              <Dropdown
                trigger={['click']}
                menu={{
                  items,
                }}
                placement='bottomRight'
                arrow
              >
                <div className='flex align-center cursor-pointer'>
                  <img
                    src={`${loggedInUser.avi}`}
                    style={{
                      height: '25px',
                      width: '25px',
                      objectFit: 'cover',
                      borderRadius: '50%', // Apply circular mask
                    }}
                    alt='icon'
                  />
                  <div className='font-14 ml-1'>{loggedInUser?.fullName}</div>
                </div>
              </Dropdown>
            </div>
          </div>

        </div>
      </div>
    </header>
  );
};

const mapDispatchToProps = (dispatch) => {
  const pathName = window.location.pathname.split('/')[2];
  return {
    getWorkspaceNotifications: () => dispatch(getWorkspaceNotifications()),
    teamsOpener: (arg) => dispatch(setTeamsState(arg)),
    chatOpener: (arg) => dispatch(isChatOpen(arg)),
    taskOpener: (arg) => dispatch(isTicketOpen(arg)),
    getUser: () => dispatch(getUser()),
    getCurrentTask: () =>
      pathName !== 'board' && dispatch(getSingleTask(pathName)),
  };
};

const mapStateToProp = (state) => {
  return {
    user: state.userReducer.user,
    notifications: state.notificationReducer.notifications,
    currentTask: state.taskReducer.singleTask,
    currentWorkspace: state.workspaceReducer.currentWorkspace,
    tasks: state.taskReducer.originalTasks
  };
};
export default connect(mapStateToProp, mapDispatchToProps)(TopNavbar);
