import React from 'react';
import ParticipantCard from '../../atoms/team-drawer/ParticipantCard';

const ParticipantCardsWrapper = (props) => {
  return (
    <div className='participants-cards'>
      <label className='inp-lbl' style={{ color: '#434343' }}>Existing Members</label>
      <div style={{ marginTop: '15px' }}>
        {props?.participants?.map((participant) => (
          <ParticipantCard
            key={participant?._id}
            participant={participant}
            projectId={props?.projectId}
            phase={props?.phase}
            teamId={props?.teamId}
            workspaceId={props?.workspaceId}
          />
        ))}
      </div>
    </div>
  );
};

export default ParticipantCardsWrapper;
