import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import Chat from './components/pages/Chat';
import Activity from './components/pages/Activity';
import SignIn from './components/UI/organisms/SignIn';
import SignUp from './components/UI/organisms/SignUp';
import CreateWorkspace from './components/UI/organisms/CreateWorkspace';
import SelectWorkspace from './components/UI/organisms/SelectWorkspace';
import ResetPassword from './components/UI/organisms/ResetPassword';
import NewPassword from './components/UI/organisms/NewPassword';
import Home from './components/pages/Home';
import CalendarComponent from './components/UI/organisms/CalendarComponent';
import TeamDashboard from './components/UI/organisms/TeamDashboard';
import WorkspaceDashboard from './components/UI/organisms/WorkspaceDashboard';
import ProjectMembers from './components/UI/organisms/ProjectMembers';
import ProjectCustomFieldsTab from './components/UI/organisms/ProjectCustomFields';
import ProjectSettings from './components/UI/organisms/ProjectSettings';
import TaskProject from './components/pages/TaskProject';
import socket from './js/socket';
import { getWorkspaceNotifications, updateNotificationState } from './actions/notificationAction';
import {
  getChatRooms,
  addChatMessage,
  downloadChatUrl,
  getFileUsingSignedUrl,
  getUsers,
  addCommentToTask,
  setIsMobile,
  deleteCommentFromTask,
  incrementChatUnreadCount,
  getWorkspaceById,
} from './actions';
import {
  addChatReply,
  deleteChatReply,
  deleteSingleMessage,
  editChatReply,
  editMessageDetails,
  setScrollPosition,
  updateSingleMessage,
} from './actions/messageAction';
import ProjectChat from './components/pages/ProjectChat';
import AdminConsole from './components/UI/organisms/AdminConsole';
import Layout from './components/pages/Layout';
import Product from './components/pages/Product';
import Pricing from './components/pages/Pricing';
import Teams from './components/pages/Teams';
import StripeWrapper from './components/UI/molecules/global/StripeWrapper';
import PaymentSuccess from './components/pages/PaymentSuccess';
import PaymentError from './components/pages/PaymentError';
import PricingUsers from './components/pages/PricingUsers';
import { getWorkspaceDashboardData } from './actions/WorkspaceActions';
import AllUsers from './components/pages/AllUsers';

function App() {
  const dispatch = useDispatch();

  const { query } = useSelector((state) => state.notificationReducer);
  const windowWidth = window.screen?.width || 501;

  const workspaceId = localStorage.getItem("workspaceId");

  // Connecting to socket
  useEffect(() => {
    socket.on('connection', (sock) => {
      sock.on('disconnect', function () {
        console.log('Socket Disconnected');
      });
    });
  }, []);

  // Reconnecting to socket
  useEffect(() => {
    const reconnect = () => {
      if (document.visibilityState === 'visible' && !socket.connected) {
        socket.on('connection', (sock) => {
          sock.on('disconnect', function () {
            console.log('Socket Disconnected');
          });
        });
      }
    };

    document.addEventListener('visibilitychange', reconnect);

    return () => {
      document.removeEventListener('visibilitychange', reconnect);
    };
  }, []);

  // Recieving task messages

  useEffect(() => {
    dispatch(setIsMobile(windowWidth < 845));
    socket.on('message-received', (msg) => {
      dispatch(addCommentToTask(msg));
      dispatch(getWorkspaceNotifications(query));
    });

    socket.on('ProjectAdded', (project) => {
      // dispatch(getTeamsByWorkspaceId());
      // dispatch(getWorkspaceById());
      dispatch(getWorkspaceDashboardData());
    });

    socket.on('AddedToProject', (projectId) => {
      dispatch(getWorkspaceDashboardData());
      dispatch(getWorkspaceNotifications(query));
    })

    socket.on('ProjectUpdated', (project) => {
      // dispatch(getTeamsByWorkspaceId());
      // dispatch(deleteProjectTasksFromLRU(project?._id));
      // dispatch(getWorkspaceById());
      dispatch(getWorkspaceDashboardData());
    });

    socket.on('ProjectDeleted', (project) => {
      // dispatch(getTeamsByWorkspaceId());
      dispatch(getWorkspaceDashboardData());
      // dispatch(getWorkspaceById());
    });

    socket.on('notifications', (msg) => {
      dispatch(getWorkspaceNotifications(query));
    });

    socket.on('message-deleted', (payload) => {
      // dispatch(getChatRooms(query));
      const context = payload?.context;
      const messageId = payload?.messageId;
      if (context?.type === 'task') {
        dispatch(deleteCommentFromTask(messageId, context?._id));
      } else if (context?.type === 'project') {
        dispatch(deleteSingleMessage({ roomId: context?._id, messageId }));
        // TODO: Remove messageId from project chat. Match active project _id with context._id
      } else if (context?.type === 'chat') {
        dispatch(deleteSingleMessage({ roomId: context?._id, messageId }));
        // TODO: Remove messageId from chat. Match active chat _id with context._id or check if chat messages are present in state.
      }
    });

    socket.on('reply-deleted', (data) => {
      dispatch(deleteChatReply(data));
    });

    socket.on('message-edited', (result) => {
      if (result?.context === 'chat') {
        const regex = /\/chat\/([a-zA-Z0-9]+)/;
        const url = window?.location?.pathname;
        const match = url && url.match(regex);
        const chatRoomId = match ? match[1] : url.split('/')[1];

        if (result?.roomId === chatRoomId) {
          dispatch(
            editMessageDetails({
              roomId: chatRoomId,
              msgId: result?._id,
              messageDescription: result?.messageDescription,
            })
          );
        }
        // dispatch(getChatRooms());
        dispatch(getWorkspaceById());
      } else if (result?.context === 'task') {
        dispatch(addCommentToTask(result));
      }
    });

    socket.on('reply-edited', (result) => {
      const regex = /\/chat\/([a-zA-Z0-9]+)/;
      const url = window?.location?.pathname;
      const match = url && url.match(regex);
      const chatRoomId = match ? match[1] : url.split('/')[1];

      if (result?.roomId?.toString() === chatRoomId?.toString()) {
        // dispatch(
        //   editChatReply({
        //     roomId: chatRoomId,
        //     messageId: result?._id,
        //     messageDescription: result?.repliedMsg,
        //     parent: result?.parent
        //   })
        // );
      dispatch(updateSingleMessage(result))
      }
    });

    socket.on('user-added-successfully', () => {
      dispatch(getUsers());
    });

    socket.on('message-reply', function (message) {
      const regex = /\/chat\/([a-zA-Z0-9]+)/;
      const url = window?.location?.pathname;
      const match = url && url.match(regex);
      const chatRoomId = match ? match[1] : url.split('/')[1];
      const msgId = new URLSearchParams(window?.location?.search).get('msgId');

      if (message?.roomId?.toString() === chatRoomId?.toString() || msgId?.toString() === message?._id?.toString()) {
        // dispatch(updateSingleMessage(message));
        dispatch(addChatReply(message));
        dispatch(setScrollPosition());
        message &&
          message.files &&
          message.files.map(async (file) => {
            const { signedUrl, fileId } = await dispatch(
              downloadChatUrl({
                fileId: file,
                chatId: message.roomId,
              })
            );
            if (signedUrl) {
              await dispatch(getFileUsingSignedUrl(signedUrl, fileId));
            }
          });
        // dispatch(getMessages(chat.roomId, 'chat-received'));
      } else {
        console.log('Else runs...');
        // dispatch(getChatRooms());
        dispatch(getWorkspaceById());
        dispatch(getWorkspaceNotifications(query));
      }
    });

    socket.on('chat-received', function (chat) {
      const regex = /\/chat\/([a-zA-Z0-9]+)/;
      const url = window?.location?.pathname;
      const match = url && url.match(regex);
      const chatRoomId = match ? match[1] : url.split('/')[1];

      dispatch(incrementChatUnreadCount(chat));
      if (chat?.roomId?.toString() === chatRoomId?.toString()) {
        dispatch(addChatMessage(chatRoomId, chat));
        dispatch(setScrollPosition());
        chat &&
          chat.files &&
          chat.files.map(async (file) => {
            const { signedUrl, fileId } = await dispatch(
              downloadChatUrl({
                fileId: file,
                chatId: chat.roomId,
              })
            );
            if (signedUrl) {
              await dispatch(getFileUsingSignedUrl(signedUrl, fileId));
            }
          });
      } else {
        console.log('Else runs...');
        // Get notification after 3s as chat-received is sent back before notification is inserted in database
        setTimeout(() => {
          dispatch(getWorkspaceNotifications(query));
        }, 3000);      
      }
    });

    socket.on('UpdateNotification', (not) => {
      dispatch(updateNotificationState(not));
    });


  }, [dispatch]);

  const getPath = (dest) => {
    return `/${workspaceId}/${dest}`;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/all-tasks"
          element={
            <Layout phase={'home'}>
              <Home />
            </Layout>
          } />
        <Route exact path='/home' element={
          <Layout phase='home'>
            <Home />
          </Layout>
        } />
        <Route exact path='/product' element={<Product />} />
        <Route exact path='/activity' element={
          <Layout phase='home'>
            <Activity />
          </Layout>
        } />
        <Route exact path='/pricing' element={<Pricing />} />
        <Route exact path='/pricing/users' element={<PricingUsers />} />
        <Route exact path='/payment/success' element={<PaymentSuccess />} />
        <Route exact path='/payment/error' element={<PaymentError />} />

        <Route exact path='/:projectid/board' element={
          <Layout phase='project'>
            <TaskProject />
          </Layout>
        } />
        <Route exact path='/:projectid/list' element={
          <Layout phase='project'>
            <TaskProject />
          </Layout>
        } />
        <Route exact path='/:projectid/chat' element={
          <Layout phase='project'>
            <ProjectChat />
          </Layout>
        } />
        <Route exact path='/:projectid/:taskid' element={
          <Layout phase='project'>
            <TaskProject />
          </Layout>
        } />
        <Route
          exact
          path='/:projectid/:taskid/:fullView'
          element={
            <Layout phase='project'>
              <TaskProject />
            </Layout>
          } />
        <Route
          exact
          path='/:projectid/calendar'
          element={
            <Layout phase='project'>
              <CalendarComponent />
            </Layout>
          } />
        <Route
          exact
          path='/:projectid/settings'
          element={
            <Layout phase='project'>
              <ProjectSettings />
            </Layout>
          } />
        <Route
          exact
          path='/:projectid/members'
          element={
            <Layout phase='project'>
              <ProjectMembers />
            </Layout>
          } />
        <Route
          exact
          path='/:projectid/custom-fields'
          element={
            <Layout phase='project'>
              <ProjectCustomFieldsTab />
            </Layout>
          } />
        <Route
          exact
          path='/:teamId/team-dashboard'
          element={
            <Layout phase='team'>
              <TeamDashboard />
            </Layout>
          } />
        <Route exact path='/chat/:chatId' element={
          <Layout phase='chat'>
            <Chat />
          </Layout>
        } />
        <Route exact path='/teams' element={
          <Layout phase='teams'>
            <Teams />
          </Layout>
        } />
        <Route exact path='/admin-console' element={<AdminConsole />} />
        <Route exact path='/create-workspace' element={<CreateWorkspace />} />
        <Route exact path='/select-workspace' element={<SelectWorkspace />} />
        <Route exact path={getPath('')} element={
          <Layout phase='workspace'>
            <WorkspaceDashboard />
          </Layout>
        } />
        <Route exact path='/signin' element={<SignIn />} />
        <Route exact path='/signup' element={<SignUp />} />
        <Route exact path='/resetpassword' element={<ResetPassword />} />
        <Route exact path='/createpassword' element={<NewPassword />} />
        <Route exact path='/payment' element={<StripeWrapper />} />
        <Route exact path='/admin/sales' element={<AllUsers />} />
        <Route path="*" element={<Navigate to={getPath('')} replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
