import * as searchTypes from '../actions/types/search.types';

const initialState = {
    users: [],
    loading: false,
    errors: []
  };
  

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case searchTypes.SEARCH_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: [],
            };
        case searchTypes.SEARCH_SUCCESS:
            return {
                ...state,
                users: action.payload,
                loading: false,
                error: [],
            };
        case searchTypes.SEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                users: [],
            };

        default:
            return state;
    }
};

export default searchReducer;
