const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';
const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST';
const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';
const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST';
const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';
const UPDATE_QUERY_STATE = 'UPDATE_QUERY_STATE';
const UPDATE_TOTAL_UNREAD_COUNT = 'UPDATE_TOTAL_UNREAD_COUNT';
const UPDATE_ONE_NOTIFICATION = 'UPDATE_ONE_NOTIFICATION';

export {
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  DELETE_NOTIFICATION_REQUEST,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAILURE,
  UPDATE_QUERY_STATE,
  UPDATE_TOTAL_UNREAD_COUNT,
  UPDATE_ONE_NOTIFICATION
};
