import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import moment from 'moment';

import { notification, Dropdown, Tooltip } from 'antd';

import ConfirmationModal from '../../molecules/modals/ConfirmationModal';

import {
  removeMemberFromWorkspace,
  updateWorkspaceUsers,
  openInvitePanel
} from '../../../../actions';
import Icon from '../../atoms/global/Icon';
import { getRandomColor } from '../../../../js/helper';
import EmptyState from '../../atoms/empty-state/EmptyState';
import { toUpperCase } from '../../../../js/helper/helpers'

function DashboardMembers(props) {
  const dispatch = useDispatch();
  const { workspace, user } = props;

  const [open, setOpen] = useState(false);
  const [selectedUser, setUser] = useState();
  const [members, setMembers] = useState(workspace && workspace?.members);

  const pendingMembers = workspace && workspace?.pendingInvites;
  const me = workspace?.members?.find(m => m?._id?.toString() === user?._id?.toString());
  const isOwnerOrAdmin = me?.role == 'owner' || me?.role == 'admin';
  const updateRole = async (role, userId) => {

    let newRole = {
      "users": [
        {
          "userId": userId,
          "role": role
        }
      ],
      "workspaceId": workspace?._id
    }


    await dispatch(updateWorkspaceUsers(newRole, () => {
      let x = members?.slice();
      const index = x.findIndex(mem => mem?._id === userId);
      x[index].role = role;
      setMembers(x);
    }));
  }

  const deleteUser = async () => {
    await dispatch(removeMemberFromWorkspace({
      workspaceId: workspace._id,
      members: [
        selectedUser?._id
      ]
    }, () => {
      const x = members?.slice();
      setMembers(x.filter(mem => mem?._id !== selectedUser?._id));
    }));
    setOpen(false);
  };

  useEffect(() => {
    setMembers(workspace && workspace?.members);
  }, [workspace]);

  return (
    <>
      <ConfirmationModal
        title={`Remove from workspace?`}
        description={`Are you sure you want to remove a ${selectedUser?.fullName}?`}
        isModalOpen={open}
        onCancel={() => setOpen(false)}
        onYesClick={deleteUser}
      />
      <div className="grid grid-rows-5 grid-flow-col grid-cols-4 gap-4 mt-10 ml-2 h-[72%]">
        {isOwnerOrAdmin && <div className="row-span-1 lg:col-span-1 sm:col-span-full col-span-full flex flex-col">
          <div
            onClick={() => {
              dispatch(openInvitePanel(true, {
                pastInvites: workspace?.pendingInvites || [],
                existingMembers: workspace?.members || [],
                selectWorkspaceMembers: false,
                type: 'Workspace'
              }));
            }}
            className='bg-[#0091CC] h-full w-full flex flex-col align-center justify-center rounded-lg shadow-lg cursor-pointer hover:opacity-90'>
            <div className='font-20 text-white'>Invite to workspace</div>
          </div>
        </div>}
        {isOwnerOrAdmin && <div className="lg:col-span-1 sm:col-span-full col-span-full row-span-4 p-4 border-2 rounded-lg border-slate-600 h-full overflow-hidden">
          <div className='flex align-center justify-between mb-6'>
            <div className='text-2xl font-light'>Pending Invites</div>
          </div>
          {pendingMembers?.length > 0 ? <table className="table-auto w-full" >
            {/* Table header */}
            <thead className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm">
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left">Email</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Invited on</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm font-medium divide-y divide-slate-100 overflow-y-scroll">
              {/* Row */}
              {pendingMembers?.map(member => (
                <tr>
                  <td className="p-2">
                    <div className="flex items-center h-6">
                      <div className="text-slate-800">{member?.inviteeEmail}</div>
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="text-center">{moment(new Date(member?.createdAt)).format('DD MMM, YYYY')}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table> :
            <EmptyState
              header={"All accounted for!"}
              description={'All workspace invites will be displayed here.'}
              src='/images/icons/paper-plane.svg' />
          }
        </div>}
        <div className={`row-span-5 lg:col-span-${isOwnerOrAdmin ? '3' : '4'} sm:col-span-full col-span-full overflow-hidden p-4 border-2 rounded-lg border-slate-300 h-full`}>
          <div className='flex align-center justify-between mb-6'>
            <div className='text-2xl font-light'>Workspace Members</div>
          </div>
          <div className="overflow-scroll h-full pb-10">
            <table className="table-auto w-full" >
              {/* Table header */}
              <thead className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm">
                <tr>
                  <th className="p-2">
                    <div className="font-semibold text-left">Name / Email</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-center">Role</div>
                  </th>
                  {isOwnerOrAdmin && <th className="p-2">
                    <div className="font-semibold text-center">Actions</div>
                  </th>}
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-sm font-medium divide-y divide-slate-100 overflow-y-scroll">
                {/* Row */}
                {members?.map((member, i) => {
                  const iconLetters = member?.fullName?.slice(0, 2).toUpperCase();
                  return (
                    <tr className='cursor-pointer hover:bg-slate-100' onClick={(e) => { }}>
                      <td className="p-2">
                        <div className="flex align-center">
                          <div
                            className={'square-letter-large font-22 hidden sm:flex'}
                            style={{ background: getRandomColor(i + 1), height: 50, width: 50 }}>
                            {iconLetters}
                          </div>
                          <div className='flex flex-col'>
                            <div className="text-slate-600 font-16 sm:font-20 font-light mb-1">{member?.fullName}</div>
                            <div className='flex align-center'>
                              <div className="text-slate-600 font-14 sm:font-16 font-light">{member?.email}</div>
                              <Tooltip title='Copy Email'>
                                <img
                                  onClick={() => {
                                    navigator.clipboard.writeText(member?.email || '');
                                    notification.success({
                                      message: 'Email copied to clipboard',
                                      placement: 'bottomRight',
                                    });
                                  }}
                                  className='ml-3 cursor-pointer'
                                  src={'/images/icons/copy.svg'}
                                  height={18}
                                  width={18}
                                  style={{ cursor: 'pointer' }}
                                  alt='icon'
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="p-2">
                        {isOwnerOrAdmin ? <Dropdown
                          trigger={['click']}
                          menu={{
                            items: [
                              {
                                key: 'guest',
                                label: <div className='p-2 font-16'>Guest</div>,
                                onClick: () => updateRole('guest', member?._id)
                              },
                              {
                                key: 'member',
                                label: <div className='p-2 font-16'>Member</div>,
                                onClick: () => updateRole('member', member?._id)
                              },
                              {
                                key: 'admin',
                                label: <div className='p-2 font-16'>Admin</div>,
                                onClick: () => updateRole('admin', member?._id)
                              },
                            ],
                          }}
                          placement='bottom'
                          arrow
                          disabled={member.role === 'owner'}
                        >
                          <div className='flex align-center justify-center'>
                            <div
                              className='capitalize text-center'
                              style={{
                                color: '#747474',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}>{member.role}</div>
                            {member.role !== 'owner' && <img
                              src={'/images/icons/show-more.svg'}
                              style={{ margin: "10px" }}
                              alt="icon"
                            />}
                          </div>
                        </Dropdown> : <div className='flex align-center justify-center'>
                          <div
                            className='capitalize text-center'
                            style={{
                              color: '#747474',
                              fontSize: '16px',
                              fontWeight: 400,
                            }}>{member.role}</div>
                        </div>}
                      </td>
                      {isOwnerOrAdmin && <td className="p-2">
                        {member.role !== 'owner' &&
                          <Tooltip title='Remove from workspace'>
                            <div className='flex align-center justify-center cursor-pointer'>
                              <Icon
                                name='delete-red.svg'
                                height='24px'
                                width='24px'
                                onClick={() => {
                                  setOpen(true);
                                  setUser(member);
                                }}
                              />
                            </div>
                          </Tooltip>}
                      </td>}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardMembers;
