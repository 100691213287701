import * as notificationTypes from '../actions/types/notification.types.js';

const initialState = {
  notifications: [],
  loading: false,
  error: [],
  viewedNotification: null,
  query: { page: 1 },
  totalNotifications: 0
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationTypes.GET_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: [],
      };
    case notificationTypes.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload.notifications,
        totalNotifications: action.payload.totalNotifications,
        loading: false,
        error: [],
      };

    case notificationTypes.GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        notifications: [],
      };
    case notificationTypes.UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: [],
      };
    case notificationTypes.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        viewedNotification: action.payload,
        loading: false,
        error: [],
      };

    case notificationTypes.UPDATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case notificationTypes.DELETE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: [],
      };
    case notificationTypes.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: [],
        loading: false,
        error: [],
      };

    case notificationTypes.DELETE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case notificationTypes.UPDATE_QUERY_STATE:
      return {
        ...state,
        query: { page: action.payload },
      };

    case notificationTypes.UPDATE_TOTAL_UNREAD_COUNT:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          unreadNotifications: state.notifications.unreadNotifications > 0
            ? state.notifications.unreadNotifications - 1
            : 0,
        },
      };

    case notificationTypes.UPDATE_ONE_NOTIFICATION:
      const updatedNotifications = [...state.notifications?.notifications];
      const notIndex = updatedNotifications.findIndex(t => t?._id?.toString() === action?.payload?._id?.toString());

      updatedNotifications[notIndex].assignee.isViewed = action?.payload?.assignee?.isViewed;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          notifications: updatedNotifications
        }
      }

    default:
      return state;
  }
};

export default notificationReducer;
