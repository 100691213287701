import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, DatePicker, Dropdown, Radio } from "antd";
import { useNavigate } from 'react-router-dom';

import {
  duplicateProject,
  toggleDuplicateProjectModal
} from '../../../../actions';
import '../../../../assets/style/modal.css';
import socket from '../../../../js/socket';

const DuplicateProjectModal = (props) => {
  const {
    isModalOpen,
    project
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [moveTasks, setMoveTasks] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [frequency, setFrequency] = useState('month');
  const [duration, setDuration] = useState(1);

  const getDurationItems = () => {
    let items = [];
    for (let i = 1; i <= 7; i++) {
      items.push({
        key: i,
        label: i,
        onClick: () => setDuration(i)
      })
    }
    return items;
  }

  const closeModal = () => {
    setIsLoading(false);
    setMoveTasks(false);
    setFrequency('month');
    setDuration(1);
    dispatch(toggleDuplicateProjectModal(false, ''));
  }

  const footer = () => {
    return (
      <div
        className={`${isLoading && 'disabled'} text-center border-radius10`}
        onClick={() => {
          if (!isLoading) {
            setIsLoading(true);
            dispatch(duplicateProject(socket, {
              data: {
                projectId: project?._id,
                tasksStrategy: {
                  copyTasks: true,
                  //moveTasksDueAfter: moveTaskDate,
                  duplicateAllTasks: !moveTasks,
                  offsetDateBy: {
                    unit: frequency,
                    duration: duration
                  }
                }
              }, user: props?.user
            }, (res) => {
              setIsLoading(false);
              // navigate(`/${res?.data?._id}/board`, { replace: true });
              closeModal();
            }));
          }
        }}>
        <div className={`${isLoading && 'disabled'} flex justify-center w-full p-2 rounded text-white bg-blue-400 hover:bg-blue-300 cursor-pointer`}>
          {isLoading ? "Duplicating..." : "Duplicate"}
        </div>
      </div>
    );
  };

  const options = ['day', 'week', 'month', 'year'];

  return (
    <Modal
      title={`Duplicate Project - ${project?.title}`}
      open={isModalOpen}
      onCancel={closeModal}
      bodyStyle={{
        backgroundColor: '#FFFFFF',
        padding: '1rem'
      }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={footer()}>
      <div className="">
        {/* General */}
        <div className="text-sm">Create a copy of project <span className='text-indigo-600 font-bold'>{project?.title}</span></div>
        <ul className='w-full'>
          <li className="flex justify-between items-center py-3 border-b border-slate-200">
            {/* Left */}
            <div className='flex align-center cursor-pointer' onClick={() => setMoveTasks(!moveTasks)} >
              <Radio className='mx-3' checked={moveTasks} />
              <div className='ml-3'>
                <div className="text-slate-800 font-semibold font-16">Move Tasks?</div>
                <div className="font-14 text-slate-500">Move existing project tasks into the new project</div>
              </div>
            </div>
          </li>
          <li className="flex justify-between items-center py-3 border-b border-slate-200">
            <div className='flex align-center cursor-pointer' onClick={() => setMoveTasks(!moveTasks)} >
              <Radio className='mx-3' checked={!moveTasks} />
              <div className='ml-3'>
                <div className="text-slate-800 font-semibold font-16">Duplicate All Tasks?</div>
                <div className="font-14 text-slate-500">A copy of all tasks will be made into the new project</div>
              </div>
            </div>
          </li>
          {!moveTasks && <li className="flex justify-between items-center py-3 border-b border-slate-200 ml-3">
            {/* Left */}
            <div>
              <div className="text-slate-800 font-semibold font-16">Set new due date {duration + ' ' + frequency + (duration > 1 && 's' || '')} from now</div>
              <div className="font-14 text-slate-500">Update all task due dates by this value</div>
            </div>
            {/* Right */}
            <div className="flex items-center ml-4">
              <Dropdown
                className='mr-2'
                overlayStyle={{ zIndex: 10000 }}
                trigger={['click']}
                menu={{
                  items: getDurationItems()
                }}
                placement='bottom'
                arrow
              >
                <div className='flex align-center justify-center border border-slate-200 p-1 px-4'>
                  <div className='font-14'>{duration}</div>
                  <img
                    src={'/images/icons/show-more.svg'}
                    style={{ margin: "10px" }}
                    alt="icon"
                  />
                </div>
              </Dropdown>
              <Dropdown
                overlayStyle={{ zIndex: 10000 }}
                trigger={['click']}
                menu={{
                  items: options?.map(option => ({
                    key: option,
                    label: option,
                    onClick: () => setFrequency(option)
                  })),
                }}
                placement='bottom'
                arrow
              >
                <div className='flex align-center justify-center border border-slate-200 p-1 px-5'>
                  <div className='font-14'>{frequency}{duration > 1 && 's'}</div>
                  <img
                    src={'/images/icons/show-more.svg'}
                    style={{ margin: "10px" }}
                    alt="icon"
                  />
                </div>
              </Dropdown>
            </div>
          </li>}
        </ul>
      </div>
    </Modal>
  );
};


export default DuplicateProjectModal;
