import React from "react";

const Plan = (props) => {
  const { name, description, price, details, buttonLabel, buttonAction } = props;

  const memberPriceText = `PER MEMBER PER MONTH`;

  return (
    <div className="pricing-column">
      <div className="d-flex align-center pt-10" style={{ flexDirection: 'column' }}>
        <h2>{name}</h2>
        <ul>{description}</ul>
        <h1 className="price">{price}</h1>
        <div onClick={buttonAction} className="product-button-primary" style={{ marginTop: !price && 55 }} >{buttonLabel}</div>
        {price && <ul className="member-price-text">{memberPriceText}</ul>}
      </div>
      <div>
        <hr></hr>
        <ul className="details-list pt-5">
          {details.map((detail, index) => (
            <li key={index}>{detail}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Plan;