import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProjectById } from '../../actions';
import { isChatOpen as chatOpener, unsetActiveProject } from '../../actions';
import ChatRoom from '../UI/organisms/ChatRoom';

const ProjectChat = (props) => {
  const dispatch = useDispatch();
  // const params = useParams();
  useEffect(() => {
    dispatch(getProjectById(window.location.pathname.split('/')[1]));
  }, [dispatch]);

  const navigate = useNavigate();
  const isTicketOpen = useSelector((state) => state.toggleReducer.isTicketOpen);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/signin');
    }
  }, [navigate]);
  useEffect(() => {
    if (isTicketOpen) {
      dispatch(chatOpener(false));
    }
  }, [isTicketOpen]);

  useEffect(() => {
    document.querySelectorAll('.topNavbarLink').forEach((item) => {
      item.classList.remove('topNavBar-activeLink');
    });

    document
      .querySelector(`.chatTopNavbarLink`)
      ?.classList?.add('topNavBar-activeLink');
  }, []);

  dispatch(unsetActiveProject());

  return (
    <>
      {localStorage.getItem('token') && (
        <div className='chat-container-content' style={{ height: '94vh', width: '100%', overflow: 'hidden' }}>
          <ChatRoom />
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    toggle: state.navReducer.toggle,
    teamDrawerState: state?.toggleReducer?.teamDrawerState,
    user: state.userReducer.user,
    isMobile: state.toggleReducer.isMobile,
  };
}
export default connect(mapStateToProps)(ProjectChat);
