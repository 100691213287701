const data = {
  myProjects: [],
  getProjects: { title: "", sections: [] },
  publicTemplates: [],
  projectCustomFields: [],
  loading: false,
};


const updateProjectRoleInState = (project, projectId, roles) => {
  if (project?._id?.toString() !== projectId?.toString()) {
    return;
  }

  for (let r of roles) {
    const uIndex = project?.participants?.findIndex(p => p?._id?.toString() === r?.userId?.toString());
    if (uIndex > -1) {
      project.participants[uIndex].role = r?.role;
    }
  }
}

const projectReducer = (state = data, action) => {
  switch (action.type) {
    case "project-success":
      return {
        ...state,
        success: action.payload,
      };

    case "project-error":
      return {
        ...state,
        error: action.payload,
      };

    case "projects-loading":
    case "dashboard-loading":
      return {
        ...state,
        loading: action.payload,
      };


    case "get-projects":
      return {
        ...state,
        getProjects: action.payload,
      };

    case "updateRolesInProject":
      const selectedPrj = { ...state?.getProjects };
      updateProjectRoleInState(selectedPrj, action?.payload?.projectId, action?.payload?.users);
      return {
        ...state,
        getProjects: selectedPrj
      }

    case "set-project-custom-fields":
      return {
        ...state,
        projectCustomFields: action.payload
      };

    case "set-project-templates":
      return {
        ...state,
        publicTemplates: action.payload,
      }

    case "pre-section-update":
      return {
        ...state,
        getProjects: action.payload,
      };

    case "unset-active-project":
      return {
        ...state,
        success: null,
      };

    case "update-section-count":
      const { from, to, taskId, projectId } = action.payload;
      const project = state?.getProjects;
      if (projectId?.toString() !== project?._id?.toString()) {
        return state;
      }

      for (let section of project?.sections) {
        if (section?._id?.toString() === from?.toString()) {
          const fromTasks = section?.tasks || [];
          section.tasks = fromTasks.filter(tsk => tsk?.toString() !== taskId?.toString()) || [];
        } else if (section?._id?.toString() === to?.toString()) {
          const toTasks = section?.tasks || [];
          const isExisting = toTasks?.find(tsk => tsk?.toString() === taskId?.toString());
          if (!isExisting) {
            toTasks.push(taskId);
            section.tasks = toTasks;
          }
        }
      }

      return {
        ...state,
        getProjects: project,
      }

    case "update-project-sections":
      if (action.payload?.projectId?.toString() !== state?.getProjects?._id?.toString()) {
        return state;
      }

      const updatedProject = { ...state.getProjects };
      updatedProject.sections = action.payload?.sections;

      return {
        ...state,
        getProjects: updatedProject
      };

    case "get-users-projects":
      return {
        ...state,
        myProjects: action?.payload?.map(project => {
          const userId = localStorage.getItem('Id');
          if (project?.participants?.find(member => member?._id == userId)) {
            return project;
          }

          return;
        })
      };

    case "project-update-success":
      const updatedProjects = state.myProjects.map(project => {
        if (project?._id == action?.payload?._id) {
          return action?.payload
        }
        return project;
      });
      return {
        ...state,
        myProjects: updatedProjects
      };

    case "delete-project":
      const deletedProjects = state.myProjects.filter(proj => proj._id !== action.payload)
      return {
        ...state,
        myProjects: deletedProjects,
      };

    case "add-project":
      return {
        ...state,
        myProjects: [...state.myProjects, action.payload],
      };

    default:
      return state;
  }
};
export default projectReducer;
