import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import GroupAvatar from '../../atoms/side-navbar/GroupAvatar';
import Icon from '../../atoms/global/Icon';
import {
  joinProject,
  toggleNewProjectModal
} from '../../../../actions';

import { getRandomColor, toUpperCase } from '../../../../js/helper';
import { accessStatus } from '../../../../js/constants';

import Button from "../../atoms/global/Button";

const TeamProjects = (props) => {
  const { teams, team } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const User = useSelector((state) => state.userReducer.user);

  const isListView = useSelector((state) => state.toggleReducer.isListView);
  const [selectedProject, setSelectedProject] = useState();

  const generateHeader = (title, count) => {
    return (
      <div className='d-flex'>
        <div className='members-header'>{title}</div>
        <div className='card-number d-flex'>
          {count || 0}
        </div>
      </div>
    )
  }

  const [projectData, setProjectData] = useState([]);

  useEffect(() => {

    const x = teams?.filter(t => t._id === team._id);

    if (x && x[0]?.projects) {
      setProjectData(x[0].projects);
    }
  }, [teams]);

  return (
    <div className="mt-5 grid grid-cols-12 gap-6">
      <div className="col-span-full bg-white shadow-lg rounded-sm border border-slate-200">
        <header className="px-5 py-4 border-b border-slate-100">
          <div className="flex justify-between items-start">
            <h2 className="font-semibold text-slate-800">Team Projects</h2>
            <Button
              name="Add Project"
              src="addblack.svg"
              height="12px"
              class="font-14"
              onClick={() => dispatch(toggleNewProjectModal({ value: true, team: team }))}
              style={{ width: 'max-content', height: 10 }}
            />
          </div>
        </header>
        <div className="p-3">
          {/* Table */}
          <div className="overflow-x-auto" style={{ height: '100%' }}>
            <table className="table-auto w-full" >
              {/* Table header */}
              <thead className="text-xs uppercase text-slate-400 bg-slate-50 rounded-sm">
                <tr>
                  <th className="p-2">
                    <div className="font-semibold text-left">Name</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-center">Members</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-center">Status</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-center">Actions</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="text-sm font-medium divide-y divide-slate-100 overflow-y-scroll">
                {/* Row */}
                {projectData?.map((project, i) => {

                  const iconLetters = project?.metadata?.initials || project.title?.slice(0, 2).toUpperCase() || '';

                  const isMyProject = project.members.filter(member => member._id === User._id)?.length !== 0;
                  let status = accessStatus.view;

                  if (!isMyProject && project.access === 'public') {
                    status = accessStatus.join;
                  } else if (!isMyProject && project.access === 'private') {
                    status = accessStatus.request;
                  }

                  return (
                    <tr
                      className='cursor-pointer hover:bg-slate-100'
                      onClick={() => {
                        if (status === accessStatus.view) {
                          navigate(`/${project._id}/dashboard`)
                        } else if (status === accessStatus.join || status === accessStatus.request) {
                          dispatch(joinProject({
                            projectId: project?._id,
                            role: 'member'
                          }));
                        }
                      }}
                    >
                      <td className="p-2">
                        <div className="flex items-center">
                          <div
                            className={'square-letter-large font-14'}
                            style={{ background: getRandomColor(i + 1), height: 36, width: 36 }}>
                            {iconLetters}
                          </div>
                          <div className="text-slate-800">{project.title}</div>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className='w-10'>
                          <GroupAvatar participants={project.members} />
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="text-center">{project?.access}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-center">{status} Project</div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};


export default TeamProjects;
