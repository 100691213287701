import React from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  editUser,
  getUser,
  setOnboardingStep
} from '../../../../actions';

function DropdownContent({ title, descp, showSkip, onPress, nextButtonText = 'Next', onBoardingStep, user }) {

  const dispatch = useDispatch();

  return (
    <div className='bg-white min-h-36 flex flex-col justify-between align-center rounded shadow-xl w-2/3'>
      <div className='mt-4 w-full px-4 flex flex-col'>
        <div className='font-bold font-20'>{title}</div>
        <div className='font-light text-slate-800 font-16 py-1'>{descp}</div>
      </div>
      <div className='flex justify-around w-full p-3'>
        {showSkip && <div className='flex align-center justify-center p-2 w-full cursor-pointer text-center font-14 font-bold mr-4 rounded border-slate-300 hover:bg-slate-100 border-2'>Skip</div>}
        <div
          onClick={() => dispatch(setOnboardingStep(onBoardingStep + 1, () => {
            dispatch(
              editUser(
                {
                  _id: user._id,
                  onboarding: 'complete'
                },
                () => {
                  dispatch(getUser(user._id));
                }
              )
            );
          }))}
          className='flex align-center justify-center p-2 w-full cursor-pointer text-center font-14 font-bold text-white rounded bg-indigo-400 hover:bg-indigo-300'>
          {nextButtonText}
        </div>
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    showOnboarding: state.onboardingReducer.showOnboarding,
    onBoardingStep: state.onboardingReducer.onBoardingStep,
    user: state.userReducer.user
  };
}

export default connect(mapStateToProps)(DropdownContent);
