import React from 'react';

const IconAndName = (props) => {
  const path = window.location.pathname;

  return (
    <div
      className={`${props.phase === 'sideNav' ? 'd-flex' : 'd-flex align-center'
        } ${props.classesForParentDiv} ${path === props?.path && 'nav-item-active'}`}
      style={{
        marginBottom: props.mb,
        marginRight: props.mr,
        cursor: 'pointer',
        width: props.width,
      }}
      onClick={() => {
        if (props.iconFuction !== undefined) {
          props.iconFuction();
        }
        if (props.navigate !== undefined) {
          props.navigate();
        }
      }}
    >
      <img
        style={{ height: props.height, width: props.width, maxWidth: props.width }}
        src={'/images/icons/' + props.src}
        alt="icon"
      />
      <div
        className={props.class}
        style={{
          paddingLeft: '12px',
          color: props.color,
          paddingTop: props.namePT,
        }}
      >
        {props.name}
      </div>
    </div>
  );
};

export default IconAndName;
