import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, Dropdown } from "antd";

import Button from "../../atoms/global/Button";
import Loader from '../../atoms/global/Loader';

import { fieldTypes } from '../../../../js/constants';

import {
  toggleWorkspaceFieldsModal,
  getCustomFieldsByAI,
  deleteWorkspaceCustomField
} from '../../../../actions';

import CustomFieldPanel from '../custom-field-panel/CustomFieldPanel';
import ProjectCustomFields from '../../molecules/project-dashboard/ProjectCustomFields';

const WorkspaceFieldsModal = (props) => {
  const {
    isModalOpen,
    workspace,
    fields,
    workspaceProjects,
    workspaceCustomFields
  } = props;

  const dispatch = useDispatch();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedField, setSelectedField] = useState({});
  const [showField, setShowField] = useState(false);

  const [selectedProject, setSelectedProject] = useState();
  const [customFields, setCustomFields] = useState(workspaceCustomFields || []);

  const industry = workspace?.industry || workspace?.description

  useEffect(() => {
    setCustomFields(workspaceCustomFields || []);
  }, [workspaceCustomFields])

  useEffect(() => {
    generateCustomFields()
  }, [industry])

  const closeModal = () => {
    setSelectedField({});
    setShowField(false);
    setSelectedIndex(0);
    dispatch(toggleWorkspaceFieldsModal(false, ''));
  }

  const generateCustomFields = () => {
    if (industry) {
      setIsLoading(true);
      dispatch(getCustomFieldsByAI({
        industry,
        categories: ['essentials'],
        entityId: workspace?._id,
        entityType: 'workspace'
      }, () => setIsLoading(false)));
    }
  }

  const customFieldsToString = (arry) => {
    const names = arry?.map((value, i) => {
      if (i == arry.length - 1) {
        return value?.label;
      }
      return value.label += ' ';
    });

    return names;
  }

  const options = ['General Fields', 'Workspace Library', 'Project Fields', 'A.I. Suggested Fields']

  return (
    <Modal
      title={
        <div className='flex align-center'>
          <img
            src={'/images/icons/custom-fields.svg'}
            style={{
              cursor: 'pointer',
              height: '30px',
              width: '30px',
              marginRight: '10px'
            }}
            alt='icon'
          />
          <div>Custom Fields</div>
        </div>}
      open={isModalOpen}
      onCancel={closeModal}
      width={1400}
      bodyStyle={{
        backgroundColor: '#FFFFFF',
        paddingBottom: 0,
        maxHeight: '65vh'
      }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}>
      <div className="flex">
        <div className="px-5 py-4 w-80 h-full bg-white overflow-x-hidden overflow-y-auto border-r border-slate-200">
          <div className="mt-4">
            <div className="text-xs font-semibold text-slate-400 uppercase mb-3">Categories</div>
            <ul className="mb-6">
              {options?.map((option, i) => (
                <li className="-mx-2" key={option}>
                  <button
                    onClick={() => setSelectedIndex(i)}
                    className={`flex items-center justify-between w-full p-2 rounded ${selectedIndex === i ? 'text-white bg-blue-400' : 'bg-white hover:bg-slate-100'}`}>
                    <div className="text-sm font-medium">{option}</div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='flex justify-between w-full h-full'>

          <div className={`px-5 h-full ${showField ? 'w-2/3' : 'w-full'}`}>
            {selectedIndex === 0 && (
              <section>
                <div className="flex align-center justify-between mb-5">
                  <div className='font-20 font-bold text-slate-700'>{options[selectedIndex]}</div>
                  {!showField && <Button
                    name="Add Custom Field"
                    src="addblack.svg"
                    height="12px"
                    class="font-14"
                    onClick={() => {
                      setShowField(true);
                    }}
                  />}
                </div>
                <div className="grid grid-cols-2 gap-6 overflow-y-scroll">
                  {fieldTypes?.map(ftype => (
                    <div className='flex align-center justify-between col-span-1 border border-radius10 p-2 border-slate-300' key={ftype?.key}>
                      <div className='flex align-center'>
                        <div className='bg-red-200 border-radius10'>
                          <img
                            src={`/images/icons/${ftype?.icon || 'custom-fields.svg'}`}
                            style={{
                              cursor: 'pointer',
                              height: '50px',
                              width: '50px',
                              margin: '10px',
                            }}
                            alt='icon'
                          />
                        </div>
                        <div className='ml-3 font-16 font-bold'>
                          {ftype?.label}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setSelectedField({
                            type: ftype?.key,
                            name: ftype?.label,
                            possibleValues: ftype?.possibleValues,
                            _id: ftype?._id,
                            isUpdate: false,
                            multi: ftype?.multi
                          });
                          setShowField(true);
                        }}
                        className='pr-5 cursor-pointer hover:underline'>
                        View
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            )}

            {selectedIndex === 1 && (
              <div className='h-full'>
                <div className="flex align-center justify-between mb-5">
                  <div className='font-20 font-bold text-slate-700'>{options[selectedIndex]}</div>
                  {!showField && <Button
                    name="Add Custom Field"
                    src="addblack.svg"
                    height="12px"
                    class="font-14"
                    onClick={() => {
                      setShowField(true);
                    }}
                  />}
                </div>
                <div className="grid grid-cols-2 gap-6 overflow-y-scroll max-h-[58vh]">
                  {customFields?.map((item, i) => {
                    const icon = fieldTypes?.find(f => f.key == item?.type)?.icon || 'custom-fields.svg';
                    return (
                      <div className='flex align-center justify-between col-span-full border border-radius10 p-2 border-slate-300'>
                        <div className='flex align-center'>
                          <div className='bg-blue-200 border-radius10'>
                            <img
                              src={`/images/icons/${icon}`}
                              style={{
                                cursor: 'pointer',
                                height: '50px',
                                width: '50px',
                                margin: '10px',
                              }}
                              alt='icon'
                            />
                          </div>
                          <div className='ml-3'>
                            <div className='font-16 font-bold'>
                              {item?.name}
                            </div>
                            <div className='font-12 mt-1 font-bold'>
                              Field Type: {item?.type}
                            </div>
                          </div>
                        </div>
                        <div className='flex align-center'>
                          <div
                            onClick={() => {
                              setSelectedField({
                                type: item?.type,
                                name: item?.name,
                                possibleValues: item?.possibleValues,
                                _id: item?._id,
                                isUpdate: false,
                                isWorkspace: true
                              });
                              setShowField(true);
                            }}
                            className='pr-5 cursor-pointer hover:underline'>
                            Add to project
                          </div>
                          <div
                            onClick={() => {
                              dispatch(deleteWorkspaceCustomField(item?._id, workspace?._id));
                            }}
                            className='pr-5 cursor-pointer hover:underline text-red-500'>
                            Remove
                          </div>
                        </div>
                      </div>
                    )
                  })}

                  {workspaceCustomFields?.length === 0 && <div className="col-span-full font-16">You have 0 custom fields, let's create one!</div>}
                </div>
              </div>
            )}
            {selectedIndex === 2 && (
              <div>
                <div className="flex align-center justify-between mb-5">
                  <div className='font-20 font-bold text-slate-700 mr-5'>{options[selectedIndex]}</div>
                  {/* {!showField && <Button
                    name="Add Custom Field"
                    src="addblack.svg"
                    height="12px"
                    class="font-14"
                    onClick={() => {
                      setShowField(true);
                    }}
                  />} */}
                </div>
                <Dropdown
                  className={`flex items-center space-x-4 hover:bg-slate-50 px-5 py-2`}
                  trigger={['click']}
                  menu={{
                    items: workspaceProjects?.map(project => ({
                      key: project?._id,
                      label: project?.title,
                      onClick: async () => {
                        setSelectedProject(project);
                        // await dispatch(getProjectById(project?._id, (newProject) => {
                        //   setSelectedProject(newProject);
                        // }));
                      }
                    })),
                  }}
                  placement='bottom'
                >
                  <div className='w-1/3 flex align-center justify-between bg-white text-sm font-medium text-slate-800 px-4 py-2 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out"'>
                    <div className='font-14'>{selectedProject ? selectedProject?.title : 'Select Project'}</div>
                    <img
                      src={'/images/icons/show-more.svg'}
                      alt="icon"
                    />
                  </div>
                </Dropdown>
                <div className='overflow-y-scroll mt-5'>
                  <ProjectCustomFields
                    project={selectedProject}
                    editOverride={(item) => {
                      setSelectedField({
                        type: item?.type,
                        name: item?.name,
                        possibleValues: item?.possibleValues,
                        _id: item?._id,
                        isUpdate: true,
                        multi: item?.multi,
                        project: selectedProject
                      });
                      setShowField(true);
                    }}
                    workspace={workspace}
                    customFields={props?.customFields} />
                </div>
              </div>
            )}
            {selectedIndex === 3 && (
              <div>
                <div className="flex justify-between align-center mb-5">
                  <div>
                    <div className='font-20 font-bold text-slate-700'>{options[selectedIndex]}</div>
                    <div className='font-16'>Fields automatically generated using A.I. based on your workspace industry - <span className='text-indigo-500'>{industry}</span> </div>
                  </div>
                  {fields?.length == 0 && <Button
                    name="Show more!"
                    src="addblack.svg"
                    height="12px"
                    class="font-14"
                    onClick={() => {
                      generateCustomFields();
                    }}
                  />}
                </div>
                {!isLoading ? <div className="grid grid-cols-12 gap-6 h-[50vh]" style={{ overflowY: 'scroll' }}>
                  {fields?.length === 0 && <div className="col-span-full font-16">No custom fields found, try to generate some more!</div>}
                  {fields && fields?.map(customField => (
                    <div
                      onClick={() => {
                        setSelectedField({
                          type: !!customField?.possibleValues ? 'dropdown' : 'text',
                          name: customField?.name,
                          possibleValues: customField?.possibleValues,
                          _id: customField?._id,
                          multi: customField?.multi
                        });
                        setShowField(true);
                      }}
                      className={`col-span-6 hover:bg-indigo-100 cursor-pointer bg-white shadow-lg rounded-sm border ${(customField?._id == selectedField?._id && showField) ? 'border-indigo-400 shadow-indigo-100 border-2' : 'border-slate-200'}`}>
                      <div className="flex flex-col p-5">
                        <header>
                          <div className="flex items-center justify-between">
                            <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-indigo-500">
                              <svg className="w-9 h-9 fill-current text-rose-50" viewBox="0 0 36 36">
                                <path d="M25 24H11a1 1 0 01-1-1v-5h2v4h12v-4h2v5a1 1 0 01-1 1zM14 13h8v2h-8z" />
                              </svg>
                            </div>
                          </div>
                        </header>
                        <div className="grow mt-2">
                          <div className="inline-flex text-slate-800 hover:text-slate-900" to={props?.link}>
                            <h2 className="text-xl leading-snug font-semibold">{customField?.name}</h2>
                          </div>
                          <div className="font-16 my-4">{customField?.description}</div>
                          {customField?.possibleValues?.length > 0 && <div className="font-14 mt-2"><span className="font-black font-16">Options: </span>{customFieldsToString(customField?.possibleValues)}</div>}
                        </div>
                        <footer className="mt-5">
                          <div className="flex justify-between items-center">
                            <div>
                              <div className={`text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1  bg-blue-100`}>{customField?.possibleValues?.length > 0 ? 'Dropdown' : 'Input'}</div>
                            </div>
                            <div>
                              <div className="text-sm font-medium text-indigo-500 hover:text-indigo-600" to={props?.link}>View -&gt;</div>
                            </div>
                          </div>
                        </footer>
                      </div>
                    </div>
                  ))}
                </div> : <Loader isLoading newLoader className={'left-[40%] mt-[50px]'} />}
              </div>
            )}

          </div>

          <CustomFieldPanel
            showField={showField}
            field={selectedField}
            setShowField={setShowField}
            workspaceProjects={workspaceProjects}
            workspace={workspace}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  project: state.projectReducer.getProjects,
  user: state.userReducer.user,
  fields: state.aiReducer.customFieldSuggestions,
  workspace: state.workspaceReducer.currentWorkspace,
  workspaceProjects: state.workspaceReducer.workspaceProjects,
  workspaceCustomFields: state.workspaceReducer.workspaceCustomFields,
  customFields: state.projectReducer.projectCustomFields,
});

export default connect(mapStateToProps)(WorkspaceFieldsModal);
