import React, { useState } from 'react';

function StepThree({ onChange, handleClick, onBack, isCreating, setIsPrivate }) {

  const [isPublic, setIsPublic] = useState(true);

  return (
    <div className="px-4 py-8">
      <div className="max-w-md mx-auto">

        <h1 className="text-3xl text-slate-800 font-bold mb-6">Let's create your first project!</h1>
        {/* Form */}
        <div className="sm:flex space-y-3 sm:space-y-0 sm:space-x-4 mb-8">
          <label className="flex-1 relative block cursor-pointer">
            <input type="radio" name="radio-buttons" className="peer sr-only" checked={isPublic} onChange={() => {
              setIsPublic(true);
              setIsPrivate(false);
            }} />
            <div className="h-full flex flex-col justify-center align-center text-center bg-white px-4 py-6 rounded border border-slate-200 hover:border-slate-300 shadow-sm duration-150 ease-in-out">
              <img src="/images/icons/unlock.svg" className="h-8 w-8 mb-2" alt="error" />
              <div className="font-medium text-slate-800 mb-1">Public Project</div>
              <div className="text-sm">Anyone from workspace can join this project</div>
            </div>
            <div className="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 rounded pointer-events-none" aria-hidden="true"></div>
          </label>
          <label className="flex-1 relative block cursor-pointer">
            <input type="radio" name="radio-buttons" className="peer sr-only" checked={!isPublic} onChange={() => {
              setIsPublic(false);
              setIsPrivate(true);
            }} />
            <div className="h-full text-center flex flex-col justify-center align-center bg-white px-4 py-6 rounded border border-slate-200 hover:border-slate-300 shadow-sm duration-150 ease-in-out">
              <img src="/images/icons/lock.svg" className="h-8 w-8 mb-2" alt="error" />
              <div className="font-medium text-slate-800 mb-1">Private Project</div>
              <div className="text-sm">Only those invited can join this project</div>
            </div>
            <div className="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 rounded pointer-events-none" aria-hidden="true"></div>
          </label>
        </div>
        <div className="flex items-center justify-between space-x-6 mb-8">
          <div className='w-full'>
            <input
              type='text'
              placeholder='Project name...'
              onChange={onChange}
              maxLength={35}
              className="w-full flex items-center bg-white text-sm font-medium text-slate-800 p-4 rounded border border-blue-400 hover:border-blue-300 shadow-sm duration-150 ease-in-out" />
          </div>
          <div className="flex items-center">
            <div className="form-switch">
              <input type="checkbox" id="switch" className="sr-only" defaultChecked />
              <label className="bg-slate-400" htmlFor="switch">
                <span className="bg-white shadow-sm" aria-hidden="true"></span>
                <span className="sr-only">Switch label</span>
              </label>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div
            onClick={onBack}
            className="text-16 text-blue-500 underline hover:no-underline px-2 border-radius10 cursor-pointer">Go Back</div>
          <div
            onClick={handleClick}
            className={`btn bg-blue-500 hover:bg-blue-400 text-white ml-auto p-2 px-5 rounded-md cursor-pointer ${isCreating && 'disabled'}`}>Create</div>
        </div>

      </div>
    </div>
  );
}

export default StepThree;