import React, { useEffect } from 'react';
import { connect, useDispatch } from "react-redux";

import ProjectCustomFields from '../molecules/project-dashboard/ProjectCustomFields';

import { getProjectById } from '../../../actions';

const ProjectCustomFieldsTab = (props) => {
  const dispatch = useDispatch();
  const { project } = props;

  useEffect(() => {
    document.querySelectorAll('.topNavbarLink').forEach((item) => {
      item.classList.remove('topNavBar-activeLink');
    });
    document
      .querySelector(`.customFieldsTopNavbarLink`)
      ?.classList?.add('topNavBar-activeLink');
  }, []);

  useEffect(() => {
    if (!project?.participants) {
      const { 1: projectId, 2: path } = window.location.pathname.split("/");
      dispatch(getProjectById(projectId));
    }

  }, []);

  return (
    <div style={{ margin: 20 }}>
      <ProjectCustomFields project={project} fields={props?.fields} workspace={props?.workspace} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.projectReducer.getProjects,
  user: state.userReducer.user,
  fields: state.aiReducer.customFieldSuggestions,
  workspace: state.workspaceReducer.currentWorkspace
});

export default connect(mapStateToProps)(ProjectCustomFieldsTab);
